import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";

import TotalTips from "../../../assets/images/dashboard/TotalTips.svg";
import MyEarnings from "../../../assets/images/dashboard/MyEarnings.svg";
import TotalRestaurantsBar from "../../../assets/images/dashboard/TotalRestaurantsBar.svg";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  No_Of_RestaurantList,
  No_Of_Total_Tips,
  No_Of_Request_Recieved,
  Recent_Tip_List,
  getGraphDataList,
} from "../service/Auth.service";
import { Link, useNavigate } from "react-router-dom";
import ApexChart from "react-apexcharts";

function Dashboard() {
  const [noofrestaurantList, setNo_Of_RestaurantList] = useState();
  const [nooftotaltips, setNo_Of_TotalTips] = useState();
  const [noofrequestrecieved, setNo_Of_Request_Recieved] = useState();
  const [recenttiplist, setNo_Of_Recenttiplist] = useState();

  const maxDate = new Date().toISOString().split('T')[0];
  //Graph Related State
  const [graphData, setGraphData] = useState();
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .substring(0, 10)
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
 

  const userdata = useSelector((state) => state);
  let adminRole = userdata?.login?.data?.role;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    getnoofrestaurantlist();
    getnototaltips();
    getnoofrequestrecieved();
    getrecenttiplist();
    getGraphData(startDate, endDate);
  }, [dispatch]);
  const getnoofrestaurantlist = () => {
    No_Of_RestaurantList(
      {},
      (data) => {
        setNo_Of_RestaurantList(data?.length);
      },
      dispatch
    );
  };
  const getnototaltips = () => {
    No_Of_Total_Tips(
      {},
      (data) => {
        setNo_Of_TotalTips(data);
      },
      dispatch
    );
  };
  const getnoofrequestrecieved = () => {
    No_Of_Request_Recieved(
      {},
      (data) => {
        setNo_Of_Request_Recieved(data?.length);
      },
      dispatch
    );
  };
  const getrecenttiplist = () => {
    Recent_Tip_List(
      {},
      (data) => {
        setNo_Of_Recenttiplist(data?.docs);
      },
      dispatch
    );
  };
  /*----------@Api Call for getting graph related Data----------- */
  const getGraphData = (start_date, end_date) => {
    getGraphDataList(
      { start_date, end_date },
      (data) => {
        setGraphData(data);
      },
      dispatch
    );
  };
  /*----------@Bar graph Series and Options----------- */
  const series = [
    {
      name: "Donation",
      data: graphData && graphData?.map((data) => data?.donation_amount),
    },
    {
      name: "Service Fees",
      data: graphData && graphData?.map((data) => data?.service_charge),
    },
    {
      name: "Group Tip",
      data: graphData && graphData?.map((data) => data?.groupTipAmount),
    },
    {
      name: "Staff Tip",
      data: graphData && graphData?.map((data) => data?.staffTipAmount),
    },
  ];
  const options = {
    chart: {
      type: "bar",
      scrollable: true,
      scrollX: true, // enable scrolling for X-axis labels
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: graphData && graphData?.map((data) => data?._id),
    },
  };
  // weekday[new Date(data?._id).getDay()]),
  /*--------@Handler function for setting Start Date----------- */
  const handleStartDate = (newDate) => {
    setStartDate(newDate.target.value);
  };
  /*--------@Handler function for setting End Date----------- */
  const handleEndDate = (newDate) => {
    setEndDate(newDate.target.value);
    getGraphData(startDate, newDate.target.value);
  };
  var totalTiprecieved = 0;
  for (var i = 0; i < nooftotaltips?.length; i++) {
    totalTiprecieved += nooftotaltips[i]?.total_tip_amount;
  }

  var totalEarnings = 0;
  for (var j = 0; j < nooftotaltips?.length; j++) {
    totalEarnings += nooftotaltips[j]?.service_charge;
  }
  var totaldonationamount = 0;
  for (var k = 0; k < nooftotaltips?.length; k++) {
    totaldonationamount += nooftotaltips[k]?.donation_amount;
  }
  var totalEarning = 0;
  totalEarning = totalEarnings + totaldonationamount;
  const buttonNavigateHandlerpage = async () => {
    navigate("/super-admin/total-earning");
  };

  const buttonNavigatePubsHandler = async () => {
    navigate("/super-admin/totalpubs");
  };
  const buttonNavigateTotalTipReceivedHandler = async () => {
    navigate("/super-admin/total-tipreceived");
  };
  const buttonNavigateRequestReceivedHandler = async () => {
    navigate("/super-admin/request-received");
  };
  if (adminRole == 0) {
    return (
      <>
        <h2 className="page-title">Dashboard</h2>

        <div className="content-wraper">
          <Row className="dasdboard-card">
            <Col>
              <Card
                className="mb-3"
                onClick={(e) => buttonNavigateHandlerpage()}
              >
                <Card.Body>
                  <div className="icon-outer">
                    <img src={MyEarnings} />
                  </div>
                  <h5>$ {totalEarning}</h5>
                  <p>My Earnings</p>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card
                className="mb-3"
                onClick={(e) => buttonNavigatePubsHandler()}
              >
                <Card.Body>
                  <div className="icon-outer">
                    <img src={TotalRestaurantsBar} />
                  </div>
                  <h5>{noofrestaurantList}</h5>
                  <p>Total Pubs</p>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card
                className="mb-3"
                onClick={(e) => buttonNavigateTotalTipReceivedHandler()}
              >
                <Card.Body>
                  <div className="icon-outer">
                    <img src={TotalTips} />
                  </div>
                  <h5>$ {totalTiprecieved}</h5>
                  <p>Total Tips Received</p>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card
                className="mb-3"
                onClick={(e) => buttonNavigateRequestReceivedHandler()}
              >
                <Card.Body>
                  <div className="icon-outer">
                    <img src={MyEarnings} />
                  </div>

                  <h5>{noofrequestrecieved}</h5>
                  <p>Requets Received</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <div className="table-wraper">
            <div className="top-action">
              <InputGroup className="mb-3 justify-content-between">
                <p></p>
                <Form.Group controlId="dob" className="from-to">
                  {/* <select className="form-control mx-2">
                    <option>Week</option>
                    <option>Month</option>
                    <option>Year</option>
                  </select> */}
                  <Form.Label>From</Form.Label>
                  <Form.Control
                    type="date"
                    name="dob"
                    value={startDate}
                    max={maxDate}
                    placeholder="Date of Birth"
                    onChange={handleStartDate}
                  />
                  <Form.Label>to</Form.Label>
                  <Form.Control
                    type="date"
                    name="dob"
                    value={endDate}
                    max={maxDate}
                    placeholder="Date of Birth"
                    onChange={handleEndDate}
                  />
                </Form.Group>
              </InputGroup>
            </div>
            <div id="chart">
              <ApexChart
                id="mychart"
                options={options}
                series={series}
                type="bar"
                width="100%"
                height="340px"
              />
            </div>
          </div>

          <div className="table-wraper mt-4">
            <div className="top-action">
              <p>Recents Tips</p>
            </div>

            <Table responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Customer</th>
                  <th>Restaurant</th>
                  <th>Type</th>
                  <th>Tipping Amount ($)</th>

                  <th></th>
                </tr>
              </thead>
              <tbody>
                {recenttiplist &&
                  recenttiplist?.map((data, index) => {
                    const date = new Date(data?.createdAt);

                    // Extract the day, month, and year values
                    const day = date.getDate().toString().padStart(2, "0");
                    const month = (date.getMonth() + 1)
                      .toString()
                      .padStart(2, "0");
                    const year = date.getFullYear();

                    // Format the date as dd/mm/yyyy
                    const formattedDate = `${day}/${month}/${year}`;
                    return (
                      <tr key={index}>
                        <td>{formattedDate}</td>
                        <td>
                          <p>
                            {data?.is_guest_tip == true
                              ? data?.guest?.guest_name
                              : data?.customerDetails?.full_name}
                          </p>
                          <p>
                            {data?.is_guest_tip == true
                              ? data?.guest.guest_email
                              : data?.customerDetails?.email}
                          </p>
                        </td>
                        <td>{data?.restroDetails?.restaurant_name}</td>
                        <td>
                          {data?.is_group_tip == true
                            ? "Group Tip"
                            : "Staff Tip"}
                        </td>
                        <td>{data?.total_tip_amount}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {recenttiplist?.length == 0 ? "No Records found" : ""}
            </div>
            <div className="text-end"> 
              <Link to="/super-admin/total-tipreceived" className="custom-link">View all</Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Dashboard;
