/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../features/homePages/common/HomeHeader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { restrodetails } from "../staffInterface/Slice";

import { API_BASEURL } from "../../environment";
import _ from "underscore";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { pagination } from "../../components/common/utils/message";
import {
  RestroAcceptedRequestList,
  fetchDashboardData,
  filterDashboardData,
  getRestroList,
  getnoCustomerTipList,
} from "../staffInterface/service/Auth.service";
import { searchData } from "../customerInterface/Service/Auth.Service";
import DefaultImage from "../../assets/images/defaultimage.png";
import Progressbar from "../../components/common/ProgrssBar/ProgressBar";
import { VerticleChart } from "../../components/common/VerticleBarChart/VerticleBarChart";
import restro1 from "../../assets/images/restro-1.jpg";
import { faker } from "@faker-js/faker";
const StaffHome = () => {
  const dispatch = useDispatch();
  const [resPayload, setResPayload] = useState();
  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const [restroacceptRequestdata, setAcceptRestroRequestdata] = useState([]);
  console.log("dashboardData",restroacceptRequestdata)
  const [totalPages, settotalPages] = useState();
  const [data, setData] = useState([]);
  const [modData, setModData] = useState(false);
  const [model, setModel] = useState(false);
  const navigate = useNavigate();
  const userdata = useSelector((state) => state);
  const [searchDataItem, setSearchDataItem] = useState([]);
  const [dashboardData, setDashboardData] =useState([]);
  const [filterData, setFilterData] =useState([]);
  const [values, setValues] = React.useState({
    staff_id: userdata?.login?.data?.result?._id , resto_id:"" , sort:"" , days:30
});

const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });   
};
useEffect(()=>{
  _filterDashboardData()
},[values.sort , values.days, values.resto_id])
  let staff_id1 = userdata?.login?.data?.result._id;
  let staff_id;
  let loginRole = userdata?.login?.data?.role;
  let user_id;
  if (loginRole == 3) {
    staff_id = userdata?.login?.data?.result?._id;
  }
  if (loginRole == 0) {
    staff_id = userdata?.adminstaff?.data?._id;
  }
  useEffect(() => {
    _getListing();
    _getRestroAcceptedRequestList();
    _filterDashboardData()
    _fetchDashboardData()
  }, [dispatch]);

  
  const _fetchDashboardData = () => {
    fetchDashboardData (
      values,
      (data) => {
        setDashboardData(data?.data);
      },
      dispatch
    );}

  const _filterDashboardData = () => {
    filterDashboardData(
      values,
      (data) => {
        setFilterData(data?.data)
      },
      dispatch
    );}

     var totalcustomertip = 0;
  const customerIdCounts = {};
  let  dupliactePercentage = 0
  const chartData = Array(12).fill(0);
    
     if(dashboardData.length !== 0){
    for (var i = 0; i < dashboardData?.statData.length; i++) {
if(dashboardData?.statData[i]?.tip_amount.length > 0)totalcustomertip += dashboardData?.statData[i]?.tip_amount[0]
else totalcustomertip += dashboardData?.statData[i]?.tip_amount ;
  }

  for (const entry of dashboardData?.statData) {
    const customerId = entry.customer_id;
    if (customerId in customerIdCounts) {
      customerIdCounts[customerId]++;
    } else {
      customerIdCounts[customerId] = 1;
    }
  }
  const uniqueCount = Object.keys(customerIdCounts).length;
  dupliactePercentage  = ((dashboardData?.statData.length - uniqueCount)/dashboardData?.statData.length)*100

  if(dashboardData?.statData.length >0){
    dashboardData?.statData.forEach((entry) => {
      const monthIndex = new Date(entry.createdAt).getMonth() 
      chartData[monthIndex] +=1;
    });
      }
     }

var tempTotalTips = 0
for (var j = 0; j < filterData?.length; j++) {
  if(filterData[j]?.tip_amount.length >0)tempTotalTips += filterData[j]?.tip_amount[0];
  else tempTotalTips += filterData[j]?.tip_amount}

  var totalMessage = 0
for (var j = 0; j < filterData?.length; j++) {
  if(filterData[j]?.customer_comment!== "")totalMessage +=1;
}

  

  const _getListing = () => {
    getRestroList(
      { page, limit },
      (data) => {
        setResPayload(data?.result?.docs);
        settotalPages(data?.result?.totalPages);
      },
      dispatch
    );
  };

  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await getRestroList(
      { page: currentPage, limit },
      (response) => {
        setResPayload(response?.result?.docs);
      },
      dispatch
    );
  };

  const _allListing = (Key = "") => {
    if (Key === "") {
      setModel(false);
    } else {
      setModel(true);
      searchData({ Key, page, limit }, (resdata) => {
        setData(resdata?.result);
      });
    }
  };

  const _getRestroAcceptedRequestList = () => {
    RestroAcceptedRequestList(
      { page, limit, staff_id },
      (data) => {
        setAcceptRestroRequestdata(data?.docs);
      },
      dispatch
    );
  };

  const buttonHandlerpage = async (data) => {
    navigate(`/staff/restorequest/${data?._id}`);
  };
  const buttonHandlerpage1 = async (data) => {
    dispatch(restrodetails(data));

    navigate(`/staff/restaurantprofilescrren/${data?.userDetails?._id}`);
  };

  const buttonviewHandlerpage = async (data) => {
    setSearchDataItem(data);
    setModData(true);
  };

  // let pubList = (restroacceptRequestdata).length > 0
  // && (restroacceptRequestdata).map((item, i) => {
  //     return (
  //         <option key={i} value={item.resto_id}>{item.userDetails.restaurant_name}</option>
  //     )
  // }, this);

  return (
    <>
      <Header />
      {/* <SearchFilter /> */}

      
      <div className="row my-3">
          <div className="col">
            <div className="toolsContainer">
              <div className="row justify-content-center">
                <div className="col-md-4 mt-3 mt-md-0">
                  <div className="searchBox">
                    <input
                      type="search"
                      name="Key"
                      onChange={(e) => _allListing(e.target.value)}
                      className="form-control"
                      placeholder="search pubs"
                    />
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <div
                      className={model ? "serchResults" : "serchResults d-none"}
                      style={{
                        maxHeight: "500px",
                        overflowY: "auto",
                      }}
                    >
                      {/* <div className={  modData   ? "d-none" : "serchResults" }> */}
  
                      {modData == false ? (
                        <>
                          {data?.length ? 
                            data?.map((item,index) => {
                              let path = `${API_BASEURL}/public/posts/${item?.image}`;
                              return (
                                <div key={index}>
                                  {item.role == 2 ? (
                                    <div className="result">
                                      <div className="roundImage">
                                        {/* <img src="./assets/searchresults.png" alt="" /> */}
                                        <img
                                          src={
                                            item && item?.image != undefined
                                              ? path
                                              : "ddd"
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div
                                        className="text "
                                        onClick={(e) =>
                                          buttonviewHandlerpage(data)
                                        }
                                      >
                                        <p className="mb-0">
                                          {item.restaurant_name
                                            ? item.restaurant_name
                                            : item.full_name}{" "}
                                        </p>
  
                                        {/* <div className="nextBtn" onClick={(e) => buttonviewHandlerpage(data)}> */}
                                        {/* <i className="fa-solid fa-arrow-right"></i> */}
                                        {/* </div> */}
                                        {/* <p className="mb-0">{ item?.full_name }</p> */}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}{" "}
                                </div>
                              );
                            }): <p style={{color:"black", textAlign:"center", paddingTop:"10px"}}>No records found</p>}{" "}
                        </>
                      ) : (
                        " "
                      )}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
  
                {/* <div className="col-md-2 mt-3 mt-md-0">
                              <div className="filters">
                                <div className="card flex justify-content-center">
                                  <MultiSelect
                                    value={selectedCities}
                                    onChange={(e) => setSelectedCities(e.value)}
                                    options={cities}
                                    optionLabel="name"
                                    placeholder="City"
                                    maxSelectedLabels={3}
                                    className="w-full md:w-20rem"
                                  />
                                  <i class="fa-solid fa-location-dot"></i>
                                </div>
                              </div>
                            </div> */}
                {/* <div className="col-md-2 mt-3 mt-md-0">
                              <div className="filters">
                                <div className="card flex justify-content-center">
                                  <MultiSelect
                                    value={selectedFilters}
                                    onChange={(e) => setSelectedFilters(e.value)}
                                    options={filters}
                                    optionLabel="name"
                                    placeholder="Filters"
                                    maxSelectedLabels={3}
                                    className="w-full md:w-20rem"
                                  />
                                  <i class="fa-solid fa-arrow-up-wide-short"></i>
                                </div>
                              </div>
                            </div> */}
              </div>
            </div>
          </div>
        </div>
  
        <div className="container">
          <div className="row mt-4">
            <div className="col direction">
              <p class="mb-0">
                {/* Home <i class="fa-solid fa-angles-right"></i> */}
                <span></span>
              </p>
            </div>
          </div>
        </div>
  
        <div>
          <div className="searchList">
            <section className="searchListContent">
              {searchDataItem.length > 0 ? (
                <div className="container">
                  <div className="row mb-5">
                    <div className="StaffHomeScreen">
                      <section className="rearchResults">
                        <div className="container">
                          <div className="row ">
                            {/* <div className="col text-center">
                              <p class="noResults">Search Your Pubs Here.</p>
                            </div> */}
                          </div>
  
                          <div className="row my-5">
                            <div className="col-12">
                              <h2 className="Aeonik-Regular whiteText">Search Pubs</h2>
                              <p className="Aeonik-Regular whiteText">Find your local pub and pubs here </p>
                            </div>
                            {searchDataItem &&
                              searchDataItem?.map((data, index) => {
                                
                                let path1 = `${API_BASEURL}/public/posts/${data?.image}`;
                                return (
                                  <>
                                  {data?.is_updated  ? 
                                    (
                                  <div key={index} className="col-6">
                                    <div className="pubDetails whiteBackground">
                                      <div className="image">
                                        <img src={path1} alt="" />
                                      </div>
                                      <div className="text">
                                        <h3>{data?.restaurant_name}</h3>
                                        <p className="mb-1">
                                          <i className="fa-solid fa-location-dot blackText"></i>{" "}
                                          {data?.userDetails?.addressLine1},
                                          <span>
                                            {" "}
                                            {data?.city} ({data?.postcode})
                                          </span>
                                        </p>
                                        <p className="mb-1">
                                          Status - <span>{data?.status ? "Active" : "In Active"}</span>
                                        </p>
                                        {/* <p className="mb-0">
                                          Your Role - <span>Staff</span>
                                        </p> */}
                                      </div>
                                      <div className=" dflex cursorPointer" onClick={(e) => buttonHandlerpage(data)} style={{justifyContent:"end",marginLeft:"auto",gap:"5px"}}>View Profile <i class="fa-solid fa-angles-right "style={{marginTop:"4px"}}></i></div>
                                      {/* <div
                                        className="next"
                                        onClick={(e) => buttonHandlerpage(data)}
                                      >
                                        <i class="fa-solid fa-arrow-right"></i>
                                      </div> */}
                                    </div>
                                  </div>
                                    )
                                  : null }
                                </>);
                              })}
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="container">
                  <div className="row mb-5 whiteBackground borderRadius paddingBottom">
                    {restroacceptRequestdata?.length == 0 ? (
                      resPayload?.map((data, index) => {
                        let path = `${API_BASEURL}/public/posts/${data?.image}`;
                        return (
                          <div key={index} className="col-lg-3 col-md-3 col-sm-6" onClick={(e) => buttonHandlerpage(data)}>
                            <div className="searchContent" style={{minHeight:"350px"}}>
                              {/* <img src="../../assets/image.png" alt="" /> */}
                              <img
                                src={
                                  data && data?.image != undefined ? path : "ddd"
                                }
                                height={204}
                              />
  
                              <div className="text">
                                <h4>{data?.restaurant_name}</h4>
                                <p>
                                  <i className="fa-solid fa-location-dot blackText"></i>{" "}
                                  {data?.addressLine1},<span> {data?.city}</span>
                                </p>
                              </div>
                              <div className="nextBtn">
                                <i
                                  className="fa-solid fa-arrow-right"
                                  onClick={(e) => buttonHandlerpage(data)}
                                ></i>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="StaffHomeScreen">
                        <section className="rearchResults">
                          <div className="container">
                            <div className="row ">
                              <div className="col text-center">
                                <p class="noResults">
                                  {/* Currently you are not part of any pub.
                                  Search above to find pub and send request
                                  to associate with it. */}
                                </p>
                              </div>
                            </div>
  
                            <div className="row my-5">
                              <div className="col-12">
                                <h2 className="Aeonik-Regular blackText">My Pubs</h2>
                                <p className="Aeonik-Regular blackText">Find your local pub and pubs here </p>
                              </div>
                              {restroacceptRequestdata &&
                                restroacceptRequestdata?.map((data, index) => {
                                  let path1 = `${API_BASEURL}/public/posts/${data?.userDetails?.image}`;
                                  return (
                                    <div key={index} className="col-12 " style={{cursor:"pointer"}}>
                                      <div className="pubDetails whiteBackground">
                                        <div className="image"  onClick={(e) =>
                                            buttonHandlerpage1(data)
                                          }>
                                          <img src={path1} alt="" />
                                        </div>
                                        <div className="text"  onClick={(e) =>
                                            buttonHandlerpage1(data)
                                          }>
                                          <h3>
                                            {data?.userDetails?.restaurant_name}
                                          </h3>
                                          <p className="mb-1">
                                            <i className="fa-solid fa-location-dot blackText"></i>{" "}
                                            {data?.userDetails?.addressLine1},
                                            <span>
                                              {" "}
                                              {data?.userDetails?.city} (
                                              {data?.userDetails?.postcode})
                                            </span>
                                          </p>
                                          <p className="mb-1">
                                          Status - <span>{data?.status ? "Active" : "In Active"}</span>
                                          </p>
                                          <p className="mb-0">
                                            Your Role - <span>Pubstar</span>
                                          </p>
                                        </div>
                                        <div
                                          className="next "
                                          onClick={(e) =>
                                            buttonHandlerpage1(data)
                                          }
                                          
                                        >
                                          <i class="fa-solid fa-arrow-right "></i>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </section>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </section>
            {/* <Footer /> */}
          </div>
        </div>
        <div className="row mb-5">
          <div className="col">
            <nav aria-label="Page navigation example" class="paginationRow">
              {totalPages > 1 ? (
                <ReactPaginate
                  breakLabel={"..."}
                  nextLabel={"Next >"}
                  onPageChange={handlePageChange}
                  pageCount={totalPages}
                  previousLabel={"< Back"}
                  renderOnZeroPageCount={null}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              ) : null}
            </nav>
          </div>
        </div>      
    </>
  );
};

export default StaffHome;
