import React from 'react';
import HomeLayout from '../../../components/common/layout/HomeLayout';
// import AboutUs from '../AboutUs';
// import BuildingOwners from '../BuildingOwners';
// import LibraryOfTerms from '../LibraryOfTerms';
import MainHome from '../MainHome';
import ProfileSetting from '../common/ProfileSetting';
// import MaterialManufacturers from '../MaterialManufacturers';
// import RoofingContractors from '../RoofingContractors';
// import Faq from '../Faq';
import Dashboard from '../../super-admin/components/Dashboard';
import AdminLayout from '../../../components/common/layout/AdminLayout';
import FaqManagement from '../../super-admin/components/content-management/FaqManagement';
import LandingPage from '../../super-admin/components/content-management/LandingPage';
import TotalPubs from '../../super-admin/components/pub-management/TotalPubs';
import AddFaq from '../../super-admin/components/content-management/AddFaq';
import Donation from '../../super-admin/components/payment-management/Donation';
import PaymentHistory from '../../super-admin/components/payment-management/PaymentHistory';
import ServiceFees from '../../super-admin/components/payment-management/ServiceFees';
import SetupServiceFees from '../../super-admin/components/payment-management/SetupServiceFees';
import Howitworks from '../../super-admin/components/content-management/HowItWorks';
import AddHowitworks from '../../super-admin/components/content-management/AddHowitworks';
import StaffManagement from '../../super-admin/components/staff-management/staffmanagement';
import CustomerManagement from '../../super-admin/components/customer-management/CustomerManagement';
import NotificationList from '../common/NotificationList';
import PrivacyPolicy from '../PrivacyPage';
import SearchPubstar from '../../SearchPubsatr/SearchPubstar';
import Legal from '../common/Legal';
import Cookie from '../common/CookiePolicies';
import Rights from '../common/Rights';
import MainHomeContactUs from '../common/MainContactPage';
import MainHomeAboutUs from '../common/MainAboutPage';
import PrivacyPolicies from '../common/PrivacyPolicies';
const HomePagesRoutes = [
    {
        path: `/`,
        layout: HomeLayout,
        component: <  MainHome />
    },
    {
        path: `/home`,
        layout: HomeLayout,
        component: <  MainHome />
    },
    {
        path: `/pubstarSearch`,
        layout: HomeLayout,
        component: <  SearchPubstar />
    },
    {
        path: `/privacy_policy`,
        layout: HomeLayout,
        component: <Legal />
    },
    {
        path: `/terms_conditions`,
        layout: HomeLayout,
        component: <PrivacyPolicies/>
    },
    {
        path: `/contactUs`,
        layout: HomeLayout,
        component: <MainHomeContactUs />
    },
    {
        path: `/aboutus`,
        layout: HomeLayout,
        component: <MainHomeAboutUs />
    },
    {
        path: `/cookiePolicy`,
        layout: HomeLayout,
        component: <  Cookie />
    },
    {
        path: `/rights`,
        layout: HomeLayout,
        component: <  Rights />
    },
    

    {
        path: `/profilesetting`,
        layout: HomeLayout,
        component: <  ProfileSetting />
    },
    {
        path: `/notificationlist`,
        layout: HomeLayout,
        component: <  NotificationList />
    },
    {
        path: `/privacypolicy`,
        layout: HomeLayout,
        component: < PrivacyPolicy />
    },
    // {
    //     path: `/library-of-terms`,
    //     layout: HomeLayout,
    //     component: <  LibraryOfTerms />
    // },
    // {
    //     path: `/material-manufacturers`,
    //     layout: HomeLayout,
    //     component: <  MaterialManufacturers />
    // },
    // {
    //     path: `/roofing-contractors`,
    //     layout: HomeLayout,
    //     component: <  RoofingContractors />
    // },
    // {
    //     path: `/about-us`,
    //     layout: HomeLayout,
    //     component: <  AboutUs />
    // },
    // {
    //     path: `/faq`,
    //     layout: HomeLayout,
    //     component: < Faq />
    // }
]

export default HomePagesRoutes;