import axios from "axios";
import { apiUrl, PORT } from "../../../environment";
import { loadingAction } from "../../../components/common/loader/LoaderSlice";
import { getInfo } from "../../../features/auth/service/Auth.header";
import queryString from "query-string";

const Token = getInfo();
let axiosConfig = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${Token?.Token}`,
  },
};

export const getByUserProfile = async (param, callback, dispatch) => {
  console.log("param" , param)
  try {
    axios
      .get(
        `${apiUrl}${PORT}/users/getbyid?${queryString.stringify(param)}`,
        axiosConfig
      )
      .then((response) => {
        callback(response?.data?.result);
      })
      .catch((error) => {
        callback(false);
      });
  } catch (err) {}
};

export const updateProfilepage = async (formData, callback , dispatch) => {
  try {
    dispatch(loadingAction(true));
    axios
      .put(`${apiUrl}${PORT}/users/updateprofile`, formData)
      .then((response) => {
        callback(response.data);
        dispatch(loadingAction(false));
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
  } catch (err) {}
};


export const connectStripe = async (values, callback , dispatch) => {
  try {
    dispatch(loadingAction(true));
    axios
      .post(`${apiUrl}${PORT}/users/connectStripe`, values)

      .then((response) => {
        dispatch(loadingAction(false));
        callback(response.data);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
  } catch (err) {
    console.log("sadsadsad" ,err)
  }
};
export const detachStripe = async (values, callback , dispatch) => {
  try {
    dispatch(loadingAction(true));
    axios
      .post(`${apiUrl}${PORT}/users/detachPaypal`, values)

      .then((response) => {
        dispatch(loadingAction(false));
        callback(response.data);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
  } catch (err) {
    console.log("sadsadsad" ,err)
  }
};
export const connectPaypal = async (values, callback , dispatch) => {
  try {
    dispatch(loadingAction(true));
    axios
      .post(`${apiUrl}${PORT}/users/connectPaypal`, values)

      .then((response) => {
        dispatch(loadingAction(false));
        callback(response.data);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
  } catch (err) {
    console.log("sadsadsad" ,err)
  }
};




export const Sendotpbyupdate = async (param, callback, dispatch) => {
  try {
    const response = await axios.post(
      `${apiUrl}${PORT}/users/sendotp`,
      param,
      axiosConfig
    );
    if (response) {
      return response;
    } else {
      return response;
    }
  } catch (e) {
    return null;
  }
};

export const GetLandingPageData = async (param, callback, dispatch) => {
  try {
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/landingpage/getlandingpagedata?${queryString.stringify(
          param
        )}`
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
  } catch (err) {
    console.log(err);
  }
};



export const addContactPage = async (formdata, callback, dispatch) => {
  try {
    dispatch(loadingAction(true));
    axios
      .post(`${apiUrl}${PORT}/landingpage/adddashboardcontactus`, formdata)
      .then((response) => {
        callback(response);
        dispatch(loadingAction(false));
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
  } catch (err) {
    console.log(err);
  }
};

export const StaffSearchData = async (param, callback, Key, dispatch) => {
  try {
    const response = axios
      .get(
        `${apiUrl}${PORT}/landingpage/staffsearchall?${queryString.stringify(
          param,
          Key
        )}`,
        axiosConfig
      )

      .then((response) => {
        callback(response?.data);
      })
      .catch((error) => {
        callback(false);
      });
  } catch (err) {
    console.log(err);
  }
};

export const GetLandingPageFaq = async (param, callback, dispatch) => {
  try {
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/landingpage/getlandingpagefaq?${queryString.stringify(
          param
        )}`
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
  } catch (err) {
    console.log(err);
  }
};


export const GetLandingPageHowitworks = async (param, callback, dispatch) => {
  try {
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/landingpage/getlandingpagehowitworks?${queryString.stringify(
          param
        )}`
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
  } catch (err) {
    console.log(err);
  }
};
export const GetPubsterAndPub = async (param, callback, dispatch) => {
  try {
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/landingpage/getPubsterAndPub`)
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
  } catch (err) {
    console.log(err);
  }
};

export const GetTips = async (param, callback, dispatch) => {
  try {
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/landingpage/getTips`)
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
  } catch (err) {
    console.log(err);
  }
};

export const NotificationDeletes = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .delete(
          `${apiUrl}${PORT}/notification/notificationdelete?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response.data);
        })
        .catch((error) => {
          console.log("error", error);
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};
export const getStaffTip = async (
  param,
  callback,
  dispatch
) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/restaurant/getStaffTip?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getPubDashboardData = async (
  param,
  callback,
  dispatch
) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/restaurant/getPubDashboardData?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const deleteComment = async (
  param,
  callback,
  dispatch
) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/staff/deleteTipMessage?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};



export const likePost = async (param, callback, dispatch) => {
  try {
    console.log("axiosConfig" , axiosConfig)
  await axios.post(
      `${apiUrl}${PORT}/post/likePost`,
      param,
      axiosConfig
    ).then((response) => {
      dispatch(loadingAction(false));
      callback(response);
    })
    .catch((error) => {
      dispatch(loadingAction(false));
      callback(false);
    });
  } catch (e) {
    return null;
  }
};
export const commentPost = async (param, callback, dispatch) => {
  try {
  await axios.post(
      `${apiUrl}${PORT}/post/commentPost`,
      param,
      axiosConfig
    ).then((response) => {
      dispatch(loadingAction(false));
      callback(response);
    })
    .catch((error) => {
      dispatch(loadingAction(false));
      callback(false);
    });
  } catch (e) {
    return null;
  }
};
export const editPost = async (param, callback, dispatch) => {
  try {
  await axios.post(
      `${apiUrl}${PORT}/post/editPost`,
      param,
      axiosConfig
    ).then((response) => {
      dispatch(loadingAction(false));
      callback(response);
    })
    .catch((error) => {
      dispatch(loadingAction(false));
      callback(false);
    });
  } catch (e) {
    return null;
  }
};
export const deletePost = async (param, callback, dispatch) => {
  try {
  await axios.post(
      `${apiUrl}${PORT}/post/deletePost`,
      param,
      axiosConfig
    ).then((response) => {
      dispatch(loadingAction(false));
      callback(response);
    })
    .catch((error) => {
      dispatch(loadingAction(false));
      callback(false);
    });
  } catch (e) {
    return null;
  }
  
};

export const deletePostComment = async (param, callback, dispatch) => {
  try {
  await axios.post(
      `${apiUrl}${PORT}/post/deleteComment`,
      param,
      axiosConfig
    ).then((response) => {
      dispatch(loadingAction(false));
      callback(response);
    })
    .catch((error) => {
      dispatch(loadingAction(false));
      callback(false);
    });
  } catch (e) {
    return null;
  }
};
