import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";

import { useDispatch, } from "react-redux";
import {
  AddNotificationContent,
  Notification_details_Byid,
  updateNotification,
} from "../../service/Auth.service";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
function AddNotification() {
  const NotificationId = useParams();
  const [error, setErrors] = useState({});
  const [details, setDetails] = useState();
  const [status, setStatus] = useState();

  const [confirm, serConfirm] = useState({});
  const [formData, setFormData] = useState({
    type: "",
    title: "",
    notification_content: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
    } else {
      setErrors(errors);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    _getNotificationDetails();
  }, [dispatch]);

  const _getNotificationDetails = () => {
    Notification_details_Byid(
      { _id: NotificationId.id },
      (data) => {
        setDetails(data);
        setFormData({
          type: data?.type,
          title: data?.title,
          notification_content: data?.notification_content,
        });
      },
      dispatch
    );
  };

  const handleSubmitdata = async (e) => {
    handeblur();
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
      let reqData = {
        type: formData.type,
        title: formData.title,
        notification_content: formData.notification_content,
        status: status === "true" ? true : false,
      };
      try {
        AddNotificationContent(
          reqData,
          (result) => {
            if (result?.status == true) {
              toast.success(
                "Submit successfully",
                {
                  theme: "dark",
                },
                {
                  position: toast.POSITION.BOTTOM_RIGHT,
                },
                { autoClose: 1000 }
              );

              navigate("/super-admin/notification-list");
            } else if (result?.status === false) {
              toast.error(
                "Not submit",
                {
                  theme: "dark",
                },
                {
                  position: toast.POSITION.TOP_CENTER,
                },
                { autoClose: 1000 }
              );
            }
          },
          dispatch
        );
      } catch (e) {}
    }
  };

  const handleUpdatedata = async (e) => {
    handeblur();
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
      let reqData = {
        _id: NotificationId.id,
        type: formData.type,
        title: formData.title,
        notification_content: formData.notification_content,
        status: status === "true" ? true : false,
      };
      try {
        updateNotification(
          reqData,
          (result) => {
            if (result?.status == true) {
              toast.success(
                "Update successfully",
                {
                  theme: "dark",
                
                  position: toast.POSITION.BOTTOM_RIGHT,
                },
                { autoClose: 1000 }
              );

              navigate("/super-admin/notification-list");
            } else if (result?.status === false) {
              toast.error(
                "Not submit",
                {
                  theme: "dark",
             
                  position: toast.POSITION.BOTTOM_RIGHT,
                },
                { autoClose: 1000 }
              );
            }
          },
          dispatch
        );
      } catch (e) {}
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.type) {
      errors.type = "Type is required";
    }

    if (!formData.title) {
      errors.title = "Title is required";
    }
    if (!formData.notification_content) {
      errors.notification_content = "Notification Content is required";
    }

    return errors;
  };

  const handeblur = () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
    } else {
      setErrors(errors);
    }
  };
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };
  return (
    <>
      <h2 className="page-title">Notifications & Emails</h2>
      <div className="content-wraper">
        {NotificationId.id ? (
          <h4>Edit Notifications Setting</h4>
        ) : (
          <h4>Add Notifications Setting</h4>
        )}

        <Card>
          <Card.Body>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <label>
                    Type <sup></sup>
                  </label>
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="type"
                    value={details?.type}
                    onChange={handleChange}
                    onBlur={(e) => handeblur()}
                  />
                  {error.type && (
                    <span className="text-danger">{error.type}</span>
                  )}
                </Col>
                <Col md={6}>
                  <label>
                    Title <sup></sup>
                  </label>
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="title"
                    value={details?.title}
                    onChange={handleChange}
                    onBlur={(e) => handeblur()}
                  />
                  {error.title && (
                    <span className="text-danger">{error.title}</span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label>
                    Notification Content <sup>*(edit)</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="notification_content"
                    defaultValue={details?.notification_content}
                    onChange={handleChange}
                    onBlur={(e) => handeblur()}
                  />
                  {error.notification_content && (
                    <span className="text-danger">
                      {error.notification_content}
                    </span>
                  )}
                </Col>

                {/* <Col md={6}>
                  <label>
                    Status <sup>*</sup>
                  </label>
                  <select
                    name=""
                    id=""
                    className="form-control"
                    defaultValue={details?.status ? "true" : "false"}
                    onChange={handleStatusChange}
                  >
                    <option value="true">Active</option>
                    <option value="false">Not Active</option>
                  </select>
                </Col> */}
              </Row>
            </form>
            <div className="text-end">
              <Button
                variant="outline-secondary"
                className="mx-3"
                href="/super-admin/notification-list"
              >
                Cancel
              </Button>
              {NotificationId.id ? (
                <Button variant="primary" onClick={handleUpdatedata}>
                  Update
                </Button>
              ) : (
                <Button variant="primary" onClick={handleSubmitdata}>
                  Add
                </Button>
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
export default AddNotification;
