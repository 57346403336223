import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";

import { useDispatch, } from "react-redux";
import {
    AddEmailTemplate,
    EmailTemplate_details_Byid,
    updateEmailTemplates,
} from "../../service/Auth.service";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
function AddTemplate() {
  const NotificationId = useParams();
  const [error, setErrors] = useState({});
  const [details, setDetails] = useState();
  const [status, setStatus] = useState();

  const [confirm, serConfirm] = useState({});
  const [formData, setFormData] = useState({
    template_name: "",
    subject: "",
    body: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
    } else {
      setErrors(errors);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    _getEmailDetails();
  }, [dispatch]);

  const _getEmailDetails = () => {
    EmailTemplate_details_Byid(
      { _id: NotificationId.id },
      (data) => {
        setDetails(data);
        setFormData({
          template_name: data?.template_name,
          subject: data?.subject,
          body: data?.body,
        });
      },
      dispatch
    );
  };

  const handleSubmitdata = async (e) => {
    handeblur();
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
      let reqData = {
        template_name: formData.template_name,
        subject: formData.subject,
        body: formData.body,
      
      };
      try {
        AddEmailTemplate(
          reqData,
          (result) => {
            if (result?.status == true) {
              toast.success(
                "Submit successfully",
                {
                  theme: "dark",
              
                  position: toast.POSITION.BOTTOM_RIGHT,
                },
                { autoClose: 1000 }
              );

              navigate("/super-admin/notification-list");
            } else if (result?.status === false) {
              toast.error(
                "Not submit",
                {
                  theme: "dark",
               
                  position: toast.POSITION.BOTTOM_RIGHT,
                },
                { autoClose: 1000 }
              );
            }
          },
          dispatch
        );
      } catch (e) {}
    }
  };

  const handleUpdatedata = async (e) => {
    handeblur();
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
      let reqData = {
        _id: NotificationId.id,
        template_name: formData.template_name,
        subject: formData.subject,
        body: formData.body,
     
      };
      try {
        updateEmailTemplates(
          reqData,
          (result) => {
            if (result?.status == true) {
              toast.success(
                "Update successfully",
                {
                  theme: "dark",
                },
                {
                  position: toast.POSITION.TOP_CENTER,
                },
                { autoClose: 1000 }
              );

              navigate("/super-admin/notification-list");
            } else if (result?.status === false) {
              toast.error(
                "Not submit",
                {
                  theme: "dark",
                },
                {
                  position: toast.POSITION.TOP_CENTER,
                },
                { autoClose: 1000 }
              );
            }
          },
          dispatch
        );
      } catch (e) {}
    }
  };
  
  const validateForm = () => {
    const errors = {};

    if (!formData.template_name) {
      errors.template_name = "Template is required";
    }

    if (!formData.subject) {
      errors.subject = "Subject is required";
    }
    if (!formData.body) {
      errors.body = "Body is required";
    }

    return errors;
  };

  const handeblur = () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
    } else {
      setErrors(errors);
    }
  };

  return (
    <>
      <h2 className="page-title">Notifications & Emails</h2>
      <div className="content-wraper">
        {NotificationId.id ? (
          <h4>Edit Email Template Setting</h4>
        ) : (
          <h4>Add Email Template Setting</h4>
        )}

        <Card>
          <Card.Body>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <label>
                    Template Name <sup></sup>
                  </label>
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="template_name"
                    value={details?.template_name}
                    onChange={handleChange}
                    onBlur={(e) => handeblur()}
                  />
                  {error.template_name && (
                    <span className="text-danger">{error.template_name}</span>
                  )}
                </Col>
                <Col md={6}>
                  <label>
                    Subject <sup></sup>
                  </label>
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="subject"
                    value={details?.subject}
                    onChange={handleChange}
                    onBlur={(e) => handeblur()}
                  />
                  {error.subject && (
                    <span className="text-danger">{error.subject}</span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label>
                   Body <sup>*(edit)</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="body"
                    defaultValue={details?.body}
                    onChange={handleChange}
                    onBlur={(e) => handeblur()}
                  />
                  {error.body && (
                    <span className="text-danger">
                      {error.body}
                    </span>
                  )}
                </Col>

            
              </Row>
            </form>
            <div className="text-end">
              <Button
                variant="outline-secondary"
                className="mx-3"
                href="/super-admin/notification-list"
              >
                Cancel
              </Button>
              {NotificationId.id ? (
                <Button variant="primary" onClick={handleUpdatedata}>
                  Update
                </Button>
              ) : (
                <Button variant="primary" onClick={handleSubmitdata}>
                  Add
                </Button>
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
export default AddTemplate;
