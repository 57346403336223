import HomeHeader from "./HomeHeader";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import {
  pagination,
} from "../../../components/common/utils/message";
import { useNavigate } from "react-router-dom";
import { NotificationLists } from "../../../services/auth.service";
import { NotificationDeletes } from "../Service/AuthService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function NotificationList() {
  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const [noNotificationList, setNotificationList] = useState();
  const [totalPages, settotalPages] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxData = useSelector((state) => state);
  let user_id = reduxData?.login?.data?.result?._id;
  useEffect(() => {
    _getNotificationList();
  }, [dispatch]);
  const _getNotificationList = () => {
    NotificationLists(
      {_id:user_id, page, limit },
      (data) => {
        setNotificationList(data?.data?.result?.docs);
        settotalPages(data?.data?.result?.totalPages);
      },
      dispatch
    );
  };
  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await NotificationLists(
      {_id:user_id, page: currentPage, limit },
      (response) => {
        setNotificationList(response?.data?.result?.docs);
      },
      dispatch
    );
  };
  const formatNotificationDate = (createdAt) => {
    const notificationTime = new Date(createdAt);
    const now = new Date();
    const timeDiffInSeconds = Math.floor((now - notificationTime) / 1000);

    if (timeDiffInSeconds < 60) {
      return `${timeDiffInSeconds} seconds ago`;
    } else if (timeDiffInSeconds < 3600) {
      const minutes = Math.floor(timeDiffInSeconds / 60);
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else if (timeDiffInSeconds < 86400) {
      const hours = Math.floor(timeDiffInSeconds / 3600);
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else {
      const year = notificationTime.getFullYear();
      const month = padNumber(notificationTime.getMonth() + 1);
      const day = padNumber(notificationTime.getDate());
      return `${year}-${month}-${day}`;
    }
  };

  const padNumber = (number) => {
    return number.toString().padStart(2, "0");
  };




  const handleDelete = (data) => {
    NotificationDeletes(
      {_id:data?._id },
      (result) => {
        if (result?.status == true) {
        
          _getNotificationList();
        }
      },
      dispatch
    );
  };
  return (
    <div className="notification">
      <HomeHeader />
      <div className="container-fluid">
        <div className="row topBar">
          <div className="col">
            <div className="container px-2">
              <div className="row">
                <div className="col-12 px-0">
                  <div className="content">
                    <h3 className="mb-0 Aeonik-Bold">Notifications</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* <div className="notifications recentNotification">
              <div className="text">
                <p className="mb-0">
                  Oliver Smith has replied back to you “Thank you very much,
                  Simon”.
                </p>
              </div>
              <div className="time">
                <p className="mb-0">1/23/2023</p>
              </div>
            </div> */}
            {noNotificationList &&
              noNotificationList?.map((data, index) => {
   
                //   let ProfilePath = `${API_BASEURL}/public/profile/${data?.customerDetails[0]?.profile}`;
                return (
                  <div key={index} className={`${index === 0 ? "recentNotification notifications whiteText Aeonik-Regular" : "notifications"} `}>
                    <div className="text">
                      <p className="mb-0  Aeonik-Regular">{data?.message}</p>
                    </div>
                    <div className="time">
                      <p className="mb-0 Aeonik-Regular">{formatNotificationDate(data?.createdAt)} <a onClick={(e)=>handleDelete(data)}><i class="fa-solid fa-circle-xmark "></i></a></p>
                    </div>
                    
                  </div>
                );
              })}                   
          </div>
        </div>
        <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      {noNotificationList?.length == 0
                        ? "No Records found"
                        : ""}
                    </div>
        <div className="row my-5">
          <div className="col">
            <nav aria-label="Page navigation example" class="paginationRow">
              {totalPages > 1 ? (
                <ReactPaginate
                  breakLabel={"..."}
                  nextLabel={"Next >"}
                  onPageChange={handlePageChange}
                  pageCount={totalPages}
                  previousLabel={"< Back"}
                  renderOnZeroPageCount={null}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              ) : null}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationList;
