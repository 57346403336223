import { createSlice } from "@reduxjs/toolkit";

export const AdminRestroIdSlice = createSlice({
    name: "adminrestro",
    initialState: {
        isLoggedIn: false,
        data: {},
    },
    reducers: {
        adminrestrodetails(state, action) {
            state.isLoggedIn = true;
            state.data = action.payload;
        },
    },
});

export const { adminrestrodetails } = AdminRestroIdSlice.actions;
export default AdminRestroIdSlice.reducer;
