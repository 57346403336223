import React from "react";
import { useState } from "react";

import { addRestro, resendOtp, verifyOtp } from "./service/authService";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import gotip from "../../assets/images/gotipLogo.png"
import { userlogin } from "./loginSlice";
import { Card, Carousel, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { customToastUpdate } from "../../services/project";
function RIRegistration() {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [restro, setRestro] = useState({});
  const [error, setErrors] = useState({});
  const [confirm, serConfirm] = useState({});
  const [showModel, setShowModel] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [createPasswordType, setCreatePasswordType] = useState("password");
  const [input, setInput] = useState({
    full_name: "",
    restaurant_name: "",
    contact_number: "",
    restaurantContact_number: "",
    email: "",
    countryCode: "+",
    requestCode: "",
    password: "",
    confirmPassword: "",
    social_media: "",
    role: 2,
  });

  /*--------@ For handle change for Pub register form----------- */
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...error }; // Create a copy of errors
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "confirmPassword") {
      if (value === "") {
        serConfirm({
          confirmPassword: "Confirm password is required",
        });
      } else if (input.password === value) {
        serConfirm({
          matched: "Password matched ",
        });
      } else {
        serConfirm({
          confirmPassword: "Password not matched ",
        });
      }
    }

    if (newErrors[name]) {
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  /*--------@ Handle submit for Pub register form----------- */
  const handelSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm(); // Renamed errors to newErrors
    if (
      Object.keys(newErrors).length === 0 &&
      input.password === input.confirmPassword
    ) {
      setErrors(newErrors);
      const formdata = new FormData();

      formdata.append("role", input.role);
      formdata.append("full_name", input.full_name);
      formdata.append("restaurant_name", input.restaurant_name);
      formdata.append("contact_number", input.contact_number);
      formdata.append(
        "restaurantContact_number",
        input.restaurantContact_number
      );
      formdata.append("countryCode", input.countryCode);
      formdata.append("requestCode", input.requestCode);
      formdata.append("social_media", input.social_media);

      formdata.append("email", input.email);
      formdata.append("password", input.password);
      formdata.append("password", input.password);
      const res = await addRestro(input, dispatch);
      if (res?.data?.status == "Success") {
        setShowModel(true)
      } else {
        toast.error(
          "Email already registered",
          {
            theme: "dark",

          },
          {
            position: toast.POSITION.TOP_CENTER,
          },
          { autoClose: 1000 }
        );

      }

    } else {
      setErrors(newErrors);
      if (input.password !== input.confirmPassword) {
        serConfirm({ confirmPassword: "Password does not match" });
      }
    }
  };


  const submitOtp = async (e) => {
    e.preventDefault();
    if (!input.otp) {
      return toast.error(
        "OTP can't be Blank",
        {
          theme: "dark",

        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );
    } else if (input.otp.length !== 6) {
      return toast.error(
        "OTP should be 6 Digit",
        {
          theme: "dark",

        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );
    }
    const res = await verifyOtp({ email: input.email, otp: input.otp }, dispatch);
    if (res?.data?.role == 2) {
      dispatch(userlogin(res?.data));
      toast.success(
        "Register Successfully",
        {

          theme: "dark",
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000
        },
      );
      if (res?.data?.result?.is_updated == false) {
        navigate("/resto/dashboard");
      }
      if (res?.data?.result?.is_updated == true) {
        navigate("/resto/mainrestodashboard");
      }
    } else if (res == null) {
      toast.error(
        "Incorrect Email OTP",
        {
          theme: "dark",

        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );
    }





  };

  const resentOtp = async (e) => {
    e.preventDefault();
    const res = await resendOtp({ email: input.email }, dispatch);
    if (res?.data?.status == "Success") {
      // toast.success(
      //   "OTP sent Successfully",
      //   {

      //     theme: "dark",
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //     autoClose: 1000
      //   },
      // );
      customToastUpdate(dispatch)
    } else {
      toast.error(
        "Something Went Wrong",
        {
          theme: "dark",

        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );

    }


  };

  /*--------@ Validations Pub register form----------- */
  const validateForm = () => {
    const errors = {};
    if (!input.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(input.email)) {
      errors.email = "Email is invalid";
    }

    if (!input.password) {
      errors.password = "Password is required";
    } else if (input.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }
    if (!input.full_name) {
      errors.full_name = "Pub contact name is required";
    }
    if (!input.social_media) {
      errors.social_media = "Pub Social Tag is required";
    }
    if (!input.restaurant_name) {
      errors.restaurant_name = "Pub name is required";
    }
    if (input.requestCode) {
      if (
        !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(
          input.requestCode
        )
      ) {
        errors.requestCode = "Request code is invalid";
      }
       if (input.requestCode.length !== 4) {
        errors.requestCode = "Request Code must 4 characters";
      }
    }else if(!input.requestCode){
      errors.requestCode = "Request code is required";
    }
    // if (input.contact_number) {
    //   if (
    //     !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(
    //       input.contact_number
    //     )
    //   ) {
    //     errors.contact_number = "Contact number is invalid";
    //   } else if (
    //     // input.contact_number.length > 11 ||
    //     input.contact_number.length < 16
    //   ) {
    //     errors.contact_number = "Contact number is invalid";
    //   }
    // }
    // if (input.countryCode) {
    //   if (
    //     !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(
    //       input.countryCode
    //     )
    //   ) {
    //     errors.countryCode = "Country Code is invalid";
    //   }
    // }

    //  if (
    //   !isValidPhoneNumber(input.restaurantContact_number)
    //   // input.contact_number.length < 12 ||
    //   // input.contact_number.length > 13
    // ) {
    //   // console.log(" input.contact_number.length", input.contact_number.length)
    //   errors.restaurantContact_number = "Contact number is invalid";
    // }


    return errors;
  };
  function handleInnerClick(event) {
    console.log("nextt")
    event.stopPropagation();
  }
  const handleChange1 = (value, name) => {
    let newErrors = { ...error };
    setInput({
      ...input, [name]: value
    })
    if (newErrors[name]) {
      delete newErrors[name];
      setErrors(newErrors);
    }
  }

  const handeblur = () => { };
  return (
    <div className="authPage" style={{ cursor: "pointer" }} >
      <div className="container shadow">
        <div className="row m-0">
          <div className="col-md-6  dflex alc" onClick={() => { navigate("/") }}>
            <img src={gotip} alt="" className="authImage signUpImage" style={{ marginBottom: "345px" }} height="200px" />
          </div>
          <div className="col-md-6 mt-5 mt-md-0" style={{ background: '#f4f4f4', borderRadius: '15px', cursor: "auto" }}>
            <div className="content">
              {/* <div className="logo text-center">
                <Link to="/">
                  <img src={gotip} alt="" height={50} width={50}/>
                </Link>
              </div> */}
              <h3 className="text-center mt-3">Register Your Pub</h3>
              <form className="form">
                <div className="row">
                  <div className="col">
                    <div className="inputField ">
                      <label htmlFor="socialMedia">Pub Name *</label>
                      <div className="inputBox blackBorder">
                        <input
                          type="text"
                          className="form-control"
                          id="socialMedia"
                          autoFocus={true}
                          name="restaurant_name"
                          placeholder="The Street Social "
                          onChange={handleChange}
                        />
                        <i class="fa-solid fa-utensils"></i>
                      </div>
                      {error.restaurant_name && (
                        <span className="text-danger">
                          {error.restaurant_name}
                        </span>
                      )}
                    </div>
                    <div className="inputField">
                      <label htmlFor="contactName">Pub Contact Name *</label>
                      <div className="inputBox blackBorder">
                        <input
                          type="text"
                          className="form-control"
                          id="contactName"
                          name="full_name"
                          value={restro.full_name}
                          placeholder="Pub Contact Name *"
                          onChange={handleChange}
                          onBlur={(e) => handeblur()}
                        />
                        <i className="fa-solid fa-user"></i>
                      </div>
                      {error.full_name && (
                        <span className="text-danger">{error.full_name}</span>
                      )}
                    </div>
                    <div className="inputField">
                      <label htmlFor="email">Pub Email <span style={{ fontSize: "12px", wordBreak: "break-word" }}>(Please use your pub's work email address )</span></label>
                      <div className="inputBox blackBorder">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Pub Email"
                          name="email"
                          value={restro.email}
                          onChange={handleChange}
                          onBlur={(e) => handeblur()}
                        />
                        <i className="fa-solid fa-envelope"></i>
                      </div>
                      {error.email && (
                        <span className="text-danger">{error.email}</span>
                      )}
                    </div>
                    {/* <div className="inputField ">
                      <label htmlFor="contact">Contact No *</label>
                      <div className="inputBox countryCodeBox blackBorder">
                        <input
                          type="text"
                          className="form-control px-3 countryCode"
                          onChange={handleChange}
                          value={"+44"}
                        />
                        <input
                          type="text"
                          className="form-control px-3"
                          id="contact"
                          placeholder="Contact Mobile Number"
                          name="contact_number"
                          onChange={handleChange}
                          onBlur={(e) => handeblur()}
                        />
                      </div>
                      {error.contact_number && (
                        <span className="text-danger">
                          {error.contact_number}
                        </span>
                      )}
                    </div> */}

                    {/* <div className="inputField">
                      <label htmlFor="restaurantContact">Pub Contact #</label>
                      <div className="inputBox countryCodeBox blackBorder">
                      <text className="countryCode1" >+</text>
                        <input
                          type="text"
                          className="form-control px-3 countryCode countryCode2"
                          name="countryCode"
                          // defaultValue={"+"}
                          pattern="/^\+(\d{1}\-)?(\d{1,3})$/"
                         maxLength={4}
                          onChange={handleChange}
                        />
                        <input
                          type="text"
                          className="form-control px-3"
                          name="restaurantContact_number"
                          onChange={handleChange}
                          placeholder="Pub Contact #"
                          onBlur={(e) => handeblur()}
                        />
                      </div>
                      {error.restaurantContact_number && (
                        <span className="text-danger">
                          {error.restaurantContact_number}
                        </span>
                      )}

                      {error.countryCode && (
                        <span className="text-danger">{error.countryCode}</span>
                      )}
                    </div> */}
                    <div className="inputField1 ">
                      <label htmlFor="contact">Pub Contact #</label>
                      <div className="inputBox  blackBorder">
                        <PhoneInput
                          //  fieldname="restaurantContact_number"
                          className="form-control px-3"
                          name="restaurantContact_number"
                          placeholder="Pub Contact #"
                          international
                          defaultCountry="GB"
                          onChange={(e) => handleChange1(e, "restaurantContact_number")}

                        />





                      </div>
                      {error.restaurantContact_number && (
                        <span className="text-danger">
                          {error.restaurantContact_number}
                        </span>
                      )}
                      {error.countryCode && (
                        <span className="text-danger">
                          {error.countryCode}
                        </span>
                      )}
                    </div>
                    <div className="inputField1 ">
                      <label htmlFor="contact">Pub Request Code # <span style={{ fontSize: "12px", wordBreak: "break-word" }}>(This code is for your staff to join your pub )</span></label>
                      <div className="inputBox  blackBorder">
                      <input
                          type="text"
                          className="form-control px-3"
                          id="socialMedia"
                          name="requestCode"
                          onChange={handleChange}
                          placeholder="please enter pub request Code"
                        />
                      </div>
                     
                      {error.requestCode && (
                        <span className="text-danger">
                          {error.requestCode}
                        </span>
                      )}
                    </div>
                    <div className="inputField">
                      <label htmlFor="socialMedia">Social Tag </label>
                      <div className="inputBox blackBorder">
                        <input
                          type="text"
                          className="form-control px-3"
                          id="socialMedia"
                          name="social_media"
                          onChange={handleChange}
                          placeholder="please enter one of your social media Eg. Instagram"
                        // value={restro.social_media}
                        />
                      </div>
                      {error.social_media && (
                        <span className="text-danger">{error.social_media}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="inputField">
                  <label htmlFor="createPassword">Create Password</label>
                  <div className="inputBox blackBorder">
                    <input
                      type={createPasswordType ? "password" : "text"}
                      name="password"
                      className="form-control"
                      id="createPassword"
                      placeholder="Create Password"
                      onChange={handleChange}
                    />
                    <i className="fa-solid fa-lock"></i>
                    <i onClick={() => setCreatePasswordType(!createPasswordType)} className={createPasswordType ? "fa-solid fa-eye seen" : "fa-solid fa fa-eye-slash seen"}></i>

                  </div>
                  {error.password && (
                    <span className="text-danger">{error.password}</span>
                  )}
                </div>
                <div className="inputField">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <div className="inputBox blackBorder">
                    <input
                      type={passwordType ? "password" : "text"}
                      name="confirmPassword"
                      className="form-control"
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      onChange={handleChange}
                      eyeIcon={true}
                    />

                    <i className="fa-solid fa-lock"></i>
                    <i onClick={() => setPasswordType(!passwordType)} className={passwordType ? "fa-solid fa-eye seen" : "fa-solid fa fa-eye-slash seen"}></i>

                  </div>
                  {confirm.confirmPassword && (
                    <span className="text-danger">
                      {confirm.confirmPassword}
                    </span>
                  )}
                  {confirm.matched && (
                    <span className="text-success">{confirm.matched}</span>
                  )}
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <button
                      className="btn btn-main w-100 btn-height"
                      onClick={handelSubmit}
                    >
                      Sign Up
                    </button>
                  </div>
                </div>
              </form>
              <hr />

              <div>
                <p className="text-center mt-4">
                  Already Registered?
                  <Link to="/login"> Login</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModel} onClick={handleInnerClick} onHide={() => setShowModel(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {/* <div className="modal-heading"> */}
              <h3>Verification</h3>
              {/* </div> */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body module">
              <p>We have sent you a code to verify your email.</p>
              <h4>Enter Your OTP Here</h4>
              <div className="otpBox">
                <input
                  type="text"
                  className="form-control blackBorder"
                  name="otp"
                  onChange={handleChange}
                  maxLength={6}
                />

              </div>
              <div className="dflex jcc" style={{ gap: "10px" }}>
                <button className="btn btn-main btn-height" onClick={resentOtp} >
                  Resend
                </button>
                <button className="btn btn-main btn-height" onClick={submitOtp}>
                  Continue
                </button>
              </div>
            </div>



          </Modal.Body>

        </Modal>

      </div>



    </div>
  );
}

export default RIRegistration;
