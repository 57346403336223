import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API_BASEURL, Frontend_Url } from "../../../environment";
import Header from "../../../features/homePages/common/HomeHeader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import {
  restrobyId,
  RestroSendrequest,
  getrestoDetailsById,
  joinRequest1,
} from "../../staffInterface/service/Auth.service";
import { SocketContext } from "../../../socket/SocketContext";
import { Modal } from "react-bootstrap";

const RestoProfileRequest = () => {
  const userdata = useSelector((state) => state);
  let user_id = userdata?.login?.data?.result?._id;
  let fullname = userdata?.login?.data?.result?.full_name;
  const dispatch = useDispatch();
  const [error, setErrors] = useState({});
  const retroId = useParams();
  const [restroData, setRestroData] = useState();
  const [restroDetailById, setRestroById] = useState();
  // const socket = useContext(SocketContext)
  const socket = useContext(SocketContext);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const up = async () => {
      const response = await restrobyId(retroId.id);
      setRestroData(response?.data?.result[0]);
    };
    up();
  }, []);
  let path = `${API_BASEURL}/public/posts/${restroData?.image}`;
  const [input, setInput] = useState({
    resto_id: retroId.id,
    staff_id: user_id,
    description: "",
    staff_name: fullname,
    is_closed: "true",
    status: 1,

  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((previousValue) => ({
      ...previousValue,
      [name]: value,
    }));
  };

  const RestroSendRequest1 = async (e) => {
    e.preventDefault();

  
    const newErrors = validateForm(); // Renamed errors to newErrors
    if (Object.keys(newErrors).length === 0) {
      setErrors(newErrors); 
    
      const res = await joinRequest1(restroDetailById._id , input.description,
        input.requestCode,input.is_closed,input.status);

        setShowModal(false)
    if (res?.data?.status == true) {
      toast.success(
        "Pub join successfully.",
        {
          theme: "dark",
          autoClose: 1000,
          position: toast.POSITION.BOTTOM_RIGHT,
        },

      );
      navigate(`/staff/restaurantprofilescrren/${restroData._id}`);
    }else{
      toast.error(
        res.data.message,
        {
          theme: "dark",
     
          position: toast.POSITION.BOTTOM_RIGHT,
        },
        { autoClose: 1000 }
      );
    }
    
    } else {
        setErrors(newErrors);
   
      }

  }

  const RestroSendRequest = async (e) => {
    e.preventDefault();

    const newErrors = validateForm(); // Renamed errors to newErrors
    if (Object.keys(newErrors).length === 0) {
      setErrors(newErrors);

      const res = await RestroSendrequest(
        input.resto_id,
        input.staff_id,
        input.description,
        input.staff_name,
        input.requestCode,
        input.status,
        input.is_closed,
      );
  
      setShowModal(false)
      if (res.data.status == true) {
 
        let notificationData = {
          recieverId: res?.data?.result?.resto_id,
          senderId: res?.data?.result?.staff_id,
          type: "PubstarSendRequest",
        };
        socket.emit("staff-request-event", notificationData);
        navigate(`/staff/restaurantprofilescrren/${restroData._id}`);
        // navigate("/staff/invitationrequest");
        toast.success(
          "Pub join successfully",
          {
            theme: "dark",
            className: "custom-toast",
            autoClose: 1000,
            position: toast.POSITION.BOTTOM_RIGHT,
          },
        );
      }else{
        toast.error(
          res.data.message,
          {
            theme: "dark",
       
            position: toast.POSITION.BOTTOM_RIGHT,
          },
          { autoClose: 1000 }
        );
      }

    } else {
      setErrors(newErrors);
    }

  };

  const validateForm = () => {
    const errors = {}
    if (input.requestCode === undefined ||input.requestCode === "" ) {
      errors.requestCode = "Request Code is required";
    }else if (input.requestCode.length !== 4){
      errors.requestCode = "Request Code should be 4 Digit";
    }

    return errors;
  };

  useEffect(() => {
    getByusersId();
  }, [dispatch]);

  const getByusersId = () => {
    getrestoDetailsById(
      { resto_id: retroId.id, staff_id: user_id },
      (data) => {
        setRestroById(data);
      },
      dispatch
    );
  };

  const mapStyles = {
    height: "25vh",
    width: "100%",
  };

  const defaultCenter = {
    lat: restroData?.lat || 51.901516,
    lng: restroData?.lng || -8.468283,
  };

  const locations = [
    {
      name: "Cask Pub and Kitchen",
      location: {
        lat: restroData?.lat || 51.901516,
        lng: restroData?.lng || -8.468283,
      },
    },

    ,
  ];
  const handleBack = () => {
    navigate("/staff/dashboard");
  };
  return (
    <div className="RIAddAddress">
      <Header />

      <div className="container">
        <div className="row my-4">
          <div className="col"></div>
        </div>
        <div className="row my-4">
          <div className="col direction">
            <p class="mb-0 ">
              <a onClick={() => navigate('/staff/dashboard')}>Home </a><i class="fa-solid fa-angles-right"></i>
              {restroData?.restaurant_name}{" "}
              <i class="fa-solid fa-angles-right"></i>
              {/* <span>
                {restroData?.addressLine1}, {restroData?.city} (
                {restroData?.postcode})
              </span> */}
            </p>
          </div>
        </div>
        <div className="row ProfileDesign">
          <div className="col-lg-4">
            <img src={path} alt="" className="w-100 mainImage" />
          </div>
          { console.log('...........restroData', restroData)}
          <div className="col-lg-4 mt-4 mt-lg-0">
            <h1>{restroData?.restaurant_name}</h1>
            {/* <p>
              <i className="fa-solid fa-location-dot"></i>{" "}
              {restroData?.addressLine1},
              <span>
                {restroData?.city} ({restroData?.postcode})
              </span>
            </p> */}
            <p>
              <i class="fa-solid fa-envelope"></i>{" "}
              {restroData?.email}
            </p>
            <p>
              <i class="fa-solid fa-phone"></i> 
              {/* {restroData?.restaurantContact_number} */}
              {restroData?.restaurantContact_number ? restroData?.restaurantContact_number?.substr(0, 3) + "  " + restroData?.restaurantContact_number?.substr(3):""}
            </p>
            <div className="review">
              <div className="star">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star-half"></i>
              </div>
              <p className="mb-0">(4.5)</p>
            </div>
          </div>
          <div className="col-lg-4 text-end mt-4 mt-lg-0">
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            >
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={13}
                center={defaultCenter}
              >
                {locations.map((item) => {
                  return <Marker key={item.name} position={item.location} />;
                })}
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
        <div className="row EditDetails mt-2">
          <div className="col-lg-4"></div>
          <div className="col-lg-4"></div>
          <div className="col-lg-4 text-end"></div>
        </div>
        <div className="row mb-5">
          <div className="col">
            <div className="buttonsRow">
              <div className="navBtn"></div>

              <div className="btnGroups">
                <button
                  className="btn btn-white-outline me-2 btn-height"
                  onClick={handleBack}
                >
                  Back
                </button>
                {/* {restroDetailById == null || undefined ? ( */}
                <button
                  className="btn btn-main btn-height"
                  onClick={()=>setShowModal(!showModal)}
                  data-bs-target="#exampleModal"
                >
                  Send Request
                </button>
                {/* ) : (
                  ""
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
     

      <Modal show={showModal} onHide={()=>setShowModal(!showModal)}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
                {restroDetailById?.is_closed == true ?
           <form onSubmit={(e) => RestroSendRequest1(e)}>
             <div className="row">
               {/* <div className="col-10 text-start m-auto">
                 <textarea
                   type="text"
                   name="description"
                   id="writeConcern"
                   cols="30"
                   placeholder="Enter Message"
                   rows="5"
                   onChange={handleChange}
                   className="form-control my-2 blackBorder"
                 ></textarea>
               </div> */}



               <div className="col-10 text-start m-auto">
                 <div className="inputField">
                   <div className="inputBox">
                     <input
                       type="text"
                       className="form-control px-3 bg-none"
                       id="RequestCode"
                       placeholder="Request Code"
                       name="requestCode"
                       onChange={handleChange}
                     // defaultValue={userData?.requestCode}
                     // onBlur={(e) => handeblur()}
                     />
                   </div>
                   {error.requestCode && (
                 <span className="text-danger">{error.requestCode}</span>
               )}
                 </div>
               </div>
             </div>

             <div className="row mt-4">
               <div className="col">
                 <button
                   className="btn btn-main btn-height"
                   data-bs-dismiss="modal"
                   type="submit"
                 >
                   Send Request
                 </button>
               </div>
             </div>
           </form> :
           <form onSubmit={(e) => RestroSendRequest(e)}>
             <div className="row">
               {/* <div className="col-10 text-start m-auto">
        
                 <textarea
                   type="text"
                   name="description"
                   id="writeConcern"
                   cols="30"
                   rows="5"
                   onChange={handleChange}
                   className="form-control my-2 blackBorder"
                 ></textarea>
               </div> */}
             </div>

             <div className="col-10 text-start m-auto">
            <div className="inputField">
            <label htmlFor="fullName">{`Please enter your pubs four-digit access code`}</label>
               <div className="inputBox">
                 <input
                   type="text"
                   className="form-control px-3 bg-none"
                   id="RequestCode"
                   placeholder="Request Code"
                   name="requestCode"
                   onChange={handleChange}
                   // defaultValue={userData?.requestCode}
                   // onBlur={(e) => handeblur()}
                 />
             </div>
             <p >you will get this from your manager/admin of the GoTipMe page</p>
             {error.requestCode && (
                 <span className="text-danger">{error.requestCode}</span>
               )}
             </div>
      </div>
             <div className="row mt-4">
               <div className="col">
                
                 <button
                   className="btn btn-main btn-height"
                   // data-bs-dismiss="modal"
                   type="submit"
                 >
                   Send Request
                 </button>
               </div>
             </div>
           </form>
         }

        
        </Modal.Body>
      </Modal> 


   
    </div>
  );
};

export default RestoProfileRequest;
