import React, { useState } from "react";

// import { Formik, Form } from "formik";

// import queryString from "query-string";
import { useNavigate, useParams,Link } from "react-router-dom";

import { Reset_Password } from "./service/authService";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, Button } from "react-bootstrap";
import gotip from "../../assets/images/gotipLogo.png"

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  // const [input, setInput] = useState({
  //     password: "",
  //     confirmPassword: "",
  // });
  // const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     setInput((previousValue) => ({
  //         ...previousValue,
  //         [name]: value,
  //     }));
  // };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Handle form submission
      const response = await Reset_Password(
        values.password,
        values.confirmPassword,
        token
      );
      if (response.data.status === "Success") {
        toast.success(
          "Password Reset Successfully",
          {
            theme: "dark",
            position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000 
          },
        );
        // setTimeout(() => {
        navigate("/password/set/successful");
        // }, 1000);
        // Reset form or perform other actions
      } else {
        toast.error(
          "Something went wrong",
          {
            theme: "dark",
          },
          {
            position: toast.POSITION.TOP_CENTER,
          },
          { autoClose: 1000 }
        );
      }
    },
  });

  // const reset = async (e) => {
  //     e.preventDefault();

  //     const response = await Reset_Password(input.password, input.confirmPassword, token);
  //     // toast.success('Password Reset Successfully!', {
  //     //     position: toast.POSITION.TOP_CENTER
  //     // });
  //     toast.success(
  //         "Password Reset Successfully",
  //         {
  //             theme: "dark",
  //         },
  //         {
  //             position: toast.POSITION.TOP_CENTER,
  //         },
  //         { autoClose: 1000 }
  //     );
  //     // setTimeout(() => {
  //     navigate("/password/set/successful");
  //     // }, 1000);
  // };

  return (
    <div className="authPage" style={{height:"100vh",display:"grid",alignItems:"center"}}>
      <div className="container shadow">
        <div className="row m-0">
          <div className="col-md-6 centerMid">
            {/* <img
              src="../assets/forgetPassword.png"
              alt=""
              className="authImage w-50"
            /> */}
              <img src={gotip} alt=""  style={{width:"700px",height:"fit-content"} }  className="authImage "/>{" "}
          </div>
          <div className="col-md-6 mt-5 mt-md-0 whiteBackground borderRadius paddingBottom">
            <div className="content">
             
              <h3 className="text-center mt-3 pt-5 Aeonik-Bold">Set New Passwords</h3>
              <p className="text-center Aeonik-Regular">Please enter your new password</p>
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                  className="blackBorder"
                    type="password"
                    name="password"
                    placeholder="Create Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={
                      formik.errors.password && formik.touched.password
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="confirmPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                   className="blackBorder"
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={
                      formik.errors.confirmPassword &&
                      formik.touched.confirmPassword
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.confirmPassword}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="paddingTop">
                <Button type="submit" className="btn btn-main w-100 btn-height">
                  Reset Password
                </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
