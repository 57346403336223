import HomeHeader from "../../homePages/common/HomeHeader";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTippingDetails } from "../Service/Auth.Service";
import { apiUrl, API_BASEURL } from "../../../environment";
import { useNavigate } from "react-router-dom";
function TippingDetails() {
  const staffId = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [resTippingDetail, setResTippingDetail] = useState();
  useEffect(() => {
    _getTippingDetails();
  }, [dispatch]);

  const _getTippingDetails = () => {
    getTippingDetails(
      { _id: staffId.id },
      (data) => {
        setResTippingDetail(data?.data?.result);
      },
      dispatch
    );
  };

  let ProfilePath = `${API_BASEURL}/public/profile/${resTippingDetail?.StaffDetails?.profile}`;
  let ProfilePath1 = `${API_BASEURL}/public/posts/${resTippingDetail?.restoDetails?.image}`;
  const navigatetoback = () => {
    navigate("/customer/tippinghistory");
  };
  return (
    <div className="staffProfile ">
      <HomeHeader />
      {resTippingDetail?.is_group_tip == false ? (
        <div className="container borderTop"style={{paddingBottom:"10px"}}>
          <div className="row my-4">
            <div className="col direction">
              <p class="mb-0 whiteText">
                <a onClick={navigatetoback}>Tipping History</a>{" "}
                <i class="fa-solid fa-angles-right"></i>
                {resTippingDetail?.StaffDetails?.full_name}
              </p>
            </div>
          </div>
          <div className="row profileData staffPostData">
            <div className="col-lg-6">
              <div className="profileContent">
                <div className="image">
                  <img
                    src={
                      resTippingDetail?.StaffDetails?.profile != null ||
                      resTippingDetail?.StaffDetails?.profile != undefined
                        ? ProfilePath
                        : "../../assets/startProfile.png"
                    }
                    alt=""
                  />
                </div>
                <div className="text">
                  <h1>{resTippingDetail?.StaffDetails?.full_name}</h1>
                  <p>Bartender</p>
                
                </div>
              </div>
            </div>
            <div className="col-lg-6"></div>
          </div>
          <div className="row TippingBox mt-4" >
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className="inputField-inline">
                    <label for="tipping" className="form-label">
                      Tip Amount ($) :
                    </label>
                    <div className="inputBox">
                      {resTippingDetail?.staff?.tip_amount}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="inputField-inline">
                    <label for="donation" className="form-label">
                      Donation ($) <span>(Optional)</span> :
                    </label>
                    <div className="inputBox">
                      {resTippingDetail?.donation_amount}
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="inputField-inline bgLight">
                    <label for="totalAmount" className="form-label">
                      Total Amount ($) :
                    </label>
                    <div className="inputBox">
                      {resTippingDetail?.total_tip_amount}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-12">
              
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="inputField-inline align-items-start">
                    <label for="totalAmount" className="form-label">
                      Feedback:
                    </label>
                    <div className="inputBox">
                      {resTippingDetail?.customer_comment}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
               
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="inputField-inline align-items-start">
                    <label for="totalAmount" className="form-label">
                      Pubstar Reply :
                    </label>
                    <div className="inputBox">
                      {resTippingDetail?.staff_comment}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container paddingBottom">
          <div className="row my-4">
            <div className="col direction">
              <p class="mb-0">
                <a onClick={navigatetoback}>Tipping History</a>{" "}
                <i class="fa-solid fa-angles-right"></i>
                {resTippingDetail?.restoDetails?.restaurant_name}
              </p>
            </div>
          </div>
          <div className="row profileData staffPostData">
            <div className="col-lg-6">
              <div className="profileContent">
                <div className="image">
                  <img
                    src={
                      resTippingDetail?.restoDetails?.image != null ||
                      resTippingDetail?.restoDetails?.image != undefined
                        ? ProfilePath1
                        : "../../assets/startProfile.png"
                    }
                    alt=""
                  />
                </div>
                <div className="text">
                  <h1>{resTippingDetail?.restoDetails?.restaurant_name}</h1>
                  <p>Bartender</p>
                  <div className="review">
                
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6"></div>
          </div>
          <div className="row TippingBox mt-2">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className="inputField-inline">
                    <label for="tipping" className="form-label">
                      Tip Amount ($) :
                    </label>
                    <div className="inputBox">
                      {resTippingDetail?.customer_tip_amount}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="inputField-inline">
                    <label for="donation" className="form-label">
                      Donation ($) <span>(Optional)</span> :
                    </label>
                    <div className="inputBox">
                      {resTippingDetail?.donation_amount}
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="inputField-inline bgLight">
                    <label for="totalAmount" className="form-label">
                      Total Amount ($) :
                    </label>
                    <div className="inputBox">
                      {resTippingDetail?.total_tip_amount}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-12">
                  <div className="inputField-inline">
                 
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="inputField-inline align-items-start">
                    <label for="totalAmount" className="form-label">
                      Feedback:
                    </label>
                    <div className="inputBox">
                      {resTippingDetail?.customer_comment}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
              
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="inputField-inline align-items-start">
                    <label for="totalAmount" className="form-label">
                      Pubstar Reply :
                    </label>
                    <div className="inputBox">
                      {resTippingDetail?.staff_comment}
                    </div>
                  </div>
                </div>
              </div>
              <div className="TipManagement2">
                <div className="container">
                  <div className="row mt-5">
                    <div className="col-12">
                      <h3>Pubstar Lists</h3>
                    </div>
                    <div className="col-12">
                      {resTippingDetail?.group_staff &&
                        resTippingDetail?.group_staff?.map((data, index) => {
                          let ProfilePath = `${API_BASEURL}/public/profile/${data?.profile}`;
                          return (
                            <div key={index} className="listdata">
                              <div className="profile">
                                <div className="image">
                                  <img
                                    src={
                                      data?.profile != null ||
                                      data?.profile != undefined
                                        ? ProfilePath
                                        : "../../assets/startProfile.png"
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="text">
                                  <h4 className="mb-0 fw-bold">
                                    {data?.staff_name}
                                  </h4>
                                  <p className="mb-0">Staff</p>
                                </div>
                              </div>
                              <div className="price">
                                <h3>$ {data?.group_staff_amount.toFixed(2)}</h3>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      {resTippingDetail?.group_staff?.length == 0
                        ? "No Records found"
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TippingDetails;
