import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import {
  pagination,
} from "../../../components/common/utils/message";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  Service_Donation_feesList,
  No_Of_Total_Tips,
} from "../service/Auth.service";
function TotalEarning() {
  const userdata = useSelector((state) => state);
  let adminRole = userdata?.login?.data?.role;
  const [historyState, setHistoryState] = useState(0);
  const [nooftotaltips, setNo_Of_TotalTips] = useState();
  const [myearning, setMyearning] = useState();
  const [myservicefee, setMyServiceFee] = useState();
  const [myservicefeetotalpages, setMyservicefessTotalpages] = useState();
  const [mydonationfee, setMyDonationFee] = useState();
  const [mydonationtotalpages, setDonationFeesTotalpages] = useState();
  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const [totalPages, settotalPages] = useState();
  const [searchText, setSearchText] = useState("");
  const [start_date, setStartDate] = useState();
  const [end_date, setEndDate] = useState();
  const maxDate = new Date().toISOString().split('T')[0];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onTabClick = (tabId) => () => {
    setHistoryState(tabId);
  };
  useEffect(() => {
    getnototaltips();
    _getMyEarningList();
    _getServiceFeeList();
    _getDonationFeeList();
  }, [dispatch]);
  // ----------------------------------My Earning --------------------------------------------------
  const getnototaltips = () => {
    No_Of_Total_Tips(
      {},
      (data) => {
        setNo_Of_TotalTips(data);
      },
      dispatch
    );
  };

  const _getMyEarningList = (searchText, startDate, endDate) => {
    Service_Donation_feesList(
      {
        page,
        limit,
        start_date: startDate,
        end_date: endDate,
        searchText: searchText,
      },
      (data) => {
        setMyearning(data?.docs);
        settotalPages(data?.totalPages);
      },
      dispatch
    );
  };
  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await Service_Donation_feesList(
      { page: currentPage, limit, searchText },
      (response) => {
        setMyearning(response?.docs);
      },
      dispatch
    );
  };
  const handleInputStartDate = (newDate) => {
    setStartDate(newDate.target.value);
  };

  const handleInputEndDate = (newDate) => {
    setEndDate(newDate.target.value);
    _getMyEarningList("", start_date, newDate.target.value);
  };
  const __handleSearch = async (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
    _getMyEarningList(e.target.value, start_date, end_date);
  };

  // -------------------------------------Service Fee----------------------------------------------------

  const _getServiceFeeList = (searchText, startDate, endDate) => {
    Service_Donation_feesList(
      {
        page,
        limit,
        start_date: startDate,
        end_date: endDate,
        searchText: searchText,
      },
      (data) => {
        setMyServiceFee(data?.docs);
        setMyservicefessTotalpages(data?.totalPages);
      },
      dispatch
    );
  };
  const handleServiceFeePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await Service_Donation_feesList(
      { page: currentPage, limit, searchText },
      (response) => {
        setMyServiceFee(response?.docs);
      },
      dispatch
    );
  };
  const handleServicefeeInputStartDate = (newDate) => {
    setStartDate(newDate.target.value);
  };

  const handleServicefeeInputEndDate = (newDate) => {
    setEndDate(newDate.target.value);
    _getServiceFeeList("", start_date, newDate.target.value);
  };
  const __handleServicefeeSearch = async (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
    _getServiceFeeList(e.target.value, start_date, end_date);
  };
  // -------------------------------------------Donation Amount-------------------------------------

  const _getDonationFeeList = (searchText, startDate, endDate) => {
    Service_Donation_feesList(
      {
        page,
        limit,
        start_date: startDate,
        end_date: endDate,
        searchText: searchText,
      },
      (data) => {
        setMyDonationFee(data?.docs);
        setDonationFeesTotalpages(data?.totalPages);
      },
      dispatch
    );
  };
  const handleDonationFeePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await Service_Donation_feesList(
      { page: currentPage, limit, searchText },
      (response) => {
        setMyDonationFee(response?.docs);
      },
      dispatch
    );
  };

  const handleDonationInputStartDate = (newDate) => {
    setStartDate(newDate.target.value);
  };

  const handleDonationInputEndDate = (newDate) => {
    setEndDate(newDate.target.value);
    _getDonationFeeList("", start_date, newDate.target.value);
  };
  const __handleDonationSearch = async (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
    _getDonationFeeList(e.target.value, start_date, end_date);
  };
  var totalEarnings = 0;
  for (var i = 0; i < nooftotaltips?.length; i++) {
    totalEarnings += nooftotaltips[i]?.service_charge;
  }
  var totaldonationamount = 0;
  for (var j = 0; j < nooftotaltips?.length; j++) {
    totaldonationamount += nooftotaltips[j]?.donation_amount;
  }
  var totalEarning = 0;
  totalEarning = totalEarnings + totaldonationamount;

  var totalServiceCharge = 0;
  for (var k = 0; k < nooftotaltips?.length; k++) {
    totalServiceCharge += nooftotaltips[k]?.service_charge;
  }
  var totalDonationamount = 0;
  for (var l = 0; l < nooftotaltips?.length; l++) {
    totalDonationamount += nooftotaltips[l]?.donation_amount;
  }
  if (adminRole == 0) {
    return (
      <div className="tippingHistroy">
        <div className="container-fluid">
          <div className="row topBar">
            <div className="col">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="content">
                      <div className="heading">
                        <h3>Dashboard</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="tabs">
                <div
                  className={`tab ${historyState === 0 && "active"}`}
                  onClick={onTabClick(0)}
                >
                  Total Earning
                  <div className="line"></div>
                </div>
                <div
                  className={`tab ${historyState === 1 && "active"}`}
                  onClick={onTabClick(1)}
                >
                  Service Fees
                  <div className="line"></div>
                </div>
                <div
                  className={`tab ${historyState === 2 && "active"}`}
                  onClick={onTabClick(2)}
                >
                  Donation
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className={`row tabContent ${historyState !== 0 && "d-none"}`}>
            <div className="col-12">
              <div className="content">
                <div className="price">
                  <h3>$ {totalEarning}</h3>
                  <p>My Earnings</p>
                </div>
                <div className="dates">
                  <div className="datePicker">
                    <label htmlFor="pickFrom">From</label>
                    <input
                      type="date"
                      name=""
                      id="pickFrom"
                      className="form-control"
                      max={maxDate}
                      onChange={handleInputStartDate}
                    />
                  </div>
                  <div className="datePicker">
                    <label htmlFor="pickTo">To</label>
                    <input
                      type="date"
                      name=""
                      id="pickTo"
                      max={maxDate}
                      className="form-control"
                      onChange={handleInputEndDate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row searchStaff">
                <div className="col-12 px-0">
                  <div className="searchBox">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="search customers"
                      value={searchText}
                      onChange={__handleSearch}
                    />
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </div>
                </div>
              </div>
              <div className="table-wraper mt-4">
                <div className="top-action"></div>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Customer</th>
                      <th>Restaurant</th>
                      <th>Type</th>
                      <th>Amount ($)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {myearning &&
                      myearning?.map((data, index) => {
                        const date = new Date(data?.createdAt);

                        // Extract the day, month, and year values
                        const day = date.getDate().toString().padStart(2, "0");
                        const month = (date.getMonth() + 1)
                          .toString()
                          .padStart(2, "0");
                        const year = date.getFullYear();

                        // Format the date as dd/mm/yyyy
                        const formattedDate = `${day}/${month}/${year}`;
                        return (
                          <tr key={index}>
                            <td>{formattedDate}</td>
                            <td>
                              <p>
                                {data?.is_guest_tip == true
                                  ? data?.guest?.guest_name
                                  : data?.customerDetails?.full_name}
                              </p>
                              <p>
                                {data?.is_guest_tip == true
                                  ? data?.guest.guest_email
                                  : data?.customerDetails?.email}
                              </p>{" "}
                            </td>
                            <td>{data?.restroDetails?.restaurant_name}</td>
                            <td>
                              {data?.is_group_tip == true
                                ? "Group Tip"
                                : "Staff Tip"}
                            </td>
                            <td>
                              {data?.service_charge + data?.donation_amount}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  {myearning?.length == 0 ? "No Records found" : ""}
                </div>
                <div className="text-end d-flex">
                  <Pagination className="ml-auto">
                    {totalPages > 1 ? (
                      <ReactPaginate
                        breakLabel={"..."}
                        nextLabel={"Next >"}
                        onPageChange={handlePageChange}
                        pageCount={totalPages}
                        previousLabel={"< Back"}
                        renderOnZeroPageCount={null}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    ) : null}
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
          <div className={`row tabContent ${historyState !== 1 && "d-none"}`}>
            <div className="col-12">
              <div className="content">
                <div className="price">
                  <h3>$ {totalServiceCharge}</h3>
                  <p>Earning Through Service Fees</p>
                </div>
                <div className="dates">
                  <div className="datePicker">
                    <label htmlFor="pickFrom">From</label>
                    <input
                      type="date"
                      name=""
                      id="pickFrom"
                      max={maxDate}
                      className="form-control"
                      onChange={handleServicefeeInputStartDate}
                    />
                  </div>
                  <div className="datePicker">
                    <label htmlFor="pickTo">To</label>
                    <input
                      type="date"
                      name=""
                      id="pickTo"
                      max={maxDate}
                      className="form-control"
                      onChange={handleServicefeeInputEndDate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row searchStaff">
                <div className="col-12 px-0">
                  <div className="searchBox">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="search customer"
                      value={searchText}
                      onChange={__handleServicefeeSearch}
                    />
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </div>
                </div>
              </div>

              <div className="table-wraper mt-4">
                <div className="top-action"></div>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Customer</th>
                      <th>Restaurant</th>
                      <th>Type</th>
                      <th>Amount ($)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {myservicefee &&
                      myservicefee?.map((data, index) => {
                        const date = new Date(data?.createdAt);

                        // Extract the day, month, and year values
                        const day = date.getDate().toString().padStart(2, "0");
                        const month = (date.getMonth() + 1)
                          .toString()
                          .padStart(2, "0");
                        const year = date.getFullYear();

                        // Format the date as dd/mm/yyyy
                        const formattedDate = `${day}/${month}/${year}`;
                        return (
                          <tr key={index}>
                            <td>{formattedDate}</td>
                            <td>
                              <p>
                                {data?.is_guest_tip == true
                                  ? data?.guest?.guest_name
                                  : data?.customerDetails?.full_name}
                              </p>
                              <p>
                                {data?.is_guest_tip == true
                                  ? data?.guest.guest_email
                                  : data?.customerDetails?.email}
                              </p>{" "}
                            </td>
                            <td>{data?.restroDetails?.restaurant_name}</td>
                            <td>
                              {data?.is_group_tip == true
                                ? "Group Tip"
                                : "Staff Tip"}
                            </td>
                            <td>{data?.service_charge}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  {myservicefee?.length == 0 ? "No Records found" : ""}
                </div>
                <div className="text-end d-flex">
                  <Pagination className="ml-auto">
                    {myservicefeetotalpages > 1 ? (
                      <ReactPaginate
                        breakLabel={"..."}
                        nextLabel={"Next >"}
                        onPageChange={handleServiceFeePageChange}
                        pageCount={myservicefeetotalpages}
                        previousLabel={"< Back"}
                        renderOnZeroPageCount={null}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    ) : null}
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
          <div className={`row tabContent ${historyState !== 2 && "d-none"}`}>
            <div className="col-12">
              <div className="content">
                <div className="price">
                  <h3>$ {totalDonationamount}</h3>
                  <p>Earning Through Donation</p>
                </div>
                <div className="dates">
                  <div className="datePicker">
                    <label htmlFor="pickFrom">From</label>
                    <input
                      type="date"
                      name=""
                      id="pickFrom"
                      max={maxDate}
                      className="form-control"
                      onChange={handleDonationInputStartDate}
                    />
                  </div>
                  <div className="datePicker">
                    <label htmlFor="pickTo">To</label>
                    <input
                      type="date"
                      name=""
                      id="pickTo"
                      max={maxDate}
                      className="form-control"
                      onChange={handleDonationInputEndDate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row searchStaff">
                <div className="col-12 px-0">
                  <div className="searchBox">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="search customers"
                      value={searchText}
                      onChange={__handleDonationSearch}
                    />
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </div>
                </div>
              </div>
              <div className="table-wraper mt-4">
                <div className="top-action"></div>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Customer</th>
                      <th>Restaurant</th>
                      <th>Type</th>
                      <th>Amount ($)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mydonationfee &&
                      mydonationfee?.map((data, index) => {
                        const date = new Date(data?.createdAt);

                        // Extract the day, month, and year values
                        const day = date.getDate().toString().padStart(2, "0");
                        const month = (date.getMonth() + 1)
                          .toString()
                          .padStart(2, "0");
                        const year = date.getFullYear();

                        // Format the date as dd/mm/yyyy
                        const formattedDate = `${day}/${month}/${year}`;
                        return (
                          <tr key={index}>
                            <td>{formattedDate}</td>
                            <td>
                              <p>
                                {data?.is_guest_tip == true
                                  ? data?.guest?.guest_name
                                  : data?.customerDetails?.full_name}
                              </p>
                              <p>
                                {data?.is_guest_tip == true
                                  ? data?.guest?.guest_email
                                  : data?.customerDetails?.email}
                              </p>{" "}
                            </td>
                            <td>{data?.restroDetails?.restaurant_name}</td>
                            <td>
                              {data?.is_group_tip == true
                                ? "Group Tip"
                                : "Staff Tip"}
                            </td>
                            <td>{data?.donation_amount}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  {mydonationfee?.length == 0 ? "No Records found" : ""}
                </div>
                <div className="text-end d-flex">
                  <Pagination className="ml-auto">
                    {mydonationtotalpages > 1 ? (
                      <ReactPaginate
                        breakLabel={"..."}
                        nextLabel={"Next >"}
                        onPageChange={handleDonationFeePageChange}
                        pageCount={mydonationtotalpages}
                        previousLabel={"< Back"}
                        renderOnZeroPageCount={null}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    ) : null}
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TotalEarning;
