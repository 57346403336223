
import HomeHeader from "../../homePages/common/HomeHeader";
import faq from "../../../assets/images/askedQuestions.png";
// import faq from "../../assets/images/askedQuestions.png";
function PaymentSuccess() {
  return (
    <div className="staffProfile">
     <HomeHeader/>
      <div className="container">
     
        
          <div className="col-lg-6">
            <div className="profileContent">
          
            </div>
          </div>
          <div className="col-lg-6"></div>
        </div>
        <div className="row TippingPayment py-5">
          <div className="col-12 text-center">
            <h3>Tip Sent Successfully</h3>
            <img src={faq} alt="" className="fitContent" />
          </div>
        </div>
      {/* </div> */}
    </div>
  );
}

export default PaymentSuccess;
