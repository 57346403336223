import React from "react";
import { useSelector } from "react-redux";
// import ContractorInterfaceLayout from '../../../components/common/layout/ContractorInterfaceLayout';
import HomeLayout from "../../../components/common/layout/HomeLayout";
import RestoDashboard from "../RestoDashboard";
import RIRestaurentBranch from "../components/MainRestodashboard";
import SIInvitationRequest from "../components/RestroInvitationrequest";
import ManageStaff from "../components/RestoManageStaff";
import StaffViewProfile from "../components/StaffViewProfile";
import TippingHistory from "../../RestoInterface/components/ViewTippingHistory";
import TippingViewHistory from "../components/TippingHistoryDetails";
import TipManagement from "../components/TipManagement";
import TipManagementDistributed from "../components/TipDistribute";
import TipRequestSent from "../components/TipRequestSent";
import ContactUs from "../components/ContactUs";
import Report from "../components/Report";
const RestoInterfaceRoute = [
  {
    path: `/resto/dashboard`,
    layout: HomeLayout,
    component: <RestoDashboard />,
  },
  {
    path: `/resto/mainrestodashboard`,
    layout: HomeLayout,
    component: <RIRestaurentBranch />,
  },
  {
    path: `/resto/invitationrequest`,
    layout: HomeLayout,
    component: <SIInvitationRequest />,
  },
  {
    path: `/resto/managestaff`,
    layout: HomeLayout,
    component: <ManageStaff />,
  },
  {
    path: `/resto/staffviewprofile/:id`,
    layout: HomeLayout,
    component: <StaffViewProfile />,
  },
  {
    path: `/resto/tippinghistory/:id`,
    layout: HomeLayout,
    component: <TippingHistory />,
  },
  {
    path: `/resto/tippingviewhistory/:id`,
    layout: HomeLayout,
    component: <TippingViewHistory />,
  },
  {
    path: `/resto/tipmanagement`,
    layout: HomeLayout,
    component: <TipManagement />,
  },
  {
    path: `/resto/tipdistributedpage/:id`,
    layout: HomeLayout,
    component: <TipManagementDistributed />,
  },
  {
    path: `/resto/tiprequestsent/:id`,
    layout: HomeLayout,
    component: <TipRequestSent/>,
  },
  {
    path: `/resto/contactus`,
    layout: HomeLayout,
    component: <ContactUs/>,
  },
  {
    path: `/resto/report`,
    layout: HomeLayout,
    component: <Report/>,
  },

];

export default RestoInterfaceRoute;
