import React from 'react';


import Dashboard from '../components/Dashboard';
import AdminLayout from '../../../components/common/layout/AdminLayout';
import FaqManagement from '../components/content-management/FaqManagement';
import AddFaq from '../components/content-management/AddFaq';
import LandingPage from '../components/content-management/LandingPage';
import TotalPubs from '../components/pub-management/TotalPubs';
import PaymentHistory from '../components/payment-management/PaymentHistory';
import ServiceFees from '../components/payment-management/ServiceFees';
import SetupServiceFees from '../components/payment-management/SetupServiceFees';
import Howitworks from '../components/content-management/HowItWorks';
import AddHowitworks from '../components/content-management/AddHowitworks';
import StaffManagement from '../components/staff-management/staffmanagement';
import CustomerManagement from '../components/customer-management/CustomerManagement';
import Donationamount from '../components/payment-management/Donation';
import TotalEarning from '../components/MyEarning';
import TotalTipReceived from '../components/TotalTipReceived';
import RequestReceived from '../components/RequestReceived';
import EnquiryList from '../components/queries/EnquiryList';
import ContactSupport from '../components/queries/ContactSupport';
import ContactUs from '../components/queries/ContactUs';
import EnquiryListView from '../components/queries/EnquiryListView';
import ContactSupportView from '../components/queries/ContactSupportView';
import ContactUsView from '../components/queries/ContactUsView';
import NotificationList from '../components/notifications&alerts/Notifications&Emails';
import AddNotification from '../components/notifications&alerts/AddNotifications';
import AddTemplate from '../components/notifications&alerts/AddTemplate';
import Notification_List from '../../Notifications';



const SuperAdminInterfaceRoute = [
    {
        path: `/super-admin/dashboard`,
        layout: AdminLayout,
        component: <Dashboard />
    },
    {
        path: `/super-admin/dashboard`,
        layout: AdminLayout,
        component: <  Dashboard />
    },
    {
        path: `/super-admin/faq-management`,
        layout: AdminLayout,
        component: <  FaqManagement />
    },
    {
        path: `/super-admin/add-faq/:id`,
        layout: AdminLayout,
        component: <  AddFaq />
    },
    {
        path: `/super-admin/add-faq`,
        layout: AdminLayout,
        component: <  AddFaq />
    },
    {
        path: `/super-admin/landingpage`,
        layout: AdminLayout,
        component: <  LandingPage />
    },
    {
        path: `/super-admin/totalpubs`,
        layout: AdminLayout,
        component: <  TotalPubs />
    },
    {
        path: `/super-admin/donation`,
        layout: AdminLayout,
        component: <  Donationamount />
    },
    {
        path: `/super-admin/payment-history`,
        layout: AdminLayout,
        component: <  PaymentHistory />
    },
    {
        path: `/super-admin/service-fees`,
        layout: AdminLayout,
        component: <  ServiceFees />
    },
    {
        path: `/super-admin/setup-servicefees`,
        layout: AdminLayout,
        component: <  SetupServiceFees />
    },
    {
        path: `/super-admin/how-it-works`,
        layout: AdminLayout,
        component: < Howitworks />
    },
    {
        path: `/super-admin/add-how-it-works/:id`,
        layout: AdminLayout,
        component: < AddHowitworks />
    },
    {
        path: `/super-admin/add-how-it-works`,
        layout: AdminLayout,
        component: < AddHowitworks />
    },
    {
        path: `/super-admin/staff-management`,
        layout: AdminLayout,
        component: < StaffManagement />
    },
    {
        path: `/super-admin/customer-management`,
        layout: AdminLayout,
        component: <  CustomerManagement />
    },
    {
        path: `/super-admin/total-earning`,
        layout: AdminLayout,
        component: <  TotalEarning />
    },
    {
        path: `/super-admin/total-tipreceived`,
        layout: AdminLayout,
        component: <  TotalTipReceived />
    },
    {
        path: `/super-admin/request-received`,
        layout: AdminLayout,
        component: <  RequestReceived />
    },
    {
        path: `/super-admin/enquiry-list`,
        layout: AdminLayout,
        component: <  EnquiryList />
    },
    {
        path: `/super-admin/contact-support`,
        layout: AdminLayout,
        component: <  ContactSupport />
    },
    {
        path: `/super-admin/contact-us`,
        layout: AdminLayout,
        component: <  ContactUs />
    },
    {
        path: `/super-admin/enquiry-list-view/:id`,
        layout: AdminLayout,
        component: <  EnquiryListView />
    },
    {
        path: `/super-admin/contact-support-view/:id`,
        layout: AdminLayout,
        component: <  ContactSupportView/>
    },
    {
        path: `/super-admin/contact-us-view/:id`,
        layout: AdminLayout,
        component: <  ContactUsView/>
    },
    {
        path: `/super-admin/notification-list`,
        layout: AdminLayout,
        component: <  NotificationList/>
    },
    {
        path: `/super-admin/add-notification/:id`,
        layout: AdminLayout,
        component: <  AddNotification/>
    },
    {
        path: `/super-admin/add-notification`,
        layout: AdminLayout,
        component: <  AddNotification/>
    },
    {
        path: `/super-admin/add-template`,
        layout: AdminLayout,
        component: <  AddTemplate/>
    },
    {
        path: `/super-admin/add-template/:id`,
        layout: AdminLayout,
        component: <  AddTemplate/>
    },
    {
        path: `/super-admin/adminnotification-list`,
        layout: AdminLayout,
        component: <  Notification_List/>
    },
]

export default SuperAdminInterfaceRoute;