import React, { useEffect, useState } from "react";
import Pagination from "react-bootstrap/Pagination";
import ReactPaginate from "react-paginate";
import { pagination } from "../components/common/utils/message";
import { useDispatch } from "react-redux";

import { _get_NotificationList } from "./super-admin/service/Auth.service";
import { NotificationDeletes } from "./homePages/Service/AuthService";
function Notification_List() {
  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const [noNotificationList, setNotificationList] = useState();
  const [totalPages, settotalPages] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    _getNotificationList();
  }, [dispatch]);
  const _getNotificationList = () => {
    _get_NotificationList(
      { page, limit },
      (data) => {
        setNotificationList(data?.docs);
        settotalPages(data?.totalPages);
      },
      dispatch
    );
  };
  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await _get_NotificationList(
      { page: currentPage, limit },
      (response) => {
        setNotificationList(response?.docs);
      },
      dispatch
    );
  };
  const formatNotificationDate = (createdAt) => {
    const notificationTime = new Date(createdAt);
    const now = new Date();
    const timeDiffInSeconds = Math.floor((now - notificationTime) / 1000);

    if (timeDiffInSeconds < 60) {
      return `${timeDiffInSeconds} seconds ago`;
    } else if (timeDiffInSeconds < 3600) {
      const minutes = Math.floor(timeDiffInSeconds / 60);
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else if (timeDiffInSeconds < 86400) {
      const hours = Math.floor(timeDiffInSeconds / 3600);
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else {
      const year = notificationTime.getFullYear();
      const month = padNumber(notificationTime.getMonth() + 1);
      const day = padNumber(notificationTime.getDate());
      return `${year}-${month}-${day}`;
    }
  };

  const padNumber = (number) => {
    return number.toString().padStart(2, "0");
  };
  const handleDelete = (data) => {
    NotificationDeletes(
      {_id:data?._id },
      (result) => {
        if (result?.status == true) {
        
          _getNotificationList();
        }
      },
      dispatch
    );
  };
  return (
    <>
      <h2 className="page-title">Notifications</h2>

      <div className="content-wraper">
        <div className="table-wraper">
          <div className="notification">
            <div className="container-fluid"></div>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {noNotificationList &&
                    noNotificationList?.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className={`${
                            index === 0
                              ? "recentNotification notifications"
                              : "notifications"
                          } `}
                        >
                          <div className="text">
                            <p className="mb-0">{data?.message}</p>
                          </div>
                          <div className="time">
                            <p className="mb-0">
                              {formatNotificationDate(data?.createdAt)} <a onClick={(e)=>handleDelete(data)}><i class="fa-solid fa-circle-xmark"></i></a>
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      {noNotificationList?.length == 0
                        ? "No Records found"
                        : ""}
                    </div>
                <div className="text-end d-flex">
                  <Pagination className="ml-auto">
                    {totalPages > 1 ? (
                      <ReactPaginate
                        breakLabel={"..."}
                        nextLabel={"Next >"}
                        onPageChange={handlePageChange}
                        pageCount={totalPages}
                        previousLabel={"< Back"}
                        renderOnZeroPageCount={null}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    ) : null}
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notification_List;
