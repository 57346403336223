import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import HomeHeader from "../../homePages/common/HomeHeader";
import { useDispatch, useSelector } from "react-redux";
import HomeHeader from '../../features/homePages/common/HomeHeader'
import DefaultImage from "../../assets/images/defaultimage.png";
import { useNavigate } from "react-router-dom";
import { Card, Carousel, Modal } from "react-bootstrap";
import { Grid } from "@mui/material";
import Heart from "react-heart";
import { format } from 'date-fns'
import ImageViewer from 'react-simple-image-viewer';
import { API_BASEURL } from "../../environment";
import { commentPost, deletePost, deletePostComment, editPost, getByUserProfile, likePost, updateProfilepage } from "../homePages/Service/AuthService";
import { toast } from "react-toastify";
import { addUserPost, deleteGallery, editGallery, uploadPost } from "../../services/auth.service";
import { useMediaQuery } from "react-responsive";
// import { editComment } from "../../../../TippingBackendNew/controller/post";
function StaffPost() {
  const urlParams = new URLSearchParams(window.location.search);
  let tabvalue = urlParams?.get("tabValue");
  const [historyState, setHistoryState] = useState(0);
  useEffect(()=>{
if(tabvalue == 1){
  setHistoryState(1);
}else{
  setHistoryState(0)
}
  },[tabvalue])
  const [userDetails, setUserDetails] = useState();
  const [showPhotodetail, setShowPhotodetail] = useState("");
  const [reply, showReply] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showGalleryModal, setGalleryShowModal] = useState(false);
  const [showGalleryImage, setGalleryShowImage] = useState(false);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData1, setUserdata1] = useState(0);
  const [updateUploader, showUpdateuploder] = useState(false);
  const [addImage, showaddImage] = useState("");
  const [postImages, setPostimages] = useState([]);
  const [postImages1, setPostimages1] = useState([]);
  const [pubVideo, setpubVideo] = useState("")
  const [pubVideoFile, setpubVideoFile] = useState("")
  const [showBlog, setShowblog] = useState(false)
  const [pubAudio, setpubAudio] = useState("")
  const [pubAudioFile, setpubAudioFile] = useState("")
  const [active, setActive] = useState(false);
  const [lastRecord, setLastRecord] = useState([]);
  const [bioModal,showBioModal] = useState(false)
  const [count, setCount] = useState(false);
  const [showTip, setShowTip] = useState(false);
  const [disableDesciption, setdisableDescription] = useState(true);
  const [fileErr, setFileErr] = useState(true);
  const [imageViewer, setImageViewer] = useState(true);
  const userdata = useSelector((state) => state);

  const isDesktopOrLaptop = useMediaQuery({ maxWidth: 576 })
  const { id } = useParams()
  let user_id = id !== undefined ? id : userdata?.login?.data?.result._id;

  let checkId = userdata?.login?.data?.result?._id;
  let loggedIn = userdata?.login?.isLoggedIn;
  let isLoggedIns = userdata?.login?.isLoggedIn;
  let customerId = userdata?.login?.data?.result?._id;
  let role = userdata?.login?.data?.role;
  let image = userdata?.login?.data?.result?.profile
  const [input, setInput] = useState({
    _id: user_id,
    quickUpdate: "",
    postTitle: "",
    postDescription: "",
    description: "",
    bio:"",

  });

  const handleChange1 = (e) => {
    const { name, value } = e.target;

    setInput((previousValue) => ({
      ...previousValue,
      [name]: value,
    }))
  };
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const pubImgaeupload = (e) => {
    let file = e.target.files
    if (e.target.files.length + postImages.length >= 8) {
      if (e.target.files.length + postImages.length == 8) {
        setImageViewer(false)
      } else {
        return toast.error(
          "Selected Images are more then 8",
          {
            theme: "dark",

            position: toast.POSITION.BOTTOM_RIGHT,
          },
          { autoClose: 1000 }
        );

      }

    }
    for (let i = 0; i <= e.target.files.length - 1; i++) {
      setPostimages(prevState => [...prevState, file[i]])
      setPostimages1(prevState => [...prevState, file[i]])
    }


  };





  const galleryupload = (e) => {

    let file = e.target.files
    e.preventDefault();
    const formData = new FormData();
    formData.append("_id", input._id);
    formData.append("image", file[0])
    try {
      addUserPost(
        formData,
        (result) => {
          getByusersId()
          setShowModal(false)
          if (result.status === "Success") {
            toast.success(
              "Photo added successfully",
              {
                theme: "dark",
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000
              },


            );

          } else if (result.status === "Error") {
            toast.error(
              "Photo not added successfully",
              {
                theme: "dark",

                position: toast.POSITION.BOTTOM_RIGHT,
              },
              { autoClose: 1000 }
            );
          } else {
            toast.error(
              result.message,
              {
                theme: "dark",

                position: toast.POSITION.BOTTOM_RIGHT,
              },
              { autoClose: 1000 }
            );

          }
        },
        dispatch
      );
    } catch (e) {
      console.log("Error in add photos==>", e.message)
    }


  };
  const __editGallery = (e, id) => {
    let file = e.target.files
    const formData = new FormData();
    formData.append("_id", input._id);
    formData.append("image", file[0])
    formData.append("image_id", id)
    try {
      editGallery(
        formData,
        (result) => {
          getByusersId()
          setShowModal(false)
          if (result.status === "Success") {
            toast.success(
              "Photo added successfully",
              {
                theme: "dark",
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000
              },


            );

          } else if (result.status === "Error") {
            toast.error(
              "Photo not added successfully",
              {
                theme: "dark",

                position: toast.POSITION.BOTTOM_RIGHT,
              },
              { autoClose: 1000 }
            );
          } else {
            toast.error(
              result.message,
              {
                theme: "dark",

                position: toast.POSITION.BOTTOM_RIGHT,
              },
              { autoClose: 1000 }
            );

          }
        },
        dispatch
      );
    } catch (e) {
      console.log("Error in add photos==>", e.message)
    }


  };
  const galleryDelete = (val) => {
    setGalleryShowModal(false)
    deleteGallery(
      val,
      (result) => {
        getByusersId()
        if (result.status === "Success") {
          toast.success(
            "Photo Deleted successfully",
            {
              theme: "dark",
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1000
            },


          );

        } else if (result.status === "Error") {
          toast.error(
            "Photo not added successfully",
            {
              theme: "dark",

              position: toast.POSITION.BOTTOM_RIGHT,
            },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            result.message,
            {
              theme: "dark",

              position: toast.POSITION.BOTTOM_RIGHT,
            },
            { autoClose: 1000 }
          );

        }
      },
      dispatch
    );


  };


  const pubVideoupload = (e) => {


    if (e.target.files[0].size > 10000000) {
      toast.error(
        "Vedio should be less than 10 MB",
        {
          theme: "dark",
          position: toast.POSITION.BOTTOM_RIGHT,
        },
        { autoClose: 1000 }
      );
      return
    }


    setpubVideo(URL.createObjectURL(e.target.files[0]))
    setpubVideoFile(e.target.files[0]);

  };
  const uploadpubAudio = (e) => {
    setpubAudio(URL.createObjectURL(e.target.files[0]))
    setpubAudioFile(e.target.files[0])
  }


  useEffect(() => {
    const mediaElements = document.querySelectorAll('audio, video');
    mediaElements.forEach(media => {
      media.addEventListener('play', function () {
        mediaElements.forEach(otherMedia => {
          if (otherMedia !== media) {
            otherMedia.pause();
          }
        });
      });
    });

  }, [document.querySelectorAll('audio, video')])



  const removeImage = (index) => {
    setPostimages1([
      ...postImages1.slice(0, index),
      ...postImages1.slice(index + 1, postImages1.length),
      
    ]);
    let imegFile = document.querySelector(".imageFile");
      if(imegFile)
      imegFile.value="";
    setPostimages([
      ...postImages.slice(0, index),
      ...postImages.slice(index + 1, postImages.length),
    ]);
    if (postImages.length > 8) {
      setImageViewer(false)
    } else {
      setImageViewer(true)
    }
  };

  const removeVideo = () => {
    if (addImage == "video"){
      setpubVideo("");
      setpubVideoFile([])
      let videoFile = document.querySelector(".videoFile");
      if(videoFile)
      videoFile.value=""
    }

    if (addImage == "audio")
      setpubAudio("");
      setpubAudioFile([])
      let audioFile = document.querySelector(".audios");
      if(audioFile)
      audioFile.value=""
  };

  const onTabClick = (tabId) => () => {
    setHistoryState(tabId);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get('code');
    if (paramValue) {
      if (paramValue == "2381") {
        toast.success(
          "Payment Success",
          {
            theme: "dark",
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000
          },
        );

      } else {
        toast.error(
          "Payment Failed",
          {
            theme: "dark",
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000
          },
        );

      }
      navigate('')
    }
    getByusersId();
  }, []);

  let divElement = document.querySelector(".resst");

  let elemHeight;
  if (divElement) {
    elemHeight = divElement.offsetHeight;
  }
  const height = elemHeight + 200;
  const getByusersId = async (val) => {
    await getByUserProfile(
      { _id: user_id },
      (data) => {
        setUserDetails(data[0]);
        setLastRecord(data[0]?.photoGallery)
        console.log("data", data[0])
        if (val === "Update" && data[0]?.posts) {
          for (let post of data[0]?.posts) {
            if (post?._id === showPhotodetail?._id) {
              setShowPhotodetail(post)
              showReply("")
            }
          }
        }
        if (data[0]?.gatewayPlatform) {
          for (let user of data[0]?.gatewayPlatform) {
            if (user.status === "Active") {
              setShowTip(true)
            }
          }
        }

      },
      dispatch
    );

  };

  useEffect(() => {

    if (showPhotodetail?.likes) {
      let like = showPhotodetail?.likes.some(obj => obj.user_id === userdata?.login?.data?.result?._id)
      setActive(like)
      setCount(showPhotodetail?.likes.length)
    } else {
      setActive(false)
    }


  }, [showPhotodetail])

  const __likePost = (id, res) => {
    if (!loggedIn) {

      toast.error(
        "Please Login To Like this post!",
        {
          theme: "dark",
          position: toast.POSITION.BOTTOM_RIGHT,
        },
        { autoClose: 1000 }
      );

    } else {

      likePost(
        { _id: res._id, user_id: userdata?.login?.data?.result?._id },
        (res) => {
          getByusersId()

          if (res?.data?.result?.likes) {
            setCount(res?.data?.result?.likes.length)
          } else {
            setCount(0)
          }
          let like = res?.data?.result?.likes.some(obj => obj.user_id === userdata?.login?.data?.result?._id)
          setActive(like)

        },
        dispatch
      );

    }
  };
  const __commentPost = (id, res, commID) => {
    if (!loggedIn) {
      toast.error(
        "Please Login to Comment on this Post!",
        {
          theme: "dark",
          position: toast.POSITION.BOTTOM_RIGHT,
        },
        { autoClose: 1000 }
      );

    } else {

      commentPost(
        { post_id: res._id, user_id: id, comment: commID ? input.replyComment : input.comment, comment_id: commID },
        (res) => {
          if (res?.data?.status == "Success") {
            getByusersId("Update")
            clearinputField()
          }

        },
        dispatch
      );
    };
  }

  function viewGallery() {
    onTabClick(1);
    setHistoryState(1);
  }
  const __editPost = async (e) => {
    input._id = e._id
    try {
      await editPost(
        input,
        (result) => {
          setInput({})
          showaddImage(false)
          showUpdateuploder(false)
          setShowPhotodetail(false)
          setdisableDescription(true)
          getByusersId()
          if (result?.data?.status === "Success") {
            toast.success(
              "Post updated successfully",
              {
                theme: "dark",
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000
              },
            );

          } else if (result?.data?.status === "Failed") {
            toast.error(
              "Post not updated successfully",
              {
                theme: "dark",
                position: toast.POSITION.BOTTOM_RIGHT,
              },
              { autoClose: 1000 }
            );
          }
        },
        dispatch
      );
    } catch (e) {
      console.log(e)
    }
  };

  const __deletePost = async (e) => {
    input._id = e._id
    try {

      await deletePost(
        input,

        (result) => {
          setInput({})
          showaddImage(false)
          setShowModal(false)
          setShowPhotodetail(false)
          getByusersId()
          if (result?.data?.status === "Success") {
            toast.success(
              "Post Deleted successfully",
              {
                theme: "dark",
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000
              },
            );

          } else if (result?.data?.status === "Failed") {
            toast.error(
              "Post not Deleted successfully",
              {
                theme: "dark",
                position: toast.POSITION.BOTTOM_RIGHT,
              },
              { autoClose: 1000 }
            );
          }
        },
        dispatch
      );
    } catch (e) {
      console.log(e)
    }
  };

  const __deleteComment = async (payload) => {
    deletePostComment(
      payload, (res) => {
        setShowModal(false)
        if (res?.data?.status == "Success") {
          getByusersId("Update")
          clearinputField()
        } else if (res?.data?.status === "Failed") {
          toast.error(
            "Comment not Deleted successfully",
            {
              theme: "dark",
              position: toast.POSITION.BOTTOM_RIGHT,
            },
            { autoClose: 1000 }
          );
        }

      },
      dispatch
    );
  };
  const clearinputField = () => {
    document.querySelector(".com").value = ""
    document.querySelector(".reply").value = ""
  }

  const handleSubmitdata = async (e) => {
    setFileErr(false)
    if (addImage === "image" || addImage === "video" || addImage === "audio") {
      if (addImage === "image" && postImages.length == 0) {
        return setFileErr("Plaese Upload Atleast One Image")
      } else if (addImage === "video" && pubVideoFile.length == 0) {
        return setFileErr("Plaese Upload Vedio")
      } else if (addImage === "audio" && pubAudioFile.length == 0) {
        return setFileErr("Plaese Upload Audio")
      }
    }
    const formData = new FormData();
    formData.append("user_id", userdata?.login?.data?.result._id);
    formData.append("quickUpdate", input.quickUpdate);
    if (addImage === "image") {
      for (let i = 0; i < postImages.length; i++) {
        formData.append("image", postImages[i])
      }
    } else if (addImage === "video") {
      formData.append("video", pubVideoFile)
    } else if (addImage === "audio") {
      formData.append("audio", pubAudioFile)
    }
    if (input.title) {
      formData.append("title", input.title);
    }
    if (input.description) {
      formData.append("description", input.description);
    }
    formData.append("postType", e === "blog" || e === "quickUpdate" ? e : addImage);

    try {
      await uploadPost(
        formData,
        addImage,
        (result) => {
          window.location.reload()
          if (result?.status === "Success") {
            toast.success(
              "Post added successfully",
              {
                theme: "dark",
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000
              },
            );
          } else if (result?.status === "Failed") {
            toast.error(
              "Post not added successfully",
              {
                theme: "dark",
                position: toast.POSITION.BOTTOM_RIGHT,
              },
              { autoClose: 1000 }
            );
          }
        },
        dispatch
      );
    } catch (e) {
      console.log(e)
    }
  };


  



  const handleSubmitdata22 = async (e) => {
    
    e.preventDefault();
   
      const formData = new FormData();
      formData.append("_id", checkId);
      formData.append("bio", input.bio);
      try {
        await updateProfilepage(
          formData,

          (result) => {
            if (result.status == "Success") {
              // window.location.reload();
              getByusersId()
              showBioModal(false)
              toast.success(
                "Data updated successfully",
                {
                  theme: "dark",
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 1000
                },
              );
            } else if (result.status == "Error") {
              toast.error(
                "Data not added successfully",
                {
                  theme: "dark",


                  position: toast.POSITION.TOP_CENTER,
                },
                { autoClose: 1000 }
              );
            }


          },
          dispatch
        );
      } catch (e) {
        // failed(e);
      }
  
  };

  let ProfilePath = `${API_BASEURL}/public/profile/${userDetails?.profile}`;
  const buttonHandlerpage = async (data) => {
    if (!showTip) {
      toast.error(
        "User not accepting Payments",
        {
          theme: "dark",
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000
        },
      );
      return
    }
    navigate(`/customer/stafftipping/${data?._id}/staff`);
  };
  const navigatetoback = () => {
    navigate("/customer/dashboard");
  };

  let ProfilePath1 = []
  if (lastRecord) {
    for (let data of lastRecord) {
      ProfilePath1.push(`${API_BASEURL}/public/posts/${data?.image}`)
    }
  }
 
  

  return (
    <div className="staffProfile">
      <HomeHeader />
      <div className="container" style={{ paddingBottom: "5px" }}>
        {/* <SearchFilter /> */}
        <div className="row my-4">
          <div className="col direction">
            {loggedIn == true ? (
              <p class="mb-0 whiteText">
                <a
                  onClick={
                    role == 2
                      ? () => navigate("/resto/mainrestodashboard")
                      : role == 3
                        ? () => navigate("/staff/dashboard")
                        : navigatetoback
                  }>
                  Home
                </a>
                <i class="fa-solid fa-angles-right"></i>
                {userDetails?.staffDetails?.full_name}
              </p>
            ) : (
              <p class="mb-0 whiteText">
                <a onClick={() => navigate("/")}>Home</a>
                <i class="fa-solid fa-angles-right"></i>
                {userDetails?.staffDetails?.full_name}
              </p>
            )}
          </div>
        </div>
        <div className="row profileData staffPostData">
          <div className="col-lg-6">
            <div className="profileContent">
              <div className="image">
                <img
                  src={
                    userDetails?.profile != null ||
                      userDetails?.profile != undefined
                      ? ProfilePath
                      : DefaultImage
                  }
                  alt=""
                />
              </div>
              <div className="text ">
                <h1 className="Aeonik-Bold ">
                  {userDetails?.full_name}
                </h1>
                <p className="Aeonik-Regular ">PubStar</p>
                <div className="review"></div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 dflex jce alc">
            <div className="row">
              <div className="col-12">
                <div className="feedback">
                  <h2></h2>
                  {id !== undefined &&
                    <button
                      className="btn btn-main btn-height "
                      onClick={(e) => buttonHandlerpage(userDetails)}>
                      Tip
                    </button>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      <div className="tippingHistroy">
        <div className="container-fluid postContainer" >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="tabs">
                  <div
                    className={`tab ${historyState === 0 && "active"}`}
                    onClick={onTabClick(0)}>
                    Feed
                    <div className="line"></div>
                  </div>
                  <div
                    className={`tab ${historyState === 1 && "active"}`}
                    onClick={onTabClick(1)}>
                    Gallery
                    <div className="line"></div>
                  </div>


                </div>
              </div>
            </div>

            <div className={`row tabContent ${historyState !== 0 && "d-none"}`}>
              <Grid container className="pubstarAbout" columnSpacing={2}>
                <Grid item lg={4.3} xs={12}>
                  <div className="col-lg-12 col-12">
                    
                      <Card className="p-2">
                        <div className="staffUpdate">
                          <div className="row dflex alc">
                          {id === undefined && <>
                            <div className="col-2">
                              <img src={ProfilePath} alt="" />
                            </div>
                            <div className="col-10 quickUpdate">
                              <button
                                type="button"
                                onClick={() => showUpdateuploder(true)}>
                                Write a Quick Update
                              </button>
                            </div></>}
                            <div className="col-10 mt-4">
                            <span className="text" style={{paddingLeft:"10px" , fontSize: "large" }}>Bio</span>
                              {/* <button
                                type="button"
                                style={{
                                  width: "100%",
                                  paddingLeft: "15px",
                                  paddingRight: "15px",
                                }}
                                className="btn btn-main btn-height"
                                onClick={() => showaddImage("image")}>
                                <span>🖼️</span>Images
                              </button> */}
                            </div>
                            <div className="col-2 mt-4" >
                            {id === undefined && <i class="fa fa-pencil cursorPointer" onClick={()=> showBioModal(true)}  aria-hidden="true"></i>}
                             
                            </div>

                            <div className="col-12 mt-4" >
                            <p style={{paddingLeft:"10px" , fontSize: "large" }}>{userDetails?.bio}</p>
                             
                            </div>
                            {/* <div className="col-6 mt-2">
                              <button
                                type="button"
                                style={{
                                  width: "100%",
                                  paddingLeft: "15px",
                                  paddingRight: "15px",
                                }}
                                onClick={() => setShowblog(true)}
                                className="btn btn-main btn-height"
                              >
                                <span>📝</span>Blog Post
                              </button>
                            </div>
                            <div className="col-6 mt-2">
                              <button
                                type="button"
                                style={{
                                  width: "100%",
                                  paddingLeft: "15px",
                                  paddingRight: "15px",
                                }}
                                onClick={() => showaddImage("video")}
                                className="btn btn-main btn-height"
                              >
                                <span>🎬</span>Video
                              </button>
                            </div>
                            <div className="col-6 mt-2">
                              <button
                                type="button"
                                style={{
                                  width: "100%",
                                  paddingLeft: "15px",
                                  paddingRight: "15px",
                                }}
                                onClick={() => showaddImage("audio")}
                                className="btn btn-main btn-height"
                              >
                                <span>🎧</span>Audio
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </Card>
                    

                  </div>
                  <div className={id === undefined ? "col-lg-12 col-12 mt-3" : "col-lg-12 col-12 mt-2"}>
                    <Card className="about">
                      <div className="galleryText">
                        <span className="text">Gallery</span>
                        <a onClick={viewGallery}>View Gallery</a>
                      </div>

                      {userDetails?.photoGallery && userDetails?.photoGallery?.length > 0 ?
                        <>
                          <div className="row mt-2">
                            <div className="gallery">
                              <img

                                src={`${API_BASEURL}/public/posts/${userDetails?.photoGallery[userDetails?.photoGallery?.length - 1]?.image}`}

                                alt=""
                              />
                            </div>
                          </div>


                        </>

                        : <>
                          <div className="row mt-2">
                            <div className="gallery">
                              <img
                                src={
                                  userDetails?.profile != null ||
                                    userDetails?.profile != undefined
                                    ? ProfilePath
                                    : DefaultImage
                                }
                                alt=""
                              />
                            </div>
                          </div>
                        </>
                      }

                    </Card>
                  </div>
                </Grid>
                <Grid item lg={7.7} xs={12}>
                  <div className="col-lg-12 col-12">
                    {/* <Card className="about">
                      <p className="text">About</p>
                    </Card> */}
                  </div>

                  <div className="col-lg-12 col-12  resst">
                    <Card className="about">
                      <div className="galleryText">
                        <span style={{ fontSize: "x-large" }} className="text">Feed</span>
                      </div>
                      <hr />

                      {userDetails?.posts.length > 0 ?
                        <>
                          {userDetails?.posts &&
                            userDetails?.posts.toReversed().map(val => {

                              return (
                                <>

                                  <div className="col-12 mt-3">
                                    <div className="pubstarProfile">
                                      <div className="profileImg dflex">
                                        <img
                                          src={
                                            userDetails?.profile != null ||
                                              userDetails?.profile != undefined
                                              ? ProfilePath
                                              : DefaultImage
                                          }
                                          alt=""
                                         
                                        />
                                        &nbsp; &nbsp;
                                        <div className="row">
                                          <div className="col-12">
                                            <strong>
                                              {userDetails?.full_name}

                                            </strong>
                                          </div>
                                          <div className="col-12">
                                            <p style={{ color: "gray", fontSize: "12px" }}>{format(new Date(val?.createdAt), "LLL dd, yyyy 'at' HH:mm:ss")}</p>
                                          </div>
                                        </div>



                                      </div>




                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div class="speech up">
                                      <div className="caption"
                                        onClick={() => setShowPhotodetail(val)}>
                                        <strong className="mb-3">{val.title}</strong>
                                        <p className="descr">{val.description}</p>
                                      </div>
                                      <div className="imageView">
                                        {val.postType == "video" && val.postFiles?.length > 0 &&
                                          val?.postFiles.map(vid => {
                                            return (
                                              <video width="100%"
                                                height="100%" controls
                                                onClick={() => setShowPhotodetail(val)}
                                                style={{ objectFit: "cover" }}>
                                                <source src={`${API_BASEURL}/public/video/${vid?.fileName}`} type="video/mp4"></source>
                                              </video>
                                            )
                                          }

                                          )


                                        }

                                        {val?.postFiles?.length > 1 ? <>

                                          <Carousel slide={false}>
                                            {val.postType == "image" && val.postFiles.length > 0 &&
                                              val?.postFiles.map(imge => (
                                                <Carousel.Item>
                                                  <div>
                                                    <img
                                                      onClick={() => setShowPhotodetail(val)}
                                                      src={
                                                        `${API_BASEURL}/public/posts/${imge?.fileName}`
                                                      }
                                                      alt=""
                                                      className="d-block w-100"
                                                    />


                                                    <Carousel.Caption>

                                                    </Carousel.Caption>
                                                  </div>
                                                </Carousel.Item>

                                              ))
                                            }
                                          </Carousel>

                                        </> : <>
                                          <img
                                            onClick={() => setShowPhotodetail(val)}
                                            src={
                                              `${API_BASEURL}/public/posts/${val?.postFiles[0]?.fileName}`
                                            }
                                            alt=""
                                            className="d-block w-100"
                                          />

                                        </>}

                                        {val.postType == "audio" && val.postFiles.length > 0 &&
                                          val?.postFiles.map(aud => (
                                            <div style={{ padding: "0px 10px" }} onClick={() => setShowPhotodetail(val)}>
                                              <audio
                                                height="100%" controls

                                                style={{ objectFit: "cover", borderRadius: "20px", width: "100%" }}>
                                                <source src={`${API_BASEURL}/public/audio/${aud?.fileName}`} ></source>
                                              </audio>
                                            </div>
                                          ))

                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                </>
                              )
                            }
                            )}</> : <>
                          <span className="text-center">No Feed Found</span></>}
                    </Card>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={`row tabContent ${historyState !== 1 && "d-none"}`} >
              <Grid container className="pubstarAbout">
                {userDetails?._id === userdata?.login?.data?.result?._id && (userDetails?.photoGallery?.length < 8 || !userDetails?.photoGallery) &&
                  <Grid item lg={3} sm={6} xs={12} className="about">
                    <Card>
                      <label htmlFor="postImagesss">
                        <div className="UploadBox">
                          <div className="icon">
                            <i class="fa-solid fa-add"></i>
                          </div>
                          <div className="text">Add</div>
                        </div>
                        <input
                          type="file"
                          name="postImagesss"
                          id="postImagesss"
                          className="d-none"
                          accept="image/*"
                          onChange={(e) => galleryupload(e)}
                        />

                      </label>
                    </Card>
                  </Grid>
                }



                {userDetails?.photoGallery &&
                  userDetails?.photoGallery.toReversed().map((val, index) => (
                    <Grid item lg={3} sm={6} xs={12} className="about">
                      <Card>
                        <div className="gallery">
                          {userDetails._id === userdata?.login?.data?.result?._id &&
                            <>
                              {!isViewerOpen &&
                                <>
                                  <div className="multiImageInfo1">


                                    <label htmlFor={val._id}>
                                      <i class="fas fa-pencil whiteText" ></i>
                                      <input
                                        type="file"
                                        name={val._id}
                                        id={val._id}
                                        className="d-none"
                                        accept="image/*"
                                        onChange={(e) => __editGallery(e, val._id)}

                                      />
                                    </label>
                                  </div>
                                  <div className="multiImageInfo2" onClick={() => {
                                    setGalleryShowModal({ userId: userDetails._id, _id: val._id })
                                  }}>
                                    <i class="fas fa-trash" ></i>

                                  </div></>}
                            </>

                          }

                          <img
                            src={`${API_BASEURL}/public/posts/${val?.image}`}
                            style={{ borderRadius: "20px", cursor: "pointer" }}
                            alt=""
                            onClick={() => openImageViewer(index)}
                          // onClick={() => setGalleryShowImage({ state: true, index: index })}
                          // onClick={() => openImageViewer()} 
                          />
                        </div>

                      </Card>
                    </Grid>

                  )

                  )
                }

                {showPhotodetail.user_id === userdata?.login?.data?.result?._id && (!userDetails?.photoGallery) &&
                  <Grid className="whiteText dflex jcc" item xs={12}>
                    <p >No Image Found
                    </p></Grid>}


              </Grid>
            </div>


          </div>
        </div>
      </div>

      <Modal
        size={"lg"}
        onHide={() => {
          setInput({})
          showReply("")
          setdisableDescription(true)
          setShowPhotodetail(false)
        }}
        show={showPhotodetail}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Modal.Title>
              <div className="modal-heading">

                <img
                  src={
                    userDetails?.profile != null ||
                      userDetails?.profile != undefined
                      ? ProfilePath
                      : DefaultImage
                  }
                  alt=""
                  className="post"
                />{" "}
                &nbsp;&nbsp;
                <span style={{ fontSize: "20px", fontWeight: "700" }}>
                  {" "}
                  {userDetails?.full_name}
                </span>
              </div>
            </Modal.Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-start " style={{ padding: "0px 15px" }}
          >
            <strong className="mb-3 text-start">{showPhotodetail.title}</strong>
            <p className="descr">{showPhotodetail.description}</p>
          </div>
          {showPhotodetail.postType == "video" && showPhotodetail.postFiles.length > 0 &&
            showPhotodetail?.postFiles.map(vid => (
              <video width="100%"
                height="100%" controls
                style={{ objectFit: "cover" }}
              >
                <source src={`${API_BASEURL}/public/video/${vid.fileName}`} type="video/mp4"></source>
              </video>
            ))


          }




          {showPhotodetail.postType == "image" && showPhotodetail.postFiles.length > 1 ? <>
            <Carousel slide={false}>
              {showPhotodetail.postType == "image" && showPhotodetail.postFiles.length > 0 &&
                showPhotodetail?.postFiles.map(imge => (
                  <Carousel.Item>
                    <div>
                      <img
                        src={
                          `${API_BASEURL}/public/posts/${imge.fileName}`
                        }
                        alt=""
                        className="d-block w-100"
                      />


                      <Carousel.Caption>
                        {/* <h3>First slide label</h3>
                  <p>
                    Nulla vitae elit libero, a pharetra augue mollis interdum.
                  </p> */}
                      </Carousel.Caption>
                    </div>
                  </Carousel.Item>

                ))

              }
            </Carousel>

          </> :
            <>
              {showPhotodetail.postType == "image" ? <>
                <img
                  src={
                    `${API_BASEURL}/public/posts/${showPhotodetail?.postFiles[0]?.fileName}`
                  }
                  alt=""
                  className="d-block w-100"
                />
              </> : <></>}
            </>}

          {showPhotodetail.postType == "audio" && showPhotodetail.postFiles.length > 0 &&
            showPhotodetail?.postFiles.map(aud => (
              <audio width="100%"
                height="100%" controls
                style={{ objectFit: "cover", borderRadius: "20px" }}>
                <source src={`${API_BASEURL}/public/audio/${aud.fileName}`} ></source>
              </audio>
            ))

          }

          <div className="likeSection">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: "1.5rem" }}>
                <Heart isActive={active} onClick={() => __likePost(userDetails._id, showPhotodetail)} />
              </div>
              &nbsp;&nbsp;
              <div>{count}</div>
            </div>{" "}{
              showPhotodetail.user_id === userdata?.login?.data?.result?._id &&

              <div className="profileImage dropdown editPost" style={{ marginLeft: "auto" }}>
                <div
                  className="image dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  data-bs-display="static"
                  aria-expanded="false"
                >
                  <i className="fas fa-ellipsis-h feed-dropdown-icon "></i>
                </div>
                <div
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a className="dropdown-item Aeonik-Bold" onClick={() => {

                    if (showPhotodetail.postType == "blog" || showPhotodetail.postType == "quickUpdate") {
                      setdisableDescription(false)
                    }

                    setInput(showPhotodetail)
                    showaddImage("editPost")
                  }}>
                    Edit
                  </a>
                  <a className="dropdown-item Aeonik-Bold" onClick={() => setShowModal(true)} >
                    Delete
                  </a>
                </div>

              </div>

            }

          </div>
          <div className="row comentSection">
            <div className="col-12 mt-3 mb-3" style={{ textAlign: "start" }}>
              <strong>Comments</strong>
            </div>
            <div className="col-1 mt-2 dflex alc">
              <img
                src={
                  userDetails?.profile != null ||
                    userDetails?.profile != undefined
                    ? ProfilePath
                    : DefaultImage
                }
                alt=""
                className="post"
              />
            </div>
            <div className="col-11 borderallSide">
              <div className="row comment">
                <div className="col-10">
                  <div className="inputField">
                    <div className="inputBox">

                      <textarea
                        type="text"
                        className="form-control px-3 bg-none com"
                        id="Write a comment"
                        placeholder="Write a comment"
                        name="comment"
                        autoFocus
                        onChange={handleChange1}
                        defaultValue={input.comment}
                      />
                    </div>
                    {/* {error.requestCode && (
                      <span className="text-danger">{error.requestCode}</span>
                    )} */}
                  </div>
                </div>
                <div className="col-2 dflex alc jcc">
                  <button className="btn btn-main btn-height" disabled={!input.comment ? true : false} onClick={() => __commentPost(userdata?.login?.data?.result?._id, showPhotodetail)}>
                    <i className="far fa-paper-plane"></i>
                  </button>
                </div>
              </div>
            </div>{showPhotodetail?.comments &&
              showPhotodetail?.comments.map(comm => {
                return (
                  <>
                    <div className="col-12 mt-5 dflex jcs">
                      <div className="col-1">
                        <img
                          src={
                            comm.user[0].profile != null ||
                              comm.user[0].profile != undefined
                              ? `${API_BASEURL}/public/profile/${comm.user[0].profile}`
                              : DefaultImage
                          }
                          alt=""
                          className="post"
                        />
                      </div>
                      <div className="col-10 text-start">
                        {" "}
                        <span style={{ fontSize: "15px", fontWeight: "700" }}>
                          {" "}
                          {comm.user[0].full_name}
                        </span>
                        <br />
                        <span>{comm.comment}</span>
                        <br />

                        <button
                          className="replyButton"

                          onClick={() => {
                            setInput({ ...input, replyComment: "" })
                            showReply(comm._id);
                          }

                          }
                        >
                          Reply
                        </button>
                        <span className="mt-2"> </span>
                      </div>
                      <div className="likeSection">
                        {(showPhotodetail.user_id === userdata?.login?.data?.result?._id || comm.user_id === userdata?.login?.data?.result?._id) &&
                          <div className="profileImage dropdown editPost" style={{ marginLeft: "auto" }}>
                            <div
                              className="image dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              data-bs-display="static"
                              aria-expanded="false"
                            >
                              <i className="fas fa-ellipsis-h feed-dropdown-icon "></i>
                            </div>
                            <div
                              className="dropdown-menu dropdown-menu-end"
                              aria-labelledby="dropdownMenuButton"
                            >

                              <a className="dropdown-item Aeonik-Bold" onClick={() => setShowModal({ type: "parent", _id: comm._id })} >
                                Delete
                              </a>
                            </div>

                          </div>}
                      </div>
                    </div>

                    {reply === comm._id && (
                      <>
                        <div className="col-1 mt-2 dflex alc"></div>
                        <div className="col-11 borderallSide">
                          <div className="row comment">
                            <div className="col-10">
                              <div className="inputField">
                                <div className="inputBox">
                                  <textarea
                                    type="text"
                                    className="form-control px-3 bg-none reply"
                                    id="Write a comment"
                                    placeholder={`Reply to @${comm.user[0].full_name}`}
                                    name="replyComment"
                                    autoFocus
                                    onChange={handleChange1}
                                    value={input.replyComment}
                                  />
                                </div>
                                {/* {error.requestCode && (
                      <span className="text-danger">{error.requestCode}</span>
                    )} */}
                              </div>
                            </div>
                            <div className="col-2 dflex alc jcc">
                              <button className="btn btn-main btn-height" disabled={!input.replyComment ? true : false} onClick={() => __commentPost(userdata?.login?.data?.result?._id, showPhotodetail, comm._id)}>
                                <i className="far fa-paper-plane"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {comm.replies &&
                      comm.replies.map(repl => (
                        <div
                          className="col-12 mt-3  dflex jcs"
                          style={{ marginLeft: "25px" }}>
                          <div className="col-1">
                            <img
                              src={
                                repl?.user[0]?.profile != null ||
                                  repl?.user[0]?.profile != undefined
                                  ? `${API_BASEURL}/public/profile/${repl.user[0].profile}`
                                  : DefaultImage
                              }
                              alt=""
                              className="post"
                            />
                          </div>
                          <div className="col-10 text-start">
                            {" "}
                            <span style={{ fontSize: "15px", fontWeight: "700" }}>
                              {" "}
                              {repl.user[0].full_name}
                            </span>
                            <br />
                            <span id> {repl.comment}</span>
                            <br />
                            <span className="mt-2"> </span>
                          </div>
                          <div className="likeSection" style={{ marginLeft: "-25px" }}>
                            {(showPhotodetail.user_id === userdata?.login?.data?.result?._id || repl.user_id === userdata?.login?.data?.result?._id) &&
                              <div className="profileImage dropdown editPost" style={{ marginLeft: "auto" }}>
                                <div
                                  className="image dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  data-bs-display="static"
                                  aria-expanded="false"
                                >
                                  <i className="fas fa-ellipsis-h feed-dropdown-icon "></i>
                                </div>
                                <div
                                  className="dropdown-menu dropdown-menu-end"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <a className="dropdown-item Aeonik-Bold" onClick={() => setShowModal({ type: "child", _id: repl._id, comment_id: comm._id })} >
                                    Delete
                                  </a>
                                </div>

                              </div>}
                          </div>
                        </div>

                      ))}

                  </>

                )
              })
            }



          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size={"lg"}
        onHide={() => {
          setInput({})
          showUpdateuploder(false)
        }}
        show={showBlog === true ? false : updateUploader}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="modal-heading">
              <img src={ProfilePath} alt="" className="post" />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="quickupdateText">
            <div className="quickupdateInnerdiv">
              <textarea
                rows="4"
                name="title"
                placeholder="Write a quick update...."
                onChange={handleChange1}
                defaultValue={input?.title}
              ></textarea>

              <div>
                <button
                  variant="primary"
                  onClick={() => handleSubmitdata("quickUpdate")}
                  disabled={!input?.title ? true : false}
                  className="btn btn-main btn-height postButton mt-2 mb-2">
                  Post
                </button>
              </div>
            </div>
          </div>
          <div className="orhr" style={{ backgroundColor: "#f4f4f4" }}>
            <hr />
            <p className="mb-0 orText">or</p>
          </div>
          <div className="row quickupdateText">
            <div className="col-6">
              <button
                variant="secondary"
                className="btn btn-white-outline btn-height imageButton"
                onClick={() => {
                  setInput({})
                  showUpdateuploder(false)
                  showaddImage("image")
                }}>
                <span>🖼️</span>Images
              </button>
            </div>
            <div className="col-6">
              <button
                variant="secondary"
                className="btn btn-white-outline btn-height imageButton"
                onClick={() => {
                  showUpdateuploder(false)
                  setInput({})
                  setShowblog(true)
                }}>
                <span>📝</span>Blog Post
              </button>
            </div>
            <div className="col-6">
              <button
                variant="secondary"
                className="btn btn-white-outline btn-height imageButton"
                onClick={() => {
                  showUpdateuploder(false)
                  setInput({})
                  showaddImage("video")
                }}>
                <span>🎬</span>Video
              </button>
            </div>
            <div className="col-6">
              <button
                variant="secondary"
                className=" btn btn-white-outline btn-height imageButton"
                onClick={() => {
                  showUpdateuploder(false)
                  setInput({})
                  showaddImage("audio")
                }}>
                <span>🎧</span>Audio
              </button>
            </div>

            {/* <button
              variant="primary"
              className="btn btn-main btn-height"
              onClick={deleteMessage}>
              Yes
            </button> */}
          </div>
        </Modal.Body>
      </Modal>

      {/* post uploader modal Starts here */}

      <Modal size={"lg"} onHide={() => {
        setFileErr(false)
        showaddImage(false)
        setInput({})
        setpubVideoFile("")
        setpubVideo("")
        setpubAudioFile("")
        setpubAudio("")
        setPostimages([])
        setPostimages1([])
      }} show={addImage}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="modal-heading">{addImage === "image" ? <>Add Image</> : addImage === "video" ? <>Add Video</> : addImage === "audio" ? <>Add Audio</> : addImage === "editPost" ? <>Edit Post</> : <>Add Blog</>}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="imageuploader">
            <div className={addImage == "editPost" ? "d-none" : "addImage"}>
              <div className="row" style={{ margin: "0px", padding: "10px" }}>
                {imageViewer && addImage == "image" &&
                  <div className="col-lg-3 col-6 addimagemultiple mt-2">
                    <label htmlFor="postImages">
                      <div className="UploadBox">
                        <div className="icon">
                          <i class="fa-solid fa-upload"></i>
                        </div>
                        <div className="text">Upload Photos</div>
                      </div>
                      <input
                        type="file"
                        name="postImages"
                        id="postImages"
                        multiple
                        className="d-none imageFile"
                        accept="image/*"
                        onChange={(e) => pubImgaeupload(e)}
                        onInput={(e) => {
                          const files = e.target.files;
                          if (files.length > 8) {
                            alert("You can only select up to 8 images.");
                            e.target.value = ""; // Reset the input value
                          }
                        }}
                      />

                    </label>
                  </div>}

                {addImage == "video" &&
                  <div className="col-lg-3 col-6 addimagemultiple mt-2">
                    <label htmlFor="postImages">
                      <div className="UploadBox">
                        <div className="icon">
                          <i class="fa-solid fa-upload"></i>
                        </div>
                        <div className="text">Upload Video</div>
                      </div>
                      <input
                        name="postImages"
                        type="file"
                        accept="video/*"
                        id="postImages"
                        className="d-none videoFile"
                        onChange={(e) => pubVideoupload(e)}

                      />

                    </label>
                  </div>

                }

                {addImage == "audio" &&
                  <div className={pubAudio && isDesktopOrLaptop ? "col-lg-3 col-6 addimagemultiple d-none mt-2" :"col-lg-3 col-6 addimagemultiple mt-2"  }>
                    <label htmlFor="postImages">
                      <div className="UploadBox">
                        <div className="icon">
                          <i class="fa-solid fa-upload"></i>
                        </div>
                        <div className="text">Upload Audio</div>
                      </div>
                      <input
                        type="file"
                        name="postImages"
                        id="postImages"
                        accept="audio/*"
                        className="d-none audios"
                        onChange={(e) => uploadpubAudio(e)}

                      />

                    </label>
                  </div>

                }


                {addImage == "image" && <>
                  {postImages1?.map((item, index) => {
                    return (
                      <>
                        <div className="col-lg-3 col-6 mt-2">
                          {addImage == "image" &&
                            <img
                              src={URL.createObjectURL(item)}
                              alt=""
                              width={120}
                              height={120}
                              style={{ objectFit: "cover", borderRadius: "20px" }}
                            />}

                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => removeImage(index)}>
                            Remove Image
                          </span>
                        </div>
                      </>
                    );
                  })}</>}
                {addImage == "video" && pubVideo !== "" && <>
                  <div className="col-lg-3 col-6 mt-2">
                    <video width={120}
                      height={120} controls
                      style={{ objectFit: "cover", borderRadius: "20px" }}
                    >
                      <source src={pubVideo} type="video/mp4"></source>
                    </video>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => removeVideo()}
                    >
                      Remove Video
                    </span></div></>}
                {addImage == "audio" && pubAudio !== "" && <>
                  <div className="col-lg-3 col-12 mt-2">
                    <audio width={120}
                      height={120} controls
                      style={{ objectFit: "cover", borderRadius: "20px" }}>
                      <source src={pubAudio} ></source>
                    </audio>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => removeVideo()}
                    >
                      Remove Audio
                    </span></div></>}
              </div>

            </div>
            <div className="row">

              {disableDesciption ?
                <>
                  <div className="col-12 titleBox">
                    <div className="inputField ">
                      <div className="inputBox ">
                        <input
                          type="text"
                          className="form-control px-3 "
                          id="title"
                          placeholder="Title"
                          style={{ backgroundColor: "white" }}
                          name="title"
                          onChange={handleChange1}
                          defaultValue={input.title}
                        />
                      </div>
                      {/* {error.requestCode && (
                      <span className="text-danger">{error.requestCode}</span>
                    )} */}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="inputField">
                      <div className="inputBox">
                        <textarea
                          type="text"
                          className="form-control px-3 bg-none"
                          id="description"
                          placeholder="Description"
                          name="description"
                          onChange={handleChange1}
                          defaultValue={input.description}
                        />
                      </div>
                      {/* {error.requestCode && (
                      <span className="text-danger">{error.requestCode}</span>
                    )} */}
                    </div>
                  </div></> : <>

                  <div className="col-12">
                    <div className="inputField">
                      <div className="inputBox">
                        <input
                          type="text"
                          className="form-control px-3 bg-none"
                          id="title"
                          placeholder="Blog Post"
                          name="title"
                          onChange={handleChange1}
                          defaultValue={input.title}
                        />
                      </div>
                      {/* {error.requestCode && (
                      <span className="text-danger">{error.requestCode}</span>
                    )} */}
                    </div>
                  </div>




                </>
              }
              <div className="col-12">
                {fileErr ? <span className="dflex jcs">{fileErr}</span> : ""}
                <button
                  variant="secondary"
                  className=" btn btn-white-outline btn-height imageButton"
                  // disabled={disableDesciption && input.description && input.title ? false : !disableDesciption && input.title ? false : true}




                  onClick={addImage === "editPost" ? () => __editPost(showPhotodetail) : handleSubmitdata}>
                  <span>{addImage === "image" ? <>Post Image</> : addImage === "video" ? <>Post Video</> : addImage === "audio" ? <>Post Audio</> : addImage === "editPost" ? <>Edit Post</> : <>Post Blog</>}</span>
                </button>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>

      {/* post uploader modal ends here */}

      <Modal
        size={"lg"}
        onHide={() => {
          setShowblog(false)
          setInput({})
        }}
        show={showBlog}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="modal-heading">
              <img src={ProfilePath} alt="" className="post" />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="quickupdateText">
            <div className="quickupdateInnerdiv">
              <textarea
                rows="4"
                name="title"
                placeholder="Blog Post"
                onChange={handleChange1}
                defaultValue={input?.title}
              ></textarea>

              <div>
                <button
                  variant="primary"
                  onClick={() => handleSubmitdata("blog")}
                  disabled={!input?.title ? true : false}
                  className="btn btn-main btn-height postButton mt-2 mb-2">
                  Post
                </button>
              </div>
            </div>
          </div>


        </Modal.Body>
      </Modal>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {/* <div className="modal-heading"> */}
            <h3>Do You Really Want to Delete The Post</h3>
            {/* </div> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="otpBox">
              <h5>Proceed </h5>
            </div> */}
          <div className="payment-btn">
            <button
              variant="secondary"
              className="btn btn-white-outline btn-height"
              onClick={() => setShowModal(false)}>
              No
            </button>
            <button
              variant="primary"
              className="btn btn-main btn-height"
              onClick={() => showModal?._id ? __deleteComment(showModal) : __deletePost(showPhotodetail)
              }
            >
              Yes
            </button>
          </div>
        </Modal.Body>

      </Modal>
      <Modal show={showGalleryModal} onHide={() => setGalleryShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {/* <div className="modal-heading"> */}
            <h3>Do You Really Want to Delete The Gallery Photo</h3>
            {/* </div> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="otpBox">
              <h5>Proceed </h5>
            </div> */}
          <div className="payment-btn">
            <button
              variant="secondary"
              className="btn btn-white-outline btn-height"
              onClick={() => setGalleryShowModal(false)}>
              No
            </button>
            <button
              variant="primary"
              className="btn btn-main btn-height"
              onClick={() => galleryDelete(showGalleryModal)
              }
            >
              Yes
            </button>
          </div>
        </Modal.Body>

      </Modal>


      <Modal show={showGalleryImage.state} onHide={() => setGalleryShowImage(false)}>
        <Modal.Body>
          <Carousel slide={false} defaultActiveIndex={showGalleryImage.index}>
            {userDetails?.photoGallery &&
              userDetails?.photoGallery.map(val => (
                <Carousel.Item>
                  <div>
                    <img
                      src={
                        `${API_BASEURL}/public/posts/${val.image}`
                      }
                      alt=""

                      className="d-block w-100"
                    />

                  </div>
                </Carousel.Item>

              ))
            }



          </Carousel>
        </Modal.Body>

      </Modal>


      <Modal
        size={"lg"}
        onHide={() => {
          showBioModal(false)
          setInput({})
        }}
        show={bioModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="modal-heading">
              <img src={ProfilePath} alt="" className="post" />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="quickupdateText">
            <div className="quickupdateInnerdiv">
              <textarea
                rows="4"
                name="bio"
                placeholder="Bio"
                onChange={handleChange1}
                defaultValue={userDetails?.bio}
              ></textarea>

              <div>
                <button
                  variant="primary"
                  onClick={handleSubmitdata22}
                  disabled={!input?.bio ? true : false}
                  className="btn btn-main btn-height postButton mt-2 mb-2">
                  Post
                </button>
              </div>
            </div>
          </div>


        </Modal.Body>
      </Modal>

      {isViewerOpen && (
        <ImageViewer
          src={ProfilePath1.toReversed()}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </div>
  );
}



export default StaffPost;
