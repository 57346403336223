import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Col, Row } from "reactstrap";
import { useState } from "react";
import { AddServiceFees } from "../../service/Auth.service";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function SetupServiceFees() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userdata = useSelector((state) => state);
  let adminRole = userdata?.login?.data?.role;

  const [rows, setRows] = useState([
    {
      min: "",
      max: "",
      servicefee: "",
    },
  ]);

  const handleAddRow = () => {
    setRows([...rows, { min: "", max: "", servicefee: "" }]);
  };

  const handleRemoveRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handleRowChange = (index, field, value) => {
    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);
  };

  const handleSubmitdata = async (e) => {
    e.preventDefault();

    rows?.forEach((row1) => {
      let reqData = {
        min: row1?.min,
        max: row1?.max,
        servicefees: row1?.servicefee,
      };

      try {
        AddServiceFees(
          reqData,
          (result) => {
            if (result?.status == true) {
              toast.success(
                "Submit successfully",
                {
                  theme: "dark",
               
                  position: toast.POSITION.BOTTOM_RIGHT,
                },
                { autoClose: 1000 }
              );
            } else if (result?.status === false) {
              toast.error(
                "Not submit",
                {
                  theme: "dark",
               
                  position: toast.POSITION.BOTTOM_RIGHT,
                },
                { autoClose: 1000 }
              );
            }
          },
          dispatch
        );
      } catch (e) {}
    });
  };

  if (adminRole == 0) {
    return (
      <>
        <h2 className="page-title">Payment Management</h2>

        <div className="content-wraper">
          <Row>
            <Col sm={6}>
              <h4>Setup Service Fees</h4>
            </Col>
            <Col sm={6} className="text-end mb-3 mt-2">
              <Button variant="primary" onClick={handleAddRow}>
                Add row
              </Button>
            </Col>
          </Row>
          <Card>
            <Card.Body>
              <Row>
                <Col sm={5}>
                  <p>Tip Amount Range</p>
                </Col>
                <Col sm={4}>Service Fees (%)</Col>
              </Row>
              {rows.map((row, index) => (
                <form key={index}>
                  <Row className="mb-2">
                    <Col sm={6} md={2}>
                      <label>Min ($)</label>
                      <input
                        type="text"
                        className="form-control"
                        value={row.min}
                        onChange={(e) =>
                          handleRowChange(index, "min", e.target.value)
                        }
                      />
                    </Col>
                    <Col className="px-0 col-sm-auto">
                      <div className="mt-4 text-center pt-2"> to</div>
                    </Col>
                    <Col sm={6} md={2}>
                      <label>Max ($)</label>
                      <input
                        type="text"
                        className="form-control"
                        value={row.max}
                        onChange={(e) =>
                          handleRowChange(index, "max", e.target.value)
                        }
                      />
                    </Col>
                    <Col md={1}></Col>
                    <Col sm={6} md={2}>
                      <label>Service Fees (%)</label>
                      <input
                        type="text"
                        className="form-control"
                        value={row.servicefee}
                        onChange={(e) =>
                          handleRowChange(index, "servicefee", e.target.value)
                        }
                      />
                    </Col>
                    <Col sm={1} md={1}>
                      <div className="color-primary mt-4 pt-2">
                        <i
                          className="fa-solid fa-circle-minus"
                          onClick={() => handleRemoveRow(index)}
                        ></i>
                      </div>
                    </Col>
                  </Row>
                </form>
              ))}
            </Card.Body>
          </Card>
          <div className="text-end mt-3">
            <Button variant="primary" onClick={handleSubmitdata}>
              Save
            </Button>
          </div>
        </div>
      </>
    );
  }
}
export default SetupServiceFees;
