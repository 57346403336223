import React from "react";
import { Link,} from "react-router-dom";
import mobileNavLogo from "../../assets/images/mobileNavLogo.png"

export default function PrivacyPolicy() {
  return (
    <>
      <div className="homePage">
        <div className="container-fluid">
          <div className="row headerSection">
            <div className="col">
              <div className="header">
                <nav className="navbar navbar-expand-lg">
                  <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                      <img src="./assets/logo.svg" alt="" className="webView" />
                      <img src={mobileNavLogo} className="mobileView" style={{borderRadius:'20px'}}  height={50} width={100} alt="" />
                    </a>
                    <button
                      className="navbar-toggler btn-main "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      {/* <span className="navbar-toggler-icon text-light"></span> */}
                      <i className="fa-solid fa-bars navbar-toggler-icon blackText centerMid"></i>
                    </button>
                    <div
                      className="collapse navbar-collapse justify-content-end"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav mb-2 mb-lg-0">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            aria-current="page"
                            href="/"
                          >
                            Contact
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="/">
                            How It Works
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="/">
                            Sign Up
                          </a>
                        </li>
                        {/* <li className="nav-item">
                          <a className="nav-link" href="/">
                            Contact Us
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link highlighted" href="/Register">
                            Register as a
                          </a>
                        </li> */}
                       
                        <li>
                          <Link to="/login" className="btn btn-main btn-height">
                            Sign In
                          </Link>
                        </li>
                      </ul>
                      <form className="d-flex"></form>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="center">
                <h2>Privacy Policy</h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum. Why do
                  we use it? It is a long established fact that a reader will be
                  distracted by the readable content of a page when looking at
                  its layout. The point of using Lorem Ipsum is that it has a
                  more-or-less normal distribution of letters, as opposed to
                  using 'Content here, content here', making it look like
                  readable English. Many desktop publishing packages and web
                  page editors now use Lorem Ipsum as their default model text,
                  and a search for 'lorem ipsum' will uncover many web sites
                  still in their infancy. Various versions have evolved over the
                  years, sometimes by accident, sometimes on purpose (injected
                  humour and the like). Where does it come from? Contrary to
                  popular belief, Lorem Ipsum is not simply random text. It has
                  roots in a piece of classical Latin literature from 45 BC,
                  making it over 2000 years old. Richard McClintock, a Latin
                  professor at Hampden-Sydney College in Virginia, looked up one
                  of the more obscure Latin words, consectetur, from a Lorem
                  Ipsum passage, and going through the cites of the word in
                  classical literature, discovered the undoubtable source. Lorem
                  Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                  Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
                  written in 45 BC. This book is a treatise on the theory of
                  ethics, very popular during the Renaissance. The first line of
                  Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line
                  in section 1.10.32.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
