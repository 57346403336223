import axios from "axios";
import { apiUrl, PORT } from "../../../environment";
import { getInfo } from "./Auth.header";
import queryString from "query-string";
import { loadingAction } from "../../../components/common/loader/LoaderSlice";

// function for Restro Registration
export const addRestro = async (fromdata, dispatch) => {
  try {
    dispatch(loadingAction(true));

    const response = await axios.post(
      `${apiUrl}${PORT}/users/signup`,
      fromdata
    );
    localStorage.setItem("user", JSON.stringify(response.data));
    dispatch(loadingAction(false));

    return response;
  } catch (e) {
    dispatch(loadingAction(false));
    return null;
  }
};
export const verifyOtp = async (value, dispatch) => {
  try {
    dispatch(loadingAction(true));

    const response = await axios.post(
      `${apiUrl}${PORT}/users/verifyOtp`,
      value
    );
    localStorage.setItem("user", JSON.stringify(response.data));
    dispatch(loadingAction(false));

    return response;
  } catch (e) {
    dispatch(loadingAction(false));

    return null;
  }
};
export const resendOtp = async (value, dispatch) => {
  try {
    dispatch(loadingAction(true));

    const response = await axios.post(
      `${apiUrl}${PORT}/users/resendOtp`,
      value
    );
    localStorage.setItem("user", JSON.stringify(response.data));
    dispatch(loadingAction(false));

    return response;
  } catch (e) {
    dispatch(loadingAction(false));

    return null;
  }
};

//customer registration

export const addCustomer = async (fromdata, dispatch) => {
  try {
    dispatch(loadingAction(true));

    const response = await axios.post(
      `${apiUrl}${PORT}/users/signup`,
      fromdata
    );
    dispatch(loadingAction(false));

    return response;
  } catch (e) {
    dispatch(loadingAction(false));

    return null;
  }
};

//staff registration

export const addStaff = async (fromdata) => {
  try {
    const response = await axios.post(
      `${apiUrl}${PORT}/users/signup`,
      fromdata
    );

    return response;
  } catch (e) {
    return null;
  }
};

export const forgotPassword = async (fromdata,dispatch) => {
  try {
    dispatch(loadingAction(true));

    const response = await axios.post(
      `${apiUrl}${PORT}/users/forgetPassword`,
      fromdata
    );
    dispatch(loadingAction(false));

    return response.data;

  } catch (e) {
    dispatch(loadingAction(false));

    return e;
  }
};

export const Reset_Password = async (password, confirm_password, token) => {
  return await axios.post(
    `${apiUrl}${PORT}/users/resetpass`,
    {
      password,
      confirm_password,
    },
    {
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getUserList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      axios
        .get(
          `${apiUrl}${PORT}/users/userlist?${queryString.stringify(param)}`,
          axiosConfig
        )
        .then((response) => {
          callback(response?.data?.result?.docs);
        })
        .catch((error) => {
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};




