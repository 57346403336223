import React from "react";
import { useEffect, useState } from "react";
import AuthService from "../../services/auth.service";
import { forgotPassword } from "./service/authService";
import { success, failed } from "../../components/common/Toastify";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import gotip from "../../assets/images/gotipLogo.png"

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [input, setInput] = useState({
    email: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelSubmit = async (e) => {
    e.preventDefault();

    let reqData = {
      email: input.email,
    };
    const res = await forgotPassword(reqData,dispatch);

    try {
      if (res.messageID == 200) {
        success("Email Sent Successfully");
        navigate("/login");
      } else {
        failed("Enter Valid Email Id");
      }
    } catch (e) {
      failed(e);
    }
  };
  return (
    <div className="authPage vh-100 dflex alc">
      <div className="container shadow">
        <div  className="row m-0">
          <div className="col-md-6 centerMid">
          <img src={gotip} alt="" className="authImage cursorPointer" onClick={() => { navigate("/login") }} />
          </div>
          <div className="col-md-6 mt-5 mt-md-0 whiteBackground borderRadius">
            <div className="content">
             
              <h3 className="text-center pt-5 mt-3">Forgot Password?</h3>
              <p className="text-center">
                Enter your email Id linked to this account
              </p>
              <form className="form">
                <div className="row">
                  <div className="col">
                    <div className="inputField">
                      <label htmlFor="email">Your Email</label>
                      <div className="inputBox">
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          id="email"
                          placeholder="Your Email"
                          onChange={handleChange}
                        />
                        <i className="fa-solid fa-envelope"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col">
                    <button
                      className="btn btn-main w-100 btn-height"
                      onClick={handelSubmit}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
