import axios from "axios";
import { apiUrl, PORT } from "../../../environment";
import { getInfo } from "../../auth/service/Auth.header";
import queryString from "query-string";
import { loadingAction } from "../../../components/common/loader/LoaderSlice";

let tokenString = localStorage.getItem('user');

// Parse the token string to an object
let token = JSON.parse(tokenString);
let axiosConfig = {
  headers: {
    // "Content-Type": "application/json",
    Authorization: `Bearer ${token?.Token}`,
  },
};

export const getStaffUserById = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/users/getbyid?${queryString.stringify(param)}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getStaffUsersById = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/restaurant/getstaffdetailsbyid?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const DeleteStaffList = async (_id, callback) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    axios
      .post(`${apiUrl}${PORT}/restaurant/bulkDelete`, {
        _id,
      },axiosConfig)

      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const TotalTippingHistoryListBystaff = async (
  param,
  callback,
  dispatch
) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/tip/totaltipbyid?${queryString.stringify(param)}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getStaffTippinghistory = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    axios
      .get(
        `${apiUrl}${PORT}/tip/getstaffTipDetailsById?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        callback(response?.data?.result);
      })
      .catch((error) => {
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const TotalGroupTipById = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/restaurant/getgroupstaffbyId?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getTipDetails = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    axios
      .get(
        `${apiUrl}${PORT}/tip/getstaffTipDetailsById?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getGroupTipDistributed = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/restaurant/getgroupdistributedtiplist?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));

        callback(response);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const AllstaffLists = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    axios
      .get(
        `${apiUrl}${PORT}/restaurant/allactivestafflists?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getRestroStaffTippingHistory = async (
  param,
  callback,
  dispatch
) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/restaurant/getrestrostaffbyid?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const UpdateGroupTip = async (
  _id,
  group_staff,
  staff_comment,
  group_tip_status,user_id
) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    const response = await axios.put(`${apiUrl}${PORT}/tip/updategrouptip`, {
      _id,
      group_staff,
      staff_comment,
      group_tip_status,
      user_id
    },axiosConfig);
    if (response.data.status) {
      return response;
    } else {
      return response;
    }
  }
  } catch (e) {
    return null;
  }
};

export const NoOftotaltipbyId = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    axios
      .get(
        `${apiUrl}${PORT}/tip/getnooftotaltips?${queryString.stringify(param)}`,
        axiosConfig
      )
      .then((response) => {
        callback(response?.data?.result);
      })
      .catch((error) => {
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const AllactiveStaffList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    axios
      .get(
        `${apiUrl}${PORT}/restaurant/allactivestafflists?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const UpdateStatuss = async (data) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    const response = await axios.put(
      `${apiUrl}${PORT}/restaurant/staffStatus`,
      data,axiosConfig
    );
    return response;
     }
  } catch (e) {
    console.log("e", e);
    return null;
  }
};

export const TotalTipAmountShowforDashboard = async (
  param,
  callback,
  dispatch
) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/restaurant/totaltipamountforrestro?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getAllReports = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/report/getallreport?${queryString.stringify(param)}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// export const getAllCustomerReports = async (param, callback, dispatch) => {
//   try {
//     let token = localStorage.getItem("user");
//     let token1 = JSON.parse(token);
//      if (token1?.Token) {
//        let axiosConfig = {
//          headers: {
//            Authorization: `Bearer ${token1?.Token}`,
//          },
//        };
//     dispatch(loadingAction(true));
//     axios
//       .get(
//         `${apiUrl}${PORT}/report/getCustomerReport?${queryString.stringify(param)}`,
//         axiosConfig
//       )
//       .then((response) => {
//         dispatch(loadingAction(false));
//         callback(response?.data?.result);
//       })
//       .catch((error) => {
//         dispatch(loadingAction(false));
//         callback(false);
//       });
//     }
//   } catch (err) {
//     console.log(err);
//   }
// };

export const getAllCustomerReports = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/report/getCustomerReport?${queryString.stringify(param)}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};


export const getTotalStaffList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/report/gettotalstafftips?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getTotalGroupTipList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/report/gettotalgrouptips?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getGroupTipNotDistributedList = async (
  param,
  callback,
  dispatch
) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/report/getgrouptipnotdistributed?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getGroupTipDistributedList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/report/getgrouptipdistributed?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getNoOfgetAllReports = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/report/noofgetallreport?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getNoofTotalStaffList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/report/noofgettotalstafftips?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getNooFTotalGroupTipList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/report/noofgettotalgrouptips?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getNooFTotalGroupNotDistributedTipList = async (
  param,
  callback,
  dispatch
) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/report/noofgettotalnotdistributed?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getNooFTotalGroupDistributedTipList = async (
  param,
  callback,
  dispatch
) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/report/noofgettotalgrouptipdistributed?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getNo_of_Invitationrequest = async (
  param,
  callback,
  dispatch
) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
     if (token1?.Token) {
       let axiosConfig = {
         headers: {
           Authorization: `Bearer ${token1?.Token}`,
         },
       };
    dispatch(loadingAction(true));
    axios
      .get(
        `${apiUrl}${PORT}/restaurant/No_Of_InvitationRequestList?${queryString.stringify(
          param
        )}`,
        axiosConfig
      )
      .then((response) => {
        dispatch(loadingAction(false));
        callback(response?.data?.result);
      })
      .catch((error) => {
        dispatch(loadingAction(false));
        callback(false);
      });
    }
  } catch (err) {
    console.log(err);
  }
};



