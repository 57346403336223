import React from "react";
import HomeLayout from "../../../components/common/layout/HomeLayout";
import CustomerDashboard from "../CustomerDashboard";
import StaffProfile from "../components/StaffProfile";
import StaffTipping from "../components/StaffTipping";
import TippingHistory from "../components/TippingHistory";
import RestaurantSearchResults from "../components/RestaurantSearchList";
import RestaurentProfileScreen from "../components/ProfileScreenRetro";
import GroupTippingPage from "../components/AddGroupTip";
import TippingDetails from "../components/TippingDetails";
import ContactUs from "../components/ContactUs";
import PaymentSuccess from "../components/PaymentSuccess";
import CardDetails from "../components/CardDetailspage";
import StaffPost from "../../staffInterface/StaffPosts";
import CustomerTipReport from "../../RestoInterface/components/CustomerTipReport";
import PaymentConfirmation from "../components/PaymentConfirmation";

const CustomerInterfaceRoute = [
  {
    path: `/customer/dashboard`,
    layout: HomeLayout,
    component: <CustomerDashboard />,
  },
  {
    path: `/success_transaction`,
    layout: HomeLayout,
    component: <PaymentConfirmation />,
  },
  
  {
    path: `/customer/staffprofile/:id`,
    layout: HomeLayout,
    component: <StaffPost />,
  },
  {
    path: `/customer/stafftipping/:id/:type`,
    layout: HomeLayout,
    component: <StaffTipping />,
  },
  {
    path: `/customer/tippinghistory`,
    layout: HomeLayout,
    component: <TippingHistory />,
  },
  {
    path: `/customer/customerTipReport`,
    layout: HomeLayout,
    component: <CustomerTipReport />,
  },
  {
    path: `/customer/restaurantsearchlist`,
    layout: HomeLayout,
    component: <RestaurantSearchResults />,
  },
  {
    path: `/customer/restaurantprofilescreen/:id`,
    layout: HomeLayout,
    component: <RestaurentProfileScreen />,
  },
  {
    path: `/customer/addgrouptip/:id`,
    layout: HomeLayout,
    component: <GroupTippingPage />,
  },
  {
    path: `/customer/tippingdetails/:id`,
    layout: HomeLayout,
    component: <TippingDetails />,
  },
  {
    path: `/customer/contactus`,
    layout: HomeLayout,
    component: <ContactUs />,
  },
  
  {
    path: `/customer/paymentsuccess`,
    layout: HomeLayout,
    component: <PaymentSuccess />,
  },
  {
    path: `/customer/card-details/:id`,
    layout: HomeLayout,
    component: <CardDetails />,
  },
];

export default CustomerInterfaceRoute;
