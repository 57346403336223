import { createSlice } from "@reduxjs/toolkit"

export const CustomToasterSlice = createSlice({
    name: "customToaster",
    initialState: {
        customVal: false
    },
    reducers: {
        customAction: (state, action) => {
            state.customVal = action.payload
        },
    }
})

export const { customAction } = CustomToasterSlice.actions;
export default CustomToasterSlice.reducer;