import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { sendPaymentResponse } from "../Service/Auth.Service"; // API service function
import hompageLogo from "../../../assets/images/mainPageLogo.png";
// import gotip from "../../../assets/images/gotipLogo.png";
import mobileNavLogo from "../../../assets/images/mobileNavLogo.png";

function PaymentConfirmation() {
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const location = useLocation();

  const getQueryParams = (query) => {
    const queryParams = new URLSearchParams(query);
    const params = {};

    queryParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  };

 
  const updatePaymentStatus = async (data) => {
    try {
      const response = await sendPaymentResponse(data);
  
      if (response && response.status) {
        // Use response.data or response.message if the API returns these fields
        setConfirmationMessage(
          data?.trustap_code === "paid"
            ? "Payment successful. Thank you!"
            : "Payment was cancelled."
        );
      } else {
        setConfirmationMessage("Payment failed. Please try again.");
      }
      localStorage.removeItem("transactionId"); // Assuming you want to clear the transactionId
    } catch (error) {
      setConfirmationMessage("Error updating payment status.");
      console.error("Error:", error);
    }
  };
  

  useEffect(() => {
    const transactionId = localStorage.getItem("transactionId");
    const queryParams = getQueryParams(location.search);
    const paymentStatus = queryParams.code === "paid" ? true : false;
  
    if (transactionId && paymentStatus) {
      updatePaymentStatus({
        id: transactionId,
        transaction_id: queryParams.tx_id,
        trustap_code: queryParams.code,
      });
    } else {
      setConfirmationMessage("Payment Cancelled.");
    }
  }, [location.search]);
  

  return (
    <div>
      <div className="homePage">
        <div className="container-fluid">
          <div className="customerDashboard">
            <div className="row headerSection">
              <div className="col">
                <div className="header">
                  <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                      <a className="navbar-brand">
                        <Link to="/" className="custom-link">
                          <img
                            src={hompageLogo}
                            style={{ borderRadius: "20px" }}
                            height={50}
                            width={100}
                            className="webView"
                            alt="Homepage Logo"
                          />
                          <img
                            src={mobileNavLogo}
                            className="mobileView"
                            style={{ borderRadius: "20px" }}
                            height={50}
                            width={100}
                            alt="Mobile Nav Logo"
                          />
                        </Link>
                      </a>
                      <button
                        className="navbar-toggler btn-main"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        style={{ background: "#FAF04B", color: "transparent" }}
                        aria-label="Toggle navigation"
                      >
                        <i className="fa-solid fa-bars navbar-toggler-icon blackText centerMid"></i>
                      </button>
                      <div
                        className="collapse navbar-collapse justify-content-end"
                        id="navbarSupportedContent"
                      >
                        <ul className="navbar-nav mb-2 mb-lg-0">
                          <li className="nav-item">
                            <Link to="/aboutus" className="nav-link">
                              About
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/contactUs" className="nav-link">
                              Contact
                            </Link>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/#howItWorks">
                              How It Works
                            </a>
                          </li>
                          {/* <li className="nav-item">
                            <a className="nav-link" href="/">
                              Sign Up
                            </a>
                          </li> */}
                          {/* <li className="nav-item">
                            <Link
                              to="/login"
                              className="btn btn-main btn-height"
                            >
                              Sign In
                            </Link>
                          </li> */}
                        </ul>
                        <form className="d-flex"></form>
                      </div>
                    </div>
                  </nav>
                </div>
                <div
                  className="headerContent mt-3 tippingImage dflex jcc"
                  id="aboutUs"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row headerSection">
            <div className="col"></div>
          </div>

          <div className="row contactSection" id="contactUs">
            <div className="col-md-12 text-center">
              <h1 className="Spoof-Black" style={{ color: "black" }}>
                {confirmationMessage}
              </h1>
              {/* <p className="text-muted" style={{ fontSize: "1.25rem" }}>
                We appreciate your business. If you have any questions, feel
                free to reach out to our support team.
              </p> */}
            </div>
          </div>
          <div className="row contactSection" id="contactUs">
            <div
              className="col-md-12 dflex alc"
              style={{ paddingLeft: "30px" }}
            >
              <div className="col-md-6">
                <h3 className="Spoof-Black">Business Details</h3>
                <div className="contactForm">
                  <div className="form-group">
                    <label htmlFor="business_name">Business Name : </label>{" "}
                    <i>Relentless Hospitality</i>
                  </div>
                  <div className="form-group">
                    <label htmlFor="address">Office Address : </label>{" "}
                    <i>3rd Floor, 86-90 Paul St, London EC2A 4NE, UK</i>
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone_number">Phone Number : </label>{" "}
                    <i>07825065688</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentConfirmation;
