import HomeHeader from "../../homePages/common/HomeHeader";
import { useDispatch, } from "react-redux";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getTipDetails } from "../../RestoInterface/Service/Auth.service";
import { API_BASEURL } from "../../../environment";

function TipRequestSent() {
  const [tipDetail, setTipDetail] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const TipId = useParams();

  useEffect(() => {
    _getTippingDetails();
  }, [dispatch]);

  const _getTippingDetails = () => {
    getTipDetails(
      { _id: TipId.id },
      (data) => {
       
        setTipDetail(data?.data?.result);
      },
      dispatch
    );
  };

  const navigatetoback = () => {
    navigate("/resto/tipmanagement");
};
  return (
    <div className="TipManagement2">
      <HomeHeader />

      <div className="container">
        <div className="row my-4">
          <div className="col direction">
            <p class="mb-0">
            <a onClick={navigatetoback}>Tip Management </a> <i class="fa-solid fa-angles-right"></i>
              <span>{tipDetail?.CustomerDetails?.full_name}</span>
            </p>
          </div>
        </div>
        <div className="row requestToTip">
          <div className="col-12">
            <div className="row">
              <div className="col-md-3 col-6">
                <div className="heading fw-bold  Aeonik-Regular">Tip Amount ($) :</div>
              </div>
              <div className="col-md-6 col-6">
                <div className="data Aeonik-Regular">{tipDetail?.total_tip_amount}</div>
              </div>
              <div className="col-md-3 col-12">
                <div className="text">
                  <p className="mb-0 blackText Aeonik-Regular">
                    Requested to GoTipMe Admin <i class="fa-solid fa-check blackText"></i>
                  </p>
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div className="col-12">
            <div className="row">
              {/* <div className="col-md-3 col-4">
                <div className="heading fw-bold">Ratings:</div>
              </div> */}
              <div className="col-md-6 col-8">
                <div className="data">
                  {/* <div className="star">
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star-half"></i>
                  </div> */}
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
          <div className="col-12 mt-3">
            <div className="row">
              <div className="col-md-3 col-4">
                <div className="heading fw-bold Aeonik-Regular">Feedback:</div>
              </div>
              <div className="col-md-6 col-8">
                <div className="data">
                  <p className="mb-0 Aeonik-Regular">{tipDetail?.customer_comment === "" ? "-" : tipDetail?.customer_comment} </p>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div className="row">
              {/* <div className="col-md-3 col-6">
                <div className="heading fw-bold">Ratings to Simon:</div>
              </div> */}
              <div className="col-md-6 col-6">
                <div className="data">
                  {/* <div className="star">
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star-half"></i>
                  </div> */}
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
          <div className="col-12 mt-3">
            <div className="row">
              <div className="col-md-3 dflex alc">
                <div className="heading fw-bold whiteText Aeonik-Regular">
                  Reply to{""} {tipDetail?.CustomerDetails?.full_name}:
                </div>
              </div>
              <div className="col-md-9 mt-2 mt-md-0">
                <div className="data">
                  <input
                    type="text"
                    className="form-control"
                    value={tipDetail?.staff_comment}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <h3 className="whiteText Aeonik-Regular">Staff Lists</h3>
          </div>
          <div className="col-12">
            {tipDetail?.group_staff &&
              tipDetail?.group_staff?.map((data, index) => {
                let ProfilePath = `${API_BASEURL}/public/profile/${data?.profile}`;
                return (
                  <div key={index} className="listdata">
                    <div className="profile">
                      <div className="image">
                        <img
                          src={
                            data?.profile != null || data?.profile != undefined
                              ? ProfilePath
                              : "../../assets/startProfile.png"
                          }
                          alt=""
                        />
                      </div>
                      <div className="text">
                        <h4 className="mb-0 fw-bold whiteText Aeonik-Regular">{data?.staff_name}</h4>
                        <p className="mb-0 whiteText Aeonik-Regular">Staff</p>
                      </div>
                    </div>
                    <div className="price">
                      <h3 className="whiteText Aeonik-Regular">$ {data?.group_staff_amount.toFixed(2)}</h3>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TipRequestSent;
