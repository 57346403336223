import AuthRoute from "../features/auth/routes/AuthRoutes";

import HomePagesRoutes from "../features/homePages/routes/HomePagesRoutes";

import CustomerInterfaceRoute from "../features/customerInterface/routes/CustomerInterfaceRoute";
import RestoInterfaceRoute from "../features/RestoInterface/routes/RestoInterfaceRoute";
import StaffInterfaceRoute from "../features/staffInterface/routes/StaffInterfaceRoute";
import SuperAdminInterfaceRoute from "../features/super-admin/routes/SuperAdminRoutes";


export const routes = [
  // ...DashboardRoute,
];

export const authRoutes = [...AuthRoute];
export const homeRoutes = [...HomePagesRoutes,];
export const CustomerInterfaceRoutes = [...CustomerInterfaceRoute];

export const RestoInterfaceRoutes = [...RestoInterfaceRoute];

export const StaffInterfaceRoutes = [...StaffInterfaceRoute];
export const SuperAdminInterfaceRoutes = [...SuperAdminInterfaceRoute]
