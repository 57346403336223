import HomeHeader from "../../homePages/common/HomeHeader";
import React, { useEffect, useState } from "react";
import {
  TotalTippingHistoryListBystaff,
  NoOftotaltipbyId,
} from "../../RestoInterface/Service/Auth.service";
import { useDispatch, useSelector } from "react-redux";
import { pagination } from "../../../components/common/utils/message";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getStaffUserById } from "../../RestoInterface/Service/Auth.service";
import { API_BASEURL } from "../../../environment";
import ReactPaginate from "react-paginate";
import DefaultImage from "../../../assets/images/defaultimage.png";

function TippingHistory() {
  const staffId = useParams();

  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const [totalTipHistoryList, setTotalTipHistoryList] = useState();
  const [viewProfileData, setViewProfileData] = useState();
  const [totalPages, settotalPages] = useState();
  const [no_total, setNo_Total] = useState();
  const [searchText, setSearchText] = useState("");
  const [selectedOption, setSelectedOption] = useState("option1");
  const [start_date, setStartDate] = useState();
  const [end_date, setEndDate] = useState();
  const maxDate = new Date().toISOString().split("T")[0];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [historyState, setHistoryState] = useState(0);
  const onTabClick = (tabId) => () => {
    setHistoryState(tabId);
  };

  const userdata = useSelector((state) => state);
  let roleID = userdata?.login?.data.role;

  // let retroId = userdata?.login?.data?.result._id;

  const restrodata = useSelector((state) => state);
  //let restro_staffId = restrodata?.restro?.data?.resto_id;

  // code here

  let loginRole = userdata?.login?.data?.role;
  let retroId;
  if (loginRole == 2) {
    retroId = userdata?.login?.data?.result?._id;
  }
  if (loginRole == 0) {
    retroId = userdata?.adminrestro?.data?._id;
  }

  let restro_staffId = retroId;
  // code end here
  useEffect(() => {
    _getTippingHistoryList();
    _getStaffUserbyId();
    __getNooftotaltip();
  }, [dispatch]);

  const _getStaffUserbyId = () => {
    getStaffUserById(
      { _id: staffId?.id },
      (data) => {
        setViewProfileData(data);
      },
      dispatch
    );
  };

  const __getNooftotaltip = () => {
    if (roleID == 2) {
      NoOftotaltipbyId(
        { resto_id: retroId, staff_id: staffId?.id, page, limit },
        (data) => {
          setNo_Total(data);
        },
        dispatch
      );
    } else {
      NoOftotaltipbyId(
        { resto_id: restro_staffId, staff_id: staffId?.id, page, limit },
        (data) => {
          setNo_Total(data);
        },
        dispatch
      );
    }
  };

  const _getTippingHistoryList = (search_text, start_date, end_date) => {
    if (roleID == 2) {
      TotalTippingHistoryListBystaff(
        {
          resto_id: retroId,
          staff_id: staffId?.id,
          page,
          limit,
          start_date: start_date,
          end_date: end_date,
          searchText: search_text,
        },
        (data) => {
          setTotalTipHistoryList(data?.docs);
          settotalPages(data?.totalPages);
        },
        dispatch
      );
    } else {
      TotalTippingHistoryListBystaff(
        {
          resto_id: restro_staffId,
          staff_id: staffId?.id,
          page,
          limit,
          start_date: start_date,
          end_date: end_date,
          searchText: search_text,
        },
        (data) => {
          setTotalTipHistoryList(data?.docs);
          settotalPages(data?.totalPages);
        },
        dispatch
      );
    }
  };

  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    if (roleID == 2) {
      await TotalTippingHistoryListBystaff(
        {
          resto_id: retroId,
          staff_id: staffId?.id,
          page: currentPage,
          limit,
          searchText,
        },
        (response) => {
          setTotalTipHistoryList(response?.docs);
        },
        dispatch
      );
    } else {
      await TotalTippingHistoryListBystaff(
        {
          resto_id: restro_staffId,
          staff_id: staffId?.id,
          page: currentPage,
          limit,
          searchText,
        },
        (response) => {
          setTotalTipHistoryList(response?.docs);
        },
        dispatch
      );
    }
  };
  function handleOptionChange(event) {
    setSelectedOption(event.target.value);
  }

  const handleInputStartDate = (newDate) => {
    setStartDate(newDate.target.value);
  };

  const handleInputEndDate = (newDate) => {
    setEndDate(newDate.target.value);
    _getTippingHistoryList("", start_date, newDate.target.value);
  };

  const __handleDistributedTipSearch = async (e) => {
    setSearchText(e.target.value);
    _getTippingHistoryList(e.target.value, start_date, end_date);
  };

  let ProfilePath = `${API_BASEURL}/public/profile/${viewProfileData?.profile}`;

  const buttonviewHandlerpage = async (data) => {
    navigate(`/resto/tippingviewhistory/${data?._id}`);
  };

  var total = 0;
  for (var i = 0; i < no_total?.length; i++) {
    total += no_total[i]?.total_tip_amount;
  }
  // const navigatetoback = () => {
  //     navigate("/resto/managestaff");
  // };
  return (
    <>
      <div className="staffProfile">
        <HomeHeader />
        <div className="container">
          <div className="row my-4">
            <div className="col direction">
              <p className="mb-0">
                <a href="/resto/managestaff" className="noDecoration whiteText">Manage Pubstar</a> <i className="fa-solid fa-angles-right"></i>
                <span>{viewProfileData?.full_name}</span>
              </p>
            </div>
          </div>
          <div className="row profileData">
            <div className="col-lg-6">
              <div className="profileContent">
                <div className="image">
                  <img
                    src={
                      viewProfileData?.profile != null ||
                      viewProfileData?.profile != undefined
                        ? ProfilePath
                        : DefaultImage
                    }
                    alt=""
                  />
                </div>
                <div className="text">
                  <h1>{viewProfileData?.full_name}</h1>
                  <p>
                    <i className="fa-solid fa-envelope"></i>{" "}
                    {viewProfileData?.email}
                  </p>
                  <p>
                    <i className="fa-solid fa-phone"></i> +44{" "}
                    {viewProfileData?.contact_number}
                  </p>
                  <div className="review">
                    {/* <div className="star">
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star-half"></i>
                    </div>
                    <p className="mb-0">(4.5)</p> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 centerMid">
              <div className="prices">
                <h3>
                  ${""}
                  {total}
                </h3>
                <p className="mb-0">Total Tips Received</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tippingHistroy">
        {/* <Header /> */}
        <div className="container">
          <div className={`row tabContent ${historyState !== 0 && "d-none"}`}>
            <div className="col-12">
              <div className="content">
                <div className="price">
                  <h3 className="whiteText">
                    ${""} {total}
                  </h3>
                  <p className="whiteText">Total Tips Received</p>
                </div>
                <div className="dates">
                  {/* <div className="dropdown">
                  <select
                    name=""
                    id=""
                    className="form-select"
                    onChange={handleOptionChange}
                  >
                    <option value="today">Today</option>
                    <option value="week">This Week</option>
                    <option value="month">This Month</option>
                  </select>
                </div> */}
                  <div className="datePicker">
                    <label htmlFor="pickFrom">From</label>
                    <input
                      type="date"
                      name=""
                      id="pickFrom"
                      max={maxDate}
                      className="form-control"
                      onChange={handleInputStartDate}
                    />
                  </div>
                  <div className="datePicker">
                    <label htmlFor="pickTo">To</label>
                    <input
                      type="date"
                      name=""
                      id="pickTo"
                      max={maxDate}
                      className="form-control"
                      onChange={handleInputEndDate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row searchStaff">
                <div className="col-12 px-0">
                  {/* <div className="searchBox">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="search pubstar, pubs"
                      value={searchText}
                      onChange={__handleDistributedTipSearch}
                    />
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {totalTipHistoryList &&
                    totalTipHistoryList?.map((data, index) => {
                      let ProfilePath = `${API_BASEURL}/public/profile/${data?.customerDetails[0]?.profile}`;
                      return (
                        <div key={index} className="staffProfileList">
                          <div className="firstdata">
                            <div className="image">
                              {/* <img src="../../assets/startProfile.png" alt="" /> */}

                              <img
                                src={
                                  data?.customerDetails[0]?.profile != null ||
                                  data?.customerDetails[0]?.profile != undefined
                                    ? ProfilePath
                                    : "../../assets/startProfile.png"
                                }
                                alt=""
                              />
                            </div>
                            <div className="text">
                              <p className="mb-0">
                                Recieved from {""}
                                <span className="fw-bold">
                                  {data.is_guest_tip == false
                                    ? data?.customerDetails[0]?.full_name
                                    : data?.guest?.guest_name}
                                </span>{" "}
                                
                              </p>
                              {/* <div className="date">
                                <p className="mb-0 whiteText">{data?.createdAt}</p>
                              </div> */}
                            </div>
                          </div>
                          <div className="text">
                            <p className="mb-0  dflex alc">
                              <h3 className="whiteText Aeonik-Regular" style={{color:'#f4f4f4',marginBottom:"0px"}}>
                                ${""}
                                {data?.total_tip_amount}
                              </h3>
                              <a onClick={(e) => buttonviewHandlerpage(data)} style={{paddingLeft:"10px"}}>
                                <i className="fa-solid fa-chevron-right"></i>
                              </a>
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                {totalTipHistoryList?.length == 0 ? <p className="whiteText">No Records found</p> : ""}
              </div>
              <div className="row my-5">
                <div className="col">
                  <nav
                    aria-label="Page navigation example"
                    className="paginationRow"
                  >
                    {totalPages > 1 ? (
                      <ReactPaginate
                        breakLabel={"..."}
                        nextLabel={"Next >"}
                        onPageChange={handlePageChange}
                        pageCount={totalPages}
                        previousLabel={"< Back"}
                        renderOnZeroPageCount={null}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    ) : null}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
}

export default TippingHistory;
