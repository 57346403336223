import { createSlice } from "@reduxjs/toolkit";

export const AdminCustomerId = createSlice ({
    name:'admincustomer',
    initialState: {
        isLoggedIn: false,
        data: {}
    },
    reducers: {
        admincustomerdetails(state, action) {
            state.isLoggedIn = true;
            state.data = action.payload;
        },
      
    }
})

export const {admincustomerdetails} = AdminCustomerId.actions;
export default AdminCustomerId.reducer;