import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
// import "react-toastify/dist/ReactToastify.css";
 import { failed } from "./components/common/Toastify";

export default function ProtectedOutlet() {
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  if (isLoggedIn) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }
}

export function LoggedInOutlet() {
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  return isLoggedIn ? <Navigate to="/" /> : <Outlet />;
}
