import HomeHeader from "../../homePages/common/HomeHeader";
import { Link, useParams } from "react-router-dom";
import { StaffViewProfileHistory, updateTipping } from "../../staffInterface/service/Auth.service";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Rating } from "@mui/material";

function StaffViewProfile() {
    const staffId = useParams();
    const navigate = useNavigate();
    let rating;
    const [viewProfileData, setViewProfileData] = useState([]);
    const [ratingNo, setRatingNo] = useState()
    const [input, setInput] = useState({
        _id: staffId?.id,
        staff_comment: "",
        is_staff_commented: 1,
    });
    /*---------@Handler function for comment------------ */
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInput((previousValue) => ({
            ...previousValue,
            [name]: value,
        }));
    };

    const dispatch = useDispatch();
    useEffect(() => {
        _getStaffTipHistorybyId();
    }, [dispatch]);
    /*---------@Api call for getting Staff view Profile History---------- */
    const _getStaffTipHistorybyId = () => {
        StaffViewProfileHistory(
            { _id: staffId?.id },
            (data) => {
                setViewProfileData(data);
                rating = Number(data.customer_rating_to_staff)
                setRatingNo(Number(data.customer_rating_to_staff))
            },
            dispatch
        );
    };
    /*---------@Submit Handler for form------------ */
    const buttonHandler = async () => {
        const res = await updateTipping(input._id, input.staff_comment, input.is_staff_commented);
        if (res?.data.status == true) {
            toast.success(
                "Comment Sent Successfully",
                {
                    theme: "dark",
               
                    position: toast.POSITION.BOTTOM_RIGHT,
                },
                { autoClose: 1000 }
            );
            _getStaffTipHistorybyId();
        }
    };

    const buttonHandlerpage = async () => {
        navigate("/staff/tippinghistory");
    };

// let rating=viewProfileData.customer_rating_to_staff
console.log("viewProfileData",viewProfileData);
    return (
        <div className="staffProfile">
            <HomeHeader />
            <div className="container">
                <div className="row my-4">
                    <div className="col direction">
                        <p class="mb-0">
                            <Link to="/staff/tippinghistory" className="custom-link">Tipping History </Link>
                            
                            <i class="fa-solid fa-angles-right"></i>
                            <span>{viewProfileData?.CustomerDetails?.full_name}</span>
                        </p>
                    </div>
                </div>

                <div className="row TippingBox">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="inputField-inline">
                                    <label for="tipping" className="form-label">
                                        Tip Amount ($) :
                                    </label>
                                    {viewProfileData?.staff_tip_amount}
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-12"></div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="inputField-inline align-items-start">
                                    <label for="totalAmount" className="form-label">
                                    PubGoer feedback :
                                    </label>
                                    {viewProfileData?.customer_comment === "" ? "-" : viewProfileData?.customer_comment}

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="inputField-inline">
                                    <label className="form-label">Rating :</label>
                                    <div className="inputBox">
                                        {/* <ReactStars
                                            count={5}
                                            size={30}
                                             value={rating}
                                             
                                            // value={3.5}

                                            isHalf={true}
                                            // emptyIcon={<i className="far fa-star"></i>}
                                            // halfIcon={<i className="fa fa-star-half-alt"></i>}
                                            // fullIcon={<i className="fa fa-star"></i>}
                                            activeColor="#ffd700"
                                        /> */}
                                        <Rating name="read-only" value={ratingNo ? ratingNo : 0} readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className={viewProfileData?.is_staff_commented == false ?"inputField-inline align-items-start":"inputField-inline"}>
                                    <label for="totalAmount" className="form-label">
                                        Pubstar FeedBack :
                                    </label>
                                    {viewProfileData?.is_staff_commented == false ? (
                                        <div className="inputBox">
                                            <textarea name="staff_comment" id="" cols="50" rows="4" className="form-control" onChange={handleChange}></textarea>
                                        </div>
                                    ) : (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="inputField-inline align-items-start">
                                                
                                                    {viewProfileData?.staff_comment === "" ? "-" : viewProfileData?.staff_comment}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row buttonsRow">
                    <div className="navBtn"></div>
                    <div className="btnGroups">
                        <button className="btn btn-white-outline btn-height" onClick={(e) => buttonHandlerpage()}>
                            Back
                        </button>

                        {viewProfileData?.is_staff_commented == false ? (
                            <button className="btn btn-main btn-height" onClick={buttonHandler}>
                                Next
                            </button>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StaffViewProfile;
