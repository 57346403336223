import HomeHeader from "../../../homePages/common/HomeHeader";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import { message, options, pagination, ProjectPaginationOption, project_status } from "../../../../components/common/utils/message";
import { TotalGroupTipById, getRestroStaffTippingHistory, getGroupTipDistributed } from "../../../RestoInterface/Service/Auth.service";
import { useNavigate } from "react-router-dom";
function TipManagement() {
    const [noGroupTip, setNoGroupTip] = useState();
    const [noStaffTip, setNoStaffTip] = useState();
    const [totalPages, settotalPages] = useState();
    const [totalStaffPages, settotalStaffPages] = useState();
    const [totalGroupDistributedPages, setTotalGroupDistributedPages] = useState();
    const [distributedTip, setDistributedTip] = useState();
    const [searchText, setSearchText] = useState("");

    const [start_date, setStartDate] = useState();
    const [end_date, setEndDate] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [historyState, setHistoryState] = useState(0);
    const [selectedOption, setSelectedOption] = useState("option1");

    const [limit, setLimit] = useState(pagination.projectLimit);
    const [page, setPage] = useState(pagination.page);

    const onTabClick = (tabId) => () => {
        setHistoryState(tabId);
    };

    //   const userdata = useSelector((state) => state);
    //   let user_id = userdata?.login?.data?.result._id;
    const user_id = useParams();

    useEffect(() => {
        _getGroupTipById();
        _getRestrostaffTipById();
        _getGroupDistributedTipList();
    }, [dispatch]);

    const _getGroupTipById = () => {
        TotalGroupTipById(
            { resto_id: user_id.id, page, limit, start_date, end_date, searchText },
            (data) => {
                setNoGroupTip(data?.docs);
                settotalPages(data?.totalPages);
            },
            dispatch
        );
    };
    // if(start_date != undefined && end_date != undefined){
    //   const _getGroupTipByIdList = () => {
    //     TotalGroupTipById(
    //       { resto_id: user_id, page, limit,start_date, end_date },
    //       (data) => {
    //         setNoGroupTip(data?.docs);
    //         settotalPages(data?.totalPages);
    //       },
    //       dispatch
    //     );
    //   };
    // }

    const handleGroupPageChange = async (param) => {
        let currentPage = param.selected + 1;
        setPage(currentPage);
        await TotalGroupTipById(
            { resto_id: user_id.id, page: currentPage, limit },
            (response) => {
                setNoGroupTip(response?.docs);
            },
            dispatch
        );
    };
    function handleOptionChange(event) {
        setSelectedOption(event.target.value);
    }

    const handleInputStartDate = (newDate) => {
        setStartDate(newDate.target.value);
    };

    const handleInputEndDate = (newDate) => {
        setEndDate(newDate.target.value);
        _getGroupTipById();
    };

    const __handleNotDistributedTipSearch = async (e) => {
        setSearchText(e.target.value);
        _getGroupTipById();
    };

    // --------------------------------------------------------------------------------
    const _getGroupDistributedTipList = () => {
        getGroupTipDistributed(
            { resto_id: user_id.id, page, limit, start_date, end_date, searchText },
            (data) => {
                setDistributedTip(data?.data?.result?.docs);
                setTotalGroupDistributedPages(data?.data?.result?.totalPages);
            },
            dispatch
        );
    };

    const handleGroupTipPageChange = async (param) => {
        let currentPage = param.selected + 1;
        setPage(currentPage);
        await getGroupTipDistributed(
            { resto_id: user_id.id, page: currentPage, limit },
            (response) => {
                setDistributedTip(response?.data?.result?.docs);
            },
            dispatch
        );
    };

    function handleGroupDistributedOptionChange(event) {
        setSelectedOption(event.target.value);
    }

    const handleGroupDistributedInputStartDate = (newDate) => {
        setStartDate(newDate.target.value);
    };

    const handleGroupDistributedInputEndDate = (newDate) => {
        setEndDate(newDate.target.value);

        _getGroupDistributedTipList();
    };

    const __handleDistributedTipSearch = async (e) => {
        setSearchText(e.target.value);
        _getGroupDistributedTipList();
    };
    // -------------------------------------------------------------------------------------

    const _getRestrostaffTipById = () => {
        getRestroStaffTippingHistory(
            { resto_id: user_id.id, page, limit, start_date, end_date, searchText },
            (data) => {
                setNoStaffTip(data?.data?.result?.docs);
                settotalStaffPages(data?.data?.result?.totalPages);
            },
            dispatch
        );
    };

    const handleStaffTipPageChange = async (param) => {
        let currentPage = param.selected + 1;
        setPage(currentPage);
        await getRestroStaffTippingHistory(
            { resto_id: user_id.id, page: currentPage, limit },
            (response) => {
                setNoStaffTip(response?.data?.result?.docs);
            },
            dispatch
        );
    };

    function handleStaffOptionChange(event) {
        setSelectedOption(event.target.value);
    }

    const handleStaffInputStartDate = (newDate) => {
        setStartDate(newDate.target.value);
    };

    const handleStaffInputEndDate = (newDate) => {
        setEndDate(newDate.target.value);
        _getRestrostaffTipById();
    };
    const __handleStaffTipSearch = async (e) => {
        setSearchText(e.target.value);
        _getRestrostaffTipById();
    };
    //

    var total = 0;
    for (var i = 0; i < noGroupTip?.length; i++) {
        total += noGroupTip[i]?.total_tip_amount;
    }

    var totalstafftips = 0;
    for (var i = 0; i < noStaffTip?.length; i++) {
        totalstafftips += noStaffTip[i]?.total_tip_amount;
    }
    var totalgroupdistributetips = 0;
    for (var i = 0; i < distributedTip?.length; i++) {
        totalgroupdistributetips += distributedTip[i]?.total_tip_amount;
    }
    const buttonviewHandlerpage = async (data) => {
        navigate(`/resto/tipdistributedpage/${data?._id}`);
    };

    const buttonviewGroupHandlerpage = async (data) => {
        navigate(`/resto/tiprequestsent/${data?._id}`);
    };

    // const buttonviewStaffHandlerpage = async (data) => {
    //   navigate(`/resto/staffviewprofile/${data?.staffDetails?._id}`);
    // };

    return (
        <div className="tippingHistroy">
            <HomeHeader />
            <div className="container-fluid">
                <div className="row topBar">
                    <div className="col">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="content">
                                        <div className="heading">
                                            <h3>Group Tip</h3>
                                        </div>
                                        <div className="price">
                                            <h3>$ {total}</h3>
                                            <p>Total Group Tip Received</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="tabs">
                            <div className={`tab ${historyState === 0 && "active"}`} onClick={onTabClick(0)}>
                                Group Tip (Not Distributed)
                                <div className="line"></div>
                            </div>
                            {/* <div
                className={`tab ${historyState === 1 && "active"}`}
                onClick={onTabClick(1)}
              >
                Pending Requests
                <div className="line"></div>
              </div> */}
                            <div className={`tab ${historyState === 2 && "active"}`} onClick={onTabClick(2)}>
                                Group Tip (Distributed)
                                <div className="line"></div>
                            </div>
                            <div className={`tab ${historyState === 3 && "active"}`} onClick={onTabClick(3)}>
                                Staff Tips
                                <div className="line"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`row tabContent ${historyState !== 0 && "d-none"}`}>
                    <div className="col-12">
                        <div className="content">
                            <div className="price">
                                <h3>$ {total}</h3>
                                <p>Total Tips Paid</p>
                            </div>
                            <div className="dates">
                                {/* <div className="dropdown">
                  <select
                    name=""
                    id=""
                    className="form-select"
                    onChange={handleOptionChange}
                  >
                    <option value="today">Today</option>
                    <option value="week">This Week</option>
                    <option value="month">This Month</option>
                  </select>
                </div> */}
                                <div className="datePicker">
                                    <label htmlFor="pickFrom">From</label>
                                    <input type="date" name="" id="pickFrom" className="form-control" onChange={handleInputStartDate} />
                                </div>
                                <div className="datePicker">
                                    <label htmlFor="pickTo">To</label>
                                    <input type="date" name="" id="pickTo" className="form-control" onChange={handleInputEndDate} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row searchStaff">
                            <div className="col-12 px-0">
                                <div className="searchBox">
                                    <input type="text" className="form-control" placeholder="search staff, restaurants" value={searchText} onChange={__handleNotDistributedTipSearch} />
                                    <i class="fa-solid fa-magnifying-glass"></i>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {noGroupTip &&
                                    noGroupTip?.map((data, index) => {
                                        return (
                                            <div key={index} className="staffProfileList">
                                                <div className="d-flex">
                                                    <div className="image">
                                                        <img src="./assets/startProfile.png" alt="" />
                                                    </div>
                                                    <div className="text2">
                                                        <p className="mb-0">
                                                            Received from {""}
                                                            <span className="fw-bold">{data.is_guest_tip == false ? data?.customerDetails?.full_name : data?.guest?.guest_name}</span>
                                                        </p>
                                                        <p className="mb-0 date">
                                                            {""}
                                                            {data?.createdAt}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="text">
                                                    <p className="mb-0">
                                                        <h3>$ {data?.total_tip_amount}</h3>
                                                        <a onClick={(e) => buttonviewHandlerpage(data)}>
                                                            <i class="fa-solid fa-chevron-right"></i>
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                        <div className="row my-5">
                            <div className="col">
                                <nav aria-label="Page navigation example" class="paginationRow">
                                    {totalPages > 1 ? (
                                        <ReactPaginate
                                            breakLabel={"..."}
                                            nextLabel={"Next >"}
                                            onPageChange={handleGroupPageChange}
                                            pageCount={totalPages}
                                            previousLabel={"< Back"}
                                            renderOnZeroPageCount={null}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            containerClassName={"pagination"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    ) : null}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className={`row tabContent ${historyState !== 1 && "d-none"}`}>
          <div className="col-12">
            <div className="content">
              <div className="price">
                <h3>$200</h3>
                <p>Total Tips Paid</p>
              </div>
              <div className="dates">
                <div className="dropdown">
                  <select name="" id="" className="form-select">
                    <option value="">Today</option>
                    <option value="">Today</option>
                    <option value="">Today</option>
                  </select>
                </div>
                <div className="datePicker">
                  <label htmlFor="pickFrom">From</label>
                  <input
                    type="date"
                    name=""
                    id="pickFrom"
                    className="form-control"
                  />
                </div>
                <div className="datePicker">
                  <label htmlFor="pickTo">To</label>
                  <input
                    type="date"
                    name=""
                    id="pickTo"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row searchStaff">
              <div className="col-12 px-0">
                <div className="searchBox">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="search staff, restaurants"
                  />
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="staffProfileList">
                  <div className="d-flex">
                    <div className="image">
                      <img src="./assets/startProfile.png" alt="" />
                    </div>
                    <div className="text2">
                      <p className="mb-0">
                        Received from
                        <span className="fw-bold"> Simon Smith</span>
                      </p>
                      <p className="mb-0 date">1/20/2022</p>
                    </div>
                  </div>
                  <div className="text">
                    <p className="mb-0">
                      <h3>$30</h3>
                      <a href="/">
                        <i class="fa-solid fa-chevron-right"></i>
                      </a>
                    </p>
                  </div>
                </div>
                <div className="staffProfileList">
                  <div className="d-flex">
                    <div className="image">
                      <img src="./assets/startProfile.png" alt="" />
                    </div>
                    <div className="text2">
                      <p className="mb-0">
                        Received from
                        <span className="fw-bold"> Simon Smith</span>
                      </p>
                      <p className="mb-0 date">1/20/2022</p>
                    </div>
                  </div>
                  <div className="text">
                    <p className="mb-0">
                      <h3>$30</h3>
                      <a href="/">
                        <i class="fa-solid fa-chevron-right"></i>
                      </a>
                    </p>
                  </div>
                </div>
                <div className="staffProfileList">
                  <div className="d-flex">
                    <div className="image">
                      <img src="./assets/startProfile.png" alt="" />
                    </div>
                    <div className="text2">
                      <p className="mb-0">
                        Received from
                        <span className="fw-bold"> Simon Smith</span>
                      </p>
                      <p className="mb-0 date">1/20/2022</p>
                    </div>
                  </div>
                  <div className="text">
                    <p className="mb-0">
                      <h3>$30</h3>
                      <a href="/">
                        <i class="fa-solid fa-chevron-right"></i>
                      </a>
                    </p>
                  </div>
                </div>
                <div className="staffProfileList">
                  <div className="d-flex">
                    <div className="image">
                      <img src="./assets/startProfile.png" alt="" />
                    </div>
                    <div className="text2">
                      <p className="mb-0">
                        Received from
                        <span className="fw-bold"> Simon Smith</span>
                      </p>
                      <p className="mb-0 date">1/20/2022</p>
                    </div>
                  </div>
                  <div className="text">
                    <p className="mb-0">
                      <h3>$30</h3>
                      <a href="/">
                        <i class="fa-solid fa-chevron-right"></i>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-5">
              <div className="col">
                <nav aria-label="Page navigation example" class="paginationRow">
                  <ul className="pagination">
                    <li className="page-item">
                      <a className="page-link" href="/" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="/">
                        1
                      </a>
                    </li>
                    <li className="page-item active">
                      <a className="page-link" href="/">
                        2
                      </a>
                    </li>
                    <li class="page-item">
                      <a className="page-link" href="/">
                        3
                      </a>
                    </li>
                    <li class="page-item">
                      <a className="page-link" href="/" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div> */}
                <div className={`row tabContent ${historyState !== 2 && "d-none"}`}>
                    <div className="col-12">
                        <div className="content">
                            <div className="price">
                                <h3>$ {totalgroupdistributetips}</h3>
                                <p>Total Tips Paid</p>
                            </div>
                            <div className="dates">
                                {/* <div className="dropdown">
                  <select
                    name=""
                    id=""
                    className="form-select"
                    onChange={handleGroupDistributedOptionChange}
                  >
                    <option value="today">Today</option>
                    <option value="week">This Week</option>
                    <option value="month">This Month</option>
                  </select>
                </div> */}
                                <div className="datePicker">
                                    <label htmlFor="pickFrom">From</label>
                                    <input type="date" name="" id="pickFrom" className="form-control" onChange={handleGroupDistributedInputStartDate} />
                                </div>
                                <div className="datePicker">
                                    <label htmlFor="pickTo">To</label>
                                    <input type="date" name="" id="pickTo" className="form-control" onChange={handleGroupDistributedInputEndDate} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row searchStaff">
                            <div className="col-12 px-0">
                                <div className="searchBox">
                                    <input type="text" className="form-control" placeholder="search staff, restaurants" value={searchText} onChange={__handleDistributedTipSearch} />
                                    <i class="fa-solid fa-magnifying-glass"></i>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {distributedTip &&
                                    distributedTip?.map((data, index) => {
                                        return (
                                            <div key={index} className="staffProfileList">
                                                <div className="d-flex">
                                                    <div className="image">
                                                        <img src="./assets/startProfile.png" alt="" />
                                                    </div>
                                                    <div className="text2">
                                                        <p className="mb-0">
                                                            Received from
                                                            <span className="fw-bold"> {data.is_guest_tip == false ? data?.customerDetails?.full_name : data?.guest?.guest_name}</span>
                                                        </p>
                                                        <p className="mb-0 date">{data?.createdAt}</p>
                                                    </div>
                                                </div>
                                                <div className="text">
                                                    <p className="mb-0">
                                                        <h3>$ {data?.total_tip_amount}</h3>
                                                        <a onClick={(e) => buttonviewGroupHandlerpage(data)}>
                                                            <i class="fa-solid fa-chevron-right"></i>
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>

                        <div className="row my-5">
                            <div className="col">
                                <nav aria-label="Page navigation example" class="paginationRow">
                                    {totalGroupDistributedPages > 1 ? (
                                        <ReactPaginate
                                            breakLabel={"..."}
                                            nextLabel={"Next >"}
                                            onPageChange={handleGroupTipPageChange}
                                            pageCount={totalGroupDistributedPages}
                                            previousLabel={"< Back"}
                                            renderOnZeroPageCount={null}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            containerClassName={"pagination"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    ) : null}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`row tabContent ${historyState !== 3 && "d-none"}`}>
                    <div className="col-12">
                        <div className="content">
                            <div className="price">
                                <h3>$ {totalstafftips}</h3>
                                <p>Total Tips Paid</p>
                            </div>
                            <div className="dates">
                                {/* <div className="dropdown">
                  <select
                    name=""
                    id=""
                    className="form-select"
                    onChange={handleStaffOptionChange}
                  >
                    <option value="today">Today</option>
                    <option value="week">This Week</option>
                    <option value="month">This Month</option>
                  </select>
                </div> */}
                                <div className="datePicker">
                                    <label htmlFor="pickFrom">From</label>
                                    <input type="date" name="" id="pickFrom" className="form-control" onChange={handleStaffInputStartDate} />
                                </div>
                                <div className="datePicker">
                                    <label htmlFor="pickTo">To</label>
                                    <input type="date" name="" id="pickTo" className="form-control" onChange={handleStaffInputEndDate} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row searchStaff">
                            <div className="col-12 px-0">
                                <div className="searchBox">
                                    <input type="text" className="form-control" placeholder="search staff, restaurants" value={searchText} onChange={__handleStaffTipSearch} />
                                    <i class="fa-solid fa-magnifying-glass"></i>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {noStaffTip &&
                                    noStaffTip?.map((data, index) => {
                                        return (
                                            <div key={index} className="staffProfileList">
                                                <div className="d-flex">
                                                    <div className="image">
                                                        <img src="./assets/startProfile.png" alt="" />
                                                    </div>
                                                    <div className="text2">
                                                        <p className="mb-0">
                                                            Received from
                                                            <span className="fw-bold"> {data.is_guest_tip == false ? data?.customerDetails?.full_name : data?.guest?.guest_name}</span>
                                                        </p>
                                                        <p className="mb-0 date">{data?.createdAt}</p>
                                                    </div>
                                                </div>
                                                <div className="text">
                                                    <p className="mb-0">
                                                        <h3>$ {data?.total_tip_amount}</h3>
                                                        <a>
                                                            <i class="fa-solid fa-chevron-right"></i>
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                        <div className="row my-5">
                            <div className="col">
                                <nav aria-label="Page navigation example" class="paginationRow">
                                    {totalStaffPages > 1 ? (
                                        <ReactPaginate
                                            breakLabel={"..."}
                                            nextLabel={"Next >"}
                                            onPageChange={handleStaffTipPageChange}
                                            pageCount={totalStaffPages}
                                            previousLabel={"< Back"}
                                            renderOnZeroPageCount={null}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            containerClassName={"pagination"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    ) : null}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    );
}

export default TipManagement;
