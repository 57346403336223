import React from "react";
import Header from "../../../features/super-admin/common/Header";
import Sidebar from "../../../features/super-admin/common/Sidebar";

import { useSelector } from "react-redux";
// import "react-toastify/dist/ReactToastify.css";

 import LoaderUI from "../loader/loader";
const AdminLayout = ({ children }) => {
  let isLoading = useSelector(state => state.loader.isLoading);

  let isLogin = useSelector(state => state.login.user);
  return (
    <>
      <Sidebar />
      <main className="dashboard-wraper">
        <Header />
        <div className="dashboard-wrapper">
        {isLoading && <LoaderUI
                            loader={isLoading}
                            overlay={true}
                            overlayRadius='rounded-10'
                            FullWindow={true}
                            color="danger"
                            animation='border'
                            customSize="lg"
                        />}
          {children}</div>
      </main>
    </>
  );
};

export default AdminLayout;
