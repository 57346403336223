import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";
import { GetAboutUs, UpdateAboutUs } from "../../service/Auth.service";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function LandingPage() {
  const userdata = useSelector((state) => state);
  let adminRole = userdata?.login?.data?.role;
  const [aboutusdata, setAboutUsData] = useState();

  const [formData, setFormData] = useState({
    _id: "",
    about_us_heading: "",
    about_us_content: "",
    footer_content:""
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    _getAboutUsData();
  }, [dispatch]);
  const _getAboutUsData = () => {
    GetAboutUs(
      {},
      (data) => {
        setAboutUsData(data[0]);
        setFormData({
          _id: data[0]?._id,
          about_us_heading: data[0]?.about_us?.about_us_heading,
          about_us_content: data[0]?.about_us?.about_us_content,
        });
      },
      dispatch
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((previousValue) => ({
      ...previousValue,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSubmitdata = async (e) => {
    e.preventDefault();

    let reqData = {
      _id: formData._id,
      about_us_heading: formData.about_us_heading,
      about_us_content: formData.about_us_content,
      footer_content:formData.footer_content
    };

    try {
      await UpdateAboutUs(
        reqData,
        (result) => {
          if (result.status == true) {
            toast.success(
              "Data Updated successfully",
              {
                theme: "dark",
             
                position: toast.POSITION.BOTTOM_RIGHT,
              },
              { autoClose: 1000 }
            );
          } else if (result.status == false) {
            toast.error(
              "Data not added successfully",
              {
                theme: "dark",
             
                position: toast.POSITION.BOTTOM_RIGHT,
              },
              { autoClose: 1000 }
            );
          }
        },
        dispatch
      );
    } catch (e) {
      // failed(e);
    }
  };
  if (adminRole == 0) {
    return (
      <>
        <h2 className="page-title">Content Management</h2>
        <div className="content-wraper">
          <h4>Landing Page</h4>
          <Card>
            <Card.Body>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <label>
                 About Us Banner Heading <sup>*</sup>
                    </label>
                    <input
                      type="text"
                      name="about_us_heading"
                      className="form-control mb-3"
                      defaultValue={aboutusdata?.about_us?.about_us_heading}
                      onChange={handleChange}
                    />
                  </Col>

                  <Col md={12}>
                    <label>
                      Content <sup>*</sup>
                    </label>
                    <textarea
                      className="form-control"
                      name="about_us_content"
                      defaultValue={aboutusdata?.about_us?.about_us_content}
                      onChange={handleChange}
                      rows={6}
                    ></textarea>
                  </Col>
                </Row>
                <hr />
                <Col md={12}>
                    <label>
                     Footer Content <sup>*</sup>
                    </label>
                    <textarea
                      className="form-control"
                      name="footer_content"
                      defaultValue={aboutusdata?.footer_content}
                      onChange={handleChange}
                      rows={6}
                    ></textarea>
                  </Col>
              </form>
              <div className="text-end">
                <Button variant="primary" onClick={handleSubmitdata}>
                  Save & Update
                </Button>
              </div>
            </Card.Body>
          </Card>
        </div>
      </>
    );
  }
}
export default LandingPage;
