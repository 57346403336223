import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import Logo from "../../../assets/images/dashboard/site-logo.svg";
import IconDashbboard from "../../../assets/images/dashboard/icon-dashboard.svg";
import IconContent from "../../../assets/images/dashboard/icon-content.svg";
import IconCustomer from "../../../assets/images/dashboard/icon-customer.svg";
import IconNotify from "../../../assets/images/dashboard/icon-notify.svg";
import IconPayment from "../../../assets/images/dashboard/icon-payment.svg";
import IconQuery from "../../../assets/images/dashboard/icon-query.svg";
import IconStaff from "../../../assets/images/dashboard/icon-staff.svg";
import IconResturant from "../../../assets/images/dashboard/icon-resturant.svg";
import { Link } from "react-router-dom";
function Sidebar() {
  const [open, setOpen] = useState(false);
  const [closed, setClosed] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const handleClick = (item) => {
    if (activeItem !== item) {
      setActiveItem(item);
      item.classList.add("active");
    }
  };
  return (
    <>
      <div className="admin-sidebar">
        <Link to="/super-admin/dashboard" className="admin-logo custom-link ">
          <img src={Logo} className="img-fluid" />
        </Link>
        <ul>
          {/* <li>
            <a href="/super-admin/dashboard"><img src={IconDashbboard} /> Dashboard</a>
</li> */}
          <li
            onClick={() => handleClick("dashboard")}
            className={activeItem === "dashboard" ? "active" : ""}
          >
            <Link
                                to="/super-admin/dashboard"
                                className="custom-link "
                                
                              >
                               <img src={IconDashbboard} /> Dashboard
                              </Link>
            
          </li>
          <li
            onClick={() => handleClick("Restaurant")}
            className={activeItem === "Restaurant" ? "active" : ""}
          >
            <a >
              <img src={IconResturant} />
              Pub Management
              <i class="fa-solid fa-angle-right"></i>
            </a>
            <ul className="submenu" id="example-collapse-text">
              <li>
                <Link to="/super-admin/totalpubs"    className="custom-link ">Total Pubs</Link>
              </li>
            </ul>
          </li>
          <li
            onClick={() => handleClick("Staff")}
            className={activeItem === "Staff" ? "active" : ""}
          >
            <Link to="/super-admin/staff-management" className="custom-link ">
              <img src={IconStaff} />
             Pubstar Management
            </Link>
          </li>
          <li
            onClick={() => handleClick("Customer")}
            className={activeItem === "Customer" ? "active" : ""}
          >
            <Link to="/super-admin/customer-management" className="custom-link ">
              {" "}
              <img src={IconCustomer} />
              Customer Management
            </Link>
          </li>
          <li
            onClick={() => handleClick("Payment")}
            className={activeItem === "Payment" ? "active" : ""}
          >
            <a
             
              onClick={() => setOpen(!open)}
              aria-controls="payment"
              aria-expanded={open}
            >
              <img src={IconPayment} />
              Payment Management
              <i class="fa-solid fa-angle-right"></i>
            </a>

              <ul className="submenu" id="payment">
                <li>
                  <Link to="/super-admin/setup-servicefees" className="custom-link ">Setup Service Fees</Link>
                </li>
                <li>
                  <Link to="/super-admin/service-fees" className="custom-link ">Service Fees</Link>
                </li>
                <li>
                  <Link to="/super-admin/donation" className="custom-link ">Donation</Link>
                </li>
                <li>
                  <Link to="/super-admin/payment-history" className="custom-link ">Payment History</Link>
                </li>
              </ul>
          </li>
          <li
            onClick={() => handleClick("Notifications")}
            className={activeItem === "Notifications" ? "active" : ""}
          >
            <Link to="/super-admin/notification-list" className="custom-link ">
              <img src={IconNotify} /> Notifications & Alerts
            </Link>
          </li>
          <li
            onClick={() => handleClick("Content")}
            className={activeItem === "Content" ? "active" : ""}
          >
            <a
             
              onClick={() => setClosed(!closed)}
              aria-controls="Content"
              aria-expanded={closed}
            >
              <img src={IconContent} />
              Content Management
              <i class="fa-solid fa-angle-right"></i>
            </a>

            <Collapse in={closed}>
              <ul className="submenu" id="Content">
                <li>
                  <Link to="/super-admin/landingpage" className="custom-link ">Landing page</Link>
                  <Link to="/super-admin/faq-management" className="custom-link ">FAQ Management</Link>
                  <Link to="/super-admin/how-it-works" className="custom-link ">How it works</Link>

                </li>
              </ul>
            </Collapse>
          </li>
          <li
            onClick={() => handleClick("Queries")}
            className={activeItem === "Queries" ? "active" : ""}
          >
            <a >
              <img src={IconQuery} />
              Queries
              <i class="fa-solid fa-angle-right"></i>
            </a>
            <ul className="submenu" id="example-collapse-text">
              <li>
                <Link to="/super-admin/enquiry-list" className="custom-link ">Enquiry Lists</Link>
              </li>
              <li>
                <Link to="/super-admin/contact-support" className="custom-link ">Contact Supports</Link>
              </li>
              <li>
                <Link to="/super-admin/contact-us" className="custom-link ">Contact Us</Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
