import React, { createContext, useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";

const SocketContext = createContext();

const SocketProvider = ({ socketUrl, children }) => {
  const socketRef = useRef(null);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    socketRef.current = io(socketUrl, { transports: ["websocket"] });
    setSocket(socketRef.current);

    return () => {
      socketRef.current.disconnect();
    };
  }, [socketUrl]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
