import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import authReducer from "../features/auth/loginSlice";
import loaderReducer from '../components/common/loader/LoaderSlice';
import generalReducer from '../components/common/general/GeneralSlice'
import logger from "redux-logger";
import restroReducer from '../features/staffInterface/Slice'
import adminrestroReducer from '../features/super-admin/Slice'
import customToasterSlice from '../components/common/customToaster/CustomToasterSlice'
import adminrstaffReducer from '../features/super-admin/components/staff-management/Slice'
import admincustomerReducer from '../features/super-admin/components/customer-management/Slice'
const reducers = combineReducers({
  login: authReducer,
  loader: loaderReducer,
  general: generalReducer,
  restro:restroReducer,
  adminrestro:adminrestroReducer,
  adminstaff:adminrstaffReducer,
  admincustomer:admincustomerReducer,
  customToaster: customToasterSlice
  
});


const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: true,
});
