
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Card } from "reactstrap";
import hompageLogo from "../../../assets/images/mainPageLogo.png"
import gotip from "../../../assets/images/gotipLogo.png";
import mobileNavLogo from "../../../assets/images/mobileNavLogo.png"

export default function MainHomeContactUs() {
  const navigate = useNavigate();
  return (
    <>
      <div className="homePage">
        <div className="container-fluid">
          <div className="customerDashboard">
            <div className="row headerSection">
              <div className="col">
                <div className="header">
                  <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">



                      <a className="navbar-brand">
                        <Link to="/" className="custom-link">
                          {" "}
                          <img
                            src={hompageLogo}
                            style={{ borderRadius: "20px" }} height={50} width={100} className="webView"
                            alt=""

                          />
                          <img src={mobileNavLogo} className="mobileView" style={{ borderRadius: '20px' }} height={50} width={100} alt="" />
                        </Link>
                      </a>


                      <button
                        className="navbar-toggler btn-main "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        style={{ background: "#FAF04B", color: "tranparent" }}
                        aria-label="Toggle navigation">
                        {/* <span className="navbar-toggler-icon text-light"></span> */}
                        <i className="fa-solid fa-bars navbar-toggler-icon blackText centerMid"></i>
                      </button>
                      <div
                        className="collapse navbar-collapse justify-content-end"
                        id="navbarSupportedContent">
                        <ul className="navbar-nav mb-2 mb-lg-0">
                          <li className="nav-item">
                            <Link to="/aboutus" className="nav-link">About</Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/contactUs" className="nav-link">Contact</Link>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/#howItWorks">
                              How It Works
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/">
                              Sign Up
                            </a>
                          </li>
                          {/* <li className="nav-item">
                          <a className="nav-link" href="/">
                            Contact Us
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link highlighted" href="/Register">
                            Register as a
                          </a>
                        </li> */}

                          <li>
                            <Link to="/login" className="btn btn-main btn-height">
                              Sign In
                            </Link>
                          </li>
                        </ul>
                        <form className="d-flex"></form>
                      </div>
                    </div>
                  </nav>
                </div>
                <div
                  className="headerContent mt-3 tippingImage dflex jcc"
                  id="aboutUs">

                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="container-fluid">
          <div className="row headerSection">
            <div className="col"></div>
          </div>
          <div className="row contactSection" id="contactUs">
            <div className="col-md-12 dflex alc" style={{ paddingLeft: "30px" }}>
              <div className="col-md-12">
                <h1 className="Spoof-Black" style={{ paddingBottom: "30px" }}>About GoTipMe</h1>
                <div className="contactForm">
                  <p className="form-group">
                    GoTipMe was created in 2024 by Seamus Travers, off Howl At The Moon pub in Hoxton.
                  </p>
                  <p className="form-group">
                    With a wealth of over 15 years of experience in Hospitality, Seamus Travers identified a need to help pubgoers directly reward bartenders, leading to the creation of GoTipMe.
                  </p>
                  <p className="form-group">GoTipMe is headquartered on Paul Street, close to the Silicon Roundabout and the buzzing bars of Shoreditch.
                  </p>
                  <p className="form-group">
                    Our company aims to establish a platform that delivers bartenders additional compensation while improving the pubgoer's experience.
                  </p>
                  <div className="col-md-6" style={{ paddingTop: "30px" }}>
                    <h3 className="Spoof-Black">Contact GoTipMe</h3>
                    <div className="contactForm">
                      <div className="form-group">
                        <label htmlFor="business_name">Email : </label> <i><a href="mailto:hello@gotipme.com">hello@gotipme.com</a></i>
                      </div>
                      <div className="form-group">
                        <label htmlFor="phone_number">Phone Number : </label> <i>+44 20 4578 0870</i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

