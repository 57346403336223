import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { Col, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { pagination } from "../../../../components/common/utils/message";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  _get_notifications,
  NotificationDelete,
  _get_EmailTemplates,
  EmailDelete,
} from "../../service/Auth.service";
import { Button } from "react-bootstrap";

function NotificationList() {
  const [historyState, setHistoryState] = useState(0);
  const onTabClick = (tabId) => () => {
    setHistoryState(tabId);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [totalPages, settotalPages] = useState();
  const [notificationlist, setNotificationList] = useState();
  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const [deleteid, setDeleteId] = useState();
  const [emailtotalPages, setEmailTotalPages] = useState();
  const [emaillist, setEmailList] = useState();
  useEffect(() => {
    _getnotificationList();
    _getEmailTemplateLists();
  }, [dispatch]);

  const _getnotificationList = () => {
    _get_notifications(
      { page, limit },
      (data) => {
        setNotificationList(data?.docs);
        settotalPages(data?.totalPages);
      },
      dispatch
    );
  };

  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await _get_notifications(
      { page: currentPage, limit },
      (response) => {
        setNotificationList(response?.docs);
      },
      dispatch
    );
  };

  const buttonviewHandlerpage = async (data) => {
    navigate(`/super-admin/add-notification/${data?._id}`);
  };
  const handleDelete = () => {
    NotificationDelete(
      { _id: deleteid },
      (result) => {
        if (result?.status == true) {
          toast.success(
            "Deleted successfully",
            {
              theme: "dark",
            
              position: toast.POSITION.BOTTOM_RIGHT,
            },
            { autoClose: 1000 }
          );
          _getnotificationList();
        }
      },
      dispatch
    );
  };
  const handleChangeDelete = async (_id) => {
    setDeleteId(_id);
  };

  // Email templates

  const _getEmailTemplateLists = () => {
    _get_EmailTemplates(
      { page, limit },
      (data) => {
        // console.log("data----",data);
        setEmailList(data?.docs);
        setEmailTotalPages(data?.totalPages);
      },
      dispatch
    );
  };

  const handlePageChange1 = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await _get_EmailTemplates(
      { page: currentPage, limit },
      (response) => {
        setEmailList(response?.docs);
      },
      dispatch
    );
  };
  const handleChangeDelete1 = async (_id) => {
    setDeleteId(_id);
  };
  const handleDelete1 = () => {
    EmailDelete(
      { _id: deleteid },
      (result) => {
        if (result?.status == true) {
          toast.success(
            "Deleted successfully",
            {
              theme: "dark",
            },
            {
              position: toast.POSITION.TOP_CENTER,
            },
            { autoClose: 1000 }
          );
          _getEmailTemplateLists();
        }
      },
      dispatch
    );
  };
  const buttonviewHandlerpage1 = async (data) => {
    navigate(`/super-admin/add-template/${data?._id}`);
  };
  return (
    <>
      <h2 className="page-title">Notification & Emails</h2>

      <div className="content-wraper">
        <div className="table-wraper">
          <div className="tippingHistroy">
            <div className="container-fluid"></div>
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="tabs">
                    <div
                      className={`tab ${historyState === 0 && "active"}`}
                      onClick={onTabClick(0)}
                    >
                      Notifications
                      <div className="line"></div>
                    </div>
                    <div
                      className={`tab ${historyState === 1 && "active"}`}
                      onClick={onTabClick(1)}
                    >
                      Emails
                      <div className="line"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`row tabContent ${historyState !== 0 && "d-none"}`}
              >
                <h4>Notification Settings</h4>

                <div className="table-wraper">
                  {/* <Row>
                    <Col sm={6}></Col>
                    <Col sm={6} className="text-end mb-3 mt-2">
                      {" "}
                      <Button
                        variant="primary"
                        id="button-addon1"
                        href="/super-admin/add-notification"
                      >
                        Add Notifications
                      </Button>
                    </Col>
                  </Row> */}
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>S No.</th>
                        <th>Type</th>
                        <th>Title</th>
                        <th>Notification Content</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notificationlist &&
                        notificationlist?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{data?.type}</td>
                              <td>{data?.title}</td>
                              <td>{data?.notification_content}</td>
                              <td>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="none"
                                    className="action-dropdown"
                                  >
                                    <i class="fa-solid fa-ellipsis"></i>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={(e) =>
                                        buttonviewHandlerpage(data)
                                      }
                                    >
                                      <i class="fa-sharp fa-solid fa-pen"></i>{" "}
                                      Edit
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal1"
                                      onClick={(e) => {
                                        handleChangeDelete(
                                          data?._id,
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <i class="fa-solid fa-trash-can"></i>{" "}
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  <div className="text-end d-flex">
                    <Pagination className="ml-auto">
                      {totalPages > 1 ? (
                        <ReactPaginate
                          breakLabel={"..."}
                          nextLabel={"Next >"}
                          onPageChange={handlePageChange}
                          pageCount={totalPages}
                          previousLabel={"< Back"}
                          renderOnZeroPageCount={null}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      ) : null}
                    </Pagination>
                  </div>
                </div>
              </div>
              <div
                className={`row tabContent ${historyState !== 1 && "d-none"}`}
              >
                <h4>Email Settings</h4>
                <div className="table-wraper">
                  <Row>
                    <Col sm={6}></Col>
                    <Col sm={6} className="text-end mb-3 mt-2">
                      {" "}
                      <Button
                        variant="primary"
                        id="button-addon1"
                        href="/super-admin/add-template"
                      >
                        Add Templates
                      </Button>
                    </Col>
                  </Row>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Template Name</th>
                        <th>Subject</th>
                        <th>Body</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {emaillist &&
                        emaillist?.map((data, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>

                              <td>{data?.template_name}</td>
                              <td>{data?.subject}</td>
                              <td>{data?.body}</td>
                              <td>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="none"
                                    className="action-dropdown"
                                  >
                                    <i class="fa-solid fa-ellipsis"></i>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={(e) =>
                                        buttonviewHandlerpage1(data)
                                      }
                                    >
                                      <i class="fa-sharp fa-solid fa-pen"></i>{" "}
                                      Edit
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal2"
                                      onClick={(e) => {
                                        handleChangeDelete1(
                                          data?._id,
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <i class="fa-solid fa-trash-can"></i>{" "}
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  <div className="text-end d-flex">
                    <Pagination className="ml-auto">
                      {emailtotalPages > 1 ? (
                        <ReactPaginate
                          breakLabel={"..."}
                          nextLabel={"Next >"}
                          onPageChange={handlePageChange1}
                          pageCount={emailtotalPages}
                          previousLabel={"< Back"}
                          renderOnZeroPageCount={null}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      ) : null}
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="exampleModal1"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body ">
                <div
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fa-solid fa-xmark"></i>
                </div>
                <h3>Delete confirmation</h3>
                <div className="otpBox">
                  <p>Are you want to Delete</p>
                </div>
                <button
                  className="btn btn-main"
                  data-bs-dismiss="modal"
                  onClick={handleDelete}
                >
                  Yes
                </button>
                &nbsp;
                <button
                  className="btn btn-white-outline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="exampleModal2"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body ">
                <div
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fa-solid fa-xmark"></i>
                </div>
                <h3>Delete confirmation</h3>
                <div className="otpBox">
                  <p>Are you want to Delete</p>
                </div>
                <button
                  className="btn btn-main"
                  data-bs-dismiss="modal"
                  onClick={handleDelete1}
                >
                  Yes
                </button>
                &nbsp;
                <button
                  className="btn btn-white-outline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationList;
