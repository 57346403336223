
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Card } from "reactstrap";
import hompageLogo from "../../../assets/images/mainPageLogo.png"
import gotip from "../../../assets/images/gotipLogo.png";
import mobileNavLogo from "../../../assets/images/mobileNavLogo.png"

export default function MainHomeContactUs() {
  const navigate = useNavigate();
  return (
    <>
      <div className="homePage">
        <div className="container-fluid">
          <div className="customerDashboard">
            <div className="row headerSection">
              <div className="col">
                <div className="header">
                  <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">



                      <a className="navbar-brand">
                        <Link to="/" className="custom-link">
                          {" "}
                          <img
                            src={hompageLogo}
                            style={{ borderRadius: "20px" }} height={50} width={100} className="webView"
                            alt=""

                          />
                          <img src={mobileNavLogo} className="mobileView" style={{ borderRadius: '20px' }} height={50} width={100} alt="" />
                        </Link>
                      </a>


                      <button
                        className="navbar-toggler btn-main "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        style={{ background: "#FAF04B", color: "tranparent" }}
                        aria-label="Toggle navigation">
                        {/* <span className="navbar-toggler-icon text-light"></span> */}
                        <i className="fa-solid fa-bars navbar-toggler-icon blackText centerMid"></i>
                      </button>
                      <div
                        className="collapse navbar-collapse justify-content-end"
                        id="navbarSupportedContent">
                        <ul className="navbar-nav mb-2 mb-lg-0">
                          <li className="nav-item">
                            <Link to="/aboutus" className="nav-link">About</Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/contactUs" className="nav-link">Contact</Link>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/#howItWorks">
                              How It Works
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="/">
                              Sign Up
                            </a>
                          </li>
                          {/* <li className="nav-item">
                          <a className="nav-link" href="/">
                            Contact Us
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link highlighted" href="/Register">
                            Register as a
                          </a>
                        </li> */}

                          <li>
                            <Link to="/login" className="btn btn-main btn-height">
                              Sign In
                            </Link>
                          </li>
                        </ul>
                        <form className="d-flex"></form>
                      </div>
                    </div>
                  </nav>
                </div>
                <div
                  className="headerContent mt-3 tippingImage dflex jcc"
                  id="aboutUs">

                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="container-fluid">
          <div className="row headerSection">
            <div className="col"></div>
          </div>

          <div className="row contactSection" id="contactUs">
            <div className="col-md-6 dflex alc" style={{ paddingLeft: "30px" }}>
              <img
                src={gotip}
                alt=""
                height="fit-content"
                className="contactUs d-none d-md-block"
                style={{ borderRadius: "20px" }}
                onClick={() => { navigate("/") }}
              />
              <img
                src={gotip}
                alt=""
                className="mobileImage d-block d-md-none mb-3"
              />
            </div>
            <div className="col-md-6">
              <h1 className="Spoof-Black">Contact Support</h1>
              <div className="contactForm">
                <div className="form-group">
                  <label htmlFor="fullName ">Your Name</label>
                  <input
                    type="text"
                    className="form-control blackBorder"
                    id="fullName"
                    name="full_name"
                  //   onChange={handleChange}
                  //   onBlur={(e) => handeblur()}
                  />
                </div>
                {/* {error.full_name && (
                <span className="text-danger">{error.full_name}</span>
              )} */}
                <div className="form-group">
                  <label htmlFor="emailId">Your Email</label>
                  <input
                    type="email"
                    className="form-control blackBorder"
                    id="emailId"
                    name="email"
                    //   onChange={handleChange}
                    required
                  //   onBlur={(e) => handeblur()}
                  />
                </div>
                {/* {error.email && (
                <span className="text-danger">{error.email}</span>
              )} */}
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="5"
                    className="form-control blackBorder"
                  //   onChange={handleChange}
                  //   onBlur={(e) => handeblur()}
                  ></textarea>
                </div>
                {/* {error.message && (
                <span className="text-danger">{error.message}</span> */}
                {/* )} */}
                <div className="form-group mt-3">
                  <button className="btn btn-main btn-height" >
                    Send
                  </button>
                </div>
              </div>
            </div>

          </div>
          <div className="row contactSection" id="contactUs">
            <div className="col-md-12 dflex alc" style={{ paddingLeft: "30px" }}>
              <div className="col-md-6">
                <h3 className="Spoof-Black">Business Details</h3>
                <div className="contactForm">
                  <div className="form-group">
                    <label htmlFor="business_name">Business Name : </label> <i>Relentless Hospitality</i>
                  </div>
                  <div className="form-group">
                    <label htmlFor="address">Office Address : </label> <i>3rd Floor, 86-90 Paul St, London EC2A 4NE, UK</i>
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone_number">Phone Number : </label> <i>07825065688</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

