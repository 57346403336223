import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import {
  pagination,
} from "../../../components/common/utils/message";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  Get_Total_Request_Recieved,
  No_Of_Request_Recieved,
} from "../service/Auth.service";
function RequestReceived() {
  const userdata = useSelector((state) => state);
  let adminRole = userdata?.login?.data?.role;
  const [historyState, setHistoryState] = useState(0);
  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const [totalPages, settotalPages] = useState();
  const [totalrequestrecieved, setTotalrequestRecieved] = useState();
  const [noofrequestrecieved, setNo_Of_Request_Recieved] = useState();
  const [searchText, setSearchText] = useState("");
  const [start_date, setStartDate] = useState();
  const [end_date, setEndDate] = useState();
  const maxDate = new Date().toISOString().split('T')[0];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onTabClick = (tabId) => () => {
    setHistoryState(tabId);
  };

  useEffect(() => {
    _getTotalRequestRecieved();
    getnoofrequestrecieved();
  }, [dispatch]);
  const getnoofrequestrecieved = () => {
    No_Of_Request_Recieved(
      {},
      (data) => {
        setNo_Of_Request_Recieved(data?.length);
      },
      dispatch
    );
  };

  const _getTotalRequestRecieved = (searchText) => {
    Get_Total_Request_Recieved(
      { page, limit, start_date, end_date, searchText: searchText },
      (data) => {
        setTotalrequestRecieved(data?.docs);
        settotalPages(data?.totalPages);
      },
      dispatch
    );
  };
  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await Get_Total_Request_Recieved(
      { page: currentPage, limit, searchText },
      (response) => {
        setTotalrequestRecieved(response?.docs);
      },
      dispatch
    );
  };

  const handleInputStartDate = (newDate) => {
    setStartDate(newDate.target.value);
  };

  const handleInputEndDate = (newDate) => {
    setEndDate(newDate.target.value);
    _getTotalRequestRecieved();
  };
  const __handleSearch = async (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
    _getTotalRequestRecieved(e.target.value);
  };
  if (adminRole == 0) {
    return (
      <>
        <div className="tippingHistroy">
          <div className="container-fluid">
            <div className="row topBar">
              <div className="col">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="content">
                        <div className="heading">
                          <h3>Dashboard</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="tabs">
                  <div
                    className={`tab ${historyState === 0 && "active"}`}
                    onClick={onTabClick(0)}
                  >
                    Total Request
                    <div className="line"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`row tabContent ${historyState !== 0 && "d-none"}`}>
              <div className="col-12">
                <div className="content">
                  <div className="price">
                    <h3>{noofrequestrecieved}</h3>
                    <p>Total Request Recieved</p>
                  </div>
                  <div className="dates">
                    <div className="datePicker">
                      <label htmlFor="pickFrom">From</label>
                      <input
                        type="date"
                        name=""
                        id="pickFrom"
                        max={maxDate}
                        className="form-control"
                        onChange={handleInputStartDate}
                      />
                    </div>
                    <div className="datePicker">
                      <label htmlFor="pickTo">To</label>
                      <input
                        type="date"
                        name=""
                        id="pickTo"
                        max={maxDate}
                        className="form-control"
                        onChange={handleInputEndDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row searchStaff">
                  <div className="col-12 px-0">
                    <div className="searchBox">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="search customers"
                        value={searchText}
                        onChange={__handleSearch}
                      />
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                </div>
                <div className="table-wraper mt-4">
                  <div className="top-action"></div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Date</th>

                        <th>Restaurant</th>
                        <th>Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      {totalrequestrecieved &&
                        totalrequestrecieved?.map((data, index) => {
                          const date = new Date(data?.createdAt);

                          // Extract the day, month, and year values
                          const day = date
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const month = (date.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const year = date.getFullYear();

                          // Format the date as dd/mm/yyyy
                          const formattedDate = `${day}/${month}/${year}`;

                          return (
                            <tr key={index}>
                              <td>{formattedDate}</td>
                              <td>
                                <p>{data?.restoDetails?.restaurant_name}</p>
                                <p>
                                  {data?.restoDetails?.addressLine1}
                                  {","}
                                  {data?.restoDetails?.city}
                                </p>{" "}
                              </td>
                              <td>Pending</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    {totalrequestrecieved?.length == 0
                      ? "No Records found"
                      : ""}
                  </div>
                  <div className="text-end d-flex">
                    <Pagination className="ml-auto">
                      {totalPages > 1 ? (
                        <ReactPaginate
                          breakLabel={"..."}
                          nextLabel={"Next >"}
                          onPageChange={handlePageChange}
                          pageCount={totalPages}
                          previousLabel={"< Back"}
                          renderOnZeroPageCount={null}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      ) : null}
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default RequestReceived;
