import React, { Component } from 'react'
import QrReader from 'react-qr-scanner'

class QRScan extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      delay: 500,
      result: 'No result',
    }

    this.handleScan = this.handleScan.bind(this)
  }
  // await navigator.mediaDevices.getUserMedia({ video: true })

  handleScan(data){
    if(data?.text != undefined){
        window.location.href = data?.text;
    }
    this.setState({
      result: data,
    })
  }
  handleError(err){
    console.error(err)
  }
  render(){
    const previewStyle = {
      height: 240,
      width: 320,
    }

    return(
      <div>
        <QrReader
          delay={this.state.delay}
          style={{ width: "100%" }}
          onError={this.handleError}
          onScan={this.handleScan}
          />
        {/* <p>{this.state.result}</p> */}
      </div>
    )
  }
}

export default QRScan;
