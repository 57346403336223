import React from "react";
import { Link, useNavigate } from "react-router-dom";
import gotip from "../../assets/images/gotipLogo.png"

function PasswordSetSuccessful() {
  const navigate = useNavigate();
  const buttonHandler = async () => {
    navigate("/login")
  }
    return (
      <div className="authPage vh-100 " style={{display:"grid",alignItems:"center"}}>
        <div className="container shadow">
          <div className="row m-0">
            <div className="col-md-6 centerMid">
            <img src={gotip} alt=""   className="authImage w-77"/>{" "}
            </div>
            <div className="col-md-6 mt-5 mt-md-0 whiteBackground borderRadius dflex alc">
              <div className="content">
                {/* <div className="logo text-center">
                  <img src="./assets/logo.svg" alt="" />
                </div> */}
                <h3 className="text-center mt-3 Aeonik-Bold">Password Reset Successful</h3>
                <p className="text-center Aeonik-Regular">
                  Your password has been successfully reset. Click below to login
                </p>
                <form className="form">
                  <div className="row mt-3">
                    <div className="col">
                      <button className="btn btn-main w-100 btn-height"  onClick={buttonHandler}>
                       Login
                      {/* <Link className="btn btn-main w-100" to="/login" >Register Your Bar</Link> */}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default PasswordSetSuccessful;
  