import React from 'react';
import { useSelector } from "react-redux";
// import ContractorInterfaceLayout from '../../../components/common/layout/ContractorInterfaceLayout';
import HomeLayout from '../../../components/common/layout/HomeLayout';
import StaffDashboard from '../StaffDashboard';
import RestoProfileRequest from '../components/RestoProfileRequest';
import SIInvitationRequest from '../components/InvitationRequest';
import RestoViewProfile from '../components/RestoViewProfile';
import RestaurantProfileScreen from '../components/RestaurantProfileScreen';
import TippingHistory from '../components/StaffTippingHistory';
import StaffViewProfile from '../components/StaffViewProfile';
import MyPreviousJob from '../components/MyPreviousJobList';
import MyPreviousJobProfile from '../components/PreviousJobProfile';
import ManageStaff from '../components/restro_permissions/ManageStaff';
import SIInvitationRequest1 from '../components/restro_permissions/InvitationRequest';
import TipManagement from '../components/restro_permissions/GroupTipDistribute';
import ContactUs from '../components/ContactUs';
import StaffHome from '../StaffHome';
import StaffPost from '../StaffPosts';
const StaffInterfaceRoute = [
    {
        path: `/staff/StaffHome`,
        layout: HomeLayout,
        component: <  StaffHome />
    },
    {
        path: `/staff/dashboard`,
        layout: HomeLayout,
        component: <  StaffDashboard />
    },
    {
        path: `/staff/staffPost`,
        layout: HomeLayout,
        component: <  StaffPost />
    },
    {
        path: `/staff/restorequest/:id`,
        layout: HomeLayout,
        component: < RestoProfileRequest />
    },
    {
        path: `/staff/invitationrequest`,
        layout: HomeLayout,
        component: < SIInvitationRequest />
    },
    {
        path: `/staff/restoviewprofile/:id`,
        layout: HomeLayout,
        component: < RestoViewProfile />
    },
    {
        path: `/staff/restaurantprofilescrren/:id`,
        layout: HomeLayout,
        component: < RestaurantProfileScreen />
    },
    {
        path: `/staff/tippinghistory`,
        layout: HomeLayout,
        component: < TippingHistory />
    },
    {
        path: `/staff/viewprofile/:id`,
        layout: HomeLayout,
        component: < StaffViewProfile />
    },
    {
        path: `/staff/mypreviousjob`,
        layout: HomeLayout,
        component: < MyPreviousJob />
    },
    {
        path: `/staff/previousjobprofile/:id`,
        layout: HomeLayout,
        component: < MyPreviousJobProfile />
    },
    {
        path: `/staff/managestaff/:id`,
        layout: HomeLayout,
        component: < ManageStaff />
    },
    {
        path: `/staff/invitationrequest/:id`,
        layout: HomeLayout,
        component: < SIInvitationRequest1 />
    },
    {
        path: `/staff/grouptipdistribute/:id`,
        layout: HomeLayout,
        component: < TipManagement />
    },
    {
        path: `/staff/contactus`,
        layout: HomeLayout,
        component: < ContactUs />
    }
    
    


]

export default StaffInterfaceRoute;