import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { Col, Row } from "reactstrap";
import { GetHowitworks, HowitWorksDelete } from "../../service/Auth.service";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  pagination,
} from "../../../../components/common/utils/message";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Howitworks() {
  const userdata = useSelector((state) => state);
  let adminRole = userdata?.login?.data?.role;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [howitworksdata, setHowitworksdata] = useState();
  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const [totalPages, settotalPages] = useState();
  const [deleteid, setDeleteId] = useState();

  useEffect(() => {
    _getHowitworks();
  }, [dispatch]);
  const _getHowitworks = () => {
    GetHowitworks(
      { page, limit },
      (data) => {
        setHowitworksdata(data?.docs);
        settotalPages(data?.totalPages);
      },
      dispatch
    );
  };
  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await GetHowitworks(
      { page: currentPage, limit },
      (response) => {
        setHowitworksdata(response?.docs);
      },
      dispatch
    );
  };

  const handleChangeDelete = async (_id) => {
    setDeleteId(_id);
  };

  const handleDelete = () => {
    HowitWorksDelete(
      { _id: deleteid },
      (result) => {
        if (result?.status == true) {
          toast.success(
            "Deleted successfully",
            {
              theme: "dark",
            
              position: toast.POSITION.BOTTOM_RIGHT,
            },
            { autoClose: 1000 }
          );
          _getHowitworks();
        }
      },
      dispatch
    );
  };

  const buttonviewHandlerpage = async (data) => {
    navigate(`/super-admin/add-how-it-works/${data?._id}`);
  };
  if (adminRole == 0) {
    return (
      <>
        <h2 className="page-title">Content Management</h2>

        <div className="content-wraper">
          <Row>
            <Col sm={6}>
              {" "}
              <h4>How it works</h4>
            </Col>
            <Col sm={6} className="text-end mb-3 mt-2">
              {" "}
              <Button
                variant="primary"
                id="button-addon1"
                href="/super-admin/add-how-it-works"
              >
                Add How it works
              </Button>
            </Col>
          </Row>

          <div className="table-wraper">
            <Table responsive>
              <thead>
                <tr>
                  <th>S No.</th>
                  <th>How it works content</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {howitworksdata &&
                  howitworksdata?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{data?.serial_no}</td>
                        <td>{data?.how_it_works_content}</td>

                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="none"
                              className="action-dropdown"
                            >
                              <i class="fa-solid fa-ellipsis"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={(e) => buttonviewHandlerpage(data)}
                              >
                                <i class="fa-sharp fa-solid fa-pen"></i> Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal1"
                                onClick={(e) => {
                                  handleChangeDelete(data?._id, e.target.value);
                                }}
                              >
                                <i class="fa-solid fa-trash-can"></i> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {howitworksdata?.length == 0 ? "No Records found" : ""}
            </div>
            <div className="text-end d-flex">
              <Pagination className="ml-auto">
                {totalPages > 1 ? (
                  <ReactPaginate
                    breakLabel={"..."}
                    nextLabel={"Next >"}
                    onPageChange={handlePageChange}
                    pageCount={totalPages}
                    previousLabel={"< Back"}
                    renderOnZeroPageCount={null}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                ) : null}
              </Pagination>
            </div>
          </div>
          <div
            class="modal fade"
            id="exampleModal1"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-body ">
                  <div
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </div>
                  <h3>Delete confirmation</h3>
                  <div className="otpBox">
                    <p>Are you want to Delete</p>
                  </div>
                  <button
                    className="btn btn-main"
                    data-bs-dismiss="modal"
                    onClick={handleDelete}
                  >
                    Yes
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-white-outline"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Howitworks;
