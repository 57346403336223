import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import DefaultImage from "../../../assets/images/defaultimage.png";


import {
  pagination,
} from "../../../components/common/utils/message";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  Service_Donation_feesList,
  Get_Pubstar_Tip_lists,
  Get_Group_Tip_lists,
  No_Of_Total_Tips,
  No_of_pubstars,
  No_of_GroupTip,
} from "../service/Auth.service";

function TotalTipReceived() {
  const userdata = useSelector((state) => state);
  let adminRole = userdata?.login?.data?.role;
  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const [totalPages, settotalPages] = useState();
  const [totaltips, setTotalTips] = useState();
  const [totalpubstarPages, settotalPubstarPages] = useState();
  const [totalpubstartips, setTotalPubstarTips] = useState();
  const [totalgrouptipPages, settotalGroupTipPages] = useState();
  const [totalgrouptips, setTotalGroupTips] = useState();
  const [searchText, setSearchText] = useState("");
  const [start_date, setStartDate] = useState();
  const [end_date, setEndDate] = useState();
  const maxDate = new Date().toISOString().split('T')[0];
  const [nooftotaltips, setNo_Of_TotalTips] = useState();
  const [nooftotalpubstar, setNo_Of_pubstar] = useState();
  const [nooftotalgrouptip, setNo_Of_GroupTip] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [historyState, setHistoryState] = useState(0);
  const onTabClick = (tabId) => () => {
    setHistoryState(tabId);
  };
  useEffect(() => {
    getnototaltips();
    _getTotalTipsList();
    getnooftotalpubstar();
    _getTotalPubstarList();
    getnooftotalgrouptip();
    _getTotalGroupTipList();
  }, [dispatch]);
  // -------------------------------Total Tips-----------------------------------------------
  const getnototaltips = () => {
    No_Of_Total_Tips(
      {},
      (data) => {
        setNo_Of_TotalTips(data);
      },
      dispatch
    );
  };

  const _getTotalTipsList = (searchText, startDate, endDate) => {
    Service_Donation_feesList(
      {
        page,
        limit,
        start_date: startDate,
        end_date: endDate,
        searchText: searchText,
      },
      (data) => {
        setTotalTips(data?.docs);
        settotalPages(data?.totalPages);
      },
      dispatch
    );
  };
  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await Service_Donation_feesList(
      { page: currentPage, limit, searchText },
      (response) => {
        setTotalTips(response?.docs);
      },
      dispatch
    );
  };
  const handleInputStartDate = (newDate) => {
    setStartDate(newDate.target.value);
  };

  const handleInputEndDate = (newDate) => {
    setEndDate(newDate.target.value);
    _getTotalTipsList("", start_date, newDate.target.value);
  };
  const __handleSearch = async (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
    _getTotalTipsList(e.target.value, start_date, end_date);
  };
  // ------------------------------------Pubstar Tips--------------------------------------------------
  const getnooftotalpubstar = () => {
    No_of_pubstars(
      {},
      (data) => {
        setNo_Of_pubstar(data);
      },
      dispatch
    );
  };
  const _getTotalPubstarList = (searchText, startDate, endDate) => {
    Get_Pubstar_Tip_lists(
      {
        page,
        limit,
        start_date: startDate,
        end_date: endDate,
        searchText: searchText,
      },
      (data) => {
        setTotalPubstarTips(data?.docs);
        settotalPubstarPages(data?.totalPages);
      },
      dispatch
    );
  };
  const handlePubstarPageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await Get_Pubstar_Tip_lists(
      { page: currentPage, limit, searchText },
      (response) => {
        setTotalPubstarTips(response?.docs);
      },
      dispatch
    );
  };
  const handlePubstarInputStartDate = (newDate) => {
    setStartDate(newDate.target.value);
  };

  const handlePubstarInputEndDate = (newDate) => {
    setEndDate(newDate.target.value);
    _getTotalPubstarList("", start_date, newDate.target.value);
  };
  const __handlePubstarSearch = async (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
    _getTotalPubstarList(e.target.value, start_date, end_date);
  };

  // ------------------------------------Group Tip-----------------------------------------------
  const getnooftotalgrouptip = () => {
    No_of_GroupTip(
      {},
      (data) => {
        setNo_Of_GroupTip(data);
      },
      dispatch
    );
  };

  const _getTotalGroupTipList = (searchText, startDate, endDate) => {
    Get_Group_Tip_lists(
      {
        page,
        limit,
        start_date: startDate,
        end_date: endDate,
        searchText: searchText,
      },
      (data) => {
        setTotalGroupTips(data?.docs);
        settotalGroupTipPages(data?.totalPages);
      },
      dispatch
    );
  };
  const handleGroupTipPageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await Get_Group_Tip_lists(
      { page: currentPage, limit, searchText },
      (response) => {
        setTotalGroupTips(response?.docs);
      },
      dispatch
    );
  };
  const handleGroupInputStartDate = (newDate) => {
    setStartDate(newDate.target.value);
  };

  const handleGroupInputEndDate = (newDate) => {
    setEndDate(newDate.target.value);
    _getTotalGroupTipList("", start_date, newDate.target.value);
  };
  const __handleGroupSearch = async (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
    _getTotalGroupTipList(e.target.value, start_date, end_date);
  };

  var totalTiprecieved = 0;
  for (var i = 0; i < nooftotaltips?.length; i++) {
    totalTiprecieved += nooftotaltips[i]?.total_tip_amount;
  }

  var totalTipPubstars = 0;
  for (var j = 0; j < nooftotalpubstar?.length; j++) {
    totalTipPubstars += nooftotalpubstar[j]?.total_tip_amount;
  }
  var totalTipGroupTip = 0;
  for (var k = 0; k < nooftotalgrouptip?.length; k++) {
    totalTipGroupTip += nooftotalgrouptip[k]?.total_tip_amount;
  }
  if (adminRole == 0) {
    return (
      <>
        <div className="tippingHistroy">
          <div className="container-fluid">
            <div className="row topBar">
              <div className="col">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="content">
                        <div className="heading">
                          <h3>Dashboard</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="tabs">
                  <div
                    className={`tab ${historyState === 0 && "active"}`}
                    onClick={onTabClick(0)}
                  >
                    Total Tips
                    <div className="line"></div>
                  </div>
                  <div
                    className={`tab ${historyState === 1 && "active"}`}
                    onClick={onTabClick(1)}
                  >
                    Pubstar Tips
                    <div className="line"></div>
                  </div>
                  <div
                    className={`tab ${historyState === 2 && "active"}`}
                    onClick={onTabClick(2)}
                  >
                    Group Tips
                    <div className="line"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`row tabContent ${historyState !== 0 && "d-none"}`}>
              <div className="col-12">
                <div className="content">
                  <div className="price">
                    <h3>$ {totalTiprecieved}</h3>
                    <p>Total Tips Received</p>
                  </div>
                  <div className="dates">
                    <div className="datePicker">
                      <label htmlFor="pickFrom">From</label>
                      <input
                        type="date"
                        name=""
                        id="pickFrom"
                        max={maxDate}
                        className="form-control"
                        onChange={handleInputStartDate}
                      />
                    </div>
                    <div className="datePicker">
                      <label htmlFor="pickTo">To</label>
                      <input
                        type="date"
                        name=""
                        id="pickTo"
                        max={maxDate}
                        className="form-control"
                        onChange={handleInputEndDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row searchStaff">
                  <div className="col-12 px-0">
                    <div className="searchBox">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="search customers"
                        value={searchText}
                        onChange={__handleSearch}
                      />
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                </div>
                <div className="table-wraper mt-4">
                  <div className="top-action"></div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Customer</th>
                        <th>Restaurant</th>
                        <th>Amount ($)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {totaltips &&
                        totaltips?.map((data, index) => {
                          const date = new Date(data?.createdAt);

                          // Extract the day, month, and year values
                          const day = date
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const month = (date.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const year = date.getFullYear();

                          // Format the date as dd/mm/yyyy
                          const formattedDate = `${day}/${month}/${year}`;
                          return (
                            <tr key={index}>
                              <td>{formattedDate}</td>
                              <td>
                                <div className="d-flex user-img-box">
                                  <img src={DefaultImage} />
                                  <div className="mx-2">
                                    <p>
                                      {" "}
                                      {data?.is_guest_tip == true
                                        ? data?.guest?.guest_name
                                        : data?.customerDetails?.full_name}
                                    </p>{" "}
                                    <p>
                                      {" "}
                                      {data?.is_guest_tip == true
                                        ? data?.guest.guest_email
                                        : data?.customerDetails?.email}
                                    </p>
                                  </div>
                                </div>
                              </td>

                              <td>{data?.restroDetails?.restaurant_name}</td>
                              <td>{data?.total_tip_amount}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    {totaltips?.length == 0 ? "No Records found" : ""}
                  </div>
                  <div className="text-end d-flex">
                    <Pagination className="ml-auto">
                      {totalPages > 1 ? (
                        <ReactPaginate
                          breakLabel={"..."}
                          nextLabel={"Next >"}
                          onPageChange={handlePageChange}
                          pageCount={totalPages}
                          previousLabel={"< Back"}
                          renderOnZeroPageCount={null}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      ) : null}
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
            <div className={`row tabContent ${historyState !== 1 && "d-none"}`}>
              <div className="col-12">
                <div className="content">
                  <div className="price">
                    <h3>$ {totalTipPubstars}</h3>
                    <p>Total Pubstar Tips Received</p>
                  </div>
                  <div className="dates">
                    <div className="datePicker">
                      <label htmlFor="pickFrom">From</label>
                      <input
                        type="date"
                        name=""
                        id="pickFrom"
                        max={maxDate}
                        className="form-control"
                        onChange={handlePubstarInputStartDate}
                      />
                    </div>
                    <div className="datePicker">
                      <label htmlFor="pickTo">To</label>
                      <input
                        type="date"
                        name=""
                        id="pickTo"
                        max={maxDate}
                        className="form-control"
                        onChange={handlePubstarInputEndDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row searchStaff">
                  <div className="col-12 px-0">
                    <div className="searchBox">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="search customer"
                        value={searchText}
                        onChange={__handlePubstarSearch}
                      />
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                </div>

                <div className="table-wraper mt-4">
                  <div className="top-action"></div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Customer</th>
                        <th>Restaurant</th>

                        <th>Amount ($)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {totalpubstartips &&
                        totalpubstartips?.map((data, index) => {
                          const date = new Date(data?.createdAt);

                          // Extract the day, month, and year values
                          const day = date
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const month = (date.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const year = date.getFullYear();

                          // Format the date as dd/mm/yyyy
                          const formattedDate = `${day}/${month}/${year}`;
                          return (
                            <tr key={index}>
                              <td>{formattedDate}</td>
                              <td>
                                <div className="d-flex user-img-box">
                                  <img src={DefaultImage} />
                                  <div className="mx-2">
                                    <p>
                                      {" "}
                                      {data?.is_guest_tip == true
                                        ? data?.guest?.guest_name
                                        : data?.customerDetails?.full_name}
                                    </p>{" "}
                                    <p>
                                      {" "}
                                      {data?.is_guest_tip == true
                                        ? data?.guest.guest_email
                                        : data?.customerDetails?.email}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>{data?.restoDetails?.restaurant_name}</td>
                              <td>{data?.total_tip_amount}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    {totalpubstartips?.length == 0 ? "No Records found" : ""}
                  </div>
                  <div className="text-end d-flex">
                    <Pagination className="ml-auto">
                      {totalpubstarPages > 1 ? (
                        <ReactPaginate
                          breakLabel={"..."}
                          nextLabel={"Next >"}
                          onPageChange={handlePubstarPageChange}
                          pageCount={totalpubstarPages}
                          previousLabel={"< Back"}
                          renderOnZeroPageCount={null}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      ) : null}
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
            <div className={`row tabContent ${historyState !== 2 && "d-none"}`}>
              <div className="col-12">
                <div className="content">
                  <div className="price">
                    <h3>$ {totalTipGroupTip}</h3>
                    <p>Total Group Tips Received</p>
                  </div>
                  <div className="dates">
                    <div className="datePicker">
                      <label htmlFor="pickFrom">From</label>
                      <input
                        type="date"
                        name=""
                        id="pickFrom"
                        max={maxDate}
                        className="form-control"
                        onChange={handleGroupInputStartDate}
                      />
                    </div>
                    <div className="datePicker">
                      <label htmlFor="pickTo">To</label>
                      <input
                        type="date"
                        name=""
                        id="pickTo"
                        max={maxDate}
                        className="form-control"
                        onChange={handleGroupInputEndDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row searchStaff">
                  <div className="col-12 px-0">
                    <div className="searchBox">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="search customers"
                        value={searchText}
                        onChange={__handleGroupSearch}
                      />
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </div>
                </div>
                <div className="table-wraper mt-4">
                  <div className="top-action"></div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Customer</th>
                        <th>Restaurant</th>
                        <th>Amount ($)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {totalgrouptips &&
                        totalgrouptips?.map((data, index) => {
                          const date = new Date(data?.createdAt);

                          // Extract the day, month, and year values
                          const day = date
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const month = (date.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const year = date.getFullYear();

                          // Format the date as dd/mm/yyyy
                          const formattedDate = `${day}/${month}/${year}`;
                          return (
                            <tr key={index}>
                              <td>{formattedDate}</td>

                              <td>
                                <div className="d-flex user-img-box">
                                  <img src={DefaultImage} />
                                  <div className="mx-2">
                                    <p>
                                      {" "}
                                      {data?.is_guest_tip == true
                                        ? data?.guest?.guest_name
                                        : data?.customerDetails?.full_name}
                                    </p>{" "}
                                    <p>
                                      {" "}
                                      {data?.is_guest_tip == true
                                        ? data?.guest.guest_email
                                        : data?.customerDetails?.email}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>{data?.restoDetails?.restaurant_name}</td>
                              <td>{data?.total_tip_amount}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    {totalgrouptips?.length == 0 ? "No Records found" : ""}
                  </div>
                  <div className="text-end d-flex">
                    <Pagination className="ml-auto">
                      {totalgrouptipPages > 1 ? (
                        <ReactPaginate
                          breakLabel={"..."}
                          nextLabel={"Next >"}
                          onPageChange={handleGroupTipPageChange}
                          pageCount={totalgrouptipPages}
                          previousLabel={"< Back"}
                          renderOnZeroPageCount={null}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      ) : null}
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default TotalTipReceived;
