import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthService from "../../services/auth.service";
import { userlogin } from "../auth/loginSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { refreshTokenSetup } from "./utils/refreshToken";
// import FacebookLogin from "react-facebook-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Card } from "reactstrap";
import gotip from "../../assets/images/Screenshot_1.png"
import { Modal } from "react-bootstrap";
import { resendOtp, verifyOtp } from "./service/authService";
import { customToastUpdate } from "../../services/project";
import facebookLogo from "../../assets/images/facebookLogo.svg";

// import { useContext } from "react";
// import { SocketContext } from "../../context/SocketContext";
// import io from 'socket.io-client';
// const socket = io('http://localhost:8989');

//const clientId = "1014354952864-8tg2mh1nd6qfr59h50rbmq41peslkail.apps.googleusercontent.com";
const clientId = "394282671245-kfvct5b52pkl2j2b5nmm8tgi7362js0g.apps.googleusercontent.com";
const Login = () => {
  const [showModel, setShowModel] = useState(false);
  gapi.load("client:auth2", () => {
    gapi.client.init({
      //clientId:"1014354952864-8tg2mh1nd6qfr59h50rbmq41peslkail.apps.googleusercontent.com",
      clientId : "394282671245-kfvct5b52pkl2j2b5nmm8tgi7362js0g.apps.googleusercontent.com",
      plugin_name: "chat",
    });
  });

  const onSuccess = async (res) => {
    let data = {
      userData: res.profileObj,
    }
    const result = await AuthService.googleLogin(data);
    if (result?.data?.role == 1) {
      dispatch(userlogin(result?.data));
      // toast.success(
      //   "Login Successfully ",

      //   {
      //     theme: "dark",
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //     autoClose: 1000
      //   },

      // );

      customToastUpdate(dispatch)

      navigate("/customer/dashboard");
    } else if (result?.data?.role == 3) {
      dispatch(userlogin(result?.data));
      customToastUpdate(dispatch)
      navigate("/staff/dashboard");
    }
    else if (result?.data?.status === "failure") {
      toast.error(
        result?.data?.message,
        {
          theme: "dark",
        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );
    }
  };
  const onFailure = (res) => {
    // toast.error(
    //   "Google login canceled or encountered an error",
    //   {
    //     theme: "dark",
    //   },
    //   {
    //     position: toast.POSITION.TOP_CENTER,
    //   },
    //   { autoClose: 1000 }
    // );
  };
 
  const handleFacebookResponse = async (response) => {
    if (response.accessToken) {
      let data = {
        userData: response,
      }
      
      const result = await AuthService.facebookLogin(data);

      if (result?.data?.role == 1) {
        dispatch(userlogin(result?.data));
        // toast.success(
        //   "Login Successfully",
        //   {

        //     theme: "dark",
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //     autoClose: 1000
        //   },

        // );
        customToastUpdate(dispatch)

        navigate("/customer/dashboard");
      } else if (result?.data?.role == 3) {
        dispatch(userlogin(result?.data));
        // toast.success(
        //   "Login Successfully ",

        //   {
        //     theme: "dark",
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //     autoClose: 1000
        //   },

        // );
        customToastUpdate(dispatch)
        // navigate("/staff/dashboard");
        navigate("/profilesetting");
      } else if (result?.data?.status === "failure") {
        // User with the same email already exists
        toast.error(
          result?.data?.message,
          {
            theme: "dark",
          },
          {
            position: toast.POSITION.TOP_CENTER,
          },
          { autoClose: 1000 }
        );
      }
    }





    // else {
    //   // User canceled the Facebook login or encountered an error
    //   // Handle the cancelation or error scenario
    //   // Display an error message or perform the necessary actions
    //   // Example using a toast notification library:
    //   toast.error(
    //     "Facebook login canceled or encountered an error",
    //     {
    //       theme: "dark",
    //     },
    //     {
    //       position: toast.POSITION.TOP_CENTER,
    //     },
    //     { autoClose: 1000 }
    //   );
    // }
  };
  const submitOtp = async (e) => {
    e.preventDefault();
    if (!input.otp) {
      return toast.error(
        "OTP can't be Blank",
        {
          theme: "dark",

        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );
    } else if (input.otp.length !== 6) {
      return toast.error(
        "OTP should be 6 Digit",
        {
          theme: "dark",

        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );
    }
    const res = await verifyOtp({ email: input.email, otp: input.otp }, dispatch);
    //  Login for customer
    if (res?.data?.role == 1) {
      dispatch(userlogin(res?.data));
      toast.success(
        "Login Successfully",
        {
          theme: "dark",
          className: "custom-toast",
          autoClose: 1000,
          position: toast.POSITION.BOTTOM_RIGHT,
        },
        // {
        //   position: "bottom-right",
        //   // ToastPosition:'bottom-right'
        // },
        // {  }
      );

      navigate("/customer/dashboard");
    } else if (res == null) {
      toast.error(
        "Please Check your credentials",
        {
          theme: "dark",

        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );
    }
    //  Login for pub

    if (res?.data?.role == 2) {
      dispatch(userlogin(res?.data));
      toast.success(
        "Login Successfully",
        {

          theme: "dark",
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000
        },
      );
      if (res?.data?.result?.is_updated == false) {
        navigate("/resto/dashboard");
      }
      if (res?.data?.result?.is_updated == true) {
        navigate("/resto/mainrestodashboard");
      }
    }
    //  Login for staff

    if (res?.data?.role == 3) {
      dispatch(userlogin(res?.data));
      customToastUpdate(dispatch)
      navigate("/staff/dashboard");
    }
    // For super-admin
    if (res?.data?.role == 0) {
      dispatch(userlogin(res?.data));
      toast.success(
        "Login Successfully",
        {
          theme: "dark",
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000
        },
      );

      navigate("/super-admin/dashboard");
    }


  };

  const resentOtp = async (e) => {
    e.preventDefault();
    const res = await resendOtp({ email: input.email }, dispatch);
    if (res?.data?.status == "Success") {
      toast.success(
        "OTP sent Successfully",
        {

          theme: "dark",
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000
        },
      );

    } else {
      toast.error(
        "Something Went Wrong",
        {
          theme: "dark",

        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );

    }


  };

  const navigate = useNavigate();
  // const socket = useContext(SocketContext)
  const [msg, responseMsg] = useState();
  const [rememberme, setRememberme] = useState();

  const [isChecked, setIsChecked] = useState(false);
  const remember = sessionStorage.getItem("remember_admin");
  const [passwordType, setPasswordType] = useState("password");
  const dispatch = useDispatch();

  const [input, setInput] = useState({
    email: "",
    password: "",
  });

  const [valid, setValid] = useState({
    email: false,
    password: false,

    emailError: "",
    passwordError: "",
  });
  /*--------@ For handle change for login form----------- */

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((previousValue) => ({
      ...previousValue,
      [name]: value,
    }));
  };
  const validateemail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailIsValid = pattern.test(email);

    if (emailIsValid) {
      setValid((previousValue) => ({
        ...previousValue,
        email: false,
        emailError: "",
      }));
    } else {
      setValid((previousValue) => ({
        ...previousValue,
        email: true,
        emailError: "Please Enter Email",
      }));
    }
  };
  const validatepassword = (password) => {
    if (password.length < 1) {
      setValid((previousValue) => ({
        ...previousValue,
        password: true,
        passwordError: "Enter password",
      }));
    } else {
      setValid((previousValue) => ({
        ...previousValue,
        password: false,
        passwordError: "",
      }));
    }
  };
  //
  const handleSubmit = async (e) => {
    e.preventDefault();
  };
  /*--------@ For handle submit for login form----------- */

  const buttonHandler = async () => {
    const res = await AuthService.login(input.email, input.password, dispatch);
    if (isChecked) {
      const myJSON = JSON.stringify({
        email: input.email,
        password: input.password,
      });
      sessionStorage.setItem("remember_admin", myJSON);
    }

    if (res?.data?.result?.verifyStatus == 0) {
      setShowModel(true)
      return

    }




    //  Login for customer
    if (res?.data?.role == 1) {
      dispatch(userlogin(res?.data));
      customToastUpdate(dispatch)

      navigate("/customer/dashboard");
    } else if (res == null) {
      toast.error(
        "Please Check your credentials",
        {
          theme: "dark",

        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );
    }
    //  Login for pub

    if (res?.data?.role == 2) {
      dispatch(userlogin(res?.data));
      // toast.success(
      //   "Login Successfully",
      //   {
      //     theme: "dark",
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //     autoClose: 1000
      //   },
      // );

      customToastUpdate(dispatch)
      if (res?.data?.result?.is_updated == false) {
        navigate("/resto/dashboard");
      }
      if (res?.data?.result?.is_updated == true) {
        navigate("/resto/mainrestodashboard");
      }
    }
    //  Login for staff

    if (res?.data?.role == 3) {
      dispatch(userlogin(res?.data));
  
      customToastUpdate(dispatch)
      navigate("/staff/dashboard?workplace=0");
    }
    // For super-admin
    if (res?.data?.role == 0) {
      dispatch(userlogin(res?.data));
      toast.success(
        "Login Successfully",
        {
          theme: "dark",
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000
        },
      );

      navigate("/super-admin/dashboard");
    }
  };

  const onCheckboxChecked = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };
  /*--------@ Remember Me----------- */

  useEffect(() => {
    if (sessionStorage.getItem("remember_admin")) setIsChecked(false);
    let data = JSON.parse(sessionStorage.getItem("remember_admin"));
    setRememberme(data);
    setInput({
      email: data?.email,
      password: data?.password,
    });
  }, []);
  function handleInnerClick(event) {
    event.stopPropagation();
  }

  return (
    <>
      <div className="authPage paddingTop dflex alc paddingTop" style={{ height: "100vh", cursor: "pointer" }} onClick={() => { navigate("/") }}>

        <div className="container shadow paddingNone">

          <div className="row m-0 gap jcc">

            <div className="col-md-5 " style={{ padding: "0px" }}>

              {/* ./assets/loginImage.png */}


              <img src={gotip} alt="" className="authImage imageTouse" width={720} />

            </div>

            <div className="col-md-5 mt-5 mt-md-0" style={{ background: '#f4f4f4', borderRadius: '15px', zIndex: 1, cursor: "auto" }} onClick={handleInnerClick}>

              <div className="content">
                {/* <div className="logo text-center">
                  <Link to="/">
                    {" "}
               
                    <img src={gotip} alt="" height={50} />{" "}
                  </Link>
                </div> */}

                <h3 className="text-center mt-3">Login</h3>
                <form className="form" onSubmit={(e) => handleSubmit(e)}>
                  <div className="row">
                    <div className="col">
                      <div className="inputField">
                        <label htmlFor="email">Your Email</label>
                        <div className="inputBox blackBorder">
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            defaultValue={rememberme?.email}
                            onBlur={(e) => validateemail(e.target.value)}
                            onChange={handleChange}
                          />

                          <i className="fa-solid fa-envelope"></i>
                        </div>
                        {valid.email && (
                          <span className="text-danger">
                            {valid.emailError}
                          </span>
                        )}
                      </div>
                      <div className="inputField">
                        <label htmlFor="password">Password</label>
                        <div className="inputBox blackBorder">
                          <input
                            type={passwordType ? "password" : "text"}
                            className="form-control "
                            id="password"
                            name="password"
                            defaultValue={rememberme?.password}
                            onBlur={(e) => validatepassword(e.target.value)}
                            onChange={handleChange}
                            eyeIcon={true}
                          />
                          <i className="fa-solid fa-lock"></i>
                          <i onClick={() => setPasswordType(!passwordType)} className={passwordType ? "fa-solid fa-eye seen" : "fa-solid fa fa-eye-slash seen"}></i>
                        </div>
                        {valid.password && (
                          <span className="text-danger">
                            {valid.passwordError}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 alc dflex">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="rememberme"
                          checked={isChecked}
                          value="1"
                          onClick={(e) => {
                            onCheckboxChecked(e);
                          }}
                        />
                        <span>Remember Me</span>
                      </div>
                    </div>
                    <div className="col-6 text-end mobilePaddingNone">
                      <Link
                        to="/forgot-password"
                        className="nav-link highlighted textBlack"
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <button
                        className="btn btn-height btn-main w-100"
                        onClick={buttonHandler}

                      >
                        Login
                      </button>
                      {<b className="text-info">{responseMsg}</b>}
                    </div>
                  </div>
                </form>
                <div className="or" style={{ backgroundColor: "#f4f4f4" }}>
                  <hr />
                  <p className="mb-0 orText">or</p>
                </div>
                <div className="socialMedia" >
                  <GoogleLogin
                    className="facebookButton"
                    clientId={clientId}
                    buttonText="Login with Google"
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    cookiePolicy={"single_host_origin"}
                    style={{ marginTop: "100px", background: "#1777f2", width: "100px" }}
                  // isSignedIn={true}

                  />

                  {/* <div className="box"> */}
                  {/* facebook commented by amit */}
                  <div>
                    <FacebookLogin
                      appId={process.env.REACT_APP_FACEBOOK_ID}
                      fields="name,email"
                      callback={handleFacebookResponse}
                      onFailure={handleFacebookResponse}
                      render={(renderProps) => (
                        <button
                          className="facebookButton"
                          style={{
                            background: "#1777f2",
                            display:"flex",
                            alignItems:"center",
                            color: "#fff",
                            padding: "1px 8px 1px 1px", // Adjusted padding
                            borderRadius: "3px",
                            border: "none",
                            cursor: "pointer",
                            fontSize: "15px", // Adjusted font size
                            // height: "43px", // Adjusted height
                          }}
                          onClick={renderProps.onClick}
                        >
                          <div style={{padding:"10px",background:"white"}}><img src={facebookLogo} alt="" width={20} /></div> &nbsp;   Login with Facebook
                        </button>
                      )}
                    />
                  </div>

                  {/* <img src="./assets/facebook.png" alt="" /> */}
                  {/* </div> */}
                  {/* <div className="box">
                    <img src="./assets/apple.png" alt="" />
                  </div> */}
                </div>

                <div>
                  <p className="text-center mt-4">
                    Not Registered? <a href="#AccountCreate" onClick={() => navigate("/")}> Sign Up</a>
                  </p>
                </div>

              </div>

            </div>

          </div>

        </div>
        <Modal show={showModel} onClick={handleInnerClick} onHide={() => setShowModel(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {/* <div className="modal-heading"> */}
              <h3>Verification</h3>
              {/* </div> */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body ">
              <p>We have sent you a code to verify your email.</p>
              <h4>Enter Your OTP Here</h4>
              <div className="otpBox">
                <input
                  type="text"
                  className="form-control blackBorder"
                  name="otp"
                  onChange={handleChange}
                  maxLength={6}
                />

              </div>
              <div className="dflex jcc" style={{gap:"10px"}}>
                <button className="btn btn-main btn-height" onClick={resentOtp} >
                  Resend
                </button>
                <button className="btn btn-main btn-height" onClick={submitOtp}>
                  Continue
                </button>
              </div>
            </div>



          </Modal.Body>

        </Modal>

      </div>

    </>
  );
};

export default Login;
