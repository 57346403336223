import { createSlice } from "@reduxjs/toolkit";

export const RestroIdSlice = createSlice({
    name: "restro",
    initialState: {
        isLoggedIn: false,
        data: {},
    },
    reducers: {
        restrodetails(state, action) {
            state.isLoggedIn = true;
            state.data = action.payload;
        },
    },
});

export const { restrodetails } = RestroIdSlice.actions;
export default RestroIdSlice.reducer;
