import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addHowitworks,
  HowitWorks_details_Byid,
  updateHowitWorks,
} from "../../service/Auth.service";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function AddHowitworks() {
  const HowitWorksId = useParams();
  const [details, setDetails] = useState();
  const userdata = useSelector((state) => state);
  let adminRole = userdata?.login?.data?.role;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setErrors] = useState({});
  const [confirm, serConfirm] = useState({});
  const [formData, setFormData] = useState({
    serial_no: "",
    how_it_works_content: "",
  });
  useEffect(() => {
    _getHowitworksDetails();
  }, [dispatch]);

  const _getHowitworksDetails = () => {
    HowitWorks_details_Byid(
      { _id: HowitWorksId.id },
      (data) => {
        setDetails(data);
        setFormData({
          serial_no: data?.serial_no,
          how_it_works_content: data?.how_it_works_content,
        });
      },
      dispatch
    );
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
    } else {
      setErrors(errors);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSubmitdata = async (e) => {
    handeblur();
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
      let reqData = {
        serial_no: formData.serial_no,
        how_it_works_content: formData.how_it_works_content,
      };
      try {
        addHowitworks(
          reqData,
          (result) => {
            if (result?.status == true) {
              toast.success(
                "Submit successfully",
                {
                  theme: "dark",
                
                  position: toast.POSITION.BOTTOM_RIGHT,
                },
                { autoClose: 1000 }
              );

              navigate("/super-admin/how-it-works");
            } else if (result?.status === false) {
              toast.error(
                "Not submit",
                {
                  theme: "dark",
                },
                {
                  position: toast.POSITION.TOP_CENTER,
                },
                { autoClose: 1000 }
              );
            }
          },
          dispatch
        );
      } catch (e) {}
    }
  };
  const handleUpdatedata = async (e) => {
    handeblur();
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
      let reqData = {
        _id: HowitWorksId.id,
        serial_no: formData.serial_no,
        how_it_works_content: formData.how_it_works_content,
      };
      try {
        updateHowitWorks(
          reqData,
          (result) => {
            if (result?.status == true) {
              toast.success(
                "Update successfully",
                {
                  theme: "dark",
               
                  position: toast.POSITION.BOTTOM_RIGHT,
                },
                { autoClose: 1000 }
              );

              navigate("/super-admin/how-it-works");
            } else if (result?.status === false) {
              toast.error(
                "Not submit",
                {
                  theme: "dark",
               
                  position: toast.POSITION.BOTTOM_RIGHT,
                },
                { autoClose: 1000 }
              );
            }
          },
          dispatch
        );
      } catch (e) {}
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.serial_no) {
      errors.serial_no = "Serial no. is required";
    }

    if (!formData.how_it_works_content) {
      errors.how_it_works_content = "How it works content is required";
    }

    return errors;
  };

  const handeblur = () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
    } else {
      setErrors(errors);
    }
  };
  if (adminRole == 0) {
    return (
      <>
        <h2 className="page-title">Content Management</h2>
        <div className="content-wraper">
          {HowitWorksId.id ? (
            <h4>Edit How it works</h4>
          ) : (
            <h4>Add How it works</h4>
          )}

          <Card>
            <Card.Body>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <label>
                      Serial No. <sup>*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      defaultValue={details?.serial_no}
                      name="serial_no"
                      onChange={handleChange}
                      onBlur={(e) => handeblur()}
                    />
                    {error.serial_no && (
                      <span className="text-danger">{error.serial_no}</span>
                    )}
                  </Col>

                  <Col md={12}>
                    <label>
                      How it works content <sup>*</sup>
                    </label>
                    <textarea
                      className="form-control"
                      rows={6}
                      name="how_it_works_content"
                      defaultValue={details?.how_it_works_content}
                      onChange={handleChange}
                      onBlur={(e) => handeblur()}
                    ></textarea>
                    {error.how_it_works_content && (
                      <span className="text-danger">
                        {error.how_it_works_content}
                      </span>
                    )}
                  </Col>
                </Row>
              </form>
              <div className="text-end">
                <Button
                  variant="outline-secondary"
                  className="mx-3"
                  href="/super-admin/how-it-works"
                >
                  Cancel
                </Button>
                {HowitWorksId.id ? (
                  <Button variant="primary" onClick={handleUpdatedata}>
                    Update
                  </Button>
                ) : (
                  <Button variant="primary" onClick={handleSubmitdata}>
                    Add
                  </Button>
                )}
              </div>
            </Card.Body>
          </Card>
        </div>
      </>
    );
  }
}
export default AddHowitworks;
