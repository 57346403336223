import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useParams } from "react-router-dom";
import { _get_details_by_id } from "../../service/Auth.service";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
function EnquiryListView() {
  const userdata = useSelector((state) => state);
  let adminRole = userdata?.login?.data?.role;
  const [details, setDetails] = useState();

  const enquiryView = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    _get_Details_By_Id();
  }, [dispatch]);

  const _get_Details_By_Id = () => {
    _get_details_by_id(
      { _id: enquiryView.id },
      (data) => {
        setDetails(data);
      },
      dispatch
    );
  };

  const [selectedValue, setSelectedValue] = useState("2");

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  if (adminRole == 0) {
    return (
      <>
        <h2 className="page-title">Queries</h2>

        <div className="content-wraper">
          <div className="table-wraper">
            <div className="row TippingBox">
              <div className="col-12">
                <InputGroup className="mb-3 justify-content-between">
                  <p></p>
                  <Form.Group controlId="dob" className="from-to">
                    <select
                      value={details?.status}
                      onChange={handleSelectChange}
                      className="form-control mx-2"
                    >
                      <option value="0">Open</option>
                    </select>
                  </Form.Group>
                </InputGroup>
                <div className="row">
                  <div className="col-12">
                    <div className="inputField-inline">
                      <label for="tipping" className="form-label">
                        Name :
                      </label>
                      <div className="inputBox">{details?.full_name}</div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="inputField-inline">
                      <label for="donation" className="form-label">
                        Email Id:
                      </label>
                      <div className="inputBox">{details?.email}</div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="inputField-inline align-items-start">
                      <label for="totalAmount" className="form-label">
                        Message:
                      </label>
                      <div className="inputBox">
                        <textarea
                          name=""
                          id=""
                          cols="50"
                          rows="4"
                          className="form-control"
                          value={details?.message}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default EnquiryListView;
