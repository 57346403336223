import React from "react";

import PrivateLayout from "../../../components/common/layout/PrivateLayout";
import ForgotPassword from "../ForgotPassword";

import Login from "../Login";
import PasswordSetSuccessful from "../PasswordSetSuccessful";
import ResetPassword from "../ResetPassword";

import RIRegistration from "../RIRegistration";
import Register from "../Register";
import GuestCardDetails from "../../customerInterface/components/GuestCardDetails";

const AuthRoutes = [
  {
    path: `/login`,
    layout: PrivateLayout,
    component: <Login />,
  },
  {
    path: `/registration`,
    layout: PrivateLayout,
    component: <RIRegistration />,
  },

  {
    path: `/forgot-password`,
    layout: PrivateLayout,
    component: <ForgotPassword />,
  },
  {
    path: `/reset-password/:token`,
    layout: PrivateLayout,
    component: <ResetPassword />,
  },
  {
    path: `/password/set/successful`,
    layout: PrivateLayout,
    component: <PasswordSetSuccessful />,
  },
  {
    path: `/Register`,
    layout: PrivateLayout,
    component: <Register />,
  },
  {
    path: `/guest/card-details/:id/:tipid`,
    layout: PrivateLayout,
    component: <GuestCardDetails />,
  },
];
// 
export default AuthRoutes;
