import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import DefaultImage from "../../../../assets/images/defaultimage.png";
import { API_BASEURL } from "../../../../environment";

import {
  pagination,
} from "../../../../components/common/utils/message";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Service_Donation_feesList } from "../../service/Auth.service";
function ServiceFees() {
  const userdata = useSelector((state) => state);
  let adminRole = userdata?.login?.data?.role;
  const [totalPages, settotalPages] = useState();
  const [searchText, setSearchText] = useState("");
  const [servicefeesList, setServiceFeesList] = useState();
  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    _getServiceFeesList();
  }, [dispatch]);

  const _getServiceFeesList = (searchText) => {
    Service_Donation_feesList(
      { page, limit, searchText: searchText },
      (data) => {
        setServiceFeesList(data?.docs);
        settotalPages(data?.totalPages);
      },
      dispatch
    );
  };

  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await Service_Donation_feesList(
      { page: currentPage, limit, searchText },
      (response) => {
        setServiceFeesList(response?.docs);
      },
      dispatch
    );
  };
  const __handleSearch = async (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
    _getServiceFeesList(e.target.value);
  };
  if (adminRole == 0) {
    return (
      <>
        <h2 className="page-title">Payment Management</h2>

        <div className="content-wraper">
          <h4>Service Fees</h4>
          <div className="table-wraper">
            <div className="top-action">
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </InputGroup.Text>
                <Form.Control
                  placeholder="Customer Name"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  value={searchText}
                  onChange={__handleSearch}
                />
              </InputGroup>
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th>Sr no.</th>
                  <th>Date</th>
                  <th>Customer</th>
                  <th>Restaurant</th>
                  <th>Service Fees($)</th>
                </tr>
              </thead>
              <tbody>
                {servicefeesList &&
                  servicefeesList?.map((data, index) => {
                    let ProfilePath = `${API_BASEURL}/public/profile/${data?.customerDetails?.profile}`;
                    const date = new Date(data?.createdAt);

                    // Extract the day, month, and year values
                    const day = date.getDate().toString().padStart(2, "0");
                    const month = (date.getMonth() + 1)
                      .toString()
                      .padStart(2, "0");
                    const year = date.getFullYear();

                    // Format the date as dd/mm/yyyy
                    const formattedDate = `${day}/${month}/${year}`;
                    return (
                      <tr key={index}>
                        {/* <td>
                  <Form.Check name="group1" type="checkbox" />
                </td> */}
                        <td>{index + 1}</td>

                        <td>{formattedDate}</td>
                        <td>
                          <div className="d-flex user-img-box">
                            <img src={
                                data?.customerDetails?.profile != null ||
                                data?.customerDetails?.profile != undefined
                                  ? ProfilePath
                                  : DefaultImage
                              }/>
                            <div className="mx-2">
                              <p>
                                {data?.is_guest_tip == true
                                  ? data?.guest?.guest_name
                                  : data?.customerDetails?.full_name}
                              </p>
                              <p>
                                {/* {data?.customerDetails?.email} */}
                                {data?.is_guest_tip == true
                                  ? data?.guest.guest_email
                                  : data?.customerDetails?.email}
                              </p>
                            </div>
                          </div>
                        </td>

                        <td>{data?.restroDetails?.restaurant_name}</td>
                        <td>{data?.service_charge}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {servicefeesList?.length == 0 ? "No Records found" : ""}
            </div>
            <div className="text-end d-flex">
              <Pagination className="ml-auto">
                {totalPages > 1 ? (
                  <ReactPaginate
                    breakLabel={"..."}
                    nextLabel={"Next >"}
                    onPageChange={handlePageChange}
                    pageCount={totalPages}
                    previousLabel={"< Back"}
                    renderOnZeroPageCount={null}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                ) : null}
              </Pagination>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ServiceFees;
