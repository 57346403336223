import HomeHeader from "../../homePages/common/HomeHeader";
import { useDispatch, useSelector } from "react-redux";
import { getbyUserid } from "../../../services/auth.service";
import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { apiUrl, API_BASEURL, Frontend_Url } from "../../../environment";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import {
  InvitationRequestList,
  AllNoOfstaffList,
} from "../../../services/auth.service";
import { TotalTipAmountShowforDashboard } from "../../RestoInterface/Service/Auth.service";
import { Link, useNavigate } from "react-router-dom";
import { getNo_of_Invitationrequest } from "../Service/Auth.service";
import { Card, Modal } from "react-bootstrap";
import { connectPaypal, connectStripe, detachStripe, getByUserProfile } from "../../homePages/Service/AuthService";
import { toast } from "react-toastify";

function RIRestaurentBranch() {
  const [userData, setUserData] = useState(0);
  const [noInvitationRequest, setNoInvitationRequest] = useState();
  const [noStaffList, setNoStaffList] = useState();
  const [noGroupTip, setNoGroupTip] = useState();
  const [stripe, setStripe] = useState();
  const [paypal, setPaypal] = useState();
  const [detachGateway, setDetachGateway] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log("userData",userData)
useEffect(()=>{
  _getUserProfile()
},[])



  
  const _getUserProfile = () => {
    getByUserProfile(
      { _id: user_id },
      (data) => {
        if (data[0]?.gatewayPlatform) {
          for (let gateway of data[0]?.gatewayPlatform) {
            if (gateway?.type === "Stripe") {
              setStripe(gateway)
            } else {
              setPaypal(gateway)
            }
          }
        }

      },
      dispatch
    );
  };

  const __connectStripe = async (e) => {
    e.preventDefault();
    await connectStripe(
      {
        _id: userData?._id,
        email: userData?.email,
        type:"restro"
      },

      (result) => {
        if (result.result) {
          window.location.href = result.result.url
        }

      },
      dispatch
    );
  };

  const __detachStripe = async (value) => {
    setDetachGateway(false)
    await detachStripe(
      {
        id: userData?._id,
        acc: value.id,
        recid:value.recid
      },

      (result) => {
        if (result.status == "Success") {
          toast.success(
            "Data Deleted successfully",
            {
              theme: "dark",


              position: toast.POSITION.TOP_CENTER,
            },
            { autoClose: 1000 }
          );
          setStripe({})
        } else {
          toast.error(
            "Something Went Wrong",
            {
              theme: "dark",


              position: toast.POSITION.TOP_CENTER,
            },
            { autoClose: 1000 }
          );

        }
        setTimeout(() => {
          _getUserProfile()
        }, 1000)

      },
      dispatch
    );
  };


  const __connectPaypal = async (e) => {
    e.preventDefault();
    await connectPaypal(
      {
        _id: userData?._id,
        email: userData?.email,
        type:"restro"
      },

      (result) => {
        if (result.result) {
          window.history.pushState({}, '', "/resto/mainrestodashboard?code=404&type=PayPal");
          window.location.href = result.result.href
        }

      },
      dispatch
    );
  };

  const __detachPaypal = async (value) => {
    setDetachGateway(false)
    await detachStripe(
      {
        id: userData?._id,
        acc: value.id,
        recid:value.recid
      },

      (result) => {
        if (result.status == "Success") {
          toast.success(
            "Data Deleted successfully",
            {
              theme: "dark",


              position: toast.POSITION.TOP_CENTER,
            },
            { autoClose: 1000 }
          );
          setPaypal({})
        } else {
          toast.error(
            "Something Went Wrong",
            {
              theme: "dark",


              position: toast.POSITION.TOP_CENTER,
            },
            { autoClose: 1000 }
          );

        }
        setTimeout(() => {
          _getUserProfile()
        }, 1000)

      },
      dispatch
    );
  };
 


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get('code');
    const paramValue1 = urlParams.get('type');
    if (paramValue && paramValue1) {
      if (paramValue == "202") {
        setMessageModal(<h3>{paramValue1} connected  successfully</h3>)
       
      } else if (paramValue == "205") {

        setMessageModal(<h3>Attention: Please confirm your email address on <a href="https://www.paypal.com/businessprofile/settings" >https://www.paypal.com/businessprofile/settings</a> in order to receive payments! You currently cannot receive payments</h3>)
  
      } else if (paramValue == "207") {
        setMessageModal(<h3>Attention: You currently cannot receive payments due to restriction on your PayPal account. Please reach out to PayPal Customer Support or connect to <a href="https://www.paypal.com">https://www.paypal.com</a> for more information</h3>)

      }
      else {
        setMessageModal(<h3>{paramValue1} Connection  Failed</h3>)

      }
      navigate('/resto/mainrestodashboard')
    }
    _getUserProfile();
    // scrol to bottom of page
    const connect_payment = urlParams.get('connect_payment');
    if (connect_payment === 'true') {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight+200);
      }, 1000)
    }
  }, [dispatch]);

  const userdata = useSelector((state) => state);
  let loginRole = userdata?.login?.data?.role;
  let user_id;
  if (loginRole == 2) {
    user_id = userdata?.login?.data?.result?._id;
  }
  if (loginRole == 0) {
    user_id = userdata?.adminrestro?.data?._id;
  }
  
  let path = `${API_BASEURL}/public/posts/${userData?.image}`;

  // const menuUrl = `${Frontend_Url}/customer/addgrouptip/${user_id}`; // Replace with your restaurant's menu URL
  const menuUrl = `${Frontend_Url}/staff/restorequest/${user_id}`; // Replace with your restaurant's menu URL

  useEffect(() => {
    _getUserData();
    _getRequestInvitationListing();
    _getNoofStaffListing();
    _getGroupTipById();
  }, [dispatch]);

  const _getUserData = () => {
    getbyUserid(
      { _id: user_id },
      (data) => {
        setUserData(data?.data?.result[0]);
      },
      dispatch
    );
  };

  const _getRequestInvitationListing = () => {
    getNo_of_Invitationrequest(
      { resto_id: user_id },
      (data) => {
        setNoInvitationRequest(data?.length);
      },
      dispatch
    );
  };

  const _getNoofStaffListing = () => {
    AllNoOfstaffList(
      { resto_id: user_id },
      (data) => {
        setNoStaffList(data?.data?.result?.length);
      },
      dispatch
    );
  };

  const _getGroupTipById = () => {
    TotalTipAmountShowforDashboard(
      { resto_id: user_id },
      (data) => {
        setNoGroupTip(data);
      },
      dispatch
    );
  };

  const navigateRequestHandleClick = () => {
    navigate("/resto/invitationrequest");
  };
  const navigateManagestaffHandleClick = () => {
    navigate("/resto/managestaff");
  };

  const navigateManagesTipManagement = () => {
    navigate("/resto/tipmanagement");
  };

  var total = 0;
  for (var i = 0; i < noGroupTip?.length; i++) {
    if (noGroupTip[i]?.is_group_tip === true) {
      total += noGroupTip[i]?.total_staff_given_amount;
    } else {
      total += noGroupTip[i]?.total_tip_amount;
    }
  }

  const downloadQR = () => {
    const canvas = document.getElementById("123456");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "123456.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const mapStyles = {
    height: "29vh",
    width: "100%",
  };

  const defaultCenter = {
    lat: userData?.lat || 51.901516,
    lng: userData?.lng || -8.468283,
  };

  const locations = [
    {
      name: "Cask Pub and Kitchen",
      location: {
        lat: userData?.lat || 51.901516,
        lng: userData?.lng || -8.468283,
      },
    },

    ,
  ];
  return (
    <>
      <div className="RIAddAddress">
        {/* <RIHeader /> */}
        <HomeHeader />
        <section className="main">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="content">
                  <h3 className="blackText Aeonik-Bold">
                    {userData?.restaurant_name}!{" "}
                    <img src="./assets/hand.png" alt="" />
                  </h3>
                  <h1 className="blackText Spoof-Black">
                    Welcome to <span className="blackText">GoTipMe</span>
                  </h1>
                  <p className="blackText Aeonik-Regular">
                  Manage your venue, pubstars and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row my-4 align-items-center">
            <div className="col-sm-6">
              <h3 className="whiteText Aeonik-Regular">My Pub</h3>
            </div>
            <div className="col-sm-6 text-end">
              <button
                className="btn btn-main-outline btn-height"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Generate QR Code
              </button>
            </div>
          </div>
          <div className="row ProfileDesign mypub">
            <div className="col-lg-4 col-12">
              <img
                src={path}
                style={{ borderRadius: "10px" }}
                alt=""
                className="w-100 mainImage"
              />
              
            </div>
            <div className="col-lg-4 restaurenDetail col-12 mt-4 mt-lg-0">
              <h1 className="Aeonik-Bold">{userData?.restaurant_name}</h1>
              {/* <p className="Aeonik-Regular">
                <i className="fa-solid fa-location-dot"></i>{" "}
                {userData?.addressLine1},
                <span className="Aeonik-Regular">
                  {" "}
                  {userData?.city} ({userData?.postcode})
                </span>
              </p> */}
              <p className="Aeonik-Regular">
                <i class="fa-solid fa-envelope"></i>{" "}
                {userData?.email}
              </p>
              <p className="Aeonik-Regular">
                <i class="fa-solid fa-phone"></i> 
                {/* {userData?.restaurantContact_number} */}
                {userData?.restaurantContact_number?.substr(0, 3) + "  " + userData?.restaurantContact_number?.substr(3)}
              </p>
              <div className="review">
           
              </div>
            </div>
            <div className="col-lg-4 col-12 text-end mt-4 mt-lg-0">
              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              >
                <GoogleMap
                  mapContainerStyle={mapStyles}
                  zoom={13}
                  center={defaultCenter}
                >
                  {locations.map((item) => {
                    return <Marker key={item.name} position={item.location} />;
                  })}
                </GoogleMap>
              </LoadScript>
            </div>
          </div>
          <div className="row EditDetails mt-4">
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              {/* <p>Are the pub details incorrect?</p> */}
            </div>
            <div className="col-lg-4 text-end whiteText">
            <Link to="/resto/dashboard ">
            <button
                      className="btn btn-main btn-height"
                      style={{ marginLeft: "auto" }}
                      
                    ><i class="fa-solid fa-pen "></i>{" "}Edit Details</button></Link>
             
              
            </div>
          </div>

          <Card className="mt-4">

              <div className="row p-4">
                <div className="col-12">
                  <h3 className="Aeonik-Regular">Payment Method</h3>

                </div>
                <div className="col-md-1 col-6 dflex alc">

                  <i className="fab fa-stripe-s" style={{ color: "#6772e5" }} ></i><b> Stripe</b>
                </div>
                <div className="col-md-4 col-6">
                  <div className="inputField">
                    <button
                      className="btn btn-main btn-height"
                      style={{ marginLeft: "auto" }}
                      onClick={stripe && stripe.status === "Active" ? () => setDetachGateway({type:"Stripe" ,id:stripe.account_id , recid:stripe._id}) : __connectStripe}
                    >
                      {stripe && stripe.status === "Active" ? <>Disconnect</> : <>Connect </>}
                    </button>
                  </div>
                </div>
                <div className="col-md-1 col-6 dflex alc">
                <img src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-100px.png" height={20} width={80} border="0" alt="PayPal Logo"/>
                </div>
                <div className="col-md-5 col-6">
                  <div className="inputField">
                    <button
                      className="btn btn-main btn-height"
                      onClick={paypal && paypal.status === "Active" ? () => setDetachGateway({type:"PayPal" ,id:paypal.account_id , recid:paypal._id}) : __connectPaypal}
                    >
                      {paypal && paypal.status === "Active" ? <>Disconnect</> : <>Connect</>}
                    </button>

                  </div>              </div>


              </div>
            </Card>

          <div className="row recordData my-5">
            <div className="col-md-4">
              {/* <Link to="/resto/invitationrequest"> */}
              <div className="content" onClick={navigateRequestHandleClick}>
                <div className="icon">
                  <i class="fa-solid fa-download blackText"></i>
                </div>
                <h1 className="whiteText Aeonik-Regular">{noInvitationRequest}</h1>
                <p  className="whiteText Aeonik-Regular">New Requests</p>
              </div>
              {/* </Link> */}
            </div>
            <div className="col-md-4">
              <div className="content" onClick={navigateManagestaffHandleClick}>
                <div className="icon">
                  <i class="fa-solid fa-users blackText"></i>
                </div>
                <h1  className="whiteText Aeonik-Regular">{noStaffList}</h1>
                <p  className="whiteText Aeonik-Regular">Total Pubstar </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="content" onClick={navigateManagesTipManagement}>
                <div className="icon">
                  <i class="fa-solid fa-coins blackText"></i>
                </div>
                <h1  className="whiteText Aeonik-Regular">$ {Math.round(total)}</h1>
                <p  className="whiteText Aeonik-Regular">Total Group Tips Received</p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body ">
                <div
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fa-solid fa-xmark"></i>
                </div>
                <h4>
                  Qr Code generating for "{userData?.restaurant_name}"
                  {/* {userData?.addressLine1}, {userData?.city} (
                  {userData?.postcode})" */}
                </h4>
                <div className="otpBox">
                  {/* <p>Are you want to reject this invitation</p> */}
                </div>
                <QRCode
                  id="123456"
                  value={menuUrl}
                  level={"H"}
                  includeMargin={true}
                  size={256}
                />
                <br />
                <br />
                <button
                  className="btn btn-main btn-height"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-main btn-height"
                  value="Download"
                  onClick={downloadQR}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}


        
      </div>
      <Modal show={detachGateway} onHide={() => setDetachGateway(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {/* <div className="modal-heading"> */}
           {/* </div> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding:10}}>
        <h3>Disconnecting your {detachGateway.type} account will prevent you from offering {detachGateway.type} services and products on your website. Do you wish to continue?</h3>
            
          <div className="payment-btn">
            <button
              variant="secondary"
              className="btn btn-white-outline btn-height"
              onClick={() => setDetachGateway(false)}>
              No
            </button>
            <button
              variant="primary"
              className="btn btn-main btn-height"
              onClick={() => detachGateway?.type ==  "Stripe"  ?__detachStripe(detachGateway):  __detachPaypal(detachGateway)
              }
            >
              Yes
            </button>
          </div>
        </Modal.Body>

      </Modal>
      <Modal show={messageModal} onHide={() => setMessageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
          
            {/* <div className="modal-heading"> */}
            
            {/* </div> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding:10}}>
        {messageModal}
          <div className="payment-btn">
            <button
              variant="secondary"
              className="btn btn-white-outline btn-height"
              onClick={() => setMessageModal(false)}>
              OK
            </button>
          
          </div>
        </Modal.Body>

      </Modal>

    </>
  );
}

export default RIRestaurentBranch;
