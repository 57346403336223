import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import {
  Get_Contact_Support_List,
  No_of_Contact_List,
} from "../../../super-admin/service/Auth.service";
import {
  pagination,
} from "../../../../components/common/utils/message";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";

function ContactSupport() {
  const userdata = useSelector((state) => state);
  let adminRole = userdata?.login?.data?.role;
  const [totalPages, settotalPages] = useState();
  const [noofcustomers, setNoOfCustomers] = useState();
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const [contactsupportList, setContactSupportList] = useState();
  const [noofcontactsupportList, setNoOfContactSupportList] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  useEffect(() => {
    _getContactSupportList();
    _get_No_Of_ContactSupportList();
  }, [dispatch]);

  const _getContactSupportList = (searchText) => {
    Get_Contact_Support_List(
      { page, limit, searchText: searchText },
      (data) => {
        setContactSupportList(data?.docs);
        settotalPages(data?.totalPages);
      },
      dispatch
    );
  };

  const _get_No_Of_ContactSupportList = () => {
    No_of_Contact_List(
      {},
      (data) => {
        setNoOfContactSupportList(data.length);
      },
      dispatch
    );
  };

  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await Get_Contact_Support_List(
      { page: currentPage, limit, searchText },
      (response) => {
        setContactSupportList(response?.docs);
      },
      dispatch
    );
  };

  const __handleSearch = async (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
    _getContactSupportList(e.target.value);
  };
  const buttonviewHandlerpage = async (data) => {
    navigate(`/super-admin/contact-support-view/${data?._id}`);
  };
  if (adminRole == 0) {
    return (
      <>
        <h2 className="page-title">Queries</h2>

        <div className="content-wraper">
          <h4>Contact Supports ({noofcontactsupportList})</h4>
          <div className="table-wraper">
            <div className="top-action">
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search customer by name"
                  aria-label="Pub name"
                  aria-describedby="basic-addon2"
                  value={searchText}
                  onChange={__handleSearch}
                />
              </InputGroup>
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Sent by</th>
                  <th>Contact Person No.</th>
                  <th>Message</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {contactsupportList &&
                  contactsupportList?.map((data, index) => {
                    const date = new Date(data?.createdAt);

                    // Extract the day, month, and year values
                    const day = date.getDate().toString().padStart(2, "0");
                    const month = (date.getMonth() + 1)
                      .toString()
                      .padStart(2, "0");
                    const year = date.getFullYear();

                    // Format the date as dd/mm/yyyy
                    const formattedDate = `${day}/${month}/${year}`;
                    return (
                      <tr key={index}>
                        <td>{formattedDate}</td>
                        <td>
                          <p>{data?.full_name}</p>
                          <p>{data?.email}</p>
                        </td>
                        <td>{data?.sentDetails?.contact_number}</td>
                        <td>{data?.message}</td>
                        <td>
                          {data?.status == "0" ? (
                            <span className="status-hold">Open</span>
                          ) : (
                            <span className="status-Verified">
                              Resolved{" "}
                              <svg
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21 9.98454L18.6709 7.33091L18.9955 3.81818L15.5495 3.03545L13.7455 0L10.5 1.39364L7.25455 0L5.45045 3.03545L2.00455 3.80864L2.32909 7.32136L0 9.98454L2.32909 12.6382L2.00455 16.1604L5.45045 16.9432L7.25455 19.9786L10.5 18.5754L13.7455 19.9691L15.5495 16.9336L18.9955 16.1509L18.6709 12.6382L21 9.98454ZM8.59091 14.7573L4.77273 10.9391L6.11864 9.59318L8.59091 12.0559L14.8814 5.76545L16.2273 7.12091L8.59091 14.7573Z"
                                  fill="#0066FF"
                                />
                              </svg>
                            </span>
                          )}
                        </td>
                        <td>
                          <a onClick={(e) => buttonviewHandlerpage(data)}>
                            {" "}
                            <i class="fa-solid fa-eye"></i> View
                          </a>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {contactsupportList?.length == 0 ? "No Records found" : ""}
            </div>
            <div className="text-end d-flex">
              <Pagination className="ml-auto">
                {totalPages > 1 ? (
                  <ReactPaginate
                    breakLabel={"..."}
                    nextLabel={"Next >"}
                    onPageChange={handlePageChange}
                    pageCount={totalPages}
                    previousLabel={"< Back"}
                    renderOnZeroPageCount={null}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                ) : null}
              </Pagination>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ContactSupport;
