import React, { Component } from 'react';
import Script from 'react-load-script'

class LocationMap extends Component {

    handleScriptLoad() {
        const inputEl = document.getElementById('address1');
     
        /*global google*/
        var options = {
            //types: ['address'],
            componentRestrictions: {country: 'uk'}
        };

        this.autocomplete = new google.maps.places.Autocomplete(inputEl, options );
        this.autocomplete.addListener('place_changed', this.handlePlaceSelect.bind(this));
    }

    handlePlaceSelect() {
       let result= this.autocomplete?.getPlace();
    this.props.valuesof(this.autocomplete?.getPlace())
    return result;
        
    }

    render() {
        let name= this.props?.name
        return (
            <section>
                <Script
                    url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&language=en&region=US`}
                    onLoad={this.handleScriptLoad.bind(this)}
                />        
               <label htmlFor="address-map">Enter address</label>
                <div className="inputBox">
                    
                    <input type="text"
                    className="form-control px-3 bg-none"
                    id="address1"
                    onChange={this.props.onChange}
                              defaultValue={this.props?.defaultValue}
                    name={name}
                    placeholder="Address Line 1"/>
                </div>
            </section>
        );
    }
}

export default LocationMap;