import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  // Legend:{
  //   hidden:true
  // },


  scales: {
    x: {
      tickoptions: {showgridline:false},
      grid: {
        display: false,
        
      }
    },
    
    y: {
      border:{dash: [2, 2]},
      ticks: {
        beginAtZero: false, // Make sure it doesn't always start at 0
        min: 0,            // Set the minimum value to 10
        stepSize: 20,       // Set the step size to 10
      },
      // grid: {
      //   borderDash:[5,5],
      //       color: 'rgba(151, 151, 151, 0.2)',
      //       display:true,
      //       zeroLineColor: 'gray',
      //       zeroLineWidth: 1,
      //       drawBorder:false
            
      //   }
      grid: {
        display: true,
        tickBorderDash: [2, 2],
        color: '#000', // for the grid lines
        // tickColor: '',
       
                tickWidth: 1,
                offset: false,
                drawTicks: true, // true is default 
                drawOnChartArea: true // true is default
      }
    },
  },
  responsive: true,
  maxBarThickness:20,
  scaleShowVerticalLines: false,
  plugins: {
    legend: {
     display:false,
    },
    title: {
      display: true,
      text: '',
    },
  },
};

const labels = ['J', 'F', 'M', 'A', 'M', 'J', 'J','A','S','O','N','D'];


export function VerticleChart(value) {
  let data = {
    labels,
    datasets: [
      {
        label: '',
        data: value.chartData,
        backgroundColor: '#0c0d0a',
        borderRadius: 50,
      },
      
    ],
   
  
  };
  
  return <Bar options={options} data={data} />;
}
