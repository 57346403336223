// import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import HomeHeader from "./HomeHeader";
import {
  getByUserProfile,
  updateProfilepage,
  Sendotpbyupdate,
  connectStripe,
  detachStripe,
  connectPaypal,
} from "../Service/AuthService";
import { API_BASEURL, Frontend_Url } from "../../../environment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { Card, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
// import Footer from "./Footer";
function ProfileSetting() {
  const dispatch = useDispatch();
  const [userProfileData, setUserProfileData] = useState();
  const [error, setErrors] = useState({});
  const [path, setPath] = useState();
  const navigate = useNavigate();
  const [status, setStatus] = useState();
  const [paypal, setPaypal] = useState();
  const [stripe, setStripe] = useState();
  const [detachGateway, setDetachGateway] = useState(false);
  const selectRef = useRef(null);
  const userdata = useSelector((state) => state);
  const [messageModal, setMessageModal] = useState(false);

  
  let user_id = userdata?.login?.data?.result._id;
  const userRole = userdata?.login?.data?.result?.role;
  const [input, setInput] = useState({
    _id: user_id,
    full_name: userProfileData?.full_name,
    contact_number: userProfileData?.contact_number,
    email: userProfileData?.email,
    profile: null,
    otp: "",
    countryCode:"",
    card_holder_name: "",
    card_number: "",
    card_expiring_date: "",
    card_cvv: "",
    user_name: userProfileData?.user_name,
  });
  /*-----------@Initial useEffect hook to set data --------------*/
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get('code');
    const paramValue1 = urlParams.get('type');
    if (paramValue && paramValue1) {
      if (paramValue == "202") {
        setMessageModal(<h3>{paramValue1} connected  successfully</h3>)
       
      } else if (paramValue == "205") {

        setMessageModal(<h3>Attention: Please confirm your email address on <a style={{fontSize:"18px"}} href="https://www.paypal.com/businessprofile/settings" >https://www.paypal.com/businessprofile/settings</a> in order to receive payments! You currently cannot receive payments</h3>)
  
      } else if (paramValue == "207") {
        setMessageModal(<h3>Attention: You currently cannot receive payments due to restriction on your PayPal account. Please reach out to PayPal Customer Support or connect to <a style={{fontSize:"18px"}}  href="https://www.paypal.com">https://www.paypal.com</a> for more information</h3>)

      }
      else {
        setMessageModal(<h3>{paramValue1} Connection  Failed</h3>)

      }
      navigate('/profilesetting')
    }
    _getUserProfile();
    // scrol to bottom of page
    const connect_payment = urlParams.get('connect_payment');
    if (connect_payment === 'true') {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight+200);
      }, 1000)
    }
  }, [dispatch]);

  /*-----------@Api call and set user data--------------*/
  const _getUserProfile = () => {
    getByUserProfile(
      { _id: user_id },
      (data) => {
        setUserProfileData(data[0]);
        selectRef.current.value = data?.status ? "true" : "false";
        if (data[0]?.profile) {
          setPath(`${API_BASEURL}/public/profile/${data[0]?.profile}`);
        }

        setInput({
          _id: data[0]?._id,
          full_name: data[0]?.full_name,
          contact_number: data[0]?.contact_number,
          email: data[0]?.email,
          profile: null,
          user_name: data[0].user_name,
          countryCode:data[0]?.countryCode
        });

        if (data[0]?.gatewayPlatform) {
          for (let gateway of data[0]?.gatewayPlatform) {
            if (gateway?.type === "Stripe") {
              setStripe(gateway)
            } else {
              setPaypal(gateway)
            }
          }
        }

      },
      dispatch
    );
  };


  /*-----------@Handler function to set profile image--------------*/
  const onFileChange = (e) => {
    let fileType  = e.target.files[0]?.type.split("/")
    if(fileType[1] !== "jpeg" && fileType[1]!=="jpg" && fileType[1]!=="png" ){
    return  toast.error(
        "Please Upload The Image Extension jpg , jpeg and png ",
        {
          theme: "dark",
        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );

    }
    setPath(URL.createObjectURL(e.target.files[0]));
    setInput((previous) => ({
      ...previous,
      profile: e.target.files[0],
    }));
  };

  /*-----------@Handler function for handle change in inputs--------------*/
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...error };
    setInput((previousValue) => ({
      ...previousValue,
      [name]: value,
    }));
    if (newErrors[name]) {
      delete newErrors[name];
      setErrors(newErrors);
    }
  };
  const handleChange1 =  (value,name) =>{
    console.log("e",value,name)
   setInput({
    ...input,[name]:value
   })
  }

  /*-----------@Handler function for submitting data--------------*/
  const handleSubmitdata = async (e) => {
    
    e.preventDefault();
   
    const newErrors = validateForm(); // Renamed errors to newErrors
    if (Object.keys(newErrors).length === 0) {
      setErrors(newErrors);
      console.log("sjdghjsd",e)
      const formData = new FormData();
      formData.append("_id", input._id);
      formData.append("full_name", input.full_name);
      formData.append("email", input.email);
      formData.append("user_name", input.user_name);
      formData.append("contact_number", input.contact_number);
      if (input?.profile) {
        formData.append("profile", input.profile);
      }
      formData.append("countryCode",input.countryCode);
      formData.append("otp", input.otp);
      formData.append("card_holder_name", input.card_holder_name);
      formData.append("card_number", input.card_number);
      formData.append("card_expiring_date", input.card_expiring_date);
      formData.append("card_cvv", input.card_cvv);
      formData.append("status", status === "true" ? true : false);

      try {
        await updateProfilepage(
          formData,

          (result) => {
            if (result.status == "Success") {
              // window.location.reload();

              toast.success(
                "Data updated successfully",
                {
                  theme: "dark",
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 1000
                },
              );
            } else if (result.status == "Error") {
              toast.error(
                "Data not added successfully",
                {
                  theme: "dark",


                  position: toast.POSITION.TOP_CENTER,
                },
                { autoClose: 1000 }
              );
            } else if (
              result == false
            ) {
              toast.error(
                "Incorrect OTP",
                {
                  theme: "dark",
                },
                {
                  position: toast.POSITION.TOP_CENTER,
                },
                { autoClose: 1000 }
              );
            }


          },
          dispatch
        );
      } catch (e) {
        // failed(e);
      }
    } else {
      setErrors(newErrors);
    }
  };


  const RestroSendRequest = async (e) => {
    e.preventDefault();
  };
  const __connectStripe = async (e) => {
    e.preventDefault();
    await connectStripe(
      {
        _id: input._id,
        email: input.email,
        type:"Staff"
      },

      (result) => {
        if (result.result) {
          window.location.href = result.result.url
        }

      },
      dispatch
    );
  };
  const __detachStripe = async (value) => {
    setDetachGateway(false)
    await detachStripe(
      {
        id: input._id,
        acc: value.id,
        recid:value.recid
      },

      (result) => {
        if (result.status == "Success") {
          toast.success(
            "Data Deleted successfully",
            {
              theme: "dark",


              position: toast.POSITION.TOP_CENTER,
            },
            { autoClose: 1000 }
          );
          setStripe({})
        } else {
          toast.error(
            "Something Went Wrong",
            {
              theme: "dark",


              position: toast.POSITION.TOP_CENTER,
            },
            { autoClose: 1000 }
          );

        }
        setTimeout(() => {
          _getUserProfile()
        }, 1000)

      },
      dispatch
    );
  };

  const __connectPaypal = async (e) => {
    e.preventDefault();
    await connectPaypal(
      {
        _id: input._id,
        email: input.email,
        type:"Staff"
      },

      (result) => {
        if (result.result) {
          window.history.pushState({}, '', "/profilesetting?code=404&type=PayPal");
          window.location.href = result.result.href
        }

      },
      dispatch
    );
  };
  const __detachPaypal = async (value) => {
    setDetachGateway(false)
    await detachStripe(
      {
        id: input._id,
        acc: value.id,
        recid:value.recid
      },

      (result) => {
        if (result.status == "Success") {
          toast.success(
            "Data Deleted successfully",
            {
              theme: "dark",


              position: toast.POSITION.TOP_CENTER,
            },
            { autoClose: 1000 }
          );
          setPaypal({})
        } else {
          toast.error(
            "Something Went Wrong",
            {
              theme: "dark",


              position: toast.POSITION.TOP_CENTER,
            },
            { autoClose: 1000 }
          );

        }

      },
      dispatch
    );
  };

  const handleNavigate = () => {
    if (userProfileData?.role == 1) {
      navigate("/customer/dashboard");
    } else if (userProfileData?.role == 2) {
      navigate("/resto/dashboard");
    } else if (userProfileData?.role == 3) {
      navigate("/staff/dashboard");
    }
  };



  const validateForm = () => {
    const errors = {};
    if (!input.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(input.email)) {
      errors.email = "Email is invalid";
    }

    if (!input.full_name) {
      errors.full_name = "Full name is required";
    }
    // if (!input.user_name) {
    //   errors.user_name = "User name is required";
    // }
    if (userRole != "2") {
      if (!input.user_name) {
        errors.user_name = "User name is required";
      }
    }
    // if (input.countryCode) {
    //   if (
    //    !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(
    //      input.countryCode
    //    )
    //  ) {
    //    errors.countryCode = "Country Code is invalid";
    //  } }
   if (
      !isValidPhoneNumber(input.contact_number)
      // input.contact_number.length < 12 ||
      // input.contact_number.length > 13
    ) {
      // console.log(" input.contact_number.length", input.contact_number.length)
      errors.contact_number = "Contact number is invalid";
    }

    return errors;
  };
  const handeblur = () => {
    // const errors = validateForm();
    // if (Object.keys(errors).length === 0) {
    //   setErrors(errors);
    // } else {
    //   setErrors(errors);
    // }
  };
  const handleStatusChange = (e) => {
    setStatus(selectRef.current.value);
  };

  const handleKeyPress = (e) => {
    // Prevent numbers from being pasted
    const key = String.fromCharCode(e.which);
    if (!/[A-Za-z ]/.test(key)) {
      e.preventDefault();
    }
  };


  function copytoClipboard(){
    var copyText = document.querySelector(".copytoClipboard");
  let copyUrl = navigator.clipboard.writeText(copyText.value);
  if(copyUrl){
  toast.success(
    "Profile URL Copied Successfully",
    {
      theme: "dark",
      position: toast.POSITION.TOP_RIGHT,
      autoClose:1000
    },
  );}
  }

  return (
    <div className="ProfileSetting">
      {/* <Header /> */}
      <HomeHeader />
      {/* <div className="container-fluid">
        <div className="row topBar">
          <div className="col">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="content">
                    <div className="heading">
                      <h3 className="Spoof-Black">Profile Setting</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <form onSubmit={(e) => RestroSendRequest(e)}>
        <div className="container">
          <div className="row profileSettingConent">
            <div className="col-md-6 col-12 dflex alc">
              <div className="profile" style={{ position: "relative" }}>
                <img
                  src={path != null ? path : "https://static.vecteezy.com/system/resources/thumbnails/010/260/479/small/default-avatar-profile-icon-of-social-media-user-in-clipart-style-vector.jpg"}
                  alt=""
                />
                <div className="icon">
                  <i className="fa-solid fa-pen"></i>
                  <input
                    type="file"
                    className="file-input-profile"
                    // className="d-none"
                    onChange={(e) => onFileChange(e)}
                  />
                </div>

              </div>
              <div className="content">
                <div className="heading">
                  <h3 className="Spoof-Black" style={{ marginBottom: "0px", paddingLeft: "15px" }}>Profile</h3>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="row mt-4 pb-5">
                <div className="text-end">
                  <button className="btn btn-main btn-height" onClick={handleNavigate}>
                    Cancel
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-main btn-height"
                    // data-bs-toggle="modal"
                    // data-bs-target="#exampleModal"
                    onClick={handleSubmitdata}
                  >
                    Save & Update
                  </button>


                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                {userProfileData?.role == 3 ? (
                  <div className="col-md-6">
                    <div className="inputField">
                      <label htmlFor="userName">User Name</label>
                      <div className="inputBox blackBorder">
                        <input
                          name="user_name"
                          defaultValue={userProfileData?.user_name}
                          type="text"
                          className="form-control px-3"
                          placeholder="Username"
                          onChange={handleChange}
                          onBlur={(e) => handeblur()}
                          onKeyPress={handleKeyPress}
                          pattern="[A-Za-z ]+" // Only allow letters and spaces
                          title="Please enter only text"
                        />
                      </div>
                      {error.user_name && (
                        <span className="text-danger">
                          {error.user_name}
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="col-md-6">
                  <div className="inputField">
                    <label htmlFor="userName">{userProfileData?.role == 2 ? "Pub Name" : "Name"}</label>
                    <div className="inputBox blackBorder">
                      <input
                        name="full_name"
                        defaultValue={userProfileData?.full_name}
                        type="text"
                        className="form-control px-3"
                        placeholder={userProfileData?.role == 2 ? "Pub Name" : "Name"}
                        onChange={handleChange}
                        onBlur={(e) => handeblur()}
                        onKeyPress={handleKeyPress}
                        pattern="[A-Za-z ]+" // Only allow letters and spaces
                        title="Please enter only text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="inputField">
                    <label htmlFor="userName">{userProfileData?.role == 2 ? "Pub Email" : "Email"}</label>
                    <div className="inputBox blackBorder">
                      <input
                        type="email"
                        name="email"
                        defaultValue={userProfileData?.email}
                        className="form-control px-3"
                        placeholder="Email"
                        disabled
                        onChange={handleChange}
                        onBlur={(e) => handeblur()}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="inputField1 ">
                    <label htmlFor="contact">Contact No</label>
                    <div className="inputBox  blackBorder">
                     <PhoneInput
                    //  fieldname="restaurantContact_number"
                          className="form-control px-3"
                          name="contact_number"
                          international
                          // maxLength={15}
                          defaultCountry="RU"
                          onChange={(e)=>handleChange1(e,"contact_number")}
                          value={userProfileData?.contact_number}
                          // onBlur={(e) => handeblur()}
                          // onChange={value =>{console.log(value); handleChange({ target: { input: value, name: 'restaurantContact_number' } })}}
                        />



                    {/* <text className="countryCode1" >+</text>
                      <input
                        type="text"
                        name="countryCode"
                        className="form-control px-3 countryCode countryCode2"
                        id="country"
                        defaultValue={userProfileData?.countryCode}
                        maxLength={4}
                        onChange={handleChange}
                      />
                      <input
                        type="number"
                        name="contact_number"
                        defaultValue={userProfileData?.contact_number}
                        className="form-control px-3"
                        id="contact"
                        placeholder="Contact Number"
                        onChange={handleChange}
                        onBlur={(e) => handeblur()}
                        maxLength={13}
                      /> */}
                      
                    </div>
                    {error.contact_number && (
                        <span className="text-danger">
                          {error.contact_number}
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-md-6 " style={{ paddingRight: "10px" }}>
                <div className="inputField ">
                  <label htmlFor="status">Select Status</label>
                  <div className="inputBox blackBorder whiteBackground">
                    <select
                      ref={selectRef}
                      name=""
                      id=""
                      className="form-select px-3 form-control borderNone whiteBackground"
                      // value={userdata?.login?.data?.status}
                      defaultValue={
                        status ? status : userProfileData?.status ? "true" : "false"
                      }
                      onChange={handleStatusChange}
                    >
                      <option value="true">Active</option>
                      <option value="false">Not Active</option>
                    </select>
                  </div>
                </div>
              </div>
              {userProfileData?.role == 3 &&
              <div className="col-md-6 " style={{ paddingRight: "10px",display:"flex",alignItems:"end" }}>
                <div className="inputField ">
                  <label htmlFor="copyCode">
                  <div className="UploadBox">
                  <button
                      className="btn btn-main btn-height"
                      onClick={copytoClipboard}
                    
                    >
                     <i class="fa-solid fa-copy"></i> &nbsp; Copy Profile URL
                    </button>
                      </div>
                      <input
                        type="text"
                        name="copyCode "
                        id="copyCode"
                        className="d-none copytoClipboard"
                        value={`${Frontend_Url}/customer/staffprofile/${userProfileData?._id}`}
                        // value={`${API_BASEURL}/customer/staffprofile/${userProfileData._id}`}
                        // onChange={(e) => uploadpubAudio(e)}

                      /></label>
                </div>
              </div>}

              </div>
            
             


            </div>
          </div>

          {userProfileData?.role == 3 &&

            <Card>

              <div className="row p-4">
                <div className="col-12">
                  <h3 className="Aeonik-Regular">Payment Method</h3>

                </div>
                <div className="col-md-1 col-6 dflex alc">

                  {/* <label htmlFor="userName">Card holder name</label> */}
                  <i className="fab fa-stripe-s" style={{ color: "#6772e5" }} ></i><b> Stripe</b>
                </div>
                <div className="col-md-4 col-6">
                  <div className="inputField">
                    <button
                      className="btn btn-main btn-height"
                      style={{ marginLeft: "auto" }}
                      onClick={stripe && stripe.status === "Active" ? () => setDetachGateway({type:"Stripe" ,id:stripe.account_id , recid:stripe._id}) : __connectStripe}
                    >
                      {stripe && stripe.status === "Active" ? <>Disconnect</> : <>Connect </>}
                    </button>
                  </div>
                </div>
                <div className="col-md-1 col-6 dflex alc">
                <img src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-100px.png" height={20} width={80} border="0" alt="PayPal Logo"/>
                </div>
                <div className="col-md-5 col-6">
                  <div className="inputField">
                    <button
                      className="btn btn-main btn-height"
                      onClick={paypal && paypal.status === "Active" ? () => setDetachGateway({type:"PayPal" ,id:paypal.account_id , recid:paypal._id}) : __connectPaypal}
                    >
                      {paypal && paypal.status === "Active" ? <>Disconnect</> : <>Connect</>}
                    </button>

                  </div>              </div>


              </div>
            </Card>


          }


        </div>

      </form>


      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body ">
              <div className="close" data-bs-dismiss="modal" aria-label="Close">
                <i className="fa-solid fa-xmark"></i>
              </div>
              <h3>Verification</h3>
              <p>We have sent you a code to verify your email.</p>
              <h4>Enter Your OTP Here</h4>
              <div className="otpBox">
                <input
                  type="text"
                  className="form-control blackBorder"
                  name="otp"
                  onChange={handleChange}
                  maxLength={6}
                />
                {/* <input type="text" className="form-control" name="otp" maxLength={1} />
                <input type="text" className="form-control" name="otp" maxLength={1} />
                <input type="text" className="form-control" name="otp" maxLength={1} />
                <input type="text" className="form-control" name="otp" maxLength={1} />
                <input type="text" className="form-control" name="otp" maxLength={1} /> */}
              </div>
              <button className="btn btn-main btn-height" onClick={handleSubmitdata}>
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal show={detachGateway} onHide={() => setDetachGateway(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
        
       
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding:10}}>

        <h3>Disconnecting your {detachGateway.type} account will prevent you from offering {detachGateway.type} services and products on your website. Do you wish to continue?</h3>
          <div className="payment-btn">
            <button
              variant="secondary"
              className="btn btn-white-outline btn-height"
              onClick={() => setDetachGateway(false)}>
              No
            </button>
            <button
              variant="primary"
              className="btn btn-main btn-height"
              onClick={() => detachGateway?.type ==  "Stripe"  ?__detachStripe(detachGateway):  __detachPaypal(detachGateway)
              }
            >
              Yes
            </button>
          </div>
        </Modal.Body>

      </Modal>
      <Modal show={messageModal} onHide={() => setMessageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
          
            {/* <div className="modal-heading"> */}
            
            {/* </div> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding:10}}>
        {messageModal}
          <div className="payment-btn">
            <button
              variant="secondary"
              className="btn btn-white-outline btn-height"
              onClick={() => setMessageModal(false)}>
              OK
            </button>
          
          </div>
        </Modal.Body>

      </Modal>
    </div>
    
  );
}

export default ProfileSetting;
