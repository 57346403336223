import axios from "axios";
import { apiUrl, PORT } from "../../../environment";
import { loadingAction } from "../../../components/common/loader/LoaderSlice";
import queryString from "query-string";

export const RestaurantList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/restaurantlist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const No_Of_RestaurantList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/noofrestaurantlist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const GetAboutUs = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/landingpage/getaboutus?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const UpdateAboutUs = async (formDatas, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .put(
          `${apiUrl}${PORT}/landingpage/updatelandingpage`,
          formDatas,
          axiosConfig
        )

        .then((response) => {
          dispatch(loadingAction(false));
          callback(response.data);
        })
        .catch((error) => {
          console.log("error", error);
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const Addfaq = async (formDatas, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .post(`${apiUrl}${PORT}/landingpage/addfaq`, formDatas, axiosConfig)

        .then((response) => {
          dispatch(loadingAction(false));
          callback(response.data);
        })
        .catch((error) => {
          console.log("error", error);
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const GetFaq = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/landingpage/getfaq?${queryString.stringify(param)}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const GetHowitworks = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/landingpage/gethowitworks?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const addHowitworks = async (formDatas, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .post(`${apiUrl}${PORT}/landingpage/howitworks`, formDatas, axiosConfig)

        .then((response) => {
          dispatch(loadingAction(false));
          callback(response.data);
        })
        .catch((error) => {
          console.log("error", error);
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const GetPubstarLists = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/stafflist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const GetCustomerLists = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/customerlist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const No_Of_CustomerList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/noofcustomerlist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const FaqDelete = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .delete(
          `${apiUrl}${PORT}/landingpage/deletefaq?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response.data);
        })
        .catch((error) => {
          console.log("error", error);
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const HowitWorksDelete = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .delete(
          `${apiUrl}${PORT}/landingpage/deletehowitworks?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response.data);
        })
        .catch((error) => {
          console.log("error", error);
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const Service_Donation_feesList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/recenttiplist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const No_Of_Total_Tips = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/nooftotaltip?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const No_Of_Request_Recieved = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/noofrequestrecieved?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};
export const Recent_Tip_List = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/recenttiplist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const Faq_details_Byid = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/landingpage/getfaqdetailsbyid?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const updateFaq = async (formData, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .put(`${apiUrl}${PORT}/landingpage/updatefaq`, formData, axiosConfig)

        .then((response) => {
          dispatch(loadingAction(false));
          callback(response.data);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {}
};

export const HowitWorks_details_Byid = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/landingpage/gethowitworksdetailsbyid?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const updateHowitWorks = async (formData, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .put(
          `${apiUrl}${PORT}/landingpage/updatehowitworks`,
          formData,
          axiosConfig
        )

        .then((response) => {
          dispatch(loadingAction(false));
          callback(response.data);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {}
};

export const Get_Pubstar_Tip_lists = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/pubstartiplists?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const Get_Group_Tip_lists = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/getgrouptiplists?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const No_of_pubstars = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/getnoofpubstartip?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const No_of_GroupTip = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/getnoofgrouptip?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const Get_Total_Request_Recieved = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/getpendingrequest?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const Get_Enquiry_List = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/getenquirylist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const Get_Contact_Support_List = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/getsupportlist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const No_of_Enquiry_List = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/getnoofenquirylist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const No_of_Contact_List = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/getnoofcontactlist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const _get_Contact_Us_List = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/getcontactuslist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const _get_no_of_Contact_Us_List = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/noofcontactus?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const _get_details_by_id = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/getdetailsbyid?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const updateReplyByAdmin = async (formData, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .put(
          `${apiUrl}${PORT}/superadmin/replybyadminupdate`,
          formData,
          axiosConfig
        )

        .then((response) => {
          dispatch(loadingAction(false));
          callback(response.data);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {}
};

export const _get_details_by_id_contactus = async (
  param,
  callback,
  dispatch
) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/contactusview?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const AddServiceFees = async (formDatas, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      axios
        .post(`${apiUrl}${PORT}/superadmin/servicefee`, formDatas, axiosConfig)

        .then((response) => {
          // dispatch(loadingAction(false));
          // console.log("responseeeee", response);
          callback(response.data);
        })
        .catch((error) => {
          console.log("error", error);
          // dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};
/*----------@Api Call for getting graph related Data----------- */
export const getGraphDataList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/graphtiplist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const _get_notifications = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/notification/notificationlist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const AddNotificationContent = async (formDatas, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .post(
          `${apiUrl}${PORT}/notification/addnotification`,
          formDatas,
          axiosConfig
        )

        .then((response) => {
          dispatch(loadingAction(false));
          callback(response.data);
        })
        .catch((error) => {
          console.log("error", error);
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const Notification_details_Byid = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/notification/getnotificationbyid?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const updateNotification = async (formData, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .put(
          `${apiUrl}${PORT}/notification/updatenotification`,
          formData,
          axiosConfig
        )

        .then((response) => {
          dispatch(loadingAction(false));
          callback(response.data);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {}
};

export const NotificationDelete = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .delete(
          `${apiUrl}${PORT}/notification/deletenotification?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response.data);
        })
        .catch((error) => {
          console.log("error", error);
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const _get_payment_history = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/superadmin/getpaymenthistory?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const AddEmailTemplate = async (formDatas, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .post(
          `${apiUrl}${PORT}/notification/addemailtemplate`,
          formDatas,
          axiosConfig
        )

        .then((response) => {
          dispatch(loadingAction(false));
          callback(response.data);
        })
        .catch((error) => {
          console.log("error", error);
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const EmailTemplate_details_Byid = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/notification/getemailsbyid?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const _get_EmailTemplates = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/notification/templatelists?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const updateEmailTemplates = async (formData, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .put(
          `${apiUrl}${PORT}/notification/updatetemplate`,
          formData,
          axiosConfig
        )

        .then((response) => {
          dispatch(loadingAction(false));
          callback(response.data);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {}
};

export const EmailDelete = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .delete(
          `${apiUrl}${PORT}/notification/deletetemplate?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response.data);
        })
        .catch((error) => {
          console.log("error", error);
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const _get_NotificationList = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("user");
    let token1 = JSON.parse(token);
    if (token1?.Token) {
      let axiosConfig = {
        headers: {
          Authorization: `Bearer ${token1?.Token}`,
        },
      };
      dispatch(loadingAction(true));
      axios
        .get(
          `${apiUrl}${PORT}/notification/getadminnotificationlist?${queryString.stringify(
            param
          )}`,
          axiosConfig
        )
        .then((response) => {
          dispatch(loadingAction(false));
          callback(response?.data?.result);
        })
        .catch((error) => {
          dispatch(loadingAction(false));
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};
