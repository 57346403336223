import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Addfaq } from "../../service/Auth.service";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Faq_details_Byid, updateFaq } from "../../service/Auth.service";
import { useParams } from "react-router-dom";

function AddFaq() {
  const userdata = useSelector((state) => state);
  let adminRole = userdata?.login?.data?.role;

  const FaqId = useParams();
  const [error, setErrors] = useState({});
  const [details, setDetails] = useState();

  const [confirm, serConfirm] = useState({});
  const [formData, setFormData] = useState({
    faq_question: "",
    faq_answer: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    _getFaqDetails();
  }, [dispatch]);

  const _getFaqDetails = () => {
    Faq_details_Byid(
      { _id: FaqId.id },
      (data) => {
        setDetails(data);
        setFormData({
          faq_question: data?.faq_question,
          faq_answer: data?.faq_answer,
        });
      },
      dispatch
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
    } else {
      setErrors(errors);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSubmitdata = async (e) => {
    handeblur();
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
      let reqData = {
        faq_question: formData.faq_question,
        faq_answer: formData.faq_answer,
      };
      try {
        Addfaq(
          reqData,
          (result) => {
            if (result?.status == true) {
              toast.success(
                "Submit successfully",
                {
                  theme: "dark",
             
                  position: toast.POSITION.BOTTOM_RIGHT,
                },
                { autoClose: 1000 }
              );

              navigate("/super-admin/faq-management");
            } else if (result?.status === false) {
              toast.error(
                "Not submit",
                {
                  theme: "dark",
                },
                {
                  position: toast.POSITION.TOP_CENTER,
                },
                { autoClose: 1000 }
              );
            }
          },
          dispatch
        );
      } catch (e) {}
    }
  };
  const handleUpdatedata = async (e) => {
    handeblur();
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
      let reqData = {
        _id: FaqId.id,
        faq_question: formData.faq_question,
        faq_answer: formData.faq_answer,
      };
      try {
        updateFaq(
          reqData,
          (result) => {
            if (result?.status == true) {
              toast.success(
                "Update successfully",
                {
                  theme: "dark",
              
                  position: toast.POSITION.BOTTOM_RIGHT,
                },
                { autoClose: 1000 }
              );

              navigate("/super-admin/faq-management");
            } else if (result?.status === false) {
              toast.error(
                "Not submit",
                {
                  theme: "dark",
               
                  position: toast.POSITION.BOTTOM_RIGHT,
                },
                { autoClose: 1000 }
              );
            }
          },
          dispatch
        );
      } catch (e) {}
    }
  };
  const validateForm = () => {
    const errors = {};

    if (!formData.faq_question) {
      errors.faq_question = "Faq question is required";
    }

    if (!formData.faq_answer) {
      errors.faq_answer = "Faq answer is required";
    }

    return errors;
  };

  const handeblur = () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
    } else {
      setErrors(errors);
    }
  };
  if (adminRole == 0) {
    return (
      <>
        <h2 className="page-title">Content Management</h2>
        <div className="content-wraper">
          {FaqId.id ? <h4>Edit FAQ’s</h4> : <h4>Add New FAQ’s</h4>}
          <Card>
            <Card.Body>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <label>
                      Question <sup>*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      defaultValue={details?.faq_question}
                      name="faq_question"
                      onChange={handleChange}
                      onBlur={(e) => handeblur()}
                    />
                    {error.faq_question && (
                      <span className="text-danger">{error.faq_question}</span>
                    )}
                  </Col>

                  <Col md={12}>
                    <label>
                      Answer <sup>*</sup>
                    </label>
                    <textarea
                      className="form-control"
                      name="faq_answer"
                      defaultValue={details?.faq_answer}
                      onChange={handleChange}
                      onBlur={(e) => handeblur()}
                      rows={6}
                    ></textarea>
                    {error.faq_answer && (
                      <span className="text-danger">{error.faq_answer}</span>
                    )}
                  </Col>
                </Row>
              </form>
              <div className="text-end">
                <Button
                  variant="outline-secondary"
                  className="mx-3"
                  href="/super-admin/faq-management"
                >
                  Cancel
                </Button>
                {FaqId.id ? (
                  <Button variant="primary" onClick={handleUpdatedata}>
                    Update
                  </Button>
                ) : (
                  <Button variant="primary" onClick={handleSubmitdata}>
                    Add
                  </Button>
                )}
              </div>
            </Card.Body>
          </Card>
        </div>
      </>
    );
  }
}

export default AddFaq;
