import React, { useState } from "react";
import { MultiSelect } from "primereact/multiselect";
function SearchFilter() {
  const [selectedCities, setSelectedCities] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const cities = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];
  const filters = [
    { name: "Lorem ipsum 1", code: "1" },
    { name: "Lorem ipsum 2", code: "2" },
    { name: "Lorem ipsum 3", code: "3" },
    { name: "Lorem ipsum 4", code: "4" },
    { name: "Lorem ipsum 5", code: "5" },
  ];
  return (
    <div className="row my-3">
      <div className="col">
        <div className="toolsContainer">
          <div className="row justify-content-center">
            <div className="col-md-4 mt-3 mt-md-0">
              <div className="searchBox">
                <input
                  type="text"
                  className="form-control"
                  placeholder="search restaurant, staffs"
                />
                <i className="fa-solid fa-magnifying-glass"></i>
                <div className="serchResults d-none">
                  <div className="result">
                    <div className="roundImage">
                      <img src="./assets/searchresults.png" alt="" />
                    </div>
                    <div className="text">
                      <p className="mb-0">The Street Social</p>
                    </div>
                  </div>
                  <div className="result">
                    <div className="image">
                      <img src="./assets/searchresults.png" alt="" />
                    </div>
                    <div className="text">
                      <p className="mb-0">The Street Social</p>
                    </div>
                  </div>
                  <div className="result">
                    <div className="image">
                      <img src="./assets/searchresults.png" alt="" />
                    </div>
                    <div className="text">
                      <p className="mb-0">The Street Social</p>
                    </div>
                  </div>
                  <div className="result">
                    <div className="image">
                      <img src="./assets/searchresults.png" alt="" />
                    </div>
                    <div className="text">
                      <p className="mb-0">The Street Social</p>
                    </div>
                  </div>
                  <div className="result">
                    <div className="roundImage">
                      <img src="./assets/searchresults.png" alt="" />
                    </div>
                    <div className="text">
                      <p className="mb-0">The Street Social</p>
                    </div>
                  </div>
                  <div className="result">
                    <div className="image">
                      <img src="./assets/searchresults.png" alt="" />
                    </div>
                    <div className="text">
                      <p className="mb-0">The Street Social</p>
                    </div>
                  </div>
                  <div className="result">
                    <div className="image">
                      <img src="./assets/searchresults.png" alt="" />
                    </div>
                    <div className="text">
                      <p className="mb-0">The Street Social</p>
                    </div>
                  </div>
                  <div className="result">
                    <div className="image">
                      <img src="./assets/searchresults.png" alt="" />
                    </div>
                    <div className="text">
                      <p className="mb-0">The Street Social</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 mt-3 mt-md-0">
              <div className="filters">
                <div className="card flex justify-content-center">
                  <MultiSelect
                    value={selectedCities}
                    onChange={(e) => setSelectedCities(e.value)}
                    options={cities}
                    optionLabel="name"
                    placeholder="City"
                    maxSelectedLabels={3}
                    className="w-full md:w-20rem"
                  />
                  <i class="fa-solid fa-location-dot"></i>
                </div>
              </div>
            </div>
            <div className="col-md-2 mt-3 mt-md-0">
              <div className="filters">
                <div className="card flex justify-content-center">
                  <MultiSelect
                    value={selectedFilters}
                    onChange={(e) => setSelectedFilters(e.value)}
                    options={filters}
                    optionLabel="name"
                    placeholder="Filters"
                    maxSelectedLabels={3}
                    className="w-full md:w-20rem"
                  />
                  <i class="fa-solid fa-arrow-up-wide-short"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchFilter;
