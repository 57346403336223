import HomeHeader from "../../homePages/common/HomeHeader";
import { useParams, useNavigate } from "react-router";
import { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addCardDetails, makePayment } from "../Service/Auth.Service";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { SocketContext } from "../../../socket/SocketContext";
function CardDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setErrors] = useState({});
  const userdata = useSelector((state) => state);
  const socket = useContext(SocketContext);

  // let LoggedIn = userdata?.login?.isLoggedIn;
  const [focus, setFocus] = useState();

  let customerId = userdata?.login?.data?.result?._id;
  const [showModal, setShowModal] = useState(false);
  const [values, set_values] = useState({
    customerId: customerId,
    card_Number: "",
    card_Name: "",
    card_expYear: "",
    card_CVC: "",
  });
  const [formattedCardExpDate, setFormattedCardExpDate] = useState("");

  useEffect(() => {
    if (
      values.card_expYear.length === 2 &&
      !values.card_expYear.includes("/")
    ) {
      setFormattedCardExpDate(`${values.card_expYear}/`);
    } else {
      setFormattedCardExpDate(values.card_expYear);
    }
  }, [values.card_expYear]);

  const values_handler = (e, fieldName) => {
    let name = e.target.name;
    let value = e.target.value;
    let newErrors = { ...error }; // Create a copy of errors

    if (fieldName === "card_expYear") {
      value = value.replace(/\D/g, ""); // Remove non-digit characters
      if (value.length > 2) {
        value = `${value.slice(0, 2)}/${value.slice(2)}`; // Add the forward slash (/) after two digits
      }
    } else if (fieldName === "card_Number") {
      value = value.replace(/\s/g, ""); // Remove existing spaces
      value = value.replace(/-/g, ""); // Remove existing dashes

      // Add space or dash after every four digits
      value = value.replace(/(\d{4})(?=\d)/g, "$1 ");

      // Trim extra characters if the length exceeds 19 digits (including spaces)
      value = value.slice(0, 19);
    }

    const newValues = {
      ...values,
      [name]: value,
    };

    set_values(newValues); // Update the form values
    // Clear the error message for the field if the value is valid
    if (newErrors[name]) {
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };
  const buttonHandler = async (e) => {
    // handeblur();
    e.preventDefault();
    const newErrors = validateForm(); // Renamed errors to newErrors

    if (Object.keys(newErrors).length === 0) {
      setErrors(newErrors);
      const res = await addCardDetails(values, dispatch);
      // console.log(res);
      if (res?.data.status === true) {
        setShowModal(true);
      } else {
        toast.error(
          "Sorry, something went wrong",
          {
            theme: "dark",
          },
          {
            position: toast.POSITION.TOP_CENTER,
          },
          { autoClose: 1000 }
        );
      }
    } else {
      setErrors(newErrors);
    }
  };
  const handlePayment = async () => {
    const res = await makePayment(id, customerId, dispatch);
    if (res?.data?.status === true) {
      let notificationdata = {
        recieverId: res?.data?.TipData?.is_group_tip
          ? res?.data?.TipData?.resto_id
          : res?.data?.TipData?.staff?.staff_id,
        senderId: customerId,
        type: "PaymentTip",
      };
      setShowModal(false);
      socket.emit("customer-tip", notificationdata);
      toast.success(
        "Tip sent successfully",
        {
          theme: "dark",
          position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 1000 
        },
      );
      navigate("/customer/paymentsuccess");
    } else {
      toast.error(
        "Sorry, something went wrong",
        {
          theme: "dark",
        },
        {
          position: toast.POSITION.TOP_CENTER,
        },
        { autoClose: 1000 }
      );
    }
  };
  const handleCancel = () => {
    navigate("/customer/dashboard");
  };

  const validateForm = () => {
    const errors = {};

    if (!values.card_Number) {
      errors.card_Number = "Card number is required";
    } else if (!isValidCardNumber(values.card_Number)) {
      errors.card_Number = "Invalid card number";
    } else {
      delete errors.card_Number; // Remove the error for card number if it becomes valid
    }

    if (!values.card_Name) {
      errors.card_Name = "Card name is required";
    } else if (!isValidCardName(values.card_Name)) {
      errors.card_Name = "Invalid card name";
    } else {
      delete errors.card_Name; // Remove the error for card name if it becomes valid
    }

    if (!values.card_expYear) {
      errors.card_expYear = "Card expiry date is required";
    } else if (!isValidExpiryDate(values.card_expYear)) {
      errors.card_expYear = "Invalid expiry date";
    } else {
      delete errors.card_expYear; // Remove the error for expiry date if it becomes valid
    }

    if (!values.card_CVC) {
      errors.card_CVC = "Card CVV number is required";
    } else if (!isValidCVV(values.card_CVC)) {
      errors.card_CVC = "Invalid CVV number";
    } else {
      delete errors.card_CVC; // Remove the error for CVV number if it becomes valid
    }

    return errors;
  };

  const isValidCardName = (cardName) => {
    if (!cardName) {
      return false; // Card name is required
    }

    const nameRegex = /^[A-Za-z ]+$/;

    if (!nameRegex.test(cardName)) {
      return false; // Card name should only contain alphabetic characters and spaces
    }

    if (cardName.length < 2) {
      return false; // Card name should have a minimum length of 2 characters
    }

    return true; // Card name is valid
  };

  const isValidCardNumber = (cardNumber) => {
    // Remove all non-digit characters from the card number
    const cleanCardNumber = cardNumber.replace(/\D/g, "");
    // Check if the card number is between 13 and 19 digits long
    if (cleanCardNumber.length < 13 || cleanCardNumber.length > 19) {
      return false;
    }
    // Example Luhn algorithm implementation:
    let sum = 0;
    let shouldDouble = false;
    for (let i = cleanCardNumber.length - 1; i >= 0; i--) {
      let digit = parseInt(cleanCardNumber.charAt(i), 10);
      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }
      sum += digit;
      shouldDouble = !shouldDouble;
    }
    return sum % 10 === 0;
  };

  const isValidExpiryDate = (expiryDate) => {
    // Check if the expiry date is in the format "MM/YY"
    const pattern = /^(0[1-9]|1[0-2])\/[0-9]{2}$/;
    if (!pattern.test(expiryDate)) {
      return false;
    }
    // Extract the month and year from the expiry date
    const [month, year] = expiryDate.split("/").map(Number);
    // Validate the month and year values
    const currentYear = new Date().getFullYear() % 100; // Get the last two digits of the current year
    if (
      year < currentYear ||
      (year === currentYear && month < new Date().getMonth() + 1)
    ) {
      return false; // Expiry date is in the past
    }
    return true;
  };

  const isValidCVV = (cvvNumber) => {
    // Remove all non-digit characters from the CVV number
    const cleanCVV = cvvNumber.replace(/\D/g, "");
    // Check if the CVV number is a 3 or 4 digit number
    return /^\d{3,4}$/.test(cleanCVV);
  };
  const handeblur = () => {
  
  };

  return (
    <div className="staffProfile">
      <HomeHeader />
      <div className="container">
       
        <div className="row TippingPayment">
          <div className="col-lg-6">
            <h3>Pay With Card</h3>
            <div className="paymentCards">
              <img src="../assets/discover.png" alt="" />
              <img src="../assets/masterCard.png" alt="" />
              <img src="../assets/VISA-Logo.png" alt="" />
            </div>
            <Cards
              cvc={values.card_CVC}
              expiry={values.card_expYear}
              focused={focus}
              name={values.card_Name}
              number={values.card_Number}
            />
            <form action="">
              <div className="row">
                <div className="col">
                  <div className="inputField">
                    <div className="inputBox blackBorder">
                      <input
                        type="text"
                        className="form-control px-3"
                        name="card_Name"
                        placeholder="Card Holder Name"
                        onFocus={handleInputFocus}
                        onChange={values_handler}
                        onBlur={(e) => handeblur()}
                      />
                    </div>
                    {error.card_Name && (
                      <span className="text-danger">{error.card_Name}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="inputField">
                    <div className="inputBox blackBorder">
                      <input
                        type="text"
                        className="form-control px-3"
                        name="card_Number"
                        placeholder="Card Number"
                        onFocus={handleInputFocus}
                        onChange={values_handler}
                        onBlur={(e) => handeblur()}
                      />
                    </div>
                    {error.card_Number && (
                      <span className="text-danger">{error.card_Number}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="inputField">
                    <div className="inputBox blackBorder">
                      <input
                        type="text"
                        className="form-control px-3"
                        name="card_expYear"
                        onFocus={handleInputFocus}
                        placeholder="Card Expiry Date"
                        onChange={(e) => values_handler(e, "card_expYear")}
                        value={formattedCardExpDate}
                        onBlur={handeblur}
                      />
                    </div>
                    {error.card_expYear && (
                      <span className="text-danger">{error.card_expYear}</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="inputField">
                    <div className="inputBox blackBorder">
                      <input
                        type="number"
                        className="form-control px-3"
                        name="card_CVC"
                        onFocus={handleInputFocus}
                        placeholder="CVC"
                        onChange={values_handler}
                        onBlur={(e) => handeblur()}
                      />
                    </div>
                    {error.card_CVC && (
                      <span className="text-danger">{error.card_CVC}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col">
                  <div class="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                    />
                    <label className="form-check-label" for="flexCheckChecked">
                      Checked checkbox
                    </label>
                  </div>
                </div> */}
              </div>
            </form>
          </div>
        </div>
        <div className="buttonsRow marginNone paddingBottom">
          <div className="btnGroups ">
            <button className="btn btn-white-outline btn-height" onClick={handleCancel}>
              Cancel
            </button>
            <button
              className="btn btn-main btn-height"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={buttonHandler}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {/* <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body ">
                            <div className="close" data-bs-dismiss="modal" aria-label="Close">
                                <i class="fa-solid fa-xmark"></i>
                            </div>
                            <h3>Payment</h3>
                            <div className="otpBox">
                                <p>Proceed with payment</p>
                            </div>
                            <button className="btn btn-main" data-bs-dismiss="modal" aria-label="Close">
                                Yes
                            </button>
                            &nbsp;
                            <button className="btn btn-white-outline" data-bs-dismiss="modal" aria-label="Close">
                                No
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
      <div>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {/* <div className="modal-heading"> */}
              <h3>Payment Processing</h3>
              {/* </div> */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="otpBox">
              <h5>Proceed with payment</h5>
            </div>
            <div className="payment-btn">
              <button
                variant="secondary"
                className="btn btn-white-outline btn-height"
                onClick={() => setShowModal(false)}
              >
                No
              </button>
              <button
                variant="primary"
                className="btn btn-main btn-height"
                onClick={handlePayment}
              >
                Yes
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default CardDetails;
