import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { useParams } from "react-router-dom";
import {
  _get_details_by_id,
  updateReplyByAdmin,
} from "../../service/Auth.service";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function ContactSupportView() {
  const userdata = useSelector((state) => state);
  let adminRole = userdata?.login?.data?.role;
  const [details, setDetails] = useState();
  const supportView = useParams();
  const [error, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    reply_by_admin: "",
    status: 2,
  });
  useEffect(() => {
    _get_Details_By_Id();
  }, [dispatch]);

  const _get_Details_By_Id = () => {
    _get_details_by_id(
      { _id: supportView.id },
      (data) => {
        setDetails(data);
      },
      dispatch
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
    } else {
      setErrors(errors);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSubmitdata = async (e) => {
    handeblur();
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
      let reqData = {
        _id: supportView.id,
        reply_by_admin: formData.reply_by_admin,
        status: formData.status,
      };
      try {
        updateReplyByAdmin(
          reqData,
          (result) => {
            if (result?.status == true) {
              toast.success(
                "Sent successfully",
                {
                  theme: "dark",
               
                  position: toast.POSITION.BOTTOM_RIGHT,
                },
                { autoClose: 1000 }
              );

              navigate("/super-admin/contact-support");
            } else if (result?.status === false) {
              toast.error(
                "Not submit",
                {
                  theme: "dark",
               
                  position: toast.POSITION.BOTTOM_RIGHT,
                },
                { autoClose: 1000 }
              );
            }
          },
          dispatch
        );
      } catch (e) {}
    }
  };
  const validateForm = () => {
    const errors = {};

    if (!formData.reply_by_admin) {
      errors.reply_by_admin = "Reply is required";
    }
    return errors;
  };

  const handeblur = () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setErrors(errors);
    } else {
      setErrors(errors);
    }
  };

  const [selectedValue, setSelectedValue] = useState("2");

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  if (adminRole == 0) {
    return (
      <>
        <h2 className="page-title">Queries</h2>

        <div className="content-wraper">
          <div className="table-wraper">
            <div className="row TippingBox">
              <div className="col-12">
                <form onSubmit={handleSubmit}>
                  <InputGroup className="mb-3 justify-content-between">
                    <p></p>
                    <Form.Group controlId="dob" className="from-to">
                      <select
                        value={details?.status}
                        onChange={handleSelectChange}
                        className="form-control mx-2"
                      >
                        <option value="0">Open</option>
                        <option value="2">Resolved</option>
                      </select>
                    </Form.Group>
                  </InputGroup>
                  <div className="row">
                    <div className="col-12">
                      <div className="inputField-inline">
                        <label for="tipping" className="form-label">
                          Name :
                        </label>
                        <div className="inputBox">{details?.full_name}</div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="inputField-inline">
                        <label for="donation" className="form-label">
                          Email Id:
                        </label>
                        <div className="inputBox">{details?.email}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="inputField-inline align-items-start">
                        <label for="totalAmount" className="form-label">
                          Message:
                        </label>
                        <div className="inputBox">
                          <textarea
                            name=""
                            id=""
                            cols="50"
                            rows="4"
                            className="form-control"
                            value={details?.message}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="inputField-inline align-items-start">
                        <label for="totalAmount" className="form-label">
                          Reply:
                        </label>
                        <div className="inputBox">
                          <textarea
                            name="reply_by_admin"
                            defaultValue={details?.reply_by_admin}
                            onChange={handleChange}
                            onBlur={(e) => handeblur()}
                            id=""
                            cols="50"
                            rows="4"
                            className="form-control"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    {error.reply_by_admin && (
                      <span className="text-danger">
                        {error.reply_by_admin}
                      </span>
                    )}
                  </div>
                </form>
              </div>
              <div className="text-end">
                <Button variant="primary" onClick={handleSubmitdata}>
                  Sent
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ContactSupportView;
