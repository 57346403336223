import { createSlice } from "@reduxjs/toolkit";

export const AdminStaffIdSlice = createSlice ({
    name:'adminstaff',
    initialState: {
        isLoggedIn: false,
        data: {}
    },
    reducers: {
        adminrstaffdetails(state, action) {
            state.isLoggedIn = true;
            state.data = action.payload;
        },
      
    }
})

export const {adminrstaffdetails} = AdminStaffIdSlice.actions;
export default AdminStaffIdSlice.reducer;