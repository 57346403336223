import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { _get_payment_history } from "../../../super-admin/service/Auth.service";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import DefaultImage from "../../../../assets/images/defaultimage.png";
import { API_BASEURL } from "../../../../environment";
import { pagination } from "../../../../components/common/utils/message";
function PaymentHistory() {
  const userdata = useSelector((state) => state);
  let adminRole = userdata?.login?.data?.role;
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const [paymentlist, setPaymentList] = useState();
  const [totalPages, settotalPages] = useState();
  const [start_date, setStartDate] = useState();
  const [end_date, setEndDate] = useState();
  const maxDate = new Date().toISOString().split("T")[0];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    _getPaymentHistoryList();
  }, [dispatch]);

  const _getPaymentHistoryList = (searchText, startDate, endDate) => {
    _get_payment_history(
      {
        page,
        limit,
        searchText: searchText,
        start_date: startDate,
        end_date: endDate,
      },
      (data) => {
        setPaymentList(data?.docs);
        settotalPages(data?.totalPages);
      },
      dispatch
    );
  };
  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await _get_payment_history(
      { page: currentPage, limit, searchText },
      (response) => {
        setPaymentList(response?.docs);
      },
      dispatch
    );
  };
  const __handleSearch = async (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
    _getPaymentHistoryList(e.target.value, start_date, end_date);
  };
  const handleInputStartDate = (newDate) => {
    setStartDate(newDate.target.value);
  };

  const handleInputEndDate = (newDate) => {
    setEndDate(newDate.target.value);
    _getPaymentHistoryList("", start_date, newDate.target.value);
  };
  if (adminRole == 0) {
    return (
      <>
        <h2 className="page-title">Payment Management</h2>

        <div className="content-wraper">
          <h4>Payment History</h4>
          <div className="table-wraper">
            <div className="top-action">
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search Name"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  value={searchText}
                  onChange={__handleSearch}
                />
                <Form.Group controlId="dob" className="from-to">
                  <Form.Label>From</Form.Label>
                  <Form.Control
                    type="date"
                    name="dob"
                    max={maxDate}
                    placeholder="Date of Birth"
                    onChange={handleInputStartDate}
                  />
                  <Form.Label>to</Form.Label>
                  <Form.Control
                    type="date"
                    name="dob"
                    max={maxDate}
                    placeholder="Date of Birth"
                    onChange={handleInputEndDate}
                  />
                </Form.Group>
              </InputGroup>
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Transaction Id</th>
                  <th>Name</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {paymentlist &&
                  paymentlist?.map((data, index) => {
                    // console.log("data------------", data);
                    let ProfilePath = `${API_BASEURL}/public/profile/${data?.customerDetails?.profile}`;
                    const date = new Date(data?.createdAt);

                    // Extract the day, month, and year values
                    const day = date.getDate().toString().padStart(2, "0");
                    const month = (date.getMonth() + 1)
                      .toString()
                      .padStart(2, "0");
                    const year = date.getFullYear();

                    // Format the date as dd/mm/yyyy
                    const formattedDate = `${day}/${month}/${year}`;
                    return (
                      <tr key={index}>
                        <td>{formattedDate}</td>

                        <td>{data?.transaction_id}</td>
                        <td>
                          <div className="d-flex user-img-box">
                            <img
                              src={
                                data?.customerDetails?.profile != null ||
                                data?.customerDetails?.profile != undefined
                                  ? ProfilePath
                                  : DefaultImage
                              }
                              alt=""
                            />
                            <div className="mx-2">
                              {data?.is_guest_tip === false ? (
                                <>
                                  <p>{data?.customerDetails?.full_name}</p>
                                  <p>{data?.customerDetails?.email}</p>{" "}
                                </>
                              ) : (
                                <>
                                  <p>{data?.guest?.guest_name}</p>
                                  <p>{data?.guest?.guest_email}</p>
                                </>
                              )}
                            </div>
                          </div>
                        </td>
                        <td>{data?.total_tip_amount}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              {paymentlist?.length == 0 ? "No Records found" : ""}
            </div>
            <div className="text-end d-flex">
              <Pagination className="ml-auto">
                {totalPages > 1 ? (
                  <ReactPaginate
                    breakLabel={"..."}
                    nextLabel={"Next >"}
                    onPageChange={handlePageChange}
                    pageCount={totalPages}
                    previousLabel={"< Back"}
                    renderOnZeroPageCount={null}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                ) : null}
              </Pagination>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default PaymentHistory;
