import "./App.scss";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProtectedOutlet from "./ProtectedRoutes";

import {
  authRoutes,
  homeRoutes,
  CustomerInterfaceRoutes,
  RestoInterfaceRoutes,
  StaffInterfaceRoutes,
  SuperAdminInterfaceRoutes,
} from "./routes/index";

import { SocketProvider } from "./socket/SocketContext";
// import '../src/assets/fonts/Aeonik/Aeonik-Bold-Web/css/stylesheet-family-support.css'

import { SOCKET_URL } from "./environment";
import { checkToken } from "./services/auth.service";
import { useDispatch, useSelector } from "react-redux";
import { Checkmark } from "react-checkmark";

function App() {
  const dispatch = useDispatch()
  let customToaster = useSelector((state) => state?.customToaster?.customVal);
  const userdata = useSelector((state) => state);
  if(userdata?.login?.isLoggedIn)
  checkToken( dispatch  )
  return (
    <>
      <Router>
        <ToastContainer />
        {customToaster && 
         <div className="customToast">
         <Checkmark  size={80} />
          </div>
      
        
        }
        
         
        <SocketProvider socketUrl={SOCKET_URL}>
          {/* <SocketProvider> */}
          <Routes>
            {homeRoutes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  element={<route.layout>{route.component}</route.layout>}
                />
              );
            })}
            ,
            {authRoutes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  element={<route.layout>{route.component}</route.layout>}
                />
              );
            })}
            ,
            {CustomerInterfaceRoutes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  element={<route.layout>{route.component}</route.layout>}
                />
              );
            })}
            ,
            <Route element={<ProtectedOutlet />}>
              {RestoInterfaceRoutes.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    element={<route.layout>{route.component}</route.layout>}
                  />
                );
              })}
              ,
            </Route>
            <Route element={<ProtectedOutlet />}>
              {StaffInterfaceRoutes.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    element={<route.layout>{route.component}</route.layout>}
                  />
                );
              })}
              ,
            </Route>
            <Route element={<ProtectedOutlet />}>
              {SuperAdminInterfaceRoutes.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    element={<route.layout>{route.component}</route.layout>}
                  />
                );
              })}
            </Route>
          </Routes>
        </SocketProvider>
      </Router>
    </>
  );
}

export default App;
