// import RIHeader from "./RIHeader";
import HomeHeader from "../../homePages/common/HomeHeader";
import React, { useEffect, useState } from "react";
import { AllstaffList } from "../../../services/auth.service";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_BASEURL } from "../../../environment";
import { DeleteStaffList } from "../../RestoInterface/Service/Auth.service";
import { pagination, } from "../../../components/common/utils/message";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DefaultImage from "../../../assets/images/defaultimage.png";

// import Footer from "./Footer";
function ManageStaff() {
    const [staffList, setStaffList] = useState();
    const [selectedItems, setSelectedItems] = useState([]);
    const [limit, setLimit] = useState(pagination.projectLimit);
    const [page, setPage] = useState(pagination.page);
    const [totalPages, settotalPages] = useState();
    const [searchText, setSearchText] = useState("");

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const userdata = useSelector((state) => state);

    // let user_id = userdata?.login?.data?.result._id;
    let loginRole = userdata?.login?.data?.role;
    let user_id;
    if (loginRole == 2) {
        user_id = userdata?.login?.data?.result?._id;
    }
    if (loginRole == 0) {
        user_id = userdata?.adminrestro?.data?._id;
    }

    useEffect(() => {
        _getNoofStaffListing();
    }, [dispatch]);

    const _getNoofStaffListing = (searchText) => {
        AllstaffList(
            { resto_id: user_id, searchText: searchText, page, limit },
            (data) => {
                setStaffList(data?.data?.result?.docs);
                settotalPages(data?.data?.result?.totalPages);
            },
            dispatch
        );
    };

    const handlePageChange = async (param) => {
        let currentPage = param.selected + 1;
        setPage(currentPage);
        await AllstaffList(
            { resto_id: user_id, searchText, page: currentPage, limit },
            (response) => {
                setStaffList(response?.data?.result?.docs);
            },
            dispatch
        );
    };

    const buttonviewHandlerpage = async (data) => {
        navigate(`/resto/staffviewprofile/${data?._id}`);
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedItems(staffList);
        } else {
            setSelectedItems([]);
        }
    };

    const _handleDelete = async () => {
        let array = [];

        selectedItems.forEach((staff) => {
            array.push(staff?.userDetails?._id);
        });

        let object = { _id: array, resto_id: user_id };

        const result = await DeleteStaffList(object, (res) => {
            if (res?.result?.deletedCount !== 0) {
                toast.success(
                    "Pubstar deleted successfully",
                    {
                        theme: "dark",
                    
                        position: toast.POSITION.BOTTOM_RIGHT,
                    },
                    { autoClose: 1000 }
                );
                window.location.reload();
            } else if (res?.result?.deletedCount == 0) {
                toast.error(
                    "Please select the pubstar",
                    {
                        theme: "dark",
                    
                        position: toast.POSITION.BOTTOM_RIGHT,
                    },
                    { autoClose: 1000 }
                );
            }
            _getNoofStaffListing();
        });
    };

    const __handleSearch = async (e) => {
        setSearchText(e.target.value);
        _getNoofStaffListing(e.target.value);
    };
    const buttonviewHandlerpages = async (data) => {
        navigate(`/customer/staffprofile/${data?.staff_id}`);
      };
    return (
        <div className="ManageStaff">
            <HomeHeader />
            {/* <RIHeader /> */}
            <div className="container-fluid">
                <div className="row topBar">
                    <div className="col">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="content">
                                        <div className="heading">
                                            <h3 className="mb-0">Manage Pubstar</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row mt-4">
                    <div className="col">
                        <h2 className="whiteText Aeonik-Regular">Total Pubstar </h2>
                    </div>
                </div>

                <div className="row searchStaff">
                    <div className="col-12 px-0">
                        <div className="searchBox">
                            <div className="box">
                                <input type="text" className="form-control" placeholder="search pubstar" value={searchText} onChange={__handleSearch} />
                                <i class="fa-solid fa-magnifying-glass search"></i>
                            </div>
                            <div className="icon backgroundYellow" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i class="fa-solid fa-trash-can blackText"></i>
                                <div className="text">
                                    <p className="mb-0 fw-bold blackText">Delete </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                onChange={handleSelectAll}
                                checked={staffList?.length > 0 ? staffList?.length === selectedItems?.length : null}
                            />
                            <label class="form-check-label whiteText" for="flexCheckDefault">
                                Select All
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {staffList &&
                        staffList?.map((data, index) => {
                            let ProfilePath = `${API_BASEURL}/public/profile/${data?.userDetails?.profile}`;

                            return (
                                <div className="col-md-6  " key={index}>
                                    {/* <div className="row">
                                        <div className="col-6">

                                            
                                        </div>
                                    </div> */}
                                    <div className="staffList dflex alc">


                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="flexCheckDefault"
                                            checked={selectedItems.includes(data)}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setSelectedItems([...selectedItems, data]);
                                                } else {
                                                    setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== data));
                                                }
                                            }}
                                        />

                                        <div className="staffProfileList col-md-8">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <div className="image">
                                                            <img
                                                                src={
                                                                    data?.userDetails?.profile != null || data?.userDetails?.profile != undefined
                                                                        ? ProfilePath
                                                                        : DefaultImage
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 dflex alc">
                                                        <div className="text fw-bold Aeonik-Bold">
                                                            <span className="cursorPointer"  onClick={(e)=>buttonviewHandlerpages(data)}>

                                                                {data?.userDetails?.full_name}

                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 dflex alc">
                                                        <div className="text">
                                                            <p className="mb-0 blackText">
                                                                <a onClick={(e) => buttonviewHandlerpage(data)} className="blackText">
                                                                    Manage Permission <i class="fa-solid fa-angles-right"></i>
                                                                </a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="icon backgroundYellow" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            <i class="fa-solid fa-trash-can blackText"></i>
                                        </div>

                                    </div>
                                </div>
                            );
                        })}
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {" "}
                    {staffList?.length == 0
                        ? "No Records found"
                        : ""}
                </div>
                <div className="row my-5">
                    <div className="col">
                        <nav aria-label="Page navigation example" class="paginationRow">
                            {totalPages > 1 ? (
                                <ReactPaginate
                                    breakLabel={"..."}
                                    nextLabel={"Next >"}
                                    onPageChange={handlePageChange}
                                    pageCount={totalPages}
                                    previousLabel={"< Back"}
                                    renderOnZeroPageCount={null}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    containerClassName={"pagination"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            ) : null}
                        </nav>
                    </div>
                </div>
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-body ">
                                <div className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <i class="fa-solid fa-xmark"></i>
                                </div>
                                <h3>Delete confirmation</h3>
                                <div className="otpBox">
                                    <p>Are you want to delete staff</p>
                                </div>
                                <button className="btn btn-main btn-height" data-bs-dismiss="modal" aria-label="Close" onClick={() => _handleDelete()}>
                                    Yes
                                </button>
                                &nbsp;
                                <button className="btn btn-white-outline btn-height " data-bs-dismiss="modal" aria-label="Close">
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <Footer /> */}
        </div>
    );
}

export default ManageStaff;
