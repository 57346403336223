import HomeHeader from "../../homePages/common/HomeHeader";
import { useParams } from "react-router-dom";
import {
  getStaffUsersById,
  UpdateStatuss,
} from "../../RestoInterface/Service/Auth.service";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { API_BASEURL } from "../../../environment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DefaultImage from "../../../assets/images/defaultimage.png";
import { Modal } from "react-bootstrap";

function StaffViewProfile() {
  const dispatch = useDispatch();
  const userId = useParams();
  const _id = userId.id;
  const [viewProfileData, setViewProfileData] = useState();
  const [totalTipHistoryList, setTotalTipHistoryList] = useState();
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  /*------@Main Checboxes------- */
  const [checkboxes, setCheckboxes] = useState([
    { id: 1, name: "Manage Pubstar", checked: false },
    { id: 2, name: "Invitation Request", checked: false },
    { id: 3, name: "Group Tip", checked: false },
  ]);
  const [showModal, setShowModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  /*------@Handler Function for Main Checboxes------- */
  const handleCheckboxChange = (id, checked) => {
    const updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.id === id
        ? { ...checkbox, checked: !checkbox.checked }
        : checkbox
    );
    setCheckboxes(updatedCheckboxes);
    setSelectAll(updatedCheckboxes.every((checkbox) => checkbox.checked));

    const managePubstarCheckbox = checkboxes.find(
      (checkbox) => checkbox.id === 1
    );
    const editCheckbox = managestaff_checkboxes.find(
      (checkbox) => checkbox.id === 1
    );
    const deleteCheckbox = managestaff_checkboxes.find(
      (checkbox) => checkbox.id === 2
    );
    if (id === 1) {
      if (managePubstarCheckbox.checked) {
        const updatedCheck = managestaff_checkboxes.map((checkbox) => ({
          ...checkbox,
          checked: !checkbox.checked,
        }));
        setManagestaff_Checkboxes(updatedCheck);
        setManageStaff_SelectAll(true);
      } else if (editCheckbox.checked && deleteCheckbox.checked) {
        const updatedCheck = checkboxes.map((checkbox) =>
          checkbox.id === 1 ? { ...checkbox, checked: true } : checkbox
        );
        setCheckboxes(updatedCheck);
        setSelectAll(true);
      } else {
        const updatedCheck = managestaff_checkboxes.map((checkbox) => ({
          ...checkbox,
          checked: !checkbox.checked,
        }));
        setManagestaff_Checkboxes(updatedCheck);
        setManageStaff_SelectAll(false);
      }
    }
  };
  /*------@Handler Function for Select All Checbox------- */
  const handleSelectAllChange = () => {
    const updatedCheckboxes = checkboxes.map((checkbox) => ({
      ...checkbox,
      checked: !selectAll,
    }));
    setCheckboxes(updatedCheckboxes);
    setSelectAll(!selectAll);

    const updatedCheck = managestaff_checkboxes.map((checkbox) => ({
      ...checkbox,
      checked: !selectAll,
    }));
    setManagestaff_Checkboxes(updatedCheck);
  };
  // ---------------------------------------------------------
  /*------@Manage Staff Checboxes------- */
  const [managestaff_checkboxes, setManagestaff_Checkboxes] = useState([
    { id: 1, name: "Permissions", checked: false },
    { id: 2, name: "Delete", checked: false },
  ]);
  const [managestaff_selectAll, setManageStaff_SelectAll] = useState(false);
  /*------@Handler Function for Manage Staff Checboxes------- */
  const handleManageStaff_CheckboxChange = (id) => {
    const updatedCheckboxes1 = managestaff_checkboxes.map((checkbox) =>
      checkbox.id === id
        ? { ...checkbox, checked: !checkbox.checked }
        : checkbox
    );
    setManagestaff_Checkboxes(updatedCheckboxes1);
    setManageStaff_SelectAll(
      updatedCheckboxes1.every((checkbox) => checkbox.checked)
    );

    const editCheckbox = updatedCheckboxes1.find(
      (checkbox) => checkbox.id === 1
    );
    const deleteCheckbox = updatedCheckboxes1.find(
      (checkbox) => checkbox.id === 2
    );

    if (editCheckbox.checked && deleteCheckbox.checked) {
      const updatedCheckboxes = checkboxes.map((checkbox) =>
        checkbox.id === 1
          ? { ...checkbox, checked: !checkbox.checked }
          : checkbox
      );
      setCheckboxes(updatedCheckboxes);
      setSelectAll(updatedCheckboxes.every((checkbox) => checkbox.checked));
    } else {
      const updatedCheckboxes = checkboxes.map((checkbox) =>
        checkbox.id === 1 ? { ...checkbox, checked: false } : checkbox
      );
      setCheckboxes(updatedCheckboxes);
      setSelectAll(false);
    }
  };

  const handleManageStaff_SelectAllChange = () => {
    const updatedCheckboxes1 = managestaff_checkboxes.map((checkbox) => ({
      ...checkbox,
      checked: !managestaff_selectAll,
    }));
    setManagestaff_Checkboxes(updatedCheckboxes1);
    setManageStaff_SelectAll(!managestaff_selectAll);
  };

  const navigate = useNavigate();
  const userdata = useSelector((state) => state);
  let retroId = userdata?.login?.data?.result._id;

  const getInitialState = () => {
    const value = "1";
    return value;
  };
  const getRoleInitialState = () => {
    const role_value = "4";
    return role_value;
  };
  const [status, setStatus] = useState(getInitialState);
  const [role_value, setRole_Value] = useState(getRoleInitialState);

  const handleChange = (e) => {
    setStatus(e.target.value);
  };
  const handleRoleChange = (e) => {
    setRole_Value(e.target.value);
  };

  const [input, setInput] = useState({
    manage_staff_edit: 1,
    manage_staff_delete: 1,
    invitation_request: 1,
    tip_management: 1,
  });
  /*--------@Main useEffect hook to get Api Data-------- */
  useEffect(() => {
    _getStaffUserbyId();
    // _getTippingHistoryList();
  }, [dispatch]);

  /*--------@APi call to get particular staff Data-------- */
  const _getStaffUserbyId = () => {
    getStaffUsersById(
      { _id: _id },
      (data) => {
        setViewProfileData(data);
        patchvalue(data);
      },
      dispatch
    );
  };
  /*--------@Setting checboxes checked or unchecked depending on api data conditions-------- */
  const patchvalue = (data) => {
    //For main Checkboxes
    checkboxes.map((ele) => {
      if (ele.id === 2) {
        ele.checked = data?.invitation_request;
      }
      if (ele.id === 3) {
        ele.checked = data?.tip_management;
      }
    });
    //For Manage Staff checkboxes and select all
    managestaff_checkboxes.map((ele) => {
      if (ele.id === 1) {
        ele.checked = data?.manage_staff_edit;
      }
      if (ele.id === 2) {
        ele.checked = data?.manage_staff_delete;
      }
    });
    if (data?.manage_staff_edit && data?.manage_staff_delete) {
      checkboxes.map((ele) => {
        if (ele.id === 1) {
          ele.checked = true;
        }
      });
      if (data?.invitation_request && data?.tip_management) {
        setSelectAll(true);
      }
    }
  };

  let ProfilePath = `${API_BASEURL}/public/profile/${viewProfileData?.staffDetails?.profile}`;

  const buttonviewHandlerpage = async (data) => {
    navigate(`/resto/tippinghistory/${data?._id}`);
  };

  /*----------@Submit Handler Function----------- */
  const UpdateStatusHandler = async () => {
    let reqdata = {
      _id,
      status,
      manage_staff_edit: 1,
      manage_staff_delete: 1,
      invitation_request: 1,
      tip_management: 1,
    };
    managestaff_checkboxes.map((data) => {
      if (data?.name === "Permissions") {
        reqdata.manage_staff_edit = data?.checked ? 1 : 0;
      } else {
        reqdata.manage_staff_delete = data?.checked ? 1 : 0;
      }
    });

    checkboxes.map((data) => {
      if (data?.name === "Invitation Request") {
        reqdata.invitation_request = data?.checked ? 1 : 0;
      } else if (data?.name === "Group Tip") {
        reqdata.tip_management = data?.checked ? 1 : 0;
      }
    });

    const res = await UpdateStatuss(reqdata);
    if (res?.data?.status == true) {
      
      toast.success(
        "Updated successfully",
        {
          theme: "dark",
       
          position: toast.POSITION.BOTTOM_RIGHT,
        },
        { autoClose: 1000 }
      );
      setShowModal(true)
      
    }
  };

  var total = 0;
  for (var i = 0; i < totalTipHistoryList?.length; i++) {
    total += totalTipHistoryList[i]?.total_tip_amount;
  }
  const navigatetoback = () => {
    navigate("/resto/managestaff");
  };
  return (
    <div className="staffProfile">
      <HomeHeader />
      <div className="container">
        <div className="row my-4">
          <div className="col direction">
            <p className="mb-0">
              <a onClick={navigatetoback}>Manage Pubstar</a>{" "}
              <i className="fa-solid fa-angles-right"></i>
              <span>{viewProfileData?.staffDetails?.full_name}</span>
            </p>
          </div>
        </div>
        <div className="row profileData">
          <div className="col-lg-6">
            <div className="profileContent">
              <div className="image">
                <img
                  src={
                    viewProfileData?.staffDetails?.profile != null ||
                    viewProfileData?.staffDetails?.profile != undefined
                      ? ProfilePath
                      : DefaultImage
                  }
                  alt=""
                />
              </div>
              <div className="text">
                <h1>{viewProfileData?.staffDetails?.full_name}</h1>
                <p>
                  <i className="fa-solid fa-envelope"></i>{" "}
                  {viewProfileData?.staffDetails?.email}
                </p>
                <p>
                  <i className="fa-solid fa-phone"></i> +44{" "}
                  {viewProfileData?.staffDetails?.contact_number}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6  " style={{padding:"70px"}}>
            <div className="blackBorder borderRadius">
            <div className="prices jcc dflex alc" style={{height:"45px",cursor:"pointer"}}  onClick={(e) =>
                    buttonviewHandlerpage(viewProfileData?.staffDetails)
                  }>
              <div className="link ">
                <a
                 
                >
                  View Tipping History{" "}
                  <i className="fa-solid fa-angles-right"></i>
                </a>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div className="row profileOptions">
          <div className="col">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="status">Select Status</label>
                <select
                  name=""
                  id=""
                  className="form-select"
                  value={status}
                  onChange={handleChange}
                >
                  <option value="1">Active</option>
                  <option value="4">Not Active</option>
                </select>
              </div>
              {/* <div className="col-md-6">
                                <label htmlFor="roles">Select roles</label>
                                <select name="" id="roles" className="form-select" value={role_value} onChange={handleRoleChange}>
                                    <option value="owner">Pub owner</option>

                                    <option value="bartender">Pub star</option>
                                </select>
                            </div> */}
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <h4>Assign Permissions</h4>
              </div>
              <div className="col-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                    id="selectAll"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Select All
                  </label>
                </div>
              </div>
            </div>
            <div className="row py-4">
              {checkboxes &&
                checkboxes?.map((data, index) => {
                  return (
                    <div key={index} className="col-lg-2 col-md-4 mt-4 mt-lg-0">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={data.checked}
                          onChange={() =>
                            handleCheckboxChange(data.id, data.checked)
                          }
                          id={`checkbox-${data.id}`}
                        />
                        <label
                          className="form-check-label fw-bold"
                          htmlFor="flexCheckDefault"
                        >
                          {data?.name}
                        </label>
                      </div>

                      {data.id == 1 ? (
                        <>
                          {managestaff_checkboxes &&
                            managestaff_checkboxes?.map((element, index) => {
                              return (
                                <div key={index} className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={element.checked}
                                    onChange={() =>
                                      handleManageStaff_CheckboxChange(
                                        element.id
                                      )
                                    }
                                    id={`checkbox-${element.id}`}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    {element?.name}
                                  </label>
                                </div>
                              );
                            })}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="buttonsRow">
                  <div className="navBtn"></div>
                  
                  <div className="btnGroups">
                  {/* <button
                      className="btn btn-main btn-height"
                      
                      onClick={() => {navigate("/resto/managestaff");}}
                    >
                      Cancel
                    </button> */}
                    <button
                      className="btn btn-main btn-height"
                      // onClick={()=>setShowModal(true)}
                      onClick={UpdateStatusHandler}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal show={showModal}
        //  onHide={() => setShowModal(false)}
         >
          {/* <Modal.Header closeButton>
            <Modal.Title>
              <h3>Payment Processing</h3>
            </Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            <div className="otpBox">
              <h5>{viewProfileData?.staffDetails?.full_name} permissions have been updated</h5>
            </div>
            <div className="payment-btn">
              {/* <button
                variant="secondary"
                className="btn btn-white-outline btn-height"
                onClick={() => {
                  setShowModal(false);
                  // handleCancel();
                }}
              >
                No
              </button> */}
              <button
                variant="primary"
                className="btn btn-main btn-height"
                onClick={() => {navigate("/resto/managestaff");}}
                
              >
                Ok
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default StaffViewProfile;
