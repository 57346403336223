import React from 'react'

// import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useSelector } from 'react-redux';
import LoaderUI from '../loader/loader';

const PrivateLayout = ({ children }) => {

    let isLoading = useSelector(state => state.loader.isLoading);
    return (
        <div className="login">
            <ToastContainer />
            <section className="login-section">
                <div className="login-inner">
                    <div className="login-left">
                        {isLoading && <LoaderUI
                            loader={isLoading}
                            overlay={true}
                            overlayRadius='rounded-10'
                            FullWindow={true}
                            color="danger"
                            animation='border'
                            customSize="lg"
                        />}
                        {children}
                    </div>
                  
                </div>
            </section>
        </div>
    )
}

export default PrivateLayout