import React from 'react';

import './progressbar.scss';

const customFontStyle = {
    fontWeight: '500'
}
function Progressbar({ siteName, count, color, percent }) {
    const progress_style = {
        width: percent+'%',
        background: color || 'black'
    }
   
    return (
        <>
            <div className="progress_wrapper" style={{color: 'black'}}>
                <span>{siteName}</span>
                {/* <span>{count}</span>
                 */}
            </div>
            <div className="progress_track" >
                <div className='progress' style={progress_style}>

                </div>
            </div>
        </>
    );
}

export default Progressbar;