import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import HomeHeader from "../../homePages/common/HomeHeader";
import {
  getByuserId,
  AddStafftipping,
  checkPaypalPayment,
  getStripePaypalCharge,
} from "../../customerInterface/Service/Auth.Service";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_BASEURL } from "../../../environment";
import "react-toastify/dist/ReactToastify.css";
import { Card, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import DefaultImage from "../../../assets/images/defaultimage.png";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";

function StaffTipping() {
  const userId = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState();
  // console.log(
  //   "userDetails?.staffDetails?.gatewayPlatform,",
  //   userDetails?.staffDetails?.gatewayPlatform
  // );
  const [payNext, setPaynext] = useState(false);
  const [customer_rating_to_staff, setCustomer_rating_to_staff] = useState(0);
  const userdata = useSelector((state) => state);
  const [showModel, setShowModel] = useState(false);
  const [payable, setpayable] = useState("");

  const [total, setTotal] = useState(0);
  const [error, setErrors] = useState({});
  const [orderID, setOrderID] = useState("");
  const [charges, setCharges] = useState({});
  const [gateways, setGateways] = useState({});
  const [staffTiptotal, setStaffTipTotal] = useState(0);
  const resto_id = userDetails?.resto_id;
  const staff_id = userDetails?.staff_id;
  const email = userDetails?.staffDetails?.email;
  const staff_tip_amount = staffTiptotal;
  let customerId = userdata?.login?.data?.result?._id;
  let LoggedIn = userdata?.login?.isLoggedIn;
  let loginRole = userdata?.login?.data?.role;
  let guestTipvar = 0;

  {
    LoggedIn == true ? (guestTipvar = 0) : (guestTipvar = 1);
  }

  const [values, set_values] = useState({
    customer_id: customerId,
    tip_amount: 5,
    donation_amount: 0,
    customer_comment: "",
    is_customer_commented: 1,
    is_group_tip: 1,
    group_tip_status: "",
    payment_amount: "",
    guest_email: "",
    guest_name: "",
    guest_mobile: "",
    is_guest_registerd_user: 1,
    is_guest_tip: guestTipvar,
  });

  useEffect(() => {
    getByusersId();
    __getStripePaypalCharge();
    if (loginRole == 0) {
      navigate("/super-admin/dashboard");
    }
    const isValidCardName = (cardName) => {
      if (!cardName) {
        return false; // Card name is required
      }

      const nameRegex = /^[A-Za-z ]+$/;

      if (!nameRegex.test(cardName)) {
        return false; // Card name should only contain alphabetic characters and spaces
      }

      if (cardName.length < 2) {
        return false; // Card name should have a minimum length of 2 characters
      }

      return true; // Card name is valid
    };
    calc_total(values);
  }, [dispatch]);

  const getByusersId = () => {
    getByuserId(
      { _id: userId.id },
      (data) => {
        for (let method of data?.data?.result?.staffDetails?.gatewayPlatform) {
          gateways[method.type] =
            method.status === "Pending" || method.status === "Failed"
              ? false
              : true;
        }
        setUserDetails(data?.data?.result);
      },
      dispatch
    );
  };
  const __getStripePaypalCharge = () => {
    getStripePaypalCharge((data) => {
      for (let charge of data?.result)
        if (charge.type == "Stripe") {
          charges.stripeCharges = charge.servicefees;
          charges.amount = charge.amount;
        } else if (charge.type == "Paypal") {
          charges.paypalCharges = charge.servicefees;
        }
    }, dispatch);
  };

  let ProfilePath = `${API_BASEURL}/public/profile/${userDetails?.staffDetails?.profile}`;

  useEffect(() => {
    console.log("loggedin----------------", LoggedIn);
  }, []);

  if (userId.type == "staff") {
    values.is_group_tip = 0;
  } else {
    values.is_group_tip = 1;
  }

  const ratingChanged = (newRating) => {
    setCustomer_rating_to_staff(newRating);
  };

  const values_handler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let newErrors = { ...error }; // Create a copy of errors

    const newValues = {
      ...values,
      [name]: value,
    };

    set_values(newValues); // Calling the method to sum the value
    calc_total(newValues);
    tipamountstaff(newValues);
    if (newErrors[name]) {
      delete newErrors[name];
      setErrors(newErrors);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const calc_total = (newValues) => {
    const { tip_amount, donation_amount } = newValues;
    const newTotal = Number(tip_amount) + Number(donation_amount);
    setTotal(newTotal);
  };

  const tipamountstaff = (newValues) => {
    const { tip_amount, service_charge } = newValues;
    setStaffTipTotal(parseFloat(tip_amount).toFixed(2));
  };

  const nextButtonHandler = async (e) => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      setErrors(newErrors);
      setShowModel(true);
    } else {
      setErrors(newErrors);
    }
  };
  function showPaynext(e) {
    setpayable(e);
    setPaynext(true);
    setShowModel(false);
  }

  const buttonHandler = async (e) => {
    values.paymentType = e;
    values.feesStatus = payable;
    values.customerFullname =
      LoggedIn == true
        ? userdata?.login?.data?.result?.full_name
        : values.guest_name;
    values.paymentAmount = payable
      ? total
      : total - (total / 100) * charges?.stripeCharges;
    values.totalAmount = payable
      ? parseFloat(parseFloat(total) + parseFloat(fullPaymentAmount)).toFixed(2)
      : total;
    values.email =
      LoggedIn == true ? userDetails?.staffDetails?.email : values.guest_email;
    values.full_name = userDetails?.staffDetails?.full_name;
    values.customerEmailAdd =
      LoggedIn == true
        ? userdata?.login?.data?.result?.email
        : userDetails?.staffDetails?.email;

    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      setErrors(newErrors);
      const total_tip_amount = total;
      // console.log("totalTipAmount", total_tip_amount)
      const res = await AddStafftipping(
        resto_id,
        values.customer_id,
        values.tip_amount,
        total_tip_amount,
        staff_id,
        values.donation_amount,
        values.service_charge,
        values.customer_comment,
        values.is_customer_commented,
        values.is_group_tip,
        customer_rating_to_staff,
        staff_tip_amount,
        values.guest_email,
        values.guest_name,
        values.guest_mobile,
        values.is_guest_registerd_user,
        values.is_guest_tip,
        userDetails?.staffDetails?.gatewayPlatform,
        values.paymentType,
        values.feesStatus,
        values.group_tip_status,
        values.customerFullname,
        values.paymentAmount,
        values.totalAmount,
        values.email,
        values.full_name,
        values.customerEmailAdd,
        dispatch
      );

      // setPaynext(false);
      if (res?.data.status == true) {
        if (res?.data?.data?.url) {
          console.log("id--------", res?.data?.data?.user_transaction);
          localStorage.setItem(
            "transactionId",
            res?.data?.data?.user_transaction
          );
          const object_id = localStorage.getItem("transactionId");
          console.log("object_id--------", object_id);
          window.location.href = res?.data?.data?.url;
        } else {
          setOrderID(res?.data?.data?.id);
        }
      }
    } else {
      setErrors(newErrors);
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!values.tip_amount) {
      errors.tip_amount = "Tip Amount is required";
    } else if (values.tip_amount <= 0) {
      errors.tip_amount = "Please enter valid tip amount";
    }

    if (values.donation_amount && values.donation_amount < 0) {
      errors.donation_amount = "Please enter valid donation amount";
    }

    if (LoggedIn == false) {
      if (!values.guest_name) {
        errors.guest_name = "Full name is required";
      }
      if (!values.guest_email) {
        errors.guest_email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(values.guest_email)) {
        errors.guest_email = "Email is invalid";
      }
      // if (values.guest_mobile) {
      //   if (

      //     values.guest_mobile.length < 13
      //   ) {
      //     errors.guest_mobile = "Contact number is invalid";
      //   }
      // }
    }

    return errors;
  };

  const handeblur = () => {};

  const updatePaypalPayment = async (payload) => {
    payload.customerFullname =
      LoggedIn == true
        ? userdata?.login?.data?.result?.full_name
        : values.guest_name;
    payload.paymentAmount = payable
      ? (total / 100) * charges?.stripeCharges + total
      : total - (total / 100) * charges?.paypalCharges;
    payload.totalAmount = total;
    payload.email =
      LoggedIn == true ? userDetails?.staffDetails?.email : values.guest_email;
    payload.full_name = userDetails?.staffDetails?.full_name;
    payload.customerEmailAdd =
      LoggedIn == true
        ? userdata?.login?.data?.result?.email
        : userDetails?.staffDetails?.email;
    await checkPaypalPayment(
      payload,
      (res) => {
        if (res.status) {
          navigate(`/customer/paymentsuccess`);
        } else {
          toast.error("Payment Failed", {
            theme: "dark",
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        }
      },
      dispatch
    );
  };
  const handleChange1 = (value, name) => {
    set_values({
      ...values,
      [name]: value,
    });
  };
  const buttonHandlerpage = async (data) => {
    navigate(`/customer/staffprofile/${data?._id}`);
  };
  let fullPaymentAmount = parseFloat(
    charges.amount + (charges?.stripeCharges / 100) * total
  ).toFixed(2);

  return (
    <div className="staffProfile">
      <HomeHeader />
      <div className="container">
        <div className="row my-4">
          <div className="col direction">
            <p class="mb-0 whiteText">
              <a
                onClick={() => navigate(`/customer/staffprofile/${userId.id}`)}
              >
                Home
              </a>{" "}
              <i class="fa-solid fa-angles-right whiteText"></i>{" "}
              <span className="whiteText">
                {userDetails?.staffDetails?.full_name}
              </span>
            </p>
          </div>
        </div>
        <div className="row profileData staffPostData">
          <div className="col-lg-6">
            <div className="profileContent">
              <div className="image">
                <img
                  src={
                    userDetails?.staffDetails?.profile != null ||
                    userDetails?.staffDetails?.profile != undefined
                      ? ProfilePath
                      : DefaultImage
                  }
                  alt=""
                />
              </div>
              <div className="text">
                <h1>{userDetails?.staffDetails?.full_name}</h1>
                <p>PubStar</p>
                <div className="review">
                  <div className="star"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6"></div>
        </div>
        {LoggedIn == false && !payNext ? (
          <div className="ProfileSetting">
            <div className="container">
              <div className="row profileSettingConent">
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="inputField">
                        <label htmlFor="userName">Name</label>
                        <div className="inputBox blackBorder">
                          <input
                            type="text"
                            className="form-control px-3"
                            placeholder="Name"
                            name="guest_name"
                            onChange={values_handler}
                            onBlur={(e) => handeblur()}
                          />
                        </div>
                        {error.guest_name && (
                          <span className="text-danger">
                            {error.guest_name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="inputField">
                        <label htmlFor="userName">Email</label>
                        <div className="inputBox">
                          <input
                            type="email"
                            className="form-control px-3"
                            placeholder="Email"
                            name="guest_email"
                            onChange={values_handler}
                            onBlur={(e) => handeblur()}
                          />
                        </div>
                        {error.guest_email && (
                          <span className="text-danger">
                            {error.guest_email}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      {/* <div className="inputField1 ">
                    <label htmlFor="contact">Contact No</label>
                    <div className="inputBox  blackBorder">
                     <PhoneInput
                          className="form-control px-3"
                          name="contact_number"
                          international
                          maxLength={15}
                          defaultCountry="RU"
                          onChange={(e)=>handleChange1(e,"contact_number")}
                        
                        />
                    </div>
                    {error.guest_mobile && (
                          <span className="text-danger">
                            {error.guest_mobile}
                          </span>
                        )}
                  </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row TippingBox ">
          <div className="col-12">
            {/* <div className="row jcc dflex pt-3">
              <div className="col-4">
                {orderID && (
                  <PayPalScriptProvider
                    options={{
                      clientId:
                        "AbGGlInGbVMyv90a_BPqskFcajM4JY1tbhXd5hpUZMfGUrAGwVXquAxu55i3t8-sOkFxJwsLcKCDd-rv",
                      currency: "GBP",
                    }}
                  >
                    <PayPalButtons
                      style={{
                        shape: "rect",
                        //color:'blue' change the default color of the buttons
                        layout: "vertical", //default value. Can be changed to horizontal
                      }}
                      createOrder={() => orderID}
                      onApprove={async (data, actions) => {
                        updatePaypalPayment(data);
                      }}
                    />
                  </PayPalScriptProvider>
                )}
              </div>
            </div> */}
            {!orderID && !payNext && (
              <>
                <div className="row">
                  <div className="col-12">
                    <div className="inputField-inline">
                      <label for="tipping" className="form-label">
                        Tip Amount (£) :
                      </label>
                      <div className="inputBox">
                        <input
                          type="number"
                          name="tip_amount"
                          className="form-control"
                          id="inputEmail4"
                          value={values.tip_amount}
                          onChange={values_handler}
                          onBlur={(e) => handeblur()}
                        />
                        {error.tip_amount && (
                          <span className="text-danger">
                            {error.tip_amount}
                          </span>
                        )}
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id="tooltip2">
                              GoTipMe does not charge bartenders commissions or
                              fees.
                            </Tooltip>
                          }
                        >
                          <i class="fa-solid fa-circle-info"></i>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <div className="inputField-inline">
                      <label for="donation" className="form-label">
                        Platform Support (£) <span>(Optional)</span> :
                      </label>
                      <div className="inputBox">
                        <input
                          type="number"
                          class="form-control"
                          id="donation"
                          name="donation_amount"
                          onChange={values_handler}
                        />
                        {error.donation_amount && (
                          <span className="text-danger">
                            {error.donation_amount}
                          </span>
                        )}
                       
                      </div>
                    </div>
                  </div> */}

                  <div className="col-12">
                    <div className="inputField-inline bgLight">
                      <label for="totalAmount" className="form-label">
                        Total Amount (£) :
                      </label>
                      <div className="inputBox">
                        <input
                          disabled
                          type="number"
                          class="form-control plane"
                          id="totalAmount"
                          value={
                            values?.donation_amount
                              ? total.toFixed(2)
                              : values?.tip_amount
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-12">
                    <div className="inputField-inline">
                      <label className="form-label">Rating :</label>
                      <div className="inputBox">
                        <ReactStars
                          count={5}
                          onChange={ratingChanged}
                          size={30}
                          // isHalf={true}
                          emptyIcon={<i className="far fa-star"></i>}
                          halfIcon={<i className="fa fa-star-half-alt"></i>}
                          fullIcon={<i className="fa fa-star"></i>}
                          activeColor="#ffd700"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="inputField-inline align-items-start">
                      <label for="totalAmount" className="form-label">
                        Add Feedback :
                      </label>
                      <div className="inputBox">
                        <textarea
                          name="customer_comment"
                          id=""
                          cols="50"
                          rows="4"
                          className="form-control"
                          onChange={values_handler}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {payNext && (
              <>
                <Card>
                  <div className="row p-4">
                    <div className="col-12">
                      <h3 className="Aeonik-Regular">Payment Method</h3>
                    </div>

                    {/* {gateways.Paypal && ( */}
                    <>
                      <div className="col-md-1 col-12 dflex mt-2">
                        <img
                          src="https://www.trustap.com/_next/static/media/trustap_logo.dee0e5cb.png"
                          height={30}
                          width={100}
                          border="0"
                          alt="Trustap Logo"
                        />
                        {/* <b> PayPal : </b> */}
                        {/* <h3>Trustap</h3> */}
                      </div>
                      <div className="col-md-3 col-12 dflex mt-2">
                        <p>
                          Net Amount : £ {total.toFixed(2)}
                          <br />
                          {payable && (
                            <>
                              Processing Charges ({charges?.paypalCharges}% ): £{" "}
                              {parseFloat(
                                (total / 100) * charges?.paypalCharges
                              ).toFixed(2)}
                              <br />
                            </>
                          )}
                          Net Payable Amount : £{" "}
                          {parseFloat(
                            payable
                              ? (total / 100) * charges?.paypalCharges + total
                              : total
                          ).toFixed(2)}
                        </p>
                      </div>
                      <div className="col-md-2 col-12">
                        <div className="inputField">
                          <button
                            className="btn btn-main btn-height"
                            style={{ width: "70%" }}
                            onClick={() => buttonHandler("Paypal")}
                          >
                            Pay
                          </button>
                        </div>{" "}
                      </div>
                    </>
                    {/* )} */}
                  </div>
                </Card>
              </>
            )}
          </div>
        </div>
        {!orderID && !payNext && (
          <div className="row buttonsRow">
            <div className="navBtn"></div>
            <div className="btnGroups">
              <button
                className="btn btn-white-outline btn-height"
                onClick={(e) => buttonHandlerpage(userDetails?.staffDetails)}
              >
                Cancel
              </button>
              <button
                className="btn btn-main btn-height"
                style={{ width: "114.32px" }}
                onClick={nextButtonHandler}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
      <Modal show={showModel} onHide={() => setShowModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {/* <div className="modal-heading"> */}
            <h3>Payment</h3>
            {/* </div> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body ">
            <b>
              Would you like to pay the{" "}
              {gateways.Stripe &&
                `stripe processing fees for the bartender, £${parseFloat(
                  charges.amount + (charges?.stripeCharges / 100) * total
                ).toFixed(2)}`}{" "}
              {gateways.Paypal && gateways.Stripe && "or"}{" "}
              {gateways.Paypal &&
                ` processing fee for the bartender, £${parseFloat(
                  (total / 100) * charges?.paypalCharges
                ).toFixed(2)}`}{" "}
              ?
            </b>
            <div className="dflex jcc mt-4" style={{ gap: 15 }}>
              <button
                className="btn btn-main btn-height"
                onClick={() => showPaynext(false)}
              >
                No
              </button>
              <button
                className="btn btn-main btn-height"
                onClick={() => showPaynext(true)}
              >
                Yes
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default StaffTipping;
