// import RIHeader from "./RIHeader";
// import Footer from "./Footer";
import HomeHeader from "../../homePages/common/HomeHeader";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InvitationRequestList } from "../../../services/auth.service";
import AuthService from "../../../services/auth.service";
import { useNavigate } from "react-router-dom";
import {
  pagination,
} from "../../../components/common/utils/message";
import {
  AcceptrequestList,
  RejectrequestList,
} from "../../../services/auth.service";
import ReactPaginate from "react-paginate";
import DefaultImage from "../../../assets/images/defaultimage.png";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_BASEURL } from "../../../environment";
import { SocketContext } from "../../../socket/SocketContext";

function SIInvitationRequest() {
  const [historyState, setHistoryState] = useState(1);
  const [userData, setUserData] = useState(0);
  const [updateId, setUpdateId] = useState();
  const [rejectId, setRejectId] = useState();
  const [rejectPayload, setRejectPayload] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [staffId, setStaffId] = useState();
  const [rejectstaffId, setRejectStaffId] = useState();

  const socket = useContext(SocketContext)

  const [resPayload, setResPayload] = useState();
  const [limit, setLimit] = useState(pagination.projectLimit);
  const [page, setPage] = useState(pagination.page);
  const [totalPages, settotalPages] = useState();
  const [acceptTotalPages, setAcceptTotalPages] = useState();
  const [rejectTotalPages, setRejectTotalPages] = useState();

  const [input, setInput] = useState({
    _id: updateId,
    is_closed: 1,
    status: 1,
  });

  const [accept, setAccept] = useState({
    _id: staffId,
    staff_request_accepted: 1,
  });

  const [rejectinput, setrejectInput] = useState({
    _id: rejectId,
    is_closed: 1,
    status: 2,
  });
  //
  const onTabClick = (tabId) => () => {
    setHistoryState(tabId);
  };
  const userdata = useSelector((state) => state);

  // let user_id = userdata?.login?.data?.result._id;
  let loginRole = userdata?.login?.data?.role;
  let user_id;
  if (loginRole == 2) {
    user_id = userdata?.login?.data?.result?._id;
  }
  if (loginRole == 0) {
    user_id = userdata?.adminrestro?.data?._id;
  }

  useEffect(() => {
    _getAcceptrequestListing();
    _getrejectListing();
    _getRequestInvitationListing();
  }, [dispatch]);

  const _getRequestInvitationListing = () => {
    InvitationRequestList(
      { resto_id: user_id, page, limit },
      (data) => {
        setUserData(data?.data?.result?.docs);
        settotalPages(data?.data?.result?.totalPages);
      },
      dispatch
    );
  };

  const handleRequestInvitationPageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await InvitationRequestList(
      { resto_id: user_id, page: currentPage, limit },
      (response) => {
        setUserData(response?.data?.result?.docs);
      },
      dispatch
    );
  };

  const _getAcceptrequestListing = () => {
    AcceptrequestList(
      { resto_id: user_id, page, limit },
      (data) => {
        setResPayload(data?.data?.result?.docs);
        setAcceptTotalPages(data?.data?.result?.totalPages);
      },
      dispatch
    );
  };
  const handleAcceptInvitationPageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await AcceptrequestList(
      { resto_id: user_id, page: currentPage, limit },
      (response) => {
        setResPayload(response?.data?.result?.docs);
      },
      dispatch
    );
  };

  const handleUpdate = (data) => {
    setUpdateId(data?._id);
    setStaffId(data?.userDetails?._id);
  };

  const handlerejectUpdate = async (data) => {
    setRejectId(data?._id);
    setRejectStaffId(data?.userDetails?._id)
  };

  const AcceptButtonhandler = async () => {
    const res = await AuthService.Acceptinvitation(
      updateId,
      input.is_closed,
      input.status
    );
    if (res.data.status == true) {
      let notificationData={
        recieverId:staffId,
        senderId:user_id,
        type:	"PubstarReqAccept"
      }
      
  
    socket.emit("request-accept", notificationData);
      toast.success(
        "Request accepted successfully",
        {
          theme: "dark",
      
          position: toast.POSITION.BOTTOM_RIGHT,
        },
        { autoClose: 1000 }
      );
      const result = await AuthService.staffAcceptinvitation(
        staffId,
        accept.staff_request_accepted
      );
      _getRequestInvitationListing();
      _getAcceptrequestListing();
    }
  };

  const RejectButtonhandler = async () => {
    const res = await AuthService.Rejectinvitation(
      rejectId,
      rejectinput.is_closed,
      rejectinput.status
    );
    if (res.data.status == true) {
      let notificationData={
        recieverId:rejectstaffId,
        senderId:user_id,
        type:	"PubstarReqReject"
      }
    socket.emit("request-reject", notificationData);

      toast.warn(
        "Request rejected successfully",
        {
          theme: "dark",
      
          position: toast.POSITION.BOTTOM_RIGHT,
        },
        { autoClose: 1000 }
      );
      _getRequestInvitationListing();
      _getrejectListing();
    }
  };

  const _getrejectListing = () => {
    RejectrequestList(
      { resto_id: user_id, page, limit },
      (data) => {
        setRejectPayload(data?.data?.result?.docs);
        setRejectTotalPages(data?.data?.result?.totalPages);
      },
      dispatch
    );
  };

  const handleRejectInvitationPageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage);
    await RejectrequestList(
      { resto_id: user_id, page: currentPage, limit },
      (response) => {
        setRejectPayload(response?.data?.result?.docs);
      },
      dispatch
    );
  };

  const buttonviewHandlerpage = async (data) => {
    navigate(`/customer/staffprofile/${data?.userDetails?._id}`);
  };
  return (
    <div className="invitationRequest">
      {/* <RIHeader /> */}
      <HomeHeader />
      <div className="container-fluid">
        <div className="row topBar">
          <div className="col">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="content">
                    <div className="heading">
                      <h3 className="mb-0">Invitation Requests</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="tabs">
            {/* <div
                className={`tab ${historyState === 0 && "active"}`}
                onClick={onTabClick(0)}
              >
                Pending Requests
                <div className="line"></div>
              </div> */}
            <div
                className={`tab ${historyState === 1 && "active"}`}
                onClick={onTabClick(1)}
              >
                Accepted
                <div className="line"></div>
              </div> 
             
             
              <div
                className={`tab ${historyState === 2 && "active"}`}
                onClick={onTabClick(2)}
              >
                Rejected
                <div className="line"></div>
              </div>
            </div>
          </div>
        </div>
        <div className={`row tabContent ${historyState !== 0 && "d-none"}`}>
          <div className="col-12">
            <div className="row">
              {userData &&
                userData?.map((data, index) => {
                  let ProfilePath = `${API_BASEURL}/public/profile/${data?.userDetails?.profile}`;
                  return (
                    <div key={index} className="col-12">
                      <div className="staffProfileList">
                        <div className="firstdata">
                          <div className="image">
                            <img
                              src={
                                data?.userDetails?.profile != null ||
                                data?.userDetails?.profile != undefined
                                  ? ProfilePath
                                  : DefaultImage
                              }
                              alt=""
                            />
                          </div>
                          <div className="text">
                            <p className="mb-0">
                              <span className="fw-bold">
                                {data?.userDetails?.full_name}
                              </span>{" "}
                              , has requested you to be added to Restaurant/Bar
                            </p>
                          </div>
                          <div className="date dflex alc">
                          <i className="fa-solid fa-mobile"></i> &nbsp;&nbsp;
                            <p className="mb-0">{data?.userDetails?.contact_number}</p>
                          </div>
                        </div>
                        <div className="secondData">
                          <button
                            className="btn btn-white-outline btn-height"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal1"
                            onClick={(e) => {
                              handlerejectUpdate(data, e.target.value);
                            }}
                          >
                            Reject
                          </button>
                          <button
                            className="btn btn-main btn-height"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={(e) => {
                              handleUpdate(data, e.target.value);
                            }}
                          >
                            Accept
                          </button>
                          <div className="link blackText">
                            <a onClick={(e) => buttonviewHandlerpage(data)} className="blackText">
                              View Profile{" "}
                              <i class="fa-solid fa-angles-right blackText"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      {userData?.length == 0
                        ? <p className="whiteText Aeonik-Regular">No Records found</p>
                        : ""}
                    </div>
            <div className="row my-5">
              <div className="col">
                <nav aria-label="Page navigation example" class="paginationRow">
                  {totalPages > 1 ? (
                    <ReactPaginate
                      breakLabel={"..."}
                      nextLabel={"Next >"}
                      onPageChange={handleRequestInvitationPageChange}
                      pageCount={totalPages}
                      previousLabel={"< Back"}
                      renderOnZeroPageCount={null}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  ) : null}
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className={`row tabContent ${historyState !== 1 && "d-none"}`}>
          <div className="col-12">
            {resPayload &&
              resPayload?.map((data, index) => {
                let ProfilePath = `${API_BASEURL}/public/profile/${data?.userDetails?.profile}`;
                return (
                  <div key={index} className="row">
                    <div className="col">
                      <div className="staffProfileList">
                        <div className="firstdata">
                          <div className="image">
                            <img
                              src={
                                data?.userDetails?.profile != null ||
                                data?.userDetails?.profile != undefined
                                  ? ProfilePath
                                  : DefaultImage
                              }
                              alt=""
                            />
                          </div>
                          <div className="text">
                            <p className="mb-0">
                              Request accepted to
                              <span className="fw-bold">
                                {" "}
                                {data?.userDetails?.full_name}
                              </span>{" "}
                              
                            </p>
                          </div>
                          <div className="date dflex alc">
                          <i className="fa-solid fa-mobile"></i> &nbsp;&nbsp;
                            <p className="mb-0">{data?.userDetails?.contact_number}</p>
                          </div>
                        </div>
                        <div className="secondData">
                          <div className="link blackText">
                            <a  className="blackText" onClick={(e) => buttonviewHandlerpage(data)} >
                              View Profile{" "}
                              <i class="fa-solid fa-angles-right blackText"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
                  <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      {resPayload?.length == 0
                        ?<p className="Aeonik-Regular whiteText"> No Records found</p>
                        : ""}
                    </div>
            <div className="row my-5">
              <div className="col">
                <nav aria-label="Page navigation example" class="paginationRow">
                  {acceptTotalPages > 1 ? (
                    <ReactPaginate
                      breakLabel={"..."}
                      nextLabel={"Next >"}
                      onPageChange={handleAcceptInvitationPageChange}
                      pageCount={acceptTotalPages}
                      previousLabel={"< Back"}
                      renderOnZeroPageCount={null}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  ) : null}
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className={`row tabContent ${historyState !== 2 && "d-none"}`}>
          <div className="col-12">
            {rejectPayload &&
              rejectPayload?.map((data, index) => {
                let ProfilePath = `${API_BASEURL}/public/profile/${data?.userDetails?.profile}`;

                return (
                  <div key={index} className="row">
                    <div className="col">
                      <div className="staffProfileList">
                        <div className="firstdata">
                          <div className="image">
                            <img
                              src={
                                data?.userDetails?.profile != null ||
                                data?.userDetails?.profile != undefined
                                  ? ProfilePath
                                  : DefaultImage
                              }
                              alt=""
                            />
                          </div>
                          <div className="text">
                            <p className="mb-0">
                              Request rejected to
                              <span className="fw-bold">
                                {" "}
                                {data?.userDetails?.full_name}
                              </span>{" "}
                              
                            </p>
                          </div>
                          <div className="date dflex alc">
                          <i className="fa-solid fa-mobile"></i> &nbsp;&nbsp;
                            <p className="mb-0">{data?.userDetails?.contact_number}</p>
                          </div>
                        </div>
                        <div className="secondData">
                          <div className="link blackText">
                            <a onClick={(e) => buttonviewHandlerpage(data)}  className="blackText">
                              View Profile{" "}
                              <i class="fa-solid fa-angles-right blackText"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
                 <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      {rejectPayload?.length == 0
                        ? <p className="Aeonik-Regular whiteText">No Records found</p>
                        : ""}
                    </div>
            <div className="row my-5">
              <div className="col">
                <nav aria-label="Page navigation example" class="paginationRow">
                  {rejectTotalPages > 1 ? (
                    <ReactPaginate
                      breakLabel={"..."}
                      nextLabel={"Next >"}
                      onPageChange={handleRejectInvitationPageChange}
                      pageCount={rejectTotalPages}
                      previousLabel={"< Back"}
                      renderOnZeroPageCount={null}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  ) : null}
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className={`row tabContent ${historyState !== 3 && "d-none"}`}>
          <div className="col-12">
            <div className="row">
              <div className="col">
                <div className="staffProfileList">
                  <div className="firstdata">
                    <div className="image">
                      <img src="./assets/startProfile.png" alt="" />
                    </div>
                    <div className="text">
                      <p className="mb-0">
                        Request sent to
                        <span className="fw-bold">The Street Social</span> ,
                        Covent Garden, London (W2CE 9DD)
                      </p>
                    </div>
                    <div className="date">
                      <p className="mb-0">1/20/2023</p>
                    </div>
                  </div>
                  <div className="secondData">
                    <button className="btn btn-white-outline">
                      Cancel Request
                    </button>
                    <div className="link blackText">
                      <a href="/"  className="blackText">
                        View Profile <i class="fa-solid fa-angles-right blackText"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-5">
              <div className="col">
                <nav aria-label="Page navigation example" class="paginationRow">
                  <ul className="pagination">
                    <li className="page-item">
                      <a className="page-link" href="/" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="/">
                        1
                      </a>
                    </li>
                    <li className="page-item active">
                      <a className="page-link" href="/">
                        2
                      </a>
                    </li>
                    <li class="page-item">
                      <a className="page-link" href="/">
                        3
                      </a>
                    </li>
                    <li class="page-item">
                      <a className="page-link" href="/" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body ">
              <div className="close" data-bs-dismiss="modal" aria-label="Close">
                <i class="fa-solid fa-xmark"></i>
              </div>
              <h3>Invitation Request</h3>
              <div className="otpBox">
                <p>Are you want to accept the invitation</p>
              </div>
              <button
                className="btn btn-main btn-height"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={AcceptButtonhandler}
              >
                Yes
              </button>
              &nbsp;
              <button
                className="btn btn-white-outline btn-height"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body ">
              <div className="close" data-bs-dismiss="modal" aria-label="Close">
                <i class="fa-solid fa-xmark"></i>
              </div>
              <h3>Invitation Request</h3>
              <div className="otpBox">
                <p>Are you want to reject this invitation</p>
              </div>
              <button
                className="btn btn-main btn-height"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={RejectButtonhandler}
              >
                Yes
              </button>
              &nbsp;
              <button
                className="btn btn-white-outline btn-height"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default SIInvitationRequest;