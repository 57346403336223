const local = {
    apiUrl: "http://13.60.14.147",
    encryptionkey: "",
    PORT: "/api",
    API_BASEURL: "http://13.60.14.147/api",
    SOCKET_URL: "wss://13.60.14.147", // Use 'wss' protocol for WebSocket
    Frontend_Url: "http://13.60.14.147", // Update to the correct frontend URL
};

// const local = {
//     apiUrl: "http://localhost:8989",
//     encryptionkey: "",
//     PORT: "/api",
//     API_BASEURL: "http://localhost:3000",
//     SOCKET_URL: "http://localhost:3000", // Use 'wss' protocol for WebSocket
//     Frontend_Url: "http://localhost:3000", // Update to the correct frontend URL
// };

// For Staging server --

const staging = {
    apiUrl: "https://gotipme.com",
    encryptionkey: "",
    PORT: "/api",
    API_BASEURL: "https://gotipme.com/api",
    SOCKET_URL: "wss://gotipme.com/api/socket", // Use 'wss' protocol for WebSocket
    Frontend_Url: "https://gotipme.com", // Update to the correct frontend URL
  };

const dev = {
    // apiUrl: "http://190.92.159.196:",
    // encryptionkey: "",
    // PORT: '9121/api/v1',
    // API_BASEURL: "http://190.92.159.196:9121",
    // SOCKET_URL: "ws://190.92.159.196:9121"
};

if (process.env.REACT_APP_ENV === "local") module.exports = local;
else if (process.env.REACT_APP_ENV === "dev") module.exports = dev;
else if (process.env.REACT_APP_ENV === "staging") module.exports = staging;
module.exports = staging;
